import { memoizeFunction, mergeStyleSets } from '@fluentui/react';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    cardLayout: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItem: 'center',
      gap: '2rem',
      marginLeft: 4,
    },
    card: {
      width: '160px',
      minHeight: '130px',
      padding: '15px',
    },
    title: {
      fontSize: '20px',
      height: '50px',
    },
  });
});
