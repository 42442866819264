import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import {
  DropdownPicker,
  GLOBALENUMS,
  LinkInput,
  TextField,
  __,
  Sidebar,
  FileList,
  PeoplePickerWithTags,
  showField,
  ExpandableSection,
  TextEditor,
  RequirementsDropdownPicker,
} from 'modeling-tool';
import { IProcessMap, Department, Role, Resource, Requirement } from '../../../../ts/interfaces';
import { useStyles } from './process-map-from-styles';
import { fetchLibrariesAction, fetchSitesAction } from '../../../../store/actions/file-actions';
import { updateProcessMapAction } from '../../../../store/actions/process-map-actions';
import { fetchDropdownDataByType } from '../../../../store/actions/dropdown-data-actions';
import { renderSelectDropDown } from '../../../../utils/process-management';
import { Button, Divider, Label } from '@fluentui/react-components';
import { Delete16Regular } from '@fluentui/react-icons';

interface ProcessMapFormProps {
  processMap: IProcessMap;
  readOnly?: boolean;
  closeSidebar: () => void;
  toggleModal: () => void;
}

const dropDownData = [
  GLOBALENUMS.DROPDOWNDATA.ORG_UNITS,
  GLOBALENUMS.DROPDOWNDATA.LOCATIONS,
  GLOBALENUMS.DROPDOWNDATA.ROLES,
  GLOBALENUMS.DROPDOWNDATA.RESOURCES,
  GLOBALENUMS.DROPDOWNDATA.REQUIREMENTS,
];

const fileExt = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

const ProcessMapForm = (props: ProcessMapFormProps & PropsFromRedux) => {
  const {
    DropdownDataReducer: { locations, orgUnits, roles, resources, requirements },
    ProcessMapReducer: { selectedProcessmap },
    processMap,
    readOnly,
    closeSidebar,
    fetchDropdownDataByType,
    fetchLibrariesAction,
    fetchSitesAction,
    toggleModal,
    updateProcessMapAction,
  } = props;
  const [id, setId] = useState<number>(-1);
  const [ready, setReady] = useState<boolean>(false);

  const isDeletingRef = useRef(false);

  const classes = useStyles();

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
  }, []);

  useEffect(() => {
    return () => {
      if (!isDeletingRef.current) {
        submitData();
      }
    };
  }, [selectedProcessmap]);

  useEffect(() => {
    if (selectedProcessmap) {
      reset();
      // Processmap is used to reduce the time required to populate the form with data
      const { responsibilities, attachments, logo, resources } = processMap;
      const formData = {
        ...processMap,
        attachments: attachments?.length ? attachments?.split('|') : [],
        logo: logo?.length ? logo?.split('|') : [],
        resources: resources?.map((resources: Resource) => resources.resourceUri) || [],
        requirementsList: processMap.requirementsList?.map((requirement: Requirement) => requirement.resourceUri) || [],
        responsibleDepartments:
          responsibilities?.departments?.map((department: Department) => department.resourceUri) || [],
        responsibleEmployees: responsibilities?.employees || '',
        responsibleRoles: responsibilities?.roles?.map((role: Role) => role.resourceUri) || [],
      };
      reset(formData);
      setId(Math.random());
      setReady(true);
    } else {
      setReady(false);
      reset();
    }
  }, [selectedProcessmap]);

  const onFinish = (values: IProcessMap) => {
    if (values) {
      updateProcessMapAction(values);
    }
  };

  const submitData = () => {
    if (readOnly) return;
    handleSubmit(
      (data) => {
        const processMapData: IProcessMap = {
          ...data,
          description: data.description,
          attachments: data.attachments?.length ? data.attachments.join('|') : null,
          id: selectedProcessmap?.id,
          logo: data.logo?.length ? data.logo.join('|') : null,
          processMapName: data.processMapName,
          requirementsList: data.requirementsList,
          responsibilities: {
            departments: data.responsibleDepartments,
            employees: data.responsibleEmployees,
            roles: data.responsibleRoles,
          },
        };
        onFinish(processMapData);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleOnDeleteMap = (e: any) => {
    isDeletingRef.current = true;
    e.stopPropagation();
    toggleModal();
  };

  const renderForm = () => {
    const showProcessName = showField('processMapName', control, readOnly);
    const showDescription = showField('description', control, readOnly);
    const showEmployee = showField('responsibleEmployees', control, readOnly);
    const showRoles = showField('responsibleRoles', control, readOnly);
    const showDepartments = showField('responsibleDepartments', control, readOnly);
    const showAnyResponsibilities = showEmployee.showField || showRoles.showField || showDepartments.showField;
    const hasValueAnyResponsibilities = showEmployee.hasValue || showRoles.hasValue || showDepartments.hasValue;

    const showRequirements = showField('requirementsList', control, readOnly);
    const showResources = showField('resources', control, readOnly);
    const showLocations = showField('locations', control, readOnly);
    const showAttachments = showField('attachments', control, readOnly);
    const showLink = showField('linkUrl', control, readOnly);
    const showAnyAssignments = showRequirements.showField || showResources.showField || showLocations.showField;
    const hasValueAnyAssignments = showRequirements.hasValue || showResources.hasValue || showLocations.hasValue;

    const showLogo = showField('logo', control, readOnly);

    return (
      <form>
        {showProcessName.showField && (
          <TextField
            labelTitle={__('title')}
            control={control}
            name={'processMapName'}
            placeholder={__('provide title')}
            readOnly={readOnly}
          />
        )}
        {showDescription.showField && (
          <ExpandableSection label={__('description')} control={control} isExpanded={showDescription.hasValue}>
            <div className={classes.wrapper}>
              <TextEditor
                control={control}
                name={'description'}
                placeholder={__('provide description')}
                readOnly={readOnly}
              />
            </div>
          </ExpandableSection>
        )}
        {showAnyResponsibilities && (
          <ExpandableSection label={__('responsibilities')} control={control} isExpanded={hasValueAnyResponsibilities}>
            {showRoles.showField && (
              <>
                <Label className={classes.label}>{__('roles')}</Label>
                <DropdownPicker
                  control={control}
                  name={'responsibleRoles'}
                  placeholder={__('provide role')}
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ROLES, roles)}
                  multiSelect
                  disabled={readOnly}
                />
              </>
            )}
            {showEmployee.showField && (
              <>
                <Label className={classes.label}>{__('employees')}</Label>
                <PeoplePickerWithTags
                  control={control}
                  selectionMode="multiple"
                  name={'responsibleEmployees'}
                  controlId={`${id}`}
                  disabled={readOnly}
                />
              </>
            )}
            {showDepartments.showField && (
              <>
                <Label className={classes.label}>{__('departments')}</Label>
                <DropdownPicker
                  control={control}
                  placeholder={__('provide organizational unit')}
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, orgUnits)}
                  name={'responsibleDepartments'}
                  multiSelect
                  disabled={readOnly}
                />
              </>
            )}
          </ExpandableSection>
        )}
        {showAnyAssignments && (
          <ExpandableSection label={__('assignments')} control={control} isExpanded={hasValueAnyAssignments}>
            {showRequirements.showField && (
              <>
                <Label className={classes.label}>{__('requirements')}</Label>
                <RequirementsDropdownPicker
                  control={control}
                  name={'requirementsList'}
                  options={requirements as any}
                  placeholder={__('please select requirements')}
                  disabled={readOnly}
                  multiSelect
                />
              </>
            )}
            {showResources.showField && (
              <>
                <Label className={classes.label}>{__('resources')}</Label>
                <DropdownPicker
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.RESOURCES, resources)}
                  control={control}
                  name={'resources'}
                  placeholder={__('provide resource')}
                  multiSelect
                  disabled={readOnly}
                />
              </>
            )}
            {showLocations.showField && (
              <>
                <Label className={classes.label}>{__('locations')}</Label>
                <DropdownPicker
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.LOCATIONS, locations)}
                  control={control}
                  name={'locations'}
                  placeholder={__('provide location')}
                  multiSelect
                  disabled={readOnly}
                />
              </>
            )}
          </ExpandableSection>
        )}
        {showAttachments.showField && (
          <ExpandableSection label={__('documents')} control={control} isExpanded={showAttachments.hasValue}>
            <div className={classes.wrapper}>
              <FileList
                control={control}
                name={'attachments'}
                fetchLibrariesAction={fetchLibrariesAction}
                fetchSitesAction={fetchSitesAction}
                disabled={readOnly}
                cutLength={true}
              />
            </div>
          </ExpandableSection>
        )}
        {showLink.showField && (
          <ExpandableSection label={__('link')} control={control} isExpanded={showLink.hasValue}>
            <div className={classes.wrapper}>
              <LinkInput control={control} readOnly={readOnly} disabled={readOnly} />
            </div>
          </ExpandableSection>
        )}
        {showLogo.showField && (
          <ExpandableSection label={__('logo')} control={control} isExpanded={showLogo.hasValue}>
            <div className={classes.wrapper}>
              <FileList
                control={control}
                name={'logo'}
                fileExt={fileExt}
                fetchLibrariesAction={fetchLibrariesAction}
                fetchSitesAction={fetchSitesAction}
                disabled={readOnly}
              />
            </div>
          </ExpandableSection>
        )}
        {!readOnly && (
          <>
            <Divider className={classes.customLineColor} />
            <Button
              appearance="subtle"
              className={classes.deleteButton}
              onClick={handleOnDeleteMap}
              icon={<Delete16Regular />}
            >
              {__('delete')}
            </Button>
          </>
        )}
      </form>
    );
  };

  return (
    <>
      {ready && (
        <Sidebar sidebarTitle={selectedProcessmap?.processMapName} onClick={closeSidebar}>
          {renderForm()}
        </Sidebar>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ DropdownDataReducer, UserReducer, ProcessMapReducer }: RootState) => ({
  DropdownDataReducer,
  UserReducer,
  ProcessMapReducer,
});

const connector = connect(mapStateToProps, {
  fetchLibrariesAction,
  fetchSitesAction,
  updateProcessMapAction,
  fetchDropdownDataByType,
});
export default connector(ProcessMapForm);
