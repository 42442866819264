import React from 'react';
import { PeopleDeptRoleInput } from '../..';
import { __ } from 'modeling-tool';

const RACIFields = ['responsible', 'accountable', 'consulted', 'informed'];

interface RaciInformationProps {
  readOnly?: boolean;
  control?: any;
}
const RaciInformation = (props: RaciInformationProps) => {
  const { readOnly, control } = props;

  return (
    <div className="raci-fields">
      {RACIFields.map((field: string) => (
        <>
          <PeopleDeptRoleInput key={field} field={field} control={control} readOnly={readOnly} isFoldable={true} />
        </>
      ))}
    </div>
  );
};

export default RaciInformation;
