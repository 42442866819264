import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../ts/interfaces';

export const listClassStyle = () => {
  return mergeStyles({
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '5px',
    marginTop: '10px',
    justifyContent: 'center',
  });
};
export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    header: {
      clear: 'both',
    },
    tasksWrapper: {
      margin: '10px',
    },
    taskHorizWrapper: {
      flexFlow: 'wrap',
      display: 'flex',
    },
    paneWrapper: {
      position: 'relative',
      height: '790px',
    },
    noTasksMsg: {
      paddingTop: '150px',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 'large',
      color: theme.palette.neutralTertiaryAlt,
    },
    filterButton: {
      margin: '5px',
      float: 'right',
    },
  });
});

export const cardDetailsStyles = (title?: boolean) => {
  return mergeStyleSets({
    leftDetailsStyles: {
      width: title ? '100%' : '50%',
      color: title ? '#5c2e91' : 'black',
      fontWeight: title ? 800 : 400,
      fontSize: title ? 16 : 14,
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
    },
    rightDetailsStyles: {
      width: '50%',
      color: title ? '#5c2e91' : 'black',
      fontWeight: title ? 500 : 400,
    },
    leftValue: {
      width: 120,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });
};

export const cardStyles2 = memoizeFunction((theme) => {
  return mergeStyleSets({
    globalCardStyles: {
      backgroundColor: 'transparent',
      height: '100%',
      width: '100%',
      maxWidth: '400px',
      borderStyle: 'none',
    },
    titleStyles: {
      color: theme.palette.themePrimary,
      fontWeight: 600,
      fontSize: 16,
      height: 40,
      display: 'flex',
      alignItems: 'start',
      paddingTop: 8,
    },
    cardStyles: {
      width: 278,
      minHeight: 138,
      margin: '15px 15px 0 5px',
      padding: '4px 20px 20px',
      borderTop: `4px solid ${theme.palette.themePrimary}`,

      ':hover': {
        borderTop: `4px solid ${theme.palette.themePrimary}`,
      },
    },
    dataRow: {
      width: '100%',
    },
    globalstatus: {
      marginTop: '15px',
      marginLeft: '13px',
    },
    wrapper: {
      minWidth: '330px',
      height: 'calc(100vh - 287px)',
      position: 'relative',
      maxHeight: 'inherit',
      borderLeft: '0px',
    },
    cardStack: {
      border: 'none',
      height: '100%',
      paddingTop: 4,
    },
    detailsStack: {
      height: '85%',
    },
    buttonStack: {
      height: '15%',
      marginTop: '9px',
      marginBottom: '12px',
    },
    personName: {
      marginLeft: '3px',
    },
    stackRow: {
      marginBottom: 8,
    },
  });
});

export const cardStyles = memoizeFunction((theme) => {
  return mergeStyleSets({
    globalCardStyles: {
      backgroundColor: 'transparent',
      height: '100%',
      width: '100%',
      maxWidth: '400px',
      borderStyle: 'none',
    },
    cardStyles: {
      width: 360,
      minHeight: 138,
    },
    createdByStyles: {
      paddingLeft: '15px',
    },
    cardBox: {
      padding: '0 10px 20px',
    },

    globalstatus: {
      marginTop: '15px',
      marginLeft: '13px',
    },
    tasksCard: {
      margin: '15px 15px 0 5px',
    },
    cardDetails: {
      marginTop: '10px',
      marginLeft: '5px',
    },
    wrapper: {
      height: 'calc(100vh - 226px)',
      position: 'relative',
      maxHeight: 'inherit',
      borderLeft: '0px',
    },
    titleStyles: {
      color: `${theme.palette.themePrimary}`,
    },
    personName: {
      marginLeft: '3px',
    },
    blinkHint: {
      color: theme.palette.themePrimary,
    },
  });
});
