import { PrimaryButton, Stack, TooltipHost, ActionButton, DirectionalHint } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { __ } from 'modeling-tool';
import { fetchWorkingGroupsAction } from '../../../store/actions/working-group-actions';
import WorkingGroupsCard from './working-groups-card';
import { cardStyles } from './work-groups-styles';
import WorkingGroupForm from './working-group-form';
import { Dropdown } from '../../../components';
import { useForm } from 'react-hook-form';
import { checkPermission } from '../../../config/permission-utils';
import { GLOBALENUMS, Modal } from 'modeling-tool';

export const StatusOptions = [
  { key: GLOBALENUMS.WORKINGGROUPSTATUS.ACTIVE, text: __('active') },
  { key: GLOBALENUMS.WORKINGGROUPSTATUS.INACTIVE, text: __('inactive') },
];
import { useStyleProps } from '../../../hooks';

const WorkingGroupsList = (props: PropsFromRedux) => {
  const {
    WorkingGroupReducer: { workinggroups },
    UserReducer,
    LocationReducer: { locations },
  } = props;

  const styleProps = useStyleProps();
  const styles = cardStyles(styleProps);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isModalVisibleWorkingGroups, setIsModalVisibleWorkingGroups] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = React.useState<GLOBALENUMS.WORKINGGROUPSTATUS[]>([
    GLOBALENUMS.WORKINGGROUPSTATUS.ACTIVE,
    GLOBALENUMS.WORKINGGROUPSTATUS.INACTIVE,
  ]);
  useEffect(() => {
    fetchWorkingGroupsAction();
  }, []);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });
  const onAddWorkingGroup = () => {
    setModalVisible(true);
  };
  const saveForm = () => {
    handleSubmit(
      (data) => {
        setIsModalVisibleWorkingGroups(false);
        const status = data['status'];
        status.length
          ? setSelectedStatus(status)
          : setSelectedStatus([GLOBALENUMS.WORKINGGROUPSTATUS.ACTIVE, GLOBALENUMS.WORKINGGROUPSTATUS.INACTIVE]);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleCancel = () => {
    setIsModalVisibleWorkingGroups(false);
  };

  const renderWorkingGroups = () => {
    if (workinggroups && workinggroups.length > 0) {
      return (
        <>
          <Stack horizontal>
            {selectedStatus.includes(GLOBALENUMS.WORKINGGROUPSTATUS.ACTIVE) && (
              <WorkingGroupsCard status={GLOBALENUMS.WORKINGGROUPSTATUS.ACTIVE} setModalVisible={setModalVisible} />
            )}
            {selectedStatus.includes(GLOBALENUMS.WORKINGGROUPSTATUS.INACTIVE) && (
              <WorkingGroupsCard status={GLOBALENUMS.WORKINGGROUPSTATUS.INACTIVE} setModalVisible={setModalVisible} />
            )}
          </Stack>
        </>
      );
    }
    return <div className={styles.noWorkingGroupsMsg}>{__("you don't have any working group")}</div>;
  };

  const actions = [];
  if (checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_workinggroup)) {
    actions.push(
      <PrimaryButton
        iconProps={{ iconName: 'Add' }}
        style={{
          margin: '5px',
          float: 'right',
        }}
        allowDisabledFocus
        onClick={() => onAddWorkingGroup()}
      >
        {__('new')}
      </PrimaryButton>,
    );
  }
  const renderFilterButton = () => {
    return (
      <>
        <TooltipHost content={__('filter')} directionalHint={DirectionalHint.bottomAutoEdge}>
          <ActionButton
            iconProps={{ iconName: 'Filter' }}
            className={styles.filterButton}
            onClick={() => setIsModalVisibleWorkingGroups(true)}
          />
        </TooltipHost>
        <Modal title={__('filter')} isModalOpen={isModalVisibleWorkingGroups} onSave={saveForm} onCancel={handleCancel}>
          <form>
            <Dropdown options={StatusOptions} multiSelect label={__('status')} control={control} name={'status'} />
          </form>
        </Modal>
      </>
    );
  };
  return (
    <>
      <div className={styles.btnsHolder}>
        <Stack horizontal>
          {workinggroups && workinggroups.length > 0 && renderFilterButton()}
          {actions}
        </Stack>
      </div>
      <div className={styles.headerCard}></div>
      {renderWorkingGroups()}
      {modalVisible && (
        <WorkingGroupForm visible={modalVisible} setModalVisible={setModalVisible} groupLocations={locations} />
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ WorkingGroupReducer, UserReducer, LocationReducer }: RootState) => ({
  WorkingGroupReducer,
  UserReducer,
  LocationReducer,
});
const connector = connect(mapStateToProps, {
  fetchWorkingGroupsAction,
});
export default connector(WorkingGroupsList);
