import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.less';
import App from './App';
import AppTeams from './AppTeams';
import { IsTeamsApp } from './config/config';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config/authConfig';
import { MsalProvider } from '@azure/msal-react';

// msal provider must be on top most component
export const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  root.render(
    // TODO: https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
    // React 18 hast currently behavior, where [] useEffects are run twice (but not in production)
    // when fixed, reenable strict mode for development
    // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      {!IsTeamsApp() && <App />}
      {IsTeamsApp() && <AppTeams />}
      <div id="full-screen-portal"></div>
      <div id="full-screen-portal-on-modal"></div>
    </MsalProvider>,
    // </React.StrictMode>,
  );
}
