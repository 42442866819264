import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    loginPage: {
      height: 'calc(100vh)',
      padding: '5% 25px',
      textAlign: 'center',
    },
    logoHolder: {
      margin: '16px 0px',
      textAlign: 'center',

      img: {
        height: '100px',
        marginBottom: '16px',
      },
    },
    loginPageGo: {
      margin: '16px 0px',
      textAlign: 'center',
      marginLeft: '-100px',

      img: {
        height: '100px',
        marginBottom: '16px',
      },
    },
    copyRights: {
      textAlign: 'center',
      margin: '16px 0px',
      color: '#999',
    },

    icon: {
      color: '#6bb700',
    },
    container: {
      position: 'fixed',
      top: '10%',
      left: '40%',
    },
    image: {
      overflow: 'hidden',
      paddingTop: '245px',
      maxHeight: '975px',
    },
    body: {
      overflow: 'hidden',
      position: 'fixed',
    },
  });
});
