import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useStyles } from './process-map-styles';
import {
  fetchProcessMapsAction,
  addProcessMapAction,
  clearSelectProcessMapAction,
  toggleEditMode,
  deleteProcessMapAction,
} from '../../../store/actions/process-map-actions';
import { RootState } from '../../../config/store';
import { GLOBALENUMS, Modal, UtilityContext } from 'modeling-tool';
import { checkPermission } from '../../../config/permission-utils';
import { __ } from 'modeling-tool';
import { ProcessMapForm } from './process-map-form';
import { IProcessMap } from '../../../ts/interfaces';
import { useNavigate } from 'react-router';
import { ProcessMapCard } from './process-map-card';
import { useBoolean } from '@fluentui/react-hooks';
import { EntityList } from '../../../components/shared/entity-list';
import { Button, Spinner, Text } from '@fluentui/react-components';
import { Edit16Regular } from '@fluentui/react-icons';

const ProcessMap = (props: PropsFromRedux) => {
  const {
    UserReducer: { permissions },
    ProcessMapReducer: { processmaps, editMode, selectedProcessmap },
    addProcessMapAction,
    fetchProcessMapsAction,
    clearSelectProcessMapAction,
    toggleEditMode,
    deleteProcessMapAction,
  } = props;

  const classes = useStyles();

  const { setUtilitySideBar, setBreadcrumbButtons } = useContext(UtilityContext);
  const [hasNoPermissions, setHasNoPermissions] = useState<boolean | undefined>(false);
  const [readonly, setReadonly] = useState<boolean>(true);
  const [initDone, setInitDone] = useState<boolean>(false);
  const [isModalOpen, { toggle: toggleModal }] = useBoolean(false);

  const navigate = useNavigate();

  const userHasPermission = checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_processmap);

  useEffect(() => {
    setHasNoPermissions(!userHasPermission);
    editMode ? setReadonly(!userHasPermission) : setReadonly(true);
    buttonSection();
  }, [editMode]);

  useEffect(() => {
    getProcessMap();
    window.addEventListener('click', handleEventListener);
    clearSelectProcessMapAction();
    return () => {
      closeSidebar();
      window.removeEventListener('click', handleEventListener);
      setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
    };
  }, []);

  const handleEventListener = () => {
    closeSidebar();
  };

  const getProcessMap = () => {
    fetchProcessMapsAction((res: any, err: boolean) => {
      if (!err) {
        setInitDone(true);
      }
    });
  };

  const closeSidebar = () => {
    setUtilitySideBar({
      list: [],
    });
    clearSelectProcessMapAction();
  };

  const redirectToProcessMap = (subId: number | undefined) => {
    if (subId) {
      const url = `/process-management/processmap/${subId.toString()}`;
      navigate(url);
    }
  };

  const renderDetailsBar = (processMap: IProcessMap) => {
    setUtilitySideBar &&
      setUtilitySideBar({
        list: [
          <ProcessMapForm
            key={processMap.id}
            readOnly={readonly}
            closeSidebar={closeSidebar}
            processMap={processMap}
            toggleModal={toggleModal}
          />,
        ],
      });
  };
  const handleDelete = () => {
    deleteProcessMapAction(selectedProcessmap, () => {
      clearSelectProcessMapAction();
    });
    toggleModal();
    closeSidebar();
  };
  
  const handleCancel = () => {
    toggleModal();
  };

  const buttonSection = () => {
    if (checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_processmapfullmodel)) {
      const buttonLabel = editMode ? __('end modeling') : __('start modeling');

      const buttonList = (
        <Button
          appearance="primary"
          className={classes.btn}
          onClick={() => toggleEditMode && toggleEditMode()}
          key="process-map-buttons-section"
          icon={<Edit16Regular />}
        >
          {buttonLabel}
        </Button>
      );

      if (!checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_processmap)) return;

      return (
        setBreadcrumbButtons &&
        setBreadcrumbButtons({
          list: [buttonList],
        })
      );
    }
  };

  const LoadingSpinner = () => {
    return (
      <div className={classes.centeredContainer}>
        <Spinner />
      </div>
    );
  };

  const NoProcessMapMessage = () => {
    return (
      <div className={classes.centeredContainer}>
        <span className={classes.message}>{__('no process map has been created yet')}</span>
      </div>
    );
  };

  const renderProcessMapCard = (processmap: IProcessMap) => (
    <ProcessMapCard
      processMap={processmap}
      closeSidebar={closeSidebar}
      renderDetailsBar={renderDetailsBar}
      readonly={readonly}
      redirectToProcessMap={redirectToProcessMap}
    />
  );

  if (!initDone) return <LoadingSpinner />;
  
  if (hasNoPermissions && !processmaps.length) return <NoProcessMapMessage />;
  
  return (
    <>
      <EntityList
        entities={processmaps}
        readonly={readonly}
        renderEntity={renderProcessMapCard}
        addActionText={__('create new map')}
        addAction={addProcessMapAction}
      />
      <Modal
        title={__('confirm delete')}
        isModalOpen={isModalOpen}
        onSave={handleDelete}
        onCancel={handleCancel}
        saveButtonText={'Ok'}
      >
        <Text>{__('do you really want to delete the selected process map?')}</Text>
      </Modal>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, ProcessMapReducer }: RootState) => ({
  UserReducer,
  ProcessMapReducer,
});
const connector = connect(mapStateToProps, {
  addProcessMapAction,
  fetchProcessMapsAction,
  clearSelectProcessMapAction,
  toggleEditMode,
  deleteProcessMapAction,
});
export default React.memo(connector(ProcessMap));
