import { Meta, Resource } from '../../ts/interfaces';

interface ResourceReducerState {
  resources: Resource[];
  meta?: Meta;
  selectedResource?: Resource;
  filterResources: Resource[];
}

export default function reducer(
  state: ResourceReducerState = {
    resources: [],
    meta: undefined,
    selectedResource: undefined,
    filterResources: [],
  },
  action: any,
): ResourceReducerState {
  switch (action.type) {
    case 'FETCH_RESOURCES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        resources: action.payload.objects,
      });
    }
    case 'FETCH_RESOURCE': {
      return Object.assign({}, state, {
        selectedResource: action.payload,
      });
    }
    case 'CLEAR_RESOURCE': {
      return Object.assign({}, state, {
        selectedResource: undefined,
      });
    }
    case 'ADD_RESOURCE': {
      state.resources.unshift(action.payload);
      return {
        ...state,
        resources: state.resources,
      };
    }
    case 'UPDATE_RESOURCE': {
      return {
        ...state,
        resources: state.resources.map((resource) => (resource.id === action.payload.id ? action.payload : resource)),
      };
    }
    case 'SELECT_RESOURCE_BY_ID': {
      return Object.assign({}, state, {
        selectedResource: state.resources.find((resource) => resource.id === action.payload),
      });
    }
    case 'CLEAR_SELECT_RESOURCE': {
      return Object.assign({}, state, {
        selectedResource: undefined,
      });
    }
    case 'DELETE_RESOURCE': {
      return {
        ...state,
        resources: state.resources.filter((resource) => resource.id !== action.payload),
      };
    }
    case 'SEARCH_RESOURCE': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        filterResources: action.payload.objects,
      });
    }
    case 'CLEAR_RESOURCES': {
      return {
        ...state,
        resources: (state.resources = []),
      };
    }
    default: {
      break;
    }
  }
  return state;
}
