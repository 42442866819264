import React, { Fragment, useEffect, useState } from 'react';
import { MgtTemplateProps, Person, ViewType } from '@microsoft/mgt-react';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { searchOrgNodesDataAction } from '../../../store/actions/org-node-data-actions';
import { searchProcessesAction } from '../../../store/actions/process-actions';
import { fetchDepartmentsAction } from '../../../store/actions/department-actions';
import { searchProcessInstancesAction } from '../../../store/actions/process-instance-actions';
import { RootState } from '../../../config/store';
import { getDateFormatted } from '../../../config/utils';
import { __ } from 'modeling-tool';
import { Employee, OrgNodeData, Process, ProcessInstance, User } from '../../../ts/interfaces';
import { useForm } from 'react-hook-form';
import { PersonDisplayName } from '../../../components';
import { searchEmployeeAction } from '../../../store/actions/employee-actions';
import { useNavigate } from 'react-router';
import { Parser } from 'html-to-react';
import { useStyles } from './department-activities-styles';
import { mergeClasses } from '@fluentui/react-components';

interface DepartmentActivity {
  process?: Process;
  processInstance?: ProcessInstance;
  orgNode?: OrgNodeData;
  employee?: Employee;
  createdBy: User;
  dateCreated: Date;
  activityType: ActivityType;
  activityTypeRenderText?: string;
}

enum ActivityType {
  PROCESS,
  INSTANCE,
  NODE,
  EMPLOYEE,
}

const DepartmentActivities = (props: PropsFromRedux) => {
  const {
    ProcessReducer: { processes },
    ProcessInstanceReducer: { instances },
    OrgNodeDataReducer: { nodes },
    EmployeeReducer: { employees },
    searchProcessesAction,
    searchProcessInstancesAction,
    searchOrgNodesDataAction,
    searchEmployeeAction,
    UserReducer: { language },
  } = props;

  const classes = useStyles();
  
  const [activities, setActivities] = useState<DepartmentActivity[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const filterDate = moment(new Date()).subtract(0, 'days').format('YYYY-MM-DD');
    getActivities(`?date_created__gte=${filterDate}&order_by=-id`);
    const data = {
      filterDate: '0',
      department: null,
    };
    reset(data);
  }, [language]);

  const { reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    const activities: DepartmentActivity[] = [];
    processes.forEach(function (item) {
      activities.push({
        createdBy: item.createdBy,
        dateCreated: item.dateCreated,
        process: item,
        activityType: ActivityType.PROCESS,
        activityTypeRenderText: __('new process'),
      });
    });
    instances.forEach(function (item) {
      activities.push({
        createdBy: item.createdBy,
        dateCreated: item.dateCreated,
        processInstance: item,
        activityType: ActivityType.INSTANCE,
      });
    });
    nodes.forEach(function (item) {
      activities.push({
        createdBy: item.createdBy,
        dateCreated: item.dateCreated,
        orgNode: item,
        activityType: ActivityType.NODE,
      });
    });
    employees.forEach(function (item) {
      activities.push({
        createdBy: item.createdBy,
        dateCreated: item.dateJoined,
        employee: item,
        activityType: ActivityType.EMPLOYEE,
        activityTypeRenderText: __('New employee'),
      });
    });
    activities.sort(sortByDate);
    setActivities(activities);
  }, [processes, instances, nodes, employees]);

  function sortByDate(a: DepartmentActivity, b: DepartmentActivity) {
    if (a.dateCreated < b.dateCreated) {
      return 1;
    }
    if (a.dateCreated > b.dateCreated) {
      return -1;
    }
    return 0;
  }

  const getActivities = (query: string) => {
    searchOrgNodesDataAction(query);
    searchProcessesAction(query);
    if (query.includes('department__id')) {
      query = query.replace('department__id', 'source_process__department__id');
    }
    searchProcessInstancesAction(query);
    searchEmployeeAction(query);
  };

  const activityListItemClick = (item: DepartmentActivity) => {
    if (item.process && item.process.id) {
      navigate(`/process-management/processes/${item.process.id}`);
    } else if (item.processInstance && item.processInstance.sourceProcess) {
      navigate(
        `process-management/processes/${item.processInstance.sourceProcess.id}/modeling-tool/${item.processInstance.id}`,
      );
    } else if (item.orgNode && item.orgNode.department) {
      navigate(`/organization`);
    } else if (item.orgNode && item.orgNode.role) {
      navigate(`/organization`);
    } else if (item.employee) {
      navigate(`/employee`);
    }
  };

  const GetDisplayNameByUserId = (props: { attr?: string; sentence: string } & MgtTemplateProps) => {
    const { attr, sentence } = props;
    const {
      dataContext: { person },
    } = props;

    const attrValue = person[attr || 'displayName'];
    let display = Array.isArray(attrValue) ? attrValue[0] : attrValue;
    display = display ? display : '';

    return <div>{Parser().parse(__(sentence, null, [display]))}</div>;
  };

  const renderActivityDescription = (item: DepartmentActivity) => {
    if (item.process && item.process.id) {
      return <span>{Parser().parse(__('has created a new process', null, [item.process.processName]))}</span>;
    } else if (item.processInstance) {
      return <span>{__('has created a new process modelling', null, [item.processInstance.instanceName])}</span>;
    } else if (item.orgNode && item.orgNode.department) {
      return <span>{__('has created a new department', null, [item.orgNode.department.departmentName])}</span>;
    } else if (item.orgNode && item.orgNode.role) {
      return <span>{__('has created a new role', null, [item.orgNode.role.roleName])}</span>;
    } else if (item.employee) {
      return (
        <span>
          <div>
            <Person userId={item.employee.username}>
              <GetDisplayNameByUserId sentence={'has invited person-name-placeholder'} />
            </Person>
          </div>
        </span>
      );
    }
  };

  function getActivityStyles(activityType: ActivityType) {
    switch (activityType) {
      case ActivityType.PROCESS:
        return classes.activityTypeProcess;
      default:
        return classes.activityTypeEmployee;
    }
  }

  const onRenderCell = React.useCallback((item: any) => {
    // skip tasks, since only the current user can see it and has its own view
    if (item.processInstance && item.processInstance.participant) {
      return <></>;
    }

    // skip instances and nodes, since they are not relevant for the department activities
    if (item.activityType === ActivityType.INSTANCE || item.activityType === ActivityType.NODE) {
      return <></>;
    }

    return (
      <div onClick={() => activityListItemClick(item)} className={classes.activityListItem}>
        <div className={classes.horizontalAlign}>
          <div className={classes.horizontalAlign}>
            <Person
              className={classes.departmentActivitiesText}
              userId={item?.createdBy?.username}
              view={ViewType.image}
              avatarSize="auto"
            />
            <div className={classes.userNameDateStack}>
              <Person className={classes.userNamePersonText} userId={item?.createdBy?.username}>
                <PersonDisplayName />
              </Person>
              <div>{getDateFormatted(item.dateCreated, 'lll')}</div>
            </div>
          </div>
          <div className={mergeClasses(classes.activityType, getActivityStyles(item.activityType))}>
            {item.activityTypeRenderText}
          </div>
        </div>
        <span className={classes.descriptionSpan}>{renderActivityDescription(item)}</span>
      </div>
    );
  }, []);

  return (
    <div className={classes.departmentActivities}>
      <div className={classes.departmentActivitiesTitle}>{__('Newest activity')}</div>
      <div className={classes.listWrapper}>
        {activities.map((item, index) => {
          return <Fragment key={index}> {onRenderCell(item)}</Fragment>;
        })}
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({
  ProcessReducer,
  ProcessInstanceReducer,
  DepartmentReducer,
  OrgNodeDataReducer,
  UserReducer,
  EmployeeReducer,
}: RootState) => ({
  ProcessReducer,
  ProcessInstanceReducer,
  DepartmentReducer,
  OrgNodeDataReducer,
  UserReducer,
  EmployeeReducer,
});
const connector = connect(mapStateToProps, {
  searchProcessesAction,
  searchProcessInstancesAction,
  searchOrgNodesDataAction,
  fetchDepartmentsAction,
  searchEmployeeAction,
});
export default connector(DepartmentActivities);
