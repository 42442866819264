import React from 'react';
import { initializeIcons, PartialTheme, ThemeProvider } from '@fluentui/react';
import { Provider } from 'react-redux';
import './app.less';
import TelemetryProvider from './config/appinsights/telemetry-provider';
import { getAppInsights } from './config/appinsights/telemetry-service';
import { GetAppInsightsKey } from './config/config';
import configureStore from './config/store';
import Router from './router';
import TeamsAuthenticationProvider from './teams-auth-provider';
import { BrowserRouter } from 'react-router-dom';

export const store = configureStore();

export default function AppTeams(): JSX.Element {
  initializeIcons();

  // https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/
  const appTheme: PartialTheme = {
    palette: {
      themePrimary: '#59328a',
      themeLighterAlt: '#111',
      themeLighter: '#dfd4ec',
      themeLight: '#c5b2dc',
      themeTertiary: '#9272b9',
      themeSecondary: '#694298',
      themeDarkAlt: '#502d7c',
      themeDark: '#442669',
      themeDarker: '#321c4d',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#F6F6F6',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
    },
  };

  let appInsights = null;
  const instrumentationKey = GetAppInsightsKey();

  return (
    <>
      <Provider store={store}>
        <TeamsAuthenticationProvider>
          <ThemeProvider theme={appTheme}>
            <BrowserRouter>
              {instrumentationKey && (
                <TelemetryProvider
                  instrumentationKey={instrumentationKey}
                  after={() => {
                    appInsights = getAppInsights();
                  }}
                >
                  <div id="app-container">
                    <div className="content-container">
                      <Router />
                    </div>
                  </div>
                </TelemetryProvider>
              )}
              {!instrumentationKey && (
                <div id="app-container">
                  <div className="content-container">
                    <Router />
                  </div>
                </div>
              )}
            </BrowserRouter>
          </ThemeProvider>
        </TeamsAuthenticationProvider>
      </Provider>
    </>
  );
}
