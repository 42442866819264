import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    errorPage: {
      height: 'calc(100vh)',
      padding: '5% 25px',
      textAlign: 'center',
    },
    logoHolder: {
      margin: '16px 0px',
      textAlign: 'center',

      img: {
        height: '100px',
        marginBottom: '16px',
      },
    },
    copyRights: {
      textAlign: 'center',
      margin: '16px 0px',
      color: '#999',
    },
    icon: {
      color: '#FF0000',
    },
    container: {
      position: 'fixed',
      top: '10%',
      marginRight: '7%',
    },
    image: {
      overflow: 'hidden',
      paddingTop: '245px',
      maxHeight: '975px',
    },
    body: {
      overflow: 'hidden',
      position: 'fixed',
    },
    backgroundImage: {
      backgroundImage: 'url("/images/aiio-anmeldung-testbild.svg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100vw',
      height: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  });
});
