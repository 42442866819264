import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const tabsStyles = memoizeFunction(() => {
  return mergeStyleSets({
    cardStyles: {
      justifyContent: 'center',
      height: '100%',
      minWidth: '900px',
      borderStyle: 'solid',
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '15px',
      minHeight: '400px',
    },
    cellText: {
      lineHeight: '15px',
      textAlign: 'left',
      borderTop: '5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    table: {
      width: `1600px`,
      whiteSpace: 'nowrap',
      paddingBotton: '10px',
      marginBotton: '10px',
    },
  });
});
