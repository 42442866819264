import { GLOBALENUMS, IconSvg, __ } from 'modeling-tool';
import { Checkbox, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import { useStyles } from './licence-payment-styles';
import { annualLicensePriceDetails, monthlyLicensePriceDetails } from '../checkout-constants';
import React, { useEffect } from 'react';
import {
  payOnlineStripeAction,
  payOnlineStripeLicenseExpiryAction,
  updatePayOnlineStripeCheckOutAction,
  payOnAccountAction,
  payOnAccountActionLicenseExpiryAction,
  updatePayOnAccountActionCheckOutAction,
} from '../../../../store/actions/licence-check-out-actions';
import { loadStripe } from '@stripe/stripe-js';
import { useBoolean } from '@fluentui/react-hooks';
import { useLocation } from 'react-router-dom';

interface LicencePaymentProps {
  isAnnualBillingEnabled: boolean;
  isNormalPlan: boolean;
}

const LicencePayment = (props: PropsFromRedux & LicencePaymentProps) => {
  const {
    LicenceCheckOutReducer: { licenceCheckOuts, newPlanLicenceCheckOuts, paymentMethodType },
    isAnnualBillingEnabled,
    isNormalPlan,
    payOnlineStripeAction,
    payOnlineStripeLicenseExpiryAction,
    updatePayOnlineStripeCheckOutAction,
    payOnAccountAction,
    payOnAccountActionLicenseExpiryAction,
    updatePayOnAccountActionCheckOutAction,
    UserReducer: { isLicenseTest },
  } = props;

  const classes = useStyles();
  const billingDetails = isAnnualBillingEnabled ? annualLicensePriceDetails : monthlyLicensePriceDetails;
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [showError, { setTrue: viewError, setFalse: dismissError }] = useBoolean(false);
  const termsAndConditionsUrl =
    'https://uploads-ssl.webflow.com/61b8746e0e120e69f52883cf/626a7e50b4419eab8ac6d266_220501%20aiio%20Allgemeine%20Auftragsbedingungen.pdf';
  const baseURL = window.location.origin;
  const successURL = `${baseURL}/settings?success=true`;

  const returnTotalPrice = (): string => {
    let totalPrice: number = 0;

    if (isNormalPlan && isLicenseTest) {
      licenceCheckOuts.forEach((item) => {
        totalPrice += item.cartLicencesCount * billingDetails[item.groupName as keyof typeof billingDetails];
      });
    } else if (isNormalPlan) {
      licenceCheckOuts.forEach((item) => {
        totalPrice +=
          ((item?.totalLicencesCount ?? 0) < 0 ? 0 : item?.totalLicencesCount ?? 0) *
          billingDetails[item.groupName as keyof typeof billingDetails];
      });
    } else {
      newPlanLicenceCheckOuts.forEach((item) => {
        totalPrice += item.cartLicencesCount * billingDetails[item.groupName as keyof typeof billingDetails];
      });
    }

    return totalPrice.toFixed(2);
  };

  const onCheckBoxChange = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, checked?: boolean) => {
    setIsChecked(!!checked);
  }, []);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        dismissError();
      }, 3000);
    }
  }, [showError]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const canceledParam = queryParams.get('canceled');

    if (canceledParam == 'true') {
      viewError();
    }
  }, [location]);

  function returnPayloadLineItems() {
    const line_items: {
      price: string;
      quantity: number;
    }[] = [];

    if (isNormalPlan) {
      licenceCheckOuts.forEach((item) => {
        if (item.cartLicencesCount != 0) {
          line_items.push({
            price: item.groupName,
            quantity: item.cartLicencesCount,
          });
        }
      });
    } else {
      newPlanLicenceCheckOuts.forEach((item) => {
        if (item.cartLicencesCount != 0) {
          line_items.push({
            price: item.groupName,
            quantity: item.cartLicencesCount,
          });
        }
      });
    }

    return line_items;
  }

  // Handles the pay online button click and navigates to the stripe checkout page.
  function onPayOnlineButtonClick() {
    if (isNormalPlan) {
      const payload = {
        payment_type: isAnnualBillingEnabled ? 'yearly' : 'monthly',
        line_items: returnPayloadLineItems(),
      };

      payOnlineStripeAction(
        payload,
        async (result) => {
          const stripe = await loadStripe(result.data.stripePublicKey);

          if (stripe) {
            const { error } = await stripe.redirectToCheckout({
              sessionId: result.data.sessionId,
            });

            if (error) {
              console.error(error);
            }
          }
        },
        (error) => {
          if (error.response.data.error_message) {
            const err = JSON.parse(error.response.data.error_message);

            if (err.code == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED) {
              payOnlineStripeLicenseExpiryAction(
                payload,
                async (result) => {
                  const stripe = await loadStripe(result.data.stripePublicKey);

                  if (stripe) {
                    const { error } = await stripe.redirectToCheckout({
                      sessionId: result.data.sessionId,
                    });

                    if (error) {
                      console.error(error);
                    }
                  }
                },
                (error) => {
                  console.log('error', error);
                  viewError();
                },
              );
            } else {
              viewError();
            }
          } else {
            viewError();
          }
        },
      );
    } else {
      const payload = {
        line_items: returnPayloadLineItems(),
      };

      updatePayOnlineStripeCheckOutAction(
        payload,
        async () => {
          window.location.href = successURL;
        },
        (error) => {
          console.log('error', error);
          viewError();
        },
      );
    }
  }

  // handles the pay on account button click and backend sends an email with bank details.
  function onPayOnAccountButtonClick() {
    if (isNormalPlan) {
      const payload = {
        payment_type: isAnnualBillingEnabled ? 'yearly' : 'monthly',
        line_items: returnPayloadLineItems(),
      };

      payOnAccountAction(
        payload,
        async () => {
          window.location.href = successURL;
        },
        (error) => {
          if (error.response.data.error_message) {
            const err = JSON.parse(error.response.data.error_message);

            if (err.code == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED) {
              payOnAccountActionLicenseExpiryAction(
                payload,
                async () => {
                  window.location.href = successURL;
                },
                (error) => {
                  console.log('error', error);
                  viewError();
                },
              );
            } else {
              viewError();
            }
          } else {
            viewError();
          }
        },
      );
    } else {
      const payload = {
        payment_type: isAnnualBillingEnabled ? 'yearly' : 'monthly',
        line_items: returnPayloadLineItems(),
      };

      updatePayOnAccountActionCheckOutAction(
        payload,
        async () => {
          window.location.href = successURL;
        },
        (error) => {
          console.log('error', error);
          viewError();
        },
      );
    }
  }

  const renderBody = (): JSX.Element => {
    return (
      <>
        {showError && (
          <MessageBar
            delayedRender={false}
            messageBarType={MessageBarType.error}
            styles={{
              root: {
                position: 'absolute',
                top: '10%',
                left: '30%',
                width: 'fitContentToBounds',
              },
            }}
          >
            {
              <div>
                {__('Sadly this didnt work. Please try again. If the problem persists, contact our customer care.')}
              </div>
            }
          </MessageBar>
        )}
        <div className={classes.totalPriceDiv}>
          <span className={classes.totalPriceText1}>{__('Total price')}</span>
          <span className={classes.totalPriceText2}> {returnTotalPrice()} €</span>
        </div>
        {!isNormalPlan || isLicenseTest ? (
          <>
            <div className={classes.termsDiv}>
              <Checkbox checked={isChecked} onChange={onCheckBoxChange} />
              <span>
                {__('I accept the')}{' '}
                <a target="_blank" href={termsAndConditionsUrl} style={{ color: '#0D2FA9' }}>
                  {__('terms and conditions.')}
                </a>
              </span>
            </div>
            <div className={classes.paymentButtonsDiv}>
              {isLicenseTest || paymentMethodType === 'send_invoice' ? (
                <span className={classes.payOnAccountSpan} style={{ marginRight: isLicenseTest ? '20px' : '0px' }}>
                  <PrimaryButton
                    disabled={
                      !isChecked ||
                      (isNormalPlan
                        ? !licenceCheckOuts.some((obj) => obj.cartLicencesCount != 0)
                        : !newPlanLicenceCheckOuts.some((obj) => obj.cartLicencesCount != 0))
                    }
                    onClick={onPayOnAccountButtonClick}
                  >
                    <IconSvg width={24} height={24} svgName="Invoice" />
                    <div style={{ paddingLeft: '5px' }}>{__('Pay on account')}</div>
                  </PrimaryButton>
                </span>
              ) : null}
              {isLicenseTest || paymentMethodType != 'send_invoice' ? (
                <span className={classes.payOnlineSpan}>
                  <PrimaryButton
                    disabled={
                      !isChecked ||
                      (isNormalPlan
                        ? !licenceCheckOuts.some((obj) => obj.cartLicencesCount != 0)
                        : !newPlanLicenceCheckOuts.some((obj) => obj.cartLicencesCount != 0))
                    }
                    onClick={onPayOnlineButtonClick}
                  >
                    <IconSvg width={20} height={14} svgName="CreditCard" />
                    <div style={{ paddingLeft: '5px' }}>{__('Pay online')}</div>
                  </PrimaryButton>
                </span>
              ) : null}
            </div>
          </>
        ) : null}
      </>
    );
  };

  return renderBody();
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ LicenceCheckOutReducer, UserReducer }: RootState) => ({
  LicenceCheckOutReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  payOnlineStripeAction,
  payOnlineStripeLicenseExpiryAction,
  updatePayOnlineStripeCheckOutAction,
  payOnAccountAction,
  payOnAccountActionLicenseExpiryAction,
  updatePayOnAccountActionCheckOutAction,
});
export default connector(LicencePayment);
