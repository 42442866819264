import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  entityListWrapper: {
    marginTop: '16px',
    position: 'absolute',
  },
  cardWrapper: {
    paddingTop: '10px',
    paddingRight: '50px',
    paddingBottom: '10px',
    paddingLeft: '50px',
    flexWrap: 'wrap',
    display: 'flex',
  },
  card: {
    width: '400px',
    height: '240px',
    ...shorthands.borderRadius('8px'),
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.margin('20px'),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.borderColor('#BCBCBC'),
    ...shorthands.borderStyle('solid'),
    ...shorthands.borderWidth('1px'),
    cursor: 'pointer',
    ':hover': {
      cursor: 'pointer',
      ...shorthands.borderColor(tokens.colorNeutralForeground1),
      ...shorthands.borderStyle('solid'),
      ...shorthands.borderWidth('1px'),
    },
  },
  text: {
    color: tokens.colorNeutralStroke1Hover,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100px',
  },
  addCircleIcon: {
    height: '100px',
    width: '100px',
    color: tokens.colorNeutralStroke2,
  },
});
