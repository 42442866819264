import React, { useEffect, useState } from 'react';
import { Icon, IStackTokens, Stack, Text } from '@fluentui/react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../config/store';
import { __ } from 'modeling-tool';
import { getCollapseStyles } from './process-filter-form-styles';

interface FilterCollapseProps {
  children?: JSX.Element;
  fieldName: string;
}
const stackTokens: IStackTokens = { childrenGap: 10 };

const FilterCollapse = (props: FilterCollapseProps & PropsFromRedux) => {
  const {
    ProcessReducer: { filterValues },
    children,
    fieldName,
  } = props;

  const [expand, setExpand] = useState<boolean>(false);
  const styles = getCollapseStyles();

  useEffect(() => {
    if (
      filterValues &&
      ((filterValues[`${fieldName}__departments`] && filterValues[`${fieldName}__departments`].length > 0) ||
        (filterValues[`${fieldName}__employees`] && filterValues[`${fieldName}__employees`].length > 0))
    ) {
      setExpand(true);
    }
  }, []);

  return (
    <>
      <Stack tokens={stackTokens}>
        <Stack.Item>
          <Stack horizontal>
            {!expand && <Icon iconName="CaretRight8" onClick={() => setExpand(true)} className={styles.iconStyles} />}
            {expand && <Icon iconName="CaretDown8" onClick={() => setExpand(false)} className={styles.iconStyles} />}
            <Text variant="medium" className={styles.filterCollapseHeaderText}>
              {__(fieldName)}
            </Text>
          </Stack>
        </Stack.Item>
        {expand && <>{children}</>}
      </Stack>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessReducer }: RootState) => ({ ProcessReducer });
const connector = connect(mapStateToProps);

export default connector(FilterCollapse);
