import { GLOBALENUMS } from 'modeling-tool';
import { ModelerNodeType } from '../../ts/interfaces';

interface ModelerReducerState {
  modelerNodeData: ModelerNodeType[];
  flatModelElements: any;
}

export default function reducer(
  state: ModelerReducerState = {
    modelerNodeData: [],
    flatModelElements: [],
  },
  action: any,
): ModelerReducerState {
  switch (action.type) {
    case 'FETCH_MODELER_NODE_COLOR': {
      return Object.assign({}, state, {
        modelerNodeData: action.payload.objects,
      });
    }
    case 'UPDATE_MODELER_NODE_COLOR': {
      return {
        ...state,
        modelerNodeData: state.modelerNodeData.map((node) => (node.id === action.payload.id ? action.payload : node)),
      };
    }
    case 'GET_FLAT_FULL_MODEL_BY_INSTANCE_ID': {
      const elements = action.payload.objects?.[0]?.elements ?? [];
      // Sort elements by type
      const startElements: any = [];
      const endElements: any = [];
      const filteredElements: any = [];

      elements.forEach((element: any) => {
        if (element.nodeType === GLOBALENUMS.MODELERNODETYPE.START) {
          startElements.push(element);
        } else if (element.nodeType === GLOBALENUMS.MODELERNODETYPE.END) {
          endElements.push(element);
        } else if (element.nodeType !== GLOBALENUMS.MODELERNODETYPE.TEXT) {
          filteredElements.push(element);
        }
      });

      const combinedElements = [...startElements, ...filteredElements, ...endElements];
      return {
        ...state,
        flatModelElements: combinedElements,
      };
    }
    case 'CLEAR_MODELER_REDUCER': {
      return {
        modelerNodeData: [],
        flatModelElements: [],
      };
    }
    default: {
      break;
    }
  }
  return state;
}
