import React, { useEffect, useRef, useState } from 'react';
import { CacheService, Login, Person } from '@microsoft/mgt-react';
import { connect, ConnectedProps } from 'react-redux';
import { checkPermission } from '../../../config/permission-utils';
import { RootState } from '../../../config/store';
import { getCurrentLanguage, GLOBALENUMS, __ } from 'modeling-tool';
import { changeLanguageAction } from '../../../store/actions/user-actions';
import { iconStyleProps, menuIconsProps, useHeaderBarIconStyles, useStyles } from './header-bar-styles';
import { Notifications } from './notifications';
import { WelcomePopup } from './welcome-popup';
import { useNavigate } from 'react-router';
import {
  Bug24Regular,
  Lightbulb24Regular,
  MoreHorizontal28Regular,
  Question24Regular,
  Settings24Regular,
  Translate24Regular,
} from '@fluentui/react-icons';
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';

const HeaderBar = (props: PropsFromRedux) => {
  const classes = useStyles();
  const iconHeaderBarStyleProps = useHeaderBarIconStyles();

  const {
    UserReducer: { isLicenseTest, upvoty, permissions, licenseRemainingDays },
  } = props;

  const navigate = useNavigate();

  const [welcomePopupVisible, setWelcomePopupVisible] = useState<boolean>(false);

  const loginControl = useRef<any>(null);

  useEffect(() => {
    if (loginControl) {
      loginControl.current.addEventListener('click', onLoginButtonClick);
    }
  }, [loginControl]);

  useEffect(() => {
    // clear the mgt cache, when the site is left
    const handleBeforeUnload = (e: Event) => {
      CacheService.clearCaches();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const onLoginButtonClick = () => {
    // other solutions would include to create our own login component and overwriting the flyout
    // due to the shadowroot, we cannot override existing css classes
    // this is a little bit hacky, but the simplest solution

    const mgtLogin = loginControl.current;
    const mgtFlyout = mgtLogin?.shadowRoot.querySelector('.flyout');

    // move the flyout a little bit down to fit the end of the headerbar
    const flyoutPositioner = mgtFlyout?.shadowRoot.querySelector('.flyout');
    flyoutPositioner?.setAttribute('style', 'padding-top: 8px;');

    // remove the rounded edges of the flyout
    const borderStyler = mgtLogin?.shadowRoot.querySelector('.popup');
    borderStyler?.setAttribute('style', 'border-radius: 0px !important;');
  };

  const SignedinButtonTemplate = (props: any) => {
    return (
      <div className={classes.personDisplayNameBackground}>
        <Person userId={props.dataContext.personDetails.id} />
      </div>
    );
  };

  const logoutCompleted = (e: Event) => {
    CacheService.clearCaches();
  };

  const LanguageMenu = () => {
    return (
      <Menu>
        <MenuTrigger>
          <MenuItem icon={<Translate24Regular {...menuIconsProps} />}>{`${__(
            'language',
          )} - ${getCurrentLanguage().toUpperCase()}`}</MenuItem>
        </MenuTrigger>
        <MenuPopover style={{ minWidth: '55px' }}>
          <MenuList>
            <MenuItem onClick={() => props.changeLanguageAction('en')} style={{ textAlign: 'center' }}>
              EN
            </MenuItem>
            <MenuItem onClick={() => props.changeLanguageAction('de')} style={{ textAlign: 'center' }}>
              DE
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  const MoreOptionsMenu = () => {
    return (
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <div className={iconHeaderBarStyleProps}>
            <MoreHorizontal28Regular {...iconStyleProps} />
          </div>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem
              onClick={() => navigate('/settings')}
              disabled={!checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_setting)}
              icon={<Settings24Regular {...menuIconsProps} />}
            >{`${__('settings')}`}</MenuItem>
            <LanguageMenu />
            <MenuDivider />
            <MenuItem
              icon={<Lightbulb24Regular {...menuIconsProps} />}
              onClick={() => {
                window.open(
                  `https://aiio.upvoty.com/front/handleSSO/${upvoty}/?redirectUrl=https://aiio.upvoty.com/b/aiio-features/`,
                );
              }}
            >{`${__('feature-proposal')}`}</MenuItem>
            <MenuItem
              icon={<Bug24Regular {...menuIconsProps} />}
              onClick={() => {
                window.open(
                  `https://aiio.upvoty.com/front/handleSSO/${upvoty}/?redirectUrl=https://aiio.upvoty.com/b/aiio-bugs/`,
                );
              }}
            >{`${__('report bug')}`}</MenuItem>
            <MenuDivider />
            <MenuItem
              icon={<Question24Regular {...menuIconsProps} />}
              onClick={() => {
                setWelcomePopupVisible(true);
              }}
            >{`${__('help')}`}</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.logo}>
          <a href="/">
            <img src="/images/aiio-logo.svg" />
          </a>
        </div>
        {isLicenseTest ? (
          <div style={{ flexGrow: 8 }}>
            <div className={classes.testPhaseDiv}>
              <p className={classes.testPhaseP1}>
                {__('test phase')} ({licenseRemainingDays} {__('days remaining')})
              </p>
              {checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_setting) ? (
                <p>
                  <a className={classes.testPhaseAnchor} href="/settings">
                    {__('licence now')}
                  </a>
                </p>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className={classes.userArea}>
          <MoreOptionsMenu />
          {<Notifications />}
          <Login
            className={classes.loginBtn}
            ref={loginControl}
            logoutCompleted={logoutCompleted}
            style={{ border: '1px solid blue' }}
          >
            <SignedinButtonTemplate template="signed-in-button-content"></SignedinButtonTemplate>
          </Login>
        </div>
      </div>
      <WelcomePopup visible={welcomePopupVisible} setVisible={setWelcomePopupVisible} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, MenuReducer }: RootState) => ({ UserReducer, MenuReducer });
const connector = connect(mapStateToProps, {
  changeLanguageAction,
});
export default connector(HeaderBar);
