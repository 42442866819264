import React, { useEffect, useState } from 'react';
import { Login } from '@microsoft/mgt-react';
import { createTenant } from '../../store/actions/tenant-actions';
import { getStyles } from './welcome-styles';
import { IStackTokens, Link, PrimaryButton, Spinner, SpinnerSize, Stack, Text, Icon } from '@fluentui/react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { __ } from 'modeling-tool';
import { AlertTemplate } from '../../layouts/alert-template';
import * as microsoftTeams from '@microsoft/teams-js';
import { IsTeamsApp } from '../../config/config';

const Welcome = () => {
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [warningText, setWarningText] = useState<string>();
  const [consentLink, setConsentLink] = useState<string>();

  const styles = getStyles();

  const stackTokens: IStackTokens = {
    childrenGap: 5,
  };
  const sectionStackTokens: IStackTokens = { childrenGap: 10 };

  useEffect(() => {
    if (IsTeamsApp()) {
      microsoftTeams.app.notifySuccess();
    }
  }, []);

  const onCreateButtonClick = () => {
    setIsWorking(true);
    createTenant((result) => {
      setIsWorking(false);
      if (result.response && result.response.status == 401) {
        setWarningText(__('tenant exists or licence expired'));
      } else if (result && result.status == 200) {
        setConsentLink(result.data);
        setIsDone(true);
      } else {
        setWarningText(__('unexpected error'));
      }
    });
  };

  const onGoButtonClick = () => {
    window.location.href = window.location.origin + '/home';
  };

  const renderControls = () => {
    if (warningText) {
      return (
        <Stack.Item align="center">
          <Text>{warningText}</Text>
        </Stack.Item>
      );
    } else {
      return (
        <>
          {!isDone && !isWorking && (
            <Stack.Item align="center">
              <PrimaryButton onClick={onCreateButtonClick}>{__('create tenant')}</PrimaryButton>
            </Stack.Item>
          )}
          {isWorking && (
            <Stack.Item align="center">
              <Spinner label={__('join aiio')} size={SpinnerSize.large} />
            </Stack.Item>
          )}
          {consentLink && (
            <Stack.Item align="center">
              <Text>
                {__('Aiio needs additional permissions for some functionalitites')}
                {': '}
                <Link href={consentLink} target="_blank">
                  {__('Link')}
                </Link>
              </Text>
            </Stack.Item>
          )}
          {isDone && (
            <Stack.Item align="center">
              <PrimaryButton onClick={onGoButtonClick}>{__('go to aiio')}</PrimaryButton>
            </Stack.Item>
          )}
        </>
      );
    }
  };

  return (
    <AlertProvider
      template={AlertTemplate}
      position={positions.TOP_CENTER}
      transition={transitions.FADE}
      timeout={5000}
      offset={'10px'}
    >
      <div className={styles.body}>
        <div className={styles.image}>
          <img alt="test Bild" src="/images/aiio-anmeldung-testbild.svg" />
        </div>
        <div className={styles.container}>
          <div className={isDone ? styles.loginPageGo : styles.loginPage}>
            <Stack tokens={sectionStackTokens}>
              <Stack.Item className={styles.logoHolder}>
                <img alt="aiio logo" src="/images/logo.svg" />
              </Stack.Item>
              {isDone && (
                <Stack.Item align="center">
                  <Stack wrap horizontal tokens={stackTokens}>
                    <Stack.Item grow={10}>
                      <Icon className={styles.icon} iconName="SkypeCircleCheck" />
                    </Stack.Item>
                    <Stack.Item>{__('your registration was successful')}</Stack.Item>
                  </Stack>
                </Stack.Item>
              )}
              <Stack.Item align="center">
                <Login />
              </Stack.Item>
              {renderControls()}
              <Stack.Item align="center">
                <div className={styles.copyRights}>{new Date().getFullYear()} © aiio - aiio GmbH</div>
              </Stack.Item>
            </Stack>
          </div>
        </div>
      </div>
    </AlertProvider>
  );
};

export default Welcome;
