import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const createInitialDataAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'initialdatadashboard/createinitialdata/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'CREATE_INITIAL_DATA',
    reqPayload: data,
    cb,
  });

export const deleteInitialDataAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'initialdatadashboard/deleteinitialdata/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'DELETE_INITIAL_DATA',
    reqPayload: data,
    cb,
  });
