import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react';
import { MenuItem } from '../../../ts/interfaces';
import { getCurrentLanguage } from 'modeling-tool';
import { getNavStyles, getStyles } from './sidebar-styles';
import { fetchMenusAction } from '../../../store/actions/menu-actions';
import { IsTeamsApp } from '../../../config/config';
import * as microsoftTeams from '@microsoft/teams-js';
import { useStyleProps } from '../../../hooks';
import { getNewTaskAction } from '../../../store/actions/task-actions';

enum Language {
  EN = 'EN',
  DE = 'DE',
}

const Sidebar = (props: PropsFromRedux) => {
  const {
    MenuReducer: {
      menus: { sidebar },
    },
    TaskReducer: { openTaskCount },
    getNewTaskAction,
  } = props;

  useEffect(() => {
    getNewTaskAction();
    const interval = setInterval(() => {
      getNewTaskAction();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);
  const navStyles = getNavStyles(styleProps);

  useEffect(() => {
    props.fetchMenusAction(function () {
      if (IsTeamsApp()) {
        microsoftTeams.app.notifySuccess();
      }
    });
  }, []);

  const sidebarLinks: INavLinkGroup[] = [
    {
      links: sidebar
        ? sidebar.items.map((item: MenuItem) => {
            return {
              name: getCurrentLanguage().toUpperCase() === Language.EN ? item.title : item.titleDE,
              url: item.url,
              key: item.url,
              target: '',
              icon: item.icon,
            } as INavLink;
          })
        : [],
    },
  ];

  const _onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    ev?.preventDefault();
    item?.url && navigate(item?.url);
  };

  return (
    <div className={styles.sidebar}>
      {/* {openTaskCount !== 0 && (
        <Stack className={styles.badgeContainer}>
          <Stack className={styles.notificationBadge}>{openTaskCount < 99 ? openTaskCount : '99+'}</Stack>
        </Stack>
      )} */}
      <Nav
        onLinkClick={_onLinkClick}
        selectedKey={'/' + location.pathname.split('/')[1]}
        ariaLabel="Nav basic example"
        styles={navStyles}
        groups={sidebarLinks}
      ></Nav>
    </div>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, MenuReducer, DashboardReducer, TaskReducer }: RootState) => ({
  UserReducer,
  MenuReducer,
  DashboardReducer,
  TaskReducer,
});
const connector = connect(mapStateToProps, { fetchMenusAction, getNewTaskAction });
export default connector(Sidebar);
