import React, { useContext, useEffect } from 'react';
import { UtilityContext } from 'modeling-tool';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { clearQueryChapterParamsAction, clearSelectedRequirement } from '../../store/actions/requirement-action';
import { Requirement } from '../../ts/interfaces';
import { Stack } from '@fluentui/react';
import { RequirementsList } from './requirements-list';
import RequirementForm from './requirements-list/requirement-form/requirement-form';

const Requirements = (props: PropsFromRedux) => {
  const { clearQueryChapterParamsAction, clearSelectedRequirement } = props;

  const { setUtilitySideBar } = useContext(UtilityContext);

  useEffect(() => {
    clearQueryChapterParamsAction();
    window.addEventListener('click', handleEventListener);
    return () => {
      closeSidebar();
      window.removeEventListener('click', handleEventListener);
    };
  }, []);

  const handleEventListener = () => {
    closeSidebar();
  };

  const renderDetailsBar = (selectedRequirement: Requirement) => {
    setUtilitySideBar &&
      setUtilitySideBar({
        list: [
          <RequirementForm
            key={selectedRequirement.id}
            selectedRequirement={selectedRequirement}
            closeSidebar={closeSidebar}
          />,
        ],
      });
  };

  const closeSidebar = () => {
    setUtilitySideBar({
      list: [],
    });
    clearSelectedRequirement();
  };

  return (
    <>
      <Stack>
        <Stack horizontal>
          <Stack.Item grow>
            <RequirementsList renderDetailsBar={renderDetailsBar} />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({}: RootState) => ({});
const connector = connect(mapStateToProps, {
  clearQueryChapterParamsAction,
  clearSelectedRequirement,
});
export default connector(Requirements);
