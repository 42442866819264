import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchOrgFullModel = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `orgfullmodel/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_ORG_FULL_MODEL',
    cb,
  });

export const addOrgFullModel = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `orgfullmodel/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_ORG_FULL_MODEL',
    reqPayload: data,
    cb,
  });

export const updateOrgFullModel = (data: any, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: `orgfullmodel/update/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_ORG_FULL_MODEL',
    reqPayload: data,
    cb,
    ecb,
  });

export const fetchOrgChartsAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `orgchart/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_ORG_CHARTS',
    cb,
  });

export const addOrgChartAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'orgchart/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_ORG_CHART',
    reqPayload: data,
    cb,
  });

export const selectOrgChartAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_ORG_CHART',
    payload: data,
  });

export const clearSelectOrgChartAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_SELECT_ORG_CHART',
  });

export const getOrgChartFullModelByParamsAction = (data: any, cb?: (...args: any[]) => void) => {
  const search = `?from_org_chart=${data.id}`;
  return UseAction({
    url: `orgfullmodel${search}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_ORG_CHART_FULL_MODEL',
    cb,
  });
};

export const updateOrgChartAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `orgchart/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_ORG_CHART',
    reqPayload: data,
    cb,
  });

export const deleteOrgChartAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `orgchart/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_ORG_CHART',
    payload: data.id,
    cb,
  });

export const enableEditMode = () => ({
  type: 'ENABLE_EDIT_MODE',
});

export const toggleEditMode = () => ({
  type: 'TOGGLE_EDIT_MODE',
});
