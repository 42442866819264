import { mergeStyleSets, IDetailsListStyles } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    container: {
      border: '1px solid #F1F1F1',
      background: '#FFFFFF',
    },

    sectionContainer: {
      position: 'absolute',
      width: '1554px',
      height: '251px',
      left: '15px',
      top: '265px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },

    title: {
      fontSize: '20px',
      fontWeight: 600,
      height: '27px',
    },

    content: {
      fontSize: '14px',
      fontWeight: 400,
      top: '78px',
      left: '111px',
      lineHeight: '20px',
      color: '#000000',

      position: 'absolute',
      width: '1475px',
      height: '95px',
    },

    processDescription: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '205px',
      left: '15px',
      top: '28px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },
    responsibilities: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '251px',
      left: '15px',
      top: '265px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },
    documents: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '182px',
      left: '15px',
      top: '532px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },
    externalLinks: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '152px',
      left: '15px',
      top: '730px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },
    managementSystems: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '211px',
      left: '15px',
      top: '898px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },
    resources: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '152px',
      left: '15px',
      top: '1125px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },
    locations: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '152px',
      left: '15px',
      top: '1293px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },
    subprocesses: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '152px',
      left: '15px',
      top: '1466px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },
    masterData: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '1554px',
      height: '153px',
      left: '15px',
      top: '1643px',
      background: '#FFFFFF',
      border: '1px solid #F1F1F1',
    },

    pageBreakAvoid: {
      breakInside: 'avoid',
    },
    // --------------- new styles ---------------
    sectionStyles: {
      padding: '24px 32px 24px 32px',
      background: theme.palette.white,
      height: '100% !important',
    },
    masterDataValues: {
      fontWeight: 600,
      minWidth: '200px',
    },

    cardBackground: {
      padding: '16px 0px',
      background: 'rgb(246, 246, 246, 0.6)',
    },

    raciFieldCell: {
      minWidth: '200px',
    },
    cellText: {
      width: '100%',
      fontWeight: 400,
    },
    cellTextAssignedTo: {
      textDecoration: 'none',
      color: '#605E5C',
      pointerEvents: 'none',
    },
    cellTextStatus: {
      textAlign: 'center',
      alignSelf: 'center',
    },
    status: {
      borderRadius: '100px',
      padding: '4px 8px',
      height: '24px',
      width: '120px',
    },
    tagStyles: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '3px',
      width: '150px',
      height: '24px',
      '& span': {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
    description: {
      '& > *': {
        margin: 0,
      },
    },
  });
});

export const getGridStyle = () => {
  return {
    root: {
      selectors: {
        '& [role=grid]': {
          height: 'auto !important',
        },
      },
    },
  } as Partial<IDetailsListStyles>;
};
