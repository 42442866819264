import { Meta, ProcessInstance } from '../../ts/interfaces';
interface ProcessInstanceReducerState {
  instances: ProcessInstance[];
  historyInstances: ProcessInstance[];
  meta?: Meta;
  selectedInstance?: ProcessInstance;
  selected: ProcessInstance[];
}

export default function reducer(
  state: ProcessInstanceReducerState = {
    instances: [],
    historyInstances: [],
    meta: undefined,
    selectedInstance: undefined,
    selected: [],
  },
  action: any,
): ProcessInstanceReducerState {
  switch (action.type) {
    case 'FETCH_PROCESS_INSTANCES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        instances: action.payload.objects,
      });
    }
    case 'SEARCH_PROCESS_INSTANCES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        instances: action.payload.objects,
      });
    }
    case 'SEARCH_PROCESS_HISTORY_INSTANCES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        historyInstances: action.payload.objects,
      });
    }
    case 'GET_PROCESS_INSTANCE': {
      return Object.assign({}, state, {
        selectedInstance: action.payload,
      });
    }
    case 'FETCH_MODELING_INSTANCE': {
      return Object.assign({}, state, {
        selectedInstance: action.payload,
      });
    }

    case 'SELECT_PROCESS_INSTANCE': {
      return Object.assign({}, state, {
        selectedInstance: action.payload,
      });
    }
    case 'SELECT_PROCESS_INSTANCES': {
      return Object.assign({}, state, {
        selected: state.instances.filter((instance) => action.payload.indexOf(instance.id) !== -1),
      });
    }
    case 'ADD_SELECT_PROCESS_INSTANCES': {
      return Object.assign({}, state, {
        selected: [...state.selected, action.payload],
        instances: [...state.instances, action.payload],
      });
    }
    case 'UPDATE_SELECTED_PROCESS_INSTANCE': {
      return Object.assign({}, state, {
        selectedInstance: { ...state.selectedInstance, ...action.payload },
      });
    }

    case 'SELECT_PROCESS_INSTANCE_BY_ID': {
      return Object.assign({}, state, {
        selectedInstance: state.instances.find((instance) => instance.id === parseInt(action.payload)),
      });
    }

    case 'SET_PROCESS_INSTANCE_FROM_TASK': {
      return Object.assign({}, state, {
        selectedInstance: action.payload,
      });
    }

    case 'UPDATE_PROCESS_INSTANCE': {
      return {
        ...state,
        instances: state.instances.map((process) => (process.id === action.payload.id ? action.payload : process)),
        selectedInstance: action.payload,
      };
    }
    case 'ADD_PROCESS_INSTANCE': {
      return {
        ...state,
        instances: state.instances.concat(action.payload),
      };
    }
    case 'DELETE_PROCESS_INSTANCE': {
      return {
        ...state,
        instances: state.instances.filter((instance) => instance.id !== action.payload),
      };
    }

    case 'SET_META_PROCESS_INSTANCE': {
      return Object.assign({}, state, {
        meta: { ...state.meta, ...action.payload },
      });
    }

    case 'TRANSLATE_PROCESS_MODEL': {
      return {
        ...state,
      };
    }

    case 'CLEAR_PROCESS_INSTANCES': {
      return Object.assign({}, state, {
        meta: undefined,
        instances: [],
        selectedInstance: undefined,
      });
    }

    case 'CLEAR_PROCESS_INSTANCE': {
      return Object.assign({}, state, {
        meta: undefined,
        selectedInstance: undefined,
      });
    }

    default: {
      break;
    }
  }
  return state;
}
