import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IDropdownOption, Dropdown, Stack, Icon } from '@fluentui/react';
import ReactPaginate from 'react-paginate';
import { addQueryParamsAction } from '../../../../store/actions/process-actions';
import { getStyles } from './pagination-process-styles';
import { RootState } from '../../../../config/store';
import { useStyleProps } from '../../../../hooks';
import { setQueryParams } from '../process-filter/process-filter-utility/process-filter-utils';

interface IPagination extends PropsFromRedux {
  currentOffset: number;
  search: string;
  itemsPerPage: string;
  setItemsPerPage: (arg: string) => void;
  setCurrentOffset: (arg: number) => void;
  toggleChangeParams: () => void;
}

const PaginationProcess = (props: IPagination) => {
  const {
    ProcessReducer: { meta, filterValues, params },
    currentOffset,
    itemsPerPage,
    setCurrentOffset,
    setItemsPerPage,
    addQueryParamsAction,
    toggleChangeParams,
  } = props;

  const [firstRendering, setFirstRendering] = useState<boolean>(true);
  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  const options: IDropdownOption[] = [
    { key: '20', text: '20' },
    { key: '50', text: '50' },
    { key: '100', text: '100' },
  ];

  useEffect(() => {
    setValues();
    setFirstRendering(false);
  }, []);

  useEffect(() => {
    setValues();
    if (!firstRendering) {
      toggleChangeParams();
    }
  }, [currentOffset, itemsPerPage]);

  const setValues = () => {
    const queryParams = setQueryParams(filterValues, params);
    const queryResult = queryParams.map((item: any) => {
      switch (Object.keys(item)[0]) {
        case 'outdated':
          return { outdated: 'false' };
        case 'offset':
          return { offset: currentOffset };
        case 'limit':
          return { limit: itemsPerPage };
        case 'level':
          return { level: 0 };
        default:
          return item;
      }
    });
    addQueryParamsAction(queryResult);
  };

  const onChange = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
    setItemsPerPage(`${item?.key}`);
  };

  const handlePageClick = (event: any) => {
    const newOffset = event.selected * Number(itemsPerPage);
    setCurrentOffset(newOffset);
  };

  const previousLabel = <Icon iconName="ChevronLeft" style={{ fontSize: 12 }} />;
  const nextLabel = <Icon iconName="ChevronRight" style={{ fontSize: 12 }} />;

  const renderPageControl = () => {
    if (meta && meta.totalCount && meta.totalCount > parseInt(itemsPerPage)) {
      return (
        <ReactPaginate
          previousLabel={previousLabel}
          nextLabel={nextLabel}
          pageCount={meta && meta?.totalCount === 0 ? 1 : Math.ceil(Number(meta?.totalCount) / Number(itemsPerPage))}
          marginPagesDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={styles.pagginationBttns}
          previousLinkClassName={styles.paginationLink}
          nextLinkClassName={styles.paginationLink}
          disabledClassName={styles.paginationLinkDisabled}
          activeClassName={styles.paginationLinkActive}
          forcePage={currentOffset / Number(itemsPerPage)}
        />
      );
    }
  };

  return (
    <Stack horizontal className={styles.controllSection} tokens={{ childrenGap: 10 }}>
      {renderPageControl()}
      <Dropdown
        defaultSelectedKey={itemsPerPage}
        options={options}
        onChange={onChange}
        className={styles.dropdownStyles}
      />
    </Stack>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ ProcessReducer }: RootState) => ({
  ProcessReducer,
});
const connector = connect(mapStateToProps, {
  addQueryParamsAction,
});
export default connector(PaginationProcess);
