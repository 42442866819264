import React, { MutableRefObject, useState } from 'react';
import { Callout, Icon, Stack } from '@fluentui/react';
import Item from './item';
import { getStyles } from './process-menu-styles';

interface IRenderDynamicContentProps {
  id: number;
  record: any;
  cellWidth: MutableRefObject<number>;
  isDepartment?: boolean;
}
const RenderDynamicContent = (props: IRenderDynamicContentProps) => {
  const { id, record, cellWidth, isDepartment } = props;
  const [calloutVisible, setCallOutVisible] = useState<boolean>(false);
  const calloutStackId = isDepartment ? `callout-department-${id}` : `callout-location-${id}`;
  const styles = getStyles();

  const calculateItemsWidth = () => {
    let totalItemsWidth = 0;
    const widthItems: number[] = [];
    const items = record;
    if (items) {
      items.forEach((element: any) => {
        const itemWidth = element.width || 0;
        totalItemsWidth += itemWidth + 3;
        widthItems.push(itemWidth + 3);
      });

      const countShowedItems = widthItems.reduce((count, width, index) => {
        const totalWidth = count > 0 ? widthItems.slice(0, index + 1).reduce((sum, w) => sum + w, 0) : 0;
        return totalWidth <= cellWidth.current * 0.9 ? count + 1 : count;
      }, 0);
      return { totalItemsWidth, countShowedItems };
    } else {
      return { totalItemsWidth, countShowedItems: 0 };
    }
  };

  const { totalItemsWidth, countShowedItems } = calculateItemsWidth();

  const renderDepartments = () => {
    const firstPart = record?.slice(0, countShowedItems);
    const remaining = record?.slice(countShowedItems);
    return (
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        onMouseLeave={() => setCallOutVisible(false)}
        tokens={{ childrenGap: 4 }}
        style={{ height: 'auto' }}
      >
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 3 }} style={{ width: '90%' }}>
          {firstPart?.map((unit: any, i: number) => (
            <Item
              key={isDepartment ? `department-${Math.random()}` : `location-${Math.random()}`}
              item={unit}
              calloutVisible={calloutVisible}
              isDepartment={isDepartment}
            >
              {i < record.length - 1 ? ', ' : ''}
            </Item>
          ))}
        </Stack>
        <Stack>
          <Icon
            iconName="More"
            style={{ fontSize: 12, cursor: 'pointer', marginLeft: '5px' }}
            onMouseEnter={() => setCallOutVisible(true)}
            id={calloutStackId}
          />
        </Stack>
        {calloutVisible && (
          <Callout
            isBeakVisible={false}
            onDismiss={() => setCallOutVisible(false)}
            target={`#${calloutStackId}`}
            className={styles.callout}
          >
            {remaining?.map((unit: any) => (
              <Item
                key={isDepartment ? `department-${Math.random()}` : `location-${Math.random()}`}
                item={unit}
                calloutVisible={calloutVisible}
                isDepartment={isDepartment}
              />
            ))}
          </Callout>
        )}
      </Stack>
    );
  };

  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 3 }} style={{ width: '100%', height: 'auto' }}>
      {totalItemsWidth <= cellWidth.current * 0.9 ? (
        <>
          {record?.map((unit: any, i: number) => (
            <Item
              key={isDepartment ? `department-${Math.random()}` : `location-${Math.random()}`}
              item={unit}
              calloutVisible={calloutVisible}
              isDepartment={isDepartment}
            >
              {i < record.length - 1 ? ', ' : ''}
            </Item>
          ))}
        </>
      ) : (
        <>{record.length && renderDepartments()}</>
      )}
    </Stack>
  );
};
export default RenderDynamicContent;
