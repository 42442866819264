import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../config/store';
import { useContext, useEffect, useState } from 'react';
import {
  addOrgChartAction,
  clearSelectOrgChartAction,
  deleteOrgChartAction,
  fetchOrgChartsAction,
  toggleEditMode,
} from '../../store/actions/org-action';
import { IOrgChart } from '../../ts/interfaces';
import { OrganizationCard } from './organization-card';
import { EntityList } from '../../components/shared/entity-list';
import { GLOBALENUMS, Modal, UtilityContext, __ } from 'modeling-tool';
import { checkPermission } from '../../config/permission-utils';
import { Button, Spinner, Text } from '@fluentui/react-components';
import { Edit16Regular } from '@fluentui/react-icons';
import { useStyles } from './organization-charts-styles';
import { useBoolean } from '@fluentui/react-hooks';
import { OrganizationForm } from './organization-form';
import { useNavigate } from 'react-router';

const OrganizationCharts = (props: PropsFromRedux) => {
  const {
    OrgChartReducer: { orgcharts, editMode, selectedOrgChart },
    UserReducer: { permissions },
    fetchOrgChartsAction,
    addOrgChartAction,
    clearSelectOrgChartAction,
    deleteOrgChartAction,
    toggleEditMode,
  } = props;

  const navigate = useNavigate();
  const classes = useStyles();

  const { setUtilitySideBar, setBreadcrumbButtons } = useContext(UtilityContext);
  const [readonly, setReadonly] = useState<boolean | undefined>(true);
  const [hasNoPermissions, setHasNoPermissions] = useState<boolean | undefined>(false);
  const [initDone, setInitDone] = useState<boolean>(false);
  const [isModalOpen, { toggle: toggleModal }] = useBoolean(false);

  const userHasPermission = checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_orgfullmodel);

  useEffect(() => {
    setHasNoPermissions(!userHasPermission);
    editMode ? setReadonly(!userHasPermission) : setReadonly(true);
    buttonSection();
  }, [editMode]);

  useEffect(() => {
    getOrgChart();
    window.addEventListener('click', handleEventListener);
    clearSelectOrgChartAction();
    return () => {
      closeSidebar();
      window.removeEventListener('click', handleEventListener);
      setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
    };
  }, []);

  const getOrgChart = () => {
    fetchOrgChartsAction((res: any) => {
      if (res.status === 200) {
        setInitDone(true);
      }
    });
  };

  const closeSidebar = () => {
    setUtilitySideBar({
      list: [],
    });
    clearSelectOrgChartAction();
  };

  const handleEventListener = () => {
    closeSidebar();
  };
  
  const handleDelete = () => {
    deleteOrgChartAction(selectedOrgChart, () => {
      clearSelectOrgChartAction();
    });
    toggleModal();
    closeSidebar();
  };

  const handleCancel = () => {
    toggleModal();
  };

  const redirectToOrgchart = (subId: number | undefined) => {
    if (subId) {
      const url = `/organization/${subId.toString()}`;
      navigate(url);
    }
  };

  const buttonSection = () => {
    if (checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_orgfullmodel)) {
      const buttonLabel = editMode ? __('end modeling') : __('start modeling');

      const buttonList = (
        <Button
          appearance="primary"
          onClick={() => toggleEditMode && toggleEditMode()}
          key="process-map-buttons-section"
          icon={<Edit16Regular />}
        >
          {buttonLabel}
        </Button>
      );

      if (!checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_orgfullmodel)) return;

      return (
        setBreadcrumbButtons &&
        setBreadcrumbButtons({
          list: [buttonList],
        })
      );
    }
  };

  const renderDetailsBar = (orgchart: IOrgChart) => {
    setUtilitySideBar &&
      setUtilitySideBar({
        list: [
          <OrganizationForm
            key={orgchart.id}
            orgchart={orgchart}
            closeSidebar={closeSidebar}
            toggleModal={toggleModal}
            readonly={readonly}
          />,
        ],
      });
  };

  const renderOrgCard = (orgchart: IOrgChart) => (
    <OrganizationCard
      orgchart={orgchart}
      renderDetailsBar={renderDetailsBar}
      closeSidebar={closeSidebar}
      readonly={readonly}
      redirectToOrgchart={redirectToOrgchart}
    />
  );

  const LoadingSpinner = () => {
    return (
      <div className={classes.centeredContainer}>
        <Spinner />
      </div>
    );
  };

  const NoOrgChartMessage = () => {
    return (
      <div className={classes.centeredContainer}>
        <span className={classes.message}>{__('no organization has been created yet')}</span>
      </div>
    );
  };

  if (!initDone) return <LoadingSpinner />;

  if (hasNoPermissions) {
    if (!orgcharts.length) return <NoOrgChartMessage />;
    if (orgcharts.length === 1) return <>{redirectToOrgchart(orgcharts[0].id)}</>;
  }
  return (
    <>
      <EntityList
        entities={orgcharts}
        readonly={readonly}
        renderEntity={renderOrgCard}
        addActionText={__('create new organization')}
        addAction={addOrgChartAction}
      />
      <Modal
        title={__('confirm delete')}
        isModalOpen={isModalOpen}
        onSave={handleDelete}
        onCancel={handleCancel}
        saveButtonText={'Ok'}
      >
        <Text>{__('do you really want to delete the selected organization?')}</Text>
      </Modal>
    </>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, OrgChartReducer }: RootState) => ({ UserReducer, OrgChartReducer });

const connector = connect(mapStateToProps, {
  fetchOrgChartsAction,
  addOrgChartAction,
  clearSelectOrgChartAction,
  deleteOrgChartAction,
  toggleEditMode,
});

export default connector(OrganizationCharts);
