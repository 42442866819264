import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  fieldDiv: {
    width: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...shorthands.margin('0px', '0px', '8px', '0px'),
  },
  inputField: {
    width: '72px',
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px',
  },
});
