import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchTasksAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `task/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_TASKS',
    cb,
  });

export const searchTasksAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `task/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_TASKS',
    cb,
  });

export const clearTaskAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_TASK',
    cb,
  });

export const getTaskAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `task/${id}/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_TASK',
    cb,
  });

export const selectTaskAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_TASK',
    payload: data,
  });

export const selectTaskByIdAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_TASK_BY_ID',
    payload: id,
    cb,
  });

export const selectTasksAction = (ids: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_TASKS',
    payload: ids,
  });

export const addSelectedTasksAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'ADD_SELECT_TASKS',
    payload: data,
  });

export const updateAcceptedTasksAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'UPDATE_ACCEPTED_TASK',
    payload: data,
  });

export const updateSelectedTaskAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'UPDATE_SELECTED_TASK',
    payload: data,
  });

export const updateTaskAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `task/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_TASK',
    reqPayload: data,
    cb,
  });

export const addTaskAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'task/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_TASK',
    reqPayload: data,
    cb,
  });

export const deleteTaskAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `task/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_TASK',
    payload: data.id,
    cb,
  });

export const setMetaAction = (payload: any, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SET_META_TASK',
    payload: payload,
    cb,
  });

export const getNewTaskAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `task/new_task_count/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_NEW_TASK_COUNT',
    cb,
  });
