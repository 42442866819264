import { Meta, Requirement, RequirementChapter } from '../../ts/interfaces';
import { setFilterValues } from './process-reducer';

interface RequirementReducerState {
  chapterChildren?: RequirementChapter[];
  meta?: Meta;
  openRequirement?: Requirement;
  orderByChapterColumns?: string;
  params: any;
  requirementChapters: RequirementChapter[];
  requirements: Requirement[];
  selectedRequirement?: Requirement;
  selectedRequirementChapter?: RequirementChapter;
  filterValues: any;
}

export default function reducer(
  state: RequirementReducerState = {
    requirements: [],
    requirementChapters: [],
    chapterChildren: [],
    orderByChapterColumns: 'order_by=-date_created',
    params: [],
    filterValues: {},
  },
  action: any,
): RequirementReducerState {
  switch (action.type) {
    case 'FETCH_REQUIREMENTS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        requirements: action.payload.objects,
        requirementChapters: action.payload.objects,
      });
    }
    case 'FETCH_REQUIREMENT_BY_ID': {
      return Object.assign({}, state, {
        openRequirement: action.payload,
      });
    }
    case 'OPEN_REQUIREMENT_BY_ID': {
      return Object.assign({}, state, {
        openRequirement: state.requirements.find((requirement) => requirement.id === action.payload),
      });
    }

    case 'SELECT_REQUIREMENT_BY_ID': {
      return Object.assign({}, state, {
        selectedRequirement: state.requirements.find((requirement) => requirement.id === action.payload),
      });
    }

    case 'SEARCH_REQUIREMENTS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        requirements: action.payload.objects,
      });
    }
    case 'SEARCH_REQUIREMENT_CHAPTERS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        requirementChapters: action.payload.objects,
      });
    }
    case 'CLEAR_REQUIREMENTS': {
      return Object.assign({}, state, {
        requirements: [],
        meta: undefined,
      });
    }
    case 'CLEAR_SELECTED_REQUIREMENT': {
      return Object.assign({}, state, {
        selectedRequirement: undefined,
      });
    }
    case 'CLEAR_OPEN_REQUIREMENT': {
      return Object.assign({}, state, {
        openRequirement: undefined,
      });
    }
    case 'UPDATE_REQUIREMENT': {
      return {
        ...state,
        requirements: state.requirements.map((requirement) =>
          requirement.id === action.payload.id ? action.payload : requirement,
        ),
      };
    }
    case 'ADD_REQUIREMENT': {
      state.requirements.push(action.payload);
      return {
        ...state,
        requirements: state.requirements,
      };
    }
    case 'FETCH_REQUIREMENT_CHAPTER_BY_ID': {
      return Object.assign({}, state, {
        selectedRequirementChapter: action.payload,
      });
    }
    case 'SELECT_REQUIREMENT_CHAPTER': {
      return Object.assign({}, state, {
        selectedRequirementChapter: state.requirementChapters.find((chapter) => chapter.id === action.payload),
      });
    }
    case 'CLEAR_SELECT_REQUIREMENT_CHAPTER': {
      return Object.assign({}, state, {
        selectedRequirementChapter: undefined,
      });
    }
    case 'UPDATE_REQUIREMENT_CHAPTER': {
      return Object.assign({}, state, {
        selectedRequirementChapter: action.payload,
      });
    }
    case 'ADD_REQUIREMENT_CHAPTER': {
      return Object.assign({}, state, {
        ...state,
      });
    }
    case 'DELETE_REQUIREMENT_CHAPTER': {
      return Object.assign({}, state, {
        ...state,
      });
    }
    case 'FETCH_CHAPTER_CHILDREN': {
      return Object.assign({}, state, {
        chapterChildren: action.payload,
      });
    }
    case 'SET_CHAPTER_ORDER_BY_COLUMNS': {
      return Object.assign({}, state, {
        orderByChapterColumns: action.payload,
      });
    }
    case 'ADD_QUERY_CHAPTER_PARAMS': {
      return {
        ...state,
        params: action.payload,
        filterValues: setFilterValues(state.filterValues, action),
      };
    }
    case 'CLEAR_QUERY_CHAPTER_PARAMS': {
      return {
        ...state,
        params: (state.params = undefined),
      };
    }
    default: {
      break;
    }
  }
  return state;
}
