import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const getCommentsByProcessInstanceIdAction = (uri: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `comments/?instance=${uri}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_INSTANCE_COMMENTS',
    cb,
  });
export const addCommentAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'comments/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_COMMENT',
    reqPayload: data,
    cb,
  });

export const updateCommentRatingAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `comments/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_RATING_COMMENT',
    reqPayload: data,
    cb,
  });

export const deleteCommentAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `comments/${id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_COMMENT',
    payload: id,
    cb,
  });

export const clearComments = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_COMMENTS',
    cb,
  });
