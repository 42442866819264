import { IStackItemStyles, ITooltipHostStyles, mergeStyles, mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../ts/interfaces';

export const listClassStyles = () => {
  return mergeStyles({
    display: 'inline-block',
    margin: '10px 5px 20px',
    justifyContent: 'center',
  });
};

export const cardStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    card: {
      justifyContent: 'center',
      height: '100%',
      width: 290,
      paddingBottom: '5px',
    },
    containerCard: {
      margin: '15px 5px 0 15px',
    },
    globalCardStyles: {
      height: '100%',
      width: '100%',
      marginRight: '15px',
      maxWidth: '400px',
      borderStyle: 'none',
      background: theme.palette.white,
      marginLeft: '16px',
    },
    globalstatus: {
      marginTop: '15px',
      marginLeft: '20px',
    },
    headerCard: {
      clear: 'both',
    },

    cardParticipants: {
      marginTop: '10px',
      height: '100%',
      width: 100,
    },
    cardDate: {
      marginTop: '10px',
    },
    wrapper: {
      height: 'calc(100vh - 304px)',
      position: 'relative',
      maxHeight: 'inherit',
    },
    filterButton: {
      margin: '5px',
      float: 'right',
    },
    noWorkingGroupsMsg: {
      marginTop: '150px',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 'large',
      color: theme.palette.neutralTertiaryAlt,
    },
    btnsHolder: {
      float: 'right',
      paddingRight: '16px',
    },
    icon: {
      color: `${theme.palette.themePrimary}`,
      marginRight: '5px',
    },
    location: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      marginTop: '10px',
    },
    cardButtons: {
      justifyContent: 'end',
    },
    bodyCard: {
      height: 100,
      margin: '0 5px 8px 5px',
    },
    title: {
      color: `${theme.palette.themePrimary}`,
    },
  });
});

export const stackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    minHeight: '20px',
  },
};

export const toolTipStyles: ITooltipHostStyles = {
  root: {
    width: '100%',
  },
};
