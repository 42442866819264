import React, { Dispatch, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import { DropdownWithSearch, GLOBALENUMS, LinkInput, Modal, TextField, __, FileList, TextEditor } from 'modeling-tool';
import { BasicListActionEnum, ChapterModalActions } from '../../../../ts/enums';
import { useForm } from 'react-hook-form';
import { IDropdownOption, Label, Stack } from '@fluentui/react';
import { renderSelectDropDown } from '../../../../utils/process-management';
import { fetchLibrariesAction, fetchSitesAction } from '../../../../store/actions/file-actions';
import {
  addRequirementChapterAction,
  clearSelectRequirementChapterAction,
  updateRequirementChapterAction,
} from '../../../../store/actions/requirement-action';
import { RequirementChapter } from '../../../../ts/interfaces';
import { isDescendant } from '../../../../utils';
import { BasicListAction } from '../../../../components/basic-list';
import { fetchDropdownDataByType } from '../../../../store/actions/dropdown-data-actions';

interface ChapterFormProps {
  modalActionType?: ChapterModalActions;
  modalVisible: boolean;
  closeModal: () => void;
  updateRows: Dispatch<BasicListAction>;
}
const dropDownData = [GLOBALENUMS.DROPDOWNDATA.REQUIREMENT_CHAPTERS, GLOBALENUMS.DROPDOWNDATA.PROCESSES];

const ChapterForm = (props: PropsFromRedux & ChapterFormProps) => {
  const {
    DropdownDataReducer: { processes, requirementChapters },
    RequirementReducer: { openRequirement, selectedRequirementChapter },
    modalActionType,
    modalVisible,
    addRequirementChapterAction,
    clearSelectRequirementChapterAction,
    closeModal,
    fetchLibrariesAction,
    fetchSitesAction,
    updateRequirementChapterAction,
    updateRows,
    fetchDropdownDataByType,
  } = props;

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
  }, []);

  useEffect(() => {
    if (selectedRequirementChapter) {
      let data = null;
      if (modalActionType === ChapterModalActions.CREATE_SUBCHAPTER) {
        data = {
          parent: selectedRequirementChapter.resourceUri,
        };
      } else {
        data = {
          ...selectedRequirementChapter,
          attachments: selectedRequirementChapter.attachments?.length
            ? selectedRequirementChapter.attachments?.split('|')
            : [],
          parent: selectedRequirementChapter.parent ? [selectedRequirementChapter.parent.resourceUri] : null,
          linkedProcesses: selectedRequirementChapter.linkedProcesses?.map((process) => process.resourceUri),
        };
      }
      reset(data);
    } else {
      reset();
    }
  }, []);

  const handleOnSave = () => {
    if (!isValid) {
      return;
    }
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const onFinish = (data: any) => {
    data = {
      ...data,
      attachments: data.attachments?.length ? data.attachments.join('|') : null,
      parent: data.parent && Array.isArray(data.parent) ? data.parent[0] || null : data.parent || null,
      fromRequirement: openRequirement?.resourceUri,
    };
    if (selectedRequirementChapter && modalActionType !== ChapterModalActions.CREATE_SUBCHAPTER) {
      updateRequirementChapterAction(data, (result) => {
        updateRows({
          type: BasicListActionEnum.UPDATE_ITEM_ROW,
          payload: result.data,
          id: selectedRequirementChapter.id,
        });
        setShowLoading(false);
        closeModal();
        clearSelectRequirementChapterAction();
      });
    } else {
      addRequirementChapterAction(data, (result) => {
        updateRows({ type: BasicListActionEnum.INSERT_ITEM_ROW, payload: result.data });
        setShowLoading(false);
        closeModal();
        clearSelectRequirementChapterAction();
      });
    }
  };

  const handleOnCancel = () => {
    clearSelectRequirementChapterAction();
    closeModal();
  };

  const getModalTitle = () => {
    switch (modalActionType) {
      case ChapterModalActions.NEW_CHAPTER:
      case ChapterModalActions.CREATE_SUBCHAPTER: {
        return __('create new chapter');
      }
      case ChapterModalActions.EDIT_CHAPTER: {
        return __('edit chapter');
      }
      default:
        return '';
    }
  };

  const filterChapters = (chapters: RequirementChapter[], selected: RequirementChapter): RequirementChapter[] => {
    const filterCondition = (chapter: RequirementChapter): boolean => {
      const filterSelected = chapter.id !== selected?.id || modalActionType === ChapterModalActions.CREATE_SUBCHAPTER;
      return filterSelected && !isDescendant(chapter, selected, chapters);
    };
    return chapters.filter(filterCondition);
  };

  const renderChapterDropDown = () => {
    const filteredRequirementChapters = requirementChapters.filter(
      (chapter: RequirementChapter) => chapter.fromRequirement === openRequirement?.resourceUri,
    );
    if (!selectedRequirementChapter) {
      return filteredRequirementChapters.map(
        (chapter: RequirementChapter) => ({ key: chapter.resourceUri, text: chapter.name }) as IDropdownOption,
      );
    }
    const filteredChapters = filterChapters(filteredRequirementChapters, selectedRequirementChapter);
    return filteredChapters.map(
      (chapter: RequirementChapter) => ({ key: chapter.resourceUri, text: chapter.name }) as IDropdownOption,
    );
  };

  const renderModalContent = () => {
    return (
      <form>
        <Stack tokens={{ childrenGap: 15 }}>
          <TextField
            label={__('title')}
            control={control}
            name={'name'}
            required={true}
            rules={{
              required: __('This field is required'),
              maxLength: { value: 255, message: __('too many characters') },
            }}
          />
          <DropdownWithSearch
            control={control}
            name={'parent'}
            labelTitle={__('parent chapter')}
            placeholder={__('please select a chapter')}
            options={renderChapterDropDown()}
          />
          <Stack>
            <Label styles={{ root: { fontWeight: 400 } }}>{__('description')}</Label>
            <TextEditor control={control} name={'description'} labelTitle={__('description')} />
          </Stack>
          <DropdownWithSearch
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.PROCESSES, processes)}
            control={control}
            name={'linkedProcesses'}
            placeholder={__('please select a process')}
            multiSelect
            labelTitle={__('linked processes')}
          />
          <LinkInput control={control} readOnly={false} horizontal />
          <Stack>
            <Label styles={{ root: { fontWeight: 400 } }}>{__('documents')}</Label>
            <FileList
              control={control}
              name={'attachments'}
              fetchLibrariesAction={fetchLibrariesAction}
              fetchSitesAction={fetchSitesAction}
            />
          </Stack>
        </Stack>
      </form>
    );
  };

  return (
    <Modal
      enableProgress={showLoading}
      title={getModalTitle()}
      isModalOpen={modalVisible}
      onSave={handleOnSave}
      onCancel={handleOnCancel}
    >
      {renderModalContent()}
    </Modal>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ DropdownDataReducer, RequirementReducer }: RootState) => ({
  DropdownDataReducer,
  RequirementReducer,
});
const connector = connect(mapStateToProps, {
  addRequirementChapterAction,
  clearSelectRequirementChapterAction,
  fetchLibrariesAction,
  fetchSitesAction,
  updateRequirementChapterAction,
  fetchDropdownDataByType,
});
export default connector(ChapterForm);
