import { makeStyles, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  message: {
    color: tokens.colorBrandForeground1,
    fontWeight: 600,
    fontSize: '16px',
  },
});
