import { File, MgtTemplateProps } from '@microsoft/mgt-react';
import { FileProps } from '../../ts/interfaces';
import { getStyles } from './clickable-document-styles';

const ClickableDocument = (props: MgtTemplateProps & FileProps) => {
  const { siteId, driveId, itemId } = props;
  const { file } = props.dataContext;
  const styles = getStyles();
  return (
    <div onClick={() => window.open(file['webUrl'])} className={styles.clickableDocument}>
      <File siteId={siteId} driveId={driveId} itemId={itemId} line3Property={''} />
    </div>
  );
};

export default ClickableDocument;
