import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  mainDivWrapper: {
    display: 'flex',
  },
  assignLicensesDiv: {
    ...shorthands.flex(0, 0, '45%'),
    ...shorthands.padding('10px', '0px'),
    display: 'flex',
    flexDirection: 'column',
  },
  aiioShopDiv: {
    ...shorthands.padding('10px', '0px'),
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  assignLicensesP1: {
    fontSize: '24px',
    fontWeight: '600',
    ...shorthands.margin('10px', '0px'),
  },
  assignLicensesP2: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.45)',
    ...shorthands.textDecoration('underline'),
    ...shorthands.margin('10px', '0px'),
    width: 'fit-content',
  },
  aiioShopPrimaryBtn: {
    ...shorthands.margin('10px', '0px'),
  },
});
