import { Controller } from 'react-hook-form';
import { Text, IDatePickerProps, DatePicker as FluentDatePicker } from '@fluentui/react';
import * as React from 'react';
import { HookFormProps } from './hook-form-props';
import { getDateFormatted } from '../../../config/utils';

export const DatePicker = (props: HookFormProps & IDatePickerProps) => {
  return (
    <Controller
      name={props.name ? props.name : ''}
      control={props.control}
      rules={props.rules}
      defaultValue={''}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <>
          <FluentDatePicker
            {...props}
            onSelectDate={onChange}
            onChange={onChange}
            value={value ? value : props.defaultValue}
            onBlur={onBlur}
            defaultValue={undefined}
            formatDate={getDateFormatted}
          />
          {error && (
            <Text variant="smallPlus" style={{ color: '#a4262c' }}>
              {error.message}
            </Text>
          )}
        </>
      )}
    />
  );
};
