import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  dashBoardTileCard: {
    height: '194px',
    backgroundColor: '#FAF9F8',
    cursor: 'pointer',
    ...shorthands.margin('10px', '0px', '10px', '0px'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground5),
    ':hover': {
      ...shorthands.border('1px', 'solid', tokens.colorBrandBackground),
    },
  },
  cardTitleStack: {
    ...shorthands.flex(0, 0, 'auto'),
    marginBottom: '16px',
  },
  cardBodyStack: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: tokens.colorBrandBackground,
    marginLeft: '24px',
    marginTop: '24px',
  },
});
