import { GLOBALENUMS } from 'modeling-tool';
import { UseGraphAction } from './base-actions';
import { Providers } from '@microsoft/mgt-element';
import { ResponseType } from '@microsoft/microsoft-graph-client';

export const fetchSitesAction = (cb?: (...args: any[]) => void) =>
  UseGraphAction({
    url: `sites?search=`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_SITES',
    scopes: 'Sites.ReadWrite.All',
    cb,
  });

export const fetchLibrariesAction = (siteId: string, cb?: (...args: any[]) => void) =>
  UseGraphAction({
    url: `sites/${siteId}/drives`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_LISTS',
    scopes: 'Sites.ReadWrite.All',
    cb,
  });

export const fetchLibraryPictureAction = (
  siteId: string,
  driveId: string,
  itemId: string,
  cb?: (...args: any[]) => void,
) => {
  const provider = Providers.globalProvider;
  if (provider) {
    const graphClient = provider.graph.client;

    graphClient
      .api(`/sites/${siteId}/drives/${driveId}/items/${itemId}/content`)
      .responseType(ResponseType.BLOB)
      .get()
      .then((response) => {
        const imageUrl = URL.createObjectURL(response);
        cb && cb(imageUrl);
      })
      .catch((error) => {
        cb && cb(null);
      });
  }
};
export const doNothingAction = () => {};
