import { Controller } from 'react-hook-form';
import { Dropdown as FluentDropDown, IDropdownProps } from '@fluentui/react';
import * as React from 'react';
import { HookFormProps } from './hook-form-props';

export const Dropdown = (props: HookFormProps & IDropdownProps) => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [selectedKey, setSelectedKey] = React.useState<string | number>();
  return (
    <Controller
      name={props.name ? props.name : ''}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ''}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <>
          {props.multiSelect && (
            <FluentDropDown
              {...props}
              onChange={(_e, option) => {
                if (option) {
                  const selectedOptions = [...new Set([...selectedKeys, ...value])];
                  const selected = option.selected
                    ? [...selectedOptions, option.key as string]
                    : selectedOptions.filter((key) => key !== option.key);
                  setSelectedKeys(selected);
                  onChange(selected);
                }
              }}
              selectedKeys={selectedKeys.length ? selectedKeys : value}
              onBlur={onBlur}
              errorMessage={error && error.message}
              defaultValue={value}
            />
          )}
          {!props.multiSelect && (
            <>
              <FluentDropDown
                {...props}
                onChange={(_e, option) => {
                  if (option) {
                    setSelectedKey(option.key);
                    onChange(option.key);
                    props.onChange && props.onChange(_e, option);
                  }
                }}
                selectedKey={selectedKey ? selectedKey : value}
                onBlur={onBlur}
                errorMessage={error && error.message}
                defaultValue={value}
              />
            </>
          )}
        </>
      )}
    />
  );
};
