import { mergeStyleSets, IDetailsListStyles } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  return mergeStyleSets({
    cellText: {
      lineHeight: '35px',
      textAlign: 'left',
      width: '100%',
    },

    cell: {
      width: '100%',
    },
    processHeader: {
      span: { paddingLeft: '10px' },
    },
    processName: {
      paddingLeft: '0px !important',
    },
    processNameContainer: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    cellShimmer: {
      width: '100%',
    },
    btnsHolderContainer: {
      alignItems: 'center',
    },
    spinnerOverlay: {
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: '0.5',
      zIndex: '90000',
    },
    spinner: {
      position: 'fixed',
      left: '50%',
      top: '50%',
    },
    processesListContainer: {
      paddingTop: '32px',
      paddingLeft: '15px',
      height: 'calc(100vh - 220px)',
      position: 'relative',
      '& .ms-List-cell': { minHeight: '0px !important' },
    },
    iconContainer: {
      position: 'absolute',
      right: '0px',
    },
    iconButton: {
      '&:hover': {
        cursor: 'pointer',
        background: '#E6E6E6',
        color: '#800080',
      },
    },
    reviewIcon: {
      paddingLeft: '10px',
      display: 'flex',
      alignItems: 'center',
      button: {
        display: 'flex',
      },
    },
    iconButtonHidden: {
      visibility: 'hidden',
    },

    processNameStack: {
      height: 'auto',
      '& a:link': {
        textDecoration: 'none',
        color: props.theme.palette.themePrimary,
      },
    },
    processGroupName: {
      color: props.theme.palette.themePrimary,
      fontWeight: 600,
    },
  });
});

export const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowX: 'scroll',
    selectors: {
      '& [role=grid]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: '60vh',
      },
    },
    // '& .ms-DetailsHeader-cellTitle': {
    //   paddingLeft: '50px',
    // },
  },
  headerWrapper: {
    flex: '0 0 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
};
