import React, { useEffect, useState } from 'react';
import { __ } from 'modeling-tool';
import { addToIcon, getStyles } from './resources-buttons-styles';
import {
  ActionButton,
  ButtonType,
  DefaultButton,
  DirectionalHint,
  PrimaryButton,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { ResourceGroupForm } from '../resource-group-form';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { addResourceGroupAction } from '../../../store/actions/resource-group-actions';
import { ResourceForm } from '../resource-form';
import { searchResourceAction } from '../../../store/actions/resource-actions';
import { checkPermission } from '../../../config/permission-utils';
import { Popover } from 'modeling-tool';
import { searchProcessesAction } from '../../../store/actions/process-actions';
import { GLOBALENUMS } from 'modeling-tool';
import { useLocation } from 'react-router';
interface IResourcesButtons extends PropsFromRedux {
  isFilter?: boolean;
  setIsFilter?(arg: boolean): void;
  queryResource?: string;
  enableDelete?: boolean;
  onDelete?(): void;
  isGroupsView?: boolean;
}

const ResourcesButtons = (props: IResourcesButtons) => {
  const {
    UserReducer,
    enableDelete,
    isFilter,
    isGroupsView,
    queryResource,
    addResourceGroupAction,
    onDelete,
    searchProcessesAction,
    searchResourceAction,
    setIsFilter,
  } = props;

  const [isGroupModalVisible, setGroupModalVisible] = useState<boolean>(false);
  const [isResourceModalVisible, setResourceModalVisible] = useState<boolean>(false);
  const styles = getStyles();
  const buttonsToken = { childrenGap: 10 };

  const location = useLocation();

  useEffect(() => {
    searchProcessesAction('?done_and_groups=true');
  }, []);

  const onResetFilter = () => {
    searchResourceAction('');
    setIsFilter?.(false);
  };

  return (
    <div>
      <Stack
        horizontal
        className={isGroupsView ? styles.buttonHolder : ''}
        tokens={buttonsToken}
        reversed={isGroupsView}
        horizontalAlign={isGroupsView ? 'start' : 'end'}
      >
        {location.pathname.includes('resources-list') && isFilter === true && (
          <TooltipHost content={__('show all resources')} directionalHint={DirectionalHint.bottomCenter}>
            <DefaultButton buttonType={ButtonType.default} onClick={() => onResetFilter()}>
              {__('show all')}
            </DefaultButton>
          </TooltipHost>
        )}
        {enableDelete &&
          checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.delete_resource) &&
          onDelete && (
            <Popover
              title={__('delete selected resource')}
              content={__('are you sure to delete this resource?')}
              target={'delete-resources'}
              enableConfirm={true}
              onOk={() => onDelete()}
            >
              <ActionButton id="delete-resources" iconProps={{ iconName: 'Delete' }} buttonType={ButtonType.default}>
                {__('delete')}
              </ActionButton>
            </Popover>
          )}
        {checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_resourcegroup) &&
          (isGroupsView ? (
            <PrimaryButton allowDisabledFocus onClick={() => setGroupModalVisible(true)}>
              {__('new group')}
            </PrimaryButton>
          ) : (
            <ActionButton
              iconProps={addToIcon}
              buttonType={ButtonType.default}
              onClick={() => setGroupModalVisible(true)}
            >
              {__('add group')}
            </ActionButton>
          ))}
        {checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_resource) &&
          (isGroupsView ? (
            <ActionButton
              iconProps={addToIcon}
              buttonType={ButtonType.default}
              onClick={() => setResourceModalVisible(true)}
            >
              {__('add resource')}
            </ActionButton>
          ) : (
            <PrimaryButton allowDisabledFocus onClick={() => setResourceModalVisible(true)}>
              {__('new resource')}
            </PrimaryButton>
          ))}
      </Stack>

      {isGroupModalVisible && (
        <ResourceGroupForm
          setModalVisible={setGroupModalVisible}
          isModalVisible={isGroupModalVisible}
          add={addResourceGroupAction}
        />
      )}
      {isResourceModalVisible && (
        <ResourceForm
          setModalVisible={setResourceModalVisible}
          isModalVisible={isResourceModalVisible}
          queryResource={queryResource}
        />
      )}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({
  UserReducer,
});

const connector = connect(mapStateToProps, {
  addResourceGroupAction,
  searchResourceAction,
  searchProcessesAction,
});

export default connector(ResourcesButtons);
