import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { DefaultButton, IStackItemStyles, Label, Separator, StackItem } from '@fluentui/react';
import {
  DropdownPicker,
  ExpandableSection,
  GLOBALENUMS,
  PeoplePickerWithTags,
  Sidebar,
  TextEditor,
  __,
  showField,
} from 'modeling-tool';
import { connect, ConnectedProps } from 'react-redux';
import { LinkInput, TextField, FileList } from 'modeling-tool';
import { Department, ExternalPartner, Requirement, Role, StaffPosition } from '..././../../ts/interfaces';
import { checkPermission } from '../../../../config/permission-utils';
import { renderSelectDropDown } from '../../../../utils/process-management';
import { RootState } from '../../../../config/store';
import { fetchLibrariesAction, fetchSitesAction } from '../../../../store/actions/file-actions';
import {
  addRequirementAction,
  clearOpenRequirement,
  clearSelectedRequirement,
  deleteRequirementAction,
  fetchRequirementsWithProcessAction,
  updateRequirementAction,
} from '../../../../store/actions/requirement-action';
import { useStyleProps } from '../../../../hooks';
import { getStyles } from './requirement-from-styles';

interface IRequirementForm extends PropsFromRedux {
  selectedRequirement?: Requirement;
  closeSidebar(): void;
}

const RequirementForm = (props: IRequirementForm) => {
  const {
    UserReducer,
    DropdownDataReducer: { processes, orgUnits, roles, externalPartners, staffPositions },
    selectedRequirement,
    addRequirementAction,
    clearSelectedRequirement,
    closeSidebar,
    deleteRequirementAction,
    fetchLibrariesAction,
    fetchRequirementsWithProcessAction,
    fetchSitesAction,
    updateRequirementAction,
  } = props;

  const readOnly = !checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.change_requirement);

  const isDeletingRef = useRef(false);

  const stackItemStyles: IStackItemStyles = {
    root: {
      marginTop: 8,
    },
  };
  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  const [ready, setReady] = React.useState(false);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    return () => {
      if (!isDeletingRef.current) {
        submitData();
      }
    };
  }, [selectedRequirement]);

  useEffect(() => {
    if (selectedRequirement) {
      const attachments = selectedRequirement.attachments?.length ? selectedRequirement.attachments?.split('|') : [];
      const { responsibilities } = selectedRequirement;
      const data = {
        ...selectedRequirement,
        responsibleDepartments:
          responsibilities?.departments?.map((department: Department) => department.resourceUri) || [],
        responsibleEmployees: responsibilities?.employees || '',
        responsibleRoles: responsibilities?.roles?.map((role: Role) => role.resourceUri) || [],
        responsibleExternalPartners:
          responsibilities?.externalPartners?.map((partner: ExternalPartner) => partner.resourceUri) || [],
        responsibleStaffPositions:
          responsibilities?.staffPositions?.map((position: StaffPosition) => position.resourceUri) || [],
        attachments: attachments,
      };
      reset(data);
      setReady(true);
      return () => {
        setReady(false);
        reset();
        clearSelectedRequirement();
      };
    }
  }, [selectedRequirement]);

  const submitData = () => {
    if (readOnly) return;
    handleSubmit(
      (data) => {
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
    reset();
  };

  const onFinish = (values: any) => {
    values = {
      ...values,
      attachments: values.attachments?.length ? values.attachments.join('|') : null,
      responsibilities: {
        departments: values.responsibleDepartments,
        employees: values.responsibleEmployees,
        roles: values.responsibleRoles,
        externalPartners: values.responsibleExternalPartners,
        staffPositions: values.responsibleStaffPositions,
      },
    };
    if (selectedRequirement?.id) {
      values = { ...values, id: selectedRequirement.id };

      delete values.requirementChapters;
      delete values.createdBy;
      updateRequirementAction(values, () => {
        clearSelectedRequirement();
        reset();
      });
    } else {
      values = { ...values };
      addRequirementAction(values, () => {
        reset();
      });
    }
  };

  const handleOnDelete = () => {
    isDeletingRef.current = true;
    if (selectedRequirement) {
      deleteRequirementAction(selectedRequirement);
    }
    fetchRequirementsWithProcessAction();
    reset();
    closeSidebar();
  };

  const renderForm = () => {
    const showEmployee = showField('responsibleEmployees', control, readOnly);
    const showRoles = showField('responsibleRoles', control, readOnly);
    const showDepartments = showField('responsibleDepartments', control, readOnly);
    const showExternalPartners = showField('responsibleExternalPartners', control, readOnly);
    const showStaffPositions = showField('responsibleStaffPositions', control, readOnly);
    const showAnyResponsibilities =
      showEmployee.showField ||
      showRoles.showField ||
      showDepartments.showField ||
      showExternalPartners.showField ||
      showStaffPositions.showField;
    const hasValueAnyResponsibilities =
      showEmployee.hasValue ||
      showRoles.hasValue ||
      showDepartments.hasValue ||
      showExternalPartners.hasValue ||
      showStaffPositions.hasValue;

    const showTitle = showField('name', control, readOnly);
    const showDescription = showField('description', control, readOnly);
    const showAssignedProcesses = showField('linkedProcesses', control, readOnly);
    const showLink = showField('link', control, readOnly);
    const showAttachments = showField('attachments', control, readOnly);
    return (
      <form>
        {showTitle.showField && (
          <TextField
            label={__('title')}
            control={control}
            name={'name'}
            required={true}
            placeholder={__('provide title')}
            rules={{ required: __('This field is required') }}
          />
        )}
        {showDescription.showField && (
          <ExpandableSection label={__('description')} control={control} isExpanded={showDescription.hasValue}>
            <StackItem styles={stackItemStyles}>
              <TextEditor
                control={control}
                name={'description'}
                placeholder={__('provide description')}
                readOnly={readOnly}
              />
            </StackItem>
          </ExpandableSection>
        )}
        {showAnyResponsibilities && (
          <ExpandableSection label={__('responsibilities')} control={control} isExpanded={hasValueAnyResponsibilities}>
            {showEmployee.showField && (
              <>
                <Label className={styles.labelStyle}>{__('employees')}</Label>
                <PeoplePickerWithTags
                  control={control}
                  selectionMode="multiple"
                  name={'responsibleEmployees'}
                  controlId={`${selectedRequirement?.id}`}
                  disabled={readOnly}
                />
              </>
            )}
            {showRoles.showField && (
              <>
                <Label className={styles.labelStyle}>{__('roles')}</Label>
                <DropdownPicker
                  control={control}
                  name={'responsibleRoles'}
                  placeholder={__('provide role')}
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ROLES, roles)}
                  multiSelect
                  disabled={readOnly}
                />
              </>
            )}
            {showDepartments.showField && (
              <>
                <Label className={styles.labelStyle}>{__('departments')}</Label>
                <DropdownPicker
                  control={control}
                  placeholder={__('provide organizational unit')}
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, orgUnits)}
                  name={'responsibleDepartments'}
                  multiSelect
                  disabled={readOnly}
                />
              </>
            )}
            {showExternalPartners.showField && (
              <>
                <Label className={styles.labelStyle}>{__('external partners')}</Label>
                <DropdownPicker
                  control={control}
                  placeholder={__('provide external partners')}
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.EXTERNAL_PARTNERS, externalPartners)}
                  name={'responsibleExternalPartners'}
                  multiSelect
                  disabled={readOnly}
                />
              </>
            )}
            {showExternalPartners.showField && (
              <>
                <Label className={styles.labelStyle}>{__('staff positions')}</Label>
                <DropdownPicker
                  control={control}
                  placeholder={__('provide staff positions')}
                  options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.STAFF_POSITIONS, staffPositions)}
                  name={'responsibleStaffPositions'}
                  multiSelect
                  disabled={readOnly}
                />
              </>
            )}
          </ExpandableSection>
        )}
        {showAssignedProcesses.showField && (
          <ExpandableSection
            label={__('assigned processes')}
            control={control}
            isExpanded={showAssignedProcesses.hasValue}
          >
            <StackItem styles={stackItemStyles}>
              <DropdownPicker
                options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.PROCESSES, processes)}
                control={control}
                name={'linkedProcesses'}
                placeholder={__('provide process')}
                multiSelect
              />
            </StackItem>
          </ExpandableSection>
        )}
        {showLink.showField && (
          <ExpandableSection label={__('link')} control={control} isExpanded={showLink.hasValue}>
            <StackItem styles={stackItemStyles}>
              <LinkInput control={control} readOnly={readOnly} disabled={readOnly} />
            </StackItem>
          </ExpandableSection>
        )}
        {showAttachments.showField && (
          <ExpandableSection label={__('documents')} control={control} isExpanded={showAttachments.hasValue}>
            <StackItem styles={stackItemStyles}>
              <FileList
                control={control}
                name={'attachments'}
                fetchLibrariesAction={fetchLibrariesAction}
                fetchSitesAction={fetchSitesAction}
                disabled={readOnly}
                cutLength={true}
              />
            </StackItem>
          </ExpandableSection>
        )}
        {!readOnly && (
          <>
            <Separator />
            <DefaultButton
              className={styles.deleteButton}
              onClick={handleOnDelete}
              text={__('delete')}
              iconProps={{ iconName: 'Delete' }}
            />
          </>
        )}
      </form>
    );
  };

  return (
    <>
      {ready && (
        <Sidebar sidebarTitle={selectedRequirement?.name} onClick={closeSidebar}>
          {renderForm()}
        </Sidebar>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, ProcessReducer, RequirementReducer, DropdownDataReducer }: RootState) => ({
  UserReducer,
  ProcessReducer,
  RequirementReducer,
  DropdownDataReducer,
});

const connector = connect(mapStateToProps, {
  addRequirementAction,
  clearOpenRequirement,
  clearSelectedRequirement,
  deleteRequirementAction,
  fetchLibrariesAction,
  fetchRequirementsWithProcessAction,
  fetchSitesAction,
  updateRequirementAction,
});

export default connector(RequirementForm);
