import React, { useEffect, useState } from 'react';
import { useTeamsFx } from '@microsoft/teamsfx-react';
import { connect, ConnectedProps } from 'react-redux';
import { GetAppId, GetRedirectUri } from './config/config';
import { RootState } from './config/store';
import { Providers, ProviderState } from '@microsoft/mgt-react';
import { TeamsFxProvider } from '@microsoft/mgt-teamsfx-provider';
import { basicScopes } from './config/authConfig';
import { setTeamsFx } from './store/actions/user-actions';
import { app } from '@microsoft/teams-js';
import { setCurrentLanguage } from 'modeling-tool';

interface TeamsAuthenticationProviderProps {
  children?: JSX.Element;
}

const TeamsAuthenticationProvider = (props: PropsFromRedux & TeamsAuthenticationProviderProps) => {
  const { setTeamsFx } = props;
  const [done, setDone] = useState<boolean>(false);

  const { teamsfx } = useTeamsFx({
    authorityHost: 'https://login.microsoftonline.com',
    tenantId: 'organizations',
    initiateLoginEndpoint: GetRedirectUri() + 'auth-start.html',
    clientId: GetAppId(),
  });

  useEffect(() => {
    if (teamsfx) {
      Providers.globalProvider = new TeamsFxProvider(teamsfx, basicScopes);
      teamsfx.login(basicScopes).then(function () {
        app.getContext().then((context) => {
          setCurrentLanguage(context.app.locale.indexOf('de') == 0 ? 'de' : 'en');
          setTeamsFx(teamsfx);

          Providers.globalProvider.setState(ProviderState.SignedIn);
          setDone(true);
        });
      });
    }
  }, [teamsfx]);

  return <>{done && props.children}</>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, { setTeamsFx });
export default connector(TeamsAuthenticationProvider);
