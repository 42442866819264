import { Requirement, RequirementChapterData } from '../../ts/interfaces';

const findChildren = (chapter: any, tmpChapterData: any) => {
    if (!chapter.children.length) {
        return;
    } else {
        chapter.children.forEach((subChapter: any) => {
            tmpChapterData &&
                tmpChapterData.push({
                    resourceUri: subChapter.resourceUri,
                    name: subChapter.name,
                });
            findChildren(subChapter, tmpChapterData);
        });
    }
};

export const getRequirementChapters = (requirements: Requirement[]) => {
    const chapterData: RequirementChapterData[] = [];
    requirements.forEach((requirement: Requirement) => {
        chapterData.push({
            name: requirement.name,
            resourceUri: requirement.resourceUri,
        });
        requirement.requirementChapters &&
            requirement.requirementChapters.forEach((chapter: any) => {
                chapterData.push({
                    name: chapter.name,
                    resourceUri: chapter.resourceUri,
                });
                findChildren(chapter, chapterData);
            });
    });
    return chapterData;
};

export const findRequirementName = (uri: string, chapterData: RequirementChapterData[]) => {
    const match = chapterData.find(
        (matchingRequirement: RequirementChapterData) => matchingRequirement.resourceUri === uri,
    );
    return match?.name;
};