import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { fetchModelerNodeColor, updateModelerNodeColor } from '../../../store/actions/modeler-actions';

import { useStyles } from './modelling-styles';
import ColoringShape from './ColoringShape';
import { GLOBALENUMS } from 'modeling-tool';

const Modelling = (props: PropsFromRedux) => {
  const {
    ModelerReducer: { modelerNodeData },
    fetchModelerNodeColor,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    fetchModelerNodeColor();
  }, []);

  return (
    <>
      <div className={classes.wrapper}>
        {modelerNodeData &&
          modelerNodeData
            .filter((n) => n.nodeType !== GLOBALENUMS.MODELERNODETYPE.TEXT)
            .map((node, index) => {
              const id = `img-${index}`;
              return <ColoringShape key={id} node={node} index={index} id={id} />;
            })}
      </div>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ModelerReducer }: RootState) => ({ ModelerReducer });
const connector = connect(mapStateToProps, {
  fetchModelerNodeColor,
  updateModelerNodeColor,
});

export default connector(Modelling);
