import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchProcessReminderAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processreminder/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_PROCESS_Reminder',
    cb,
  });

export const addProcessReminderAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'processreminder/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_PROCESS_REMINDER',
    reqPayload: data,
    cb,
  });

export const updateProcessReminderAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processreminder/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PUT,
    action: 'UPDATE_PROCESS_REMINDER',
    reqPayload: data,
    cb,
  });

export const selectProcessReminderAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_PROCESS_REMINDER',
    payload: data,
  });

export const deleteProcessReminderAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processreminder/${id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_PROCESS_REMINDER',
    payload: id,
    cb,
  });

export const selectProcessReminderByIdAction = (id: number) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_PROCESS_REMINDER_BY_ID',
    payload: id,
  });
