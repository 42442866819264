import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { getRACIFieldsFormObject, getRACIFieldsObject } from '../../config/utils';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { clearProcessAction, fetchProcessAction } from '../../store/actions/process-actions';
import {
  searchProcessInstancesAction,
  updateAcceptedProcessInstancesAction,
  addSelectedProcessInstancesAction,
  getProcessInstanceAction,
  updateProcessInstanceAction,
  selectProcessInstanceAction,
  clearProcessInstancesAction,
} from '../../store/actions/process-instance-actions';
import { ProcessInstance, ProcessInstanceFormValues } from '../../ts/interfaces';
import { ProcessRecordModeler, ApprovedByModal } from '../../components';
import { useForm } from 'react-hook-form';
import { useAlert } from 'react-alert';
import './process-applications.less';
import { setNewProcessInstanceStateAction } from '../../store/actions/process-state-actions';
import { useLocation, useNavigate, useParams } from 'react-router';

interface RouteParams {
  id?: string;
}

const ProcessApplications = (props: PropsFromRedux) => {
  const {
    UserReducer: { username },
    ProcessInstanceReducer: { selectedInstance },
    clearProcessAction,
    fetchProcessAction,
    searchProcessInstancesAction,
    updateProcessInstanceAction,
    selectProcessInstanceAction,
    clearProcessInstancesAction,
    setNewProcessInstanceStateAction,
  } = props;
  const alert = useAlert();

  const [isApprovedByModalVisible, setIsApprovedByModalVisible] = useState<boolean>(false);
  const [instanceFormValues, setInstanceFormValues] = useState<ProcessInstanceFormValues>(
    {} as ProcessInstanceFormValues,
  );
  const [selectedProcessInstance, setSelectedProcessInstance] = useState<ProcessInstance>();
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    if (username) {
      clearProcessInstancesAction(() => {
        getInstances(parseInt(params.id as string));
      });
    }
  }, [location.search, username]);

  useEffect(() => {
    if (params && params.id) {
      clearProcessAction(() => {
        fetchProcessAction(parseInt(params.id as string));
      });
    }
  }, []);

  useEffect(() => {
    if (`${selectedInstance?.sourceProcess?.id}` === params?.id) {
      const selected = selectedInstance;
      if (selected) {
        reset({});
        const data = {
          instanceName: selected.instanceName || null,
          message: selected.message || null,
          participant: selected.participant || null,
          documents: selected.documents || null,
          resources: selected.resources || null,
          goals: selected.goals || null,
          description: selected.description || null,
          days: selected.duration?.split('-')[0] || null,
          hours: selected.duration?.split('-')[1] || null,
          minutes: selected.duration?.split('-')[2] || null,
          ...getRACIFieldsFormObject(selected),
        };
        reset(data);
        setSelectedProcessInstance(selectedInstance);
      }
    }
  }, [selectedInstance]);

  const getInstances = (id?: number) => {
    selectProcessInstanceAction({});
    const url = `${location.search}${location.search === '' ? `?source_process=${id}` : `&source_process=${id}`}`;
    searchProcessInstancesAction(url);
  };

  const onAccept = () => {
    if (selectedInstance) {
      setNewProcessInstanceStateAction(selectedInstance, GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_ACCEPTED);
      clearProcessInstancesAction(() => {
        getInstances(parseInt(params.id as string));
        alert.success(__('process instance was accepeted successfully, new instance created from it!'));
      });
    }
  };

  const onSendBack = (rejectionMessage: string) => {
    if (!selectedInstance) return;
    setShowLoading(true);
    selectedInstance.message = rejectionMessage;
    setNewProcessInstanceStateAction(
      selectedInstance,
      GLOBALENUMS.PROCESSSTATUS.INSTANCE_REJECTED,
      (msg, withError) => {
        if (withError) {
          setShowLoading(false);
          alert.error(`${__('Error')} ${msg.response.status}`);
        } else {
          setShowLoading(false);
          alert.success(__('task was send back'));
        }
      },
      (error) => {
        console.log(error);
        setShowLoading(false);
        alert.error(`${__('Error')} ${error?.response?.status}`);
      },
    );
    updateProcessInstanceAction(
      {
        id: selectedInstance?.id,
        message: rejectionMessage,
        isNew: false,
      },
      (msg, withError) => {
        if (withError) {
          setShowLoading(false);
          alert.error(`${__('Error')} ${msg.response.status}`);
        } else {
          setShowLoading(false);
          alert.success(__('task was send back'));
        }
      },
    );
  };

  const onApprove = () => {
    handleSubmit(
      (data) => {
        setInstanceFormValues(data as ProcessInstanceFormValues);
      },
      (err) => {
        console.log(err);
      },
    )();
    setIsApprovedByModalVisible(true);
  };

  const onSave = () => {
    handleSubmit(
      (data) => {
        let values = data as ProcessInstanceFormValues;
        setShowLoading(true);
        setInstanceFormValues(values);
        const { days, hours, minutes } = values;
        const durationString = [days, hours, minutes].join('-');

        const raciValues = getRACIFieldsObject(values, selectedInstance);
        values = { ...values, ...raciValues };
        updateProcessInstanceAction(
          {
            id: selectedInstance?.id,
            duration: durationString,
            ...values,
          },
          () => {
            setShowLoading(false);
            alert.success(__('process instance was saved successfully!'));
          },
        );
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleApprovedByCancel = () => {
    setIsApprovedByModalVisible(false);
  };
  return (
    <>
      <ProcessRecordModeler
        control={control}
        isApplicationsView={true}
        onAccept={onAccept}
        onApprove={onApprove}
        onSendBack={onSendBack}
        enableLoading={showLoading}
      />
      {isApprovedByModalVisible && selectedProcessInstance && (
        <ApprovedByModal
          selectedInstance={selectedProcessInstance as ProcessInstance}
          isApprovedByModalVisible={isApprovedByModalVisible}
          handleApprovedByCancel={handleApprovedByCancel}
          instanceFormValues={instanceFormValues}
        />
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ ProcessReducer, UserReducer, ProcessInstanceReducer }: RootState) => ({
  ProcessReducer,
  UserReducer,
  ProcessInstanceReducer,
});
const connector = connect(mapStateToProps, {
  clearProcessAction,
  fetchProcessAction,
  searchProcessInstancesAction,
  addSelectedProcessInstancesAction,
  updateAcceptedProcessInstancesAction,
  getProcessInstanceAction,
  updateProcessInstanceAction,
  selectProcessInstanceAction,
  clearProcessInstancesAction,
  setNewProcessInstanceStateAction,
});
export default connector(ProcessApplications);
