import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchResourceGroupsAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `resourcegroup/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_RESOURCEGROUPS',
    cb,
  });

export const fetchResourceGroupAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `resourcegroup/${id}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_RESOURCEGROUP',
    cb,
  });

export const clearResourceGroupAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_RESOURCEGROUP',
    cb,
  });

export const addResourceGroupAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'resourcegroup/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_RESOURCEGROUP',
    reqPayload: data,
    cb,
  });

export const updateResourceGroupAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `resourcegroup/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PUT,
    action: 'UPDATE_RESOURCEGROUP',
    reqPayload: data,
    cb,
  });

export const selectResourceGroupByIdAction = (id: number) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_RESOURCEGROUP_BY_ID',
    payload: id,
  });

export const clearSelectResourceGroupAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_RESOURCEGROUP',
  });

export const deleteResourceGroupAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `resourcegroup/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_RESOURCEGROUP',
    payload: data.id,
    cb,
  });
