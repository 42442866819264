import React from 'react';
import { __, IconSvg } from 'modeling-tool';
import { RootState } from '../../../../config/store';
import { connect, ConnectedProps } from 'react-redux';

import { getStyles } from './requirement-tile-styles';
import { Requirement } from '../../../../ts/interfaces';
import { IconButton, Stack } from '@fluentui/react';
import { Parser } from 'html-to-react';

interface INormTile {
  requirement?: Requirement;
  selectedRequirement?: Requirement;
  setSelectRequirementTile?: (requirement: Requirement) => void;
  onSelectRequirement?(arg: Requirement): void;
  onOpenRequirement?(arg: Requirement): void;
  onNewRequirement?(): void;
}

const RequirementTile = (props: PropsFromRedux & INormTile) => {
  const {
    requirement,
    selectedRequirement,
    setSelectRequirementTile,
    onSelectRequirement,
    onOpenRequirement,
    onNewRequirement,
  } = props;

  const styles = getStyles();
  return (
    <>
      {requirement && (
        <div
          className={
            selectedRequirement?.id === requirement.id ? `${styles.divcard} ${styles.divcardSelected}` : styles.divcard
          }
          onClick={(ev: any) => {
            ev.stopPropagation();
            setSelectRequirementTile && setSelectRequirementTile(requirement);
            onSelectRequirement && onSelectRequirement(requirement);
          }}
        >
          <div className={styles.divCardHeader}>
            <h2 className={styles.divCardTitle}>{requirement.name || __('untitled')}</h2>
          </div>
          <div className={styles.divCardBody}>{Parser().parse(requirement.description)}</div>
          <div className={styles.divCardBodyProcesses}>
            <p>
              {__('Assigned processes')}: {requirement.processCount}
            </p>
          </div>
          <div className={styles.divCardFooter}>
            <Stack wrap horizontal>
              <div
                className={styles.footerTextRight}
                onClick={(ev: any) => {
                  ev.stopPropagation();
                  onOpenRequirement && onOpenRequirement(requirement);
                }}
              >
                {__('open')}
                <IconButton
                  id="open-icon-button"
                  iconProps={{ iconName: 'ChevronRight' }}
                  styles={{ icon: { fontSize: '14px' } }}
                />
              </div>
            </Stack>
          </div>
        </div>
      )}
      {!requirement && (
        <div
          className={styles.newDivCard}
          onClick={(ev: any) => {
            ev.stopPropagation();
            onNewRequirement && onNewRequirement();
          }}
        >
          <div>
            <IconSvg svgName="NewNormPlus" height={32} width={32} />
            <p>{__('Create new requirement')}</p>
          </div>
        </div>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ RequirementReducer, UserReducer }: RootState) => ({
  RequirementReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {});
export default React.memo(connector(RequirementTile));
