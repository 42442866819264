import { IProcessMap, Meta } from '../../ts/interfaces';

interface ProcessMapReducerState {
  processmaps: IProcessMap[];
  meta?: Meta;
  selectedProcessmap?: IProcessMap;
  editMode?: boolean;
}

export default function reducer(
  state: ProcessMapReducerState = {
    processmaps: [],
    meta: undefined,
    selectedProcessmap: undefined,
    editMode: false,
  },
  action: any,
): ProcessMapReducerState {
  switch (action.type) {
    case 'FETCH_PROCESS_MAPS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        processmaps: action.payload.objects,
      });
    }
    case 'ADD_PROCESS_MAP': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        processmaps: [...state.processmaps, action.payload],
        selectedProcessmap: action.payload,
      });
    }
    case 'UPDATE_PROCESS_MAP': {
      return {
        ...state,
        processmaps: state.processmaps.map((processmap) =>
          processmap.id === action.payload.id ? action.payload : processmap,
        ),
      };
    }
    case 'CLEAR_PROCESS_MAP_REDUCER': {
      return Object.assign({}, state, {
        meta: undefined,
        selectedProcessmap: undefined,
        processmaps: [],
      });
    }
    case 'SELECT_PROCESS_MAP': {
      return Object.assign({}, state, {
        selectedProcessmap: action.payload,
      });
    }
    case 'CLEAR_SELECT_PROCESS_MAP': {
      return Object.assign({}, state, {
        selectedProcessmap: undefined,
      });
    }
    case 'DELETE_PROCESS_MAP': {
      return {
        ...state,
        processmaps: state.processmaps.filter((processmap) => processmap.id !== action.payload),
      };
    }
    case 'ENABLE_EDIT_MODE': {
      return {
        ...state,
        editMode: true,
      };
    }
    case 'TOGGLE_EDIT_MODE': {
      return {
        ...state,
        editMode: !state.editMode,
      };
    }
    default: {
      break;
    }
  }
  return state;
}
