import { Meta, OrgNodeData } from '../../ts/interfaces';

interface OrgNodeDataReducerState {
  nodes: OrgNodeData[];
  meta?: Meta;
}

export default function reducer(
  state: OrgNodeDataReducerState = {
    nodes: [],
    meta: undefined,
  },
  action: any,
): OrgNodeDataReducerState {
  switch (action.type) {
    case 'SEARCH_ORG_NODES_DATA': {
      return Object.assign({}, state, {
        nodes: action.payload.objects,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
