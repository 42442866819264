import { useEffect, useState } from 'react';
import { useStyles } from '../entity-card-styles';
import { fetchLibraryPictureAction } from '../../../../store/actions/file-actions';

interface LogoSectionProps {
  logo: string;
}
const MAXWIDTH = 130;
const MAXHEIGHT = 72;
const LogoSection = (props: LogoSectionProps) => {
  const { logo } = props;
  const [imgUrl, setImgUrl] = useState<string>();
  const [imageWidth, setImageWidth] = useState<number>();
  const [imageHeight, setImageHeight] = useState<number>();

  const classes = useStyles();

  useEffect(() => {
    if (logo) {
      const ids = logo.split('::');
      const img = new Image();
      fetchLibraryPictureAction(ids[0], ids[1], ids[2], (res: any) => {
        img.src = res;
        img.onload = function () {
          const [imageWidth, imageHeight] = getScaleFactor(img, MAXWIDTH, MAXHEIGHT);
          setImageWidth(imageWidth);
          setImageHeight(imageHeight);
        };
        setImgUrl(res);
      });
    }
  }, [logo]);

  const getScaleFactor = (img: HTMLImageElement, maxWidth: number, maxHeight: number) => {
    const widthScaleFactor = Math.min(1, maxWidth / img.naturalWidth);
    const heightScaleFactor = Math.min(1, maxHeight / img.naturalHeight);
    const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);
    const imageWidth = img.naturalWidth * scaleFactor;
    const imageHeight = img.naturalHeight * scaleFactor;
    return [imageWidth, imageHeight];
  };

  return (
    <div className={classes.logoContainer}>
      {imgUrl && imageHeight && imageWidth && <img src={imgUrl} width={imageWidth} height={imageHeight} />}
    </div>
  );
};
export default LogoSection;
