import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { fetchDepartmentsAction } from '../../store/actions/department-actions';
import { DepartmentActivities } from './department-activities';
import { OpenTasksCard } from './open-tasks-card';
import { useStyles } from './dashboard-styles';
import { DashBoardTileCard } from './dashboard-tile-card';

export enum TileTypeEnum {
  PROCESSCARD,
  PROCESSLIST,
  ORGANIZATION,
  REQUIREMENTS,
}

const Dashboard = (props: PropsFromRedux) => {
  const { fetchDepartmentsAction } = props;

  const classes = useStyles();

  useEffect(() => {
    fetchDepartmentsAction();
  }, []);

  return (
    <div className={classes.dashboardPage}>
      <div style={{ marginTop: '15px' }}>
        <div className={classes.tilesGrid}>
          <DashBoardTileCard
            tileTitle="Process map"
            tileType={TileTypeEnum.PROCESSCARD}
            tileURLRef="/process-management/processmap"
          />
          <DashBoardTileCard
            tileTitle="Process list"
            tileType={TileTypeEnum.PROCESSLIST}
            tileURLRef="/process-management/processes"
          />
          <DashBoardTileCard tileTitle="Organization" tileType={TileTypeEnum.ORGANIZATION} tileURLRef="/organization" />
          <DashBoardTileCard tileTitle="Requirements" tileType={TileTypeEnum.REQUIREMENTS} tileURLRef="/requirements" />
        </div>
        <div className={classes.bottomTilesGrid}>
          <OpenTasksCard />
          <DepartmentActivities />
        </div>
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ DepartmentReducer }: RootState) => ({
  DepartmentReducer,
});
const connector = connect(mapStateToProps, {
  fetchDepartmentsAction,
});
export default connector(Dashboard);
