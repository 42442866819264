import { __ } from 'modeling-tool';
import { ActionButton } from '@fluentui/react';
import { useStyles } from './licence-cart-calculation-card-styles';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import { useEffect, useState } from 'react';
import { LicenceCheckOut } from '../../../../ts/interfaces';
import { annualLicensePriceDetails, monthlyLicensePriceDetails } from '../checkout-constants';

interface LicenceCartCalculationCardProps {
  permissionGroup: string;
  isAnnualBillingEnabled: boolean;
  isNormalPlan: boolean;
}

const LicenceCartCalculationCard = (props: PropsFromRedux & LicenceCartCalculationCardProps) => {
  const {
    LicenceCheckOutReducer: { licenceCheckOuts, newPlanLicenceCheckOuts },
    permissionGroup,
    isAnnualBillingEnabled,
    UserReducer: { isLicenseTest },
    isNormalPlan,
  } = props;

  const classes = useStyles();

  const [currentLicenceCheckOut, setCurrentLicenceCheckOut] = useState<LicenceCheckOut>();

  const billingDetails = isAnnualBillingEnabled ? annualLicensePriceDetails : monthlyLicensePriceDetails;

  useEffect(() => {
    if (isNormalPlan) {
      const licenceCheckOutGroup = licenceCheckOuts.find((item) => item.groupName === permissionGroup);
      setCurrentLicenceCheckOut(licenceCheckOutGroup);
    } else {
      const licenceCheckOutGroup = newPlanLicenceCheckOuts.find((item) => item.groupName === permissionGroup);
      setCurrentLicenceCheckOut(licenceCheckOutGroup);
    }
  }, [licenceCheckOuts, newPlanLicenceCheckOuts]);

  const returnCartCount = (): number => {
    let cartCount: number = 0;

    if (isNormalPlan && isLicenseTest) {
      cartCount +=
        currentLicenceCheckOut?.cartLicencesCount == undefined ? 0 : currentLicenceCheckOut?.cartLicencesCount;
    } else if (isNormalPlan) {
      cartCount +=
        (currentLicenceCheckOut?.totalLicencesCount ?? 0) < 0 ? 0 : currentLicenceCheckOut?.totalLicencesCount ?? 0;
    } else {
      cartCount +=
        currentLicenceCheckOut?.cartLicencesCount == undefined ? 0 : currentLicenceCheckOut?.cartLicencesCount;
    }

    return cartCount;
  };

  const renderCard = (): JSX.Element => {
    return (
      <>
        <div className={classes.container}>
          <div className={classes.col1Div}>
            <ActionButton iconProps={{ iconName: 'Refresh' }}></ActionButton>
          </div>
          <div className={classes.col2Div}>{__(permissionGroup)}</div>
          <div className={classes.col3Div}>
            <div className={classes.col3DivLicenceCount}>
              <div style={{ textAlign: 'right', display: 'inline-block', width: '40%' }}>{returnCartCount()} </div>
            </div>
            <div className={classes.col3DivXText}> x </div>
            <div className={classes.col3DivLicencePrice}>
              {billingDetails[permissionGroup as keyof typeof billingDetails].toFixed(2)} €{' '}
            </div>
          </div>
          <div className={classes.col4Div}>
            {(returnCartCount() * billingDetails[permissionGroup as keyof typeof billingDetails]).toFixed(2)} €
          </div>
        </div>
      </>
    );
  };

  return <div>{renderCard()}</div>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ LicenceCheckOutReducer, UserReducer }: RootState) => ({
  LicenceCheckOutReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {});
export default connector(LicenceCartCalculationCard);
