export enum ProcessModalActions {
  NEW_PROCESS,
  NEW_SUBPROCESS,
  EDIT_PROCESS,
  COPY_PROCESS,
  NEW_PROCESS_GROUP,
  NEW_SUBPROCESS_GROUP,
  EDIT_PROCESS_GROUP,
  COPY_PROCESS_GROUP,
}
