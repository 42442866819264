import { PrimaryButton } from '@fluentui/react';
import { useStyles } from './group-membership-header-styles';
import { IconSvg, __ } from 'modeling-tool';
import { useNavigate } from 'react-router';

const GroupMemberShipHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.mainDivWrapper}>
        <div className={classes.assignLicensesDiv}>
          <p className={classes.assignLicensesP1}>{__('assign licences')}</p>
          <a href="https://www.aiio.de/preise" target="_blank" className={classes.assignLicensesP2}>
            {__('learn more about licence types')}
          </a>
        </div>
        <div className={classes.aiioShopDiv}>
          <PrimaryButton
            onClick={() => {
              navigate(`/settings/checkout`);
            }}
            className={classes.aiioShopPrimaryBtn}
          >
            <IconSvg svgName="CreditCard" />
            <div style={{ paddingLeft: '5px' }}>{__('Manage licences')}</div>
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default GroupMemberShipHeader;
