import { Input, InputProps } from '@fluentui/react-components';
import { Controller } from 'react-hook-form';
import { useStyles } from './initial-data-field-styles';
import { HookFormProps } from 'modeling-tool';

interface InitialDataFieldProps extends InputProps {
  label?: string;
}

const InitialDataField = (props: HookFormProps & InitialDataFieldProps) => {
  const styles = useStyles();
  return (
    <Controller
      name={props.name ? props.name : ''}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || 0}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
        return (
          <>
            <div className={styles.fieldDiv}>
              {<label>{props.label}</label>}
              <Input
                {...props}
                onChange={(data) => {
                  onChange(parseInt(data.target.value));
                }}
                name={name}
                value={value}
                className={styles.inputField}
                type="number"
              />
            </div>
            <div className={styles.errorMessage}>{error && error.message}</div>
          </>
        );
      }}
    />
  );
};

export default InitialDataField;
