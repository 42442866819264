import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApprovedByModal, ApproversList, ProcessRecordModeler } from '../../components';
import { RootState } from '../../config/store';
import { GLOBALENUMS, UtilityContext, __ } from 'modeling-tool';
import {
  fetchProcessAction,
  clearProcessAction,
  startProcessModelingAction,
  selectProcessAction,
  makeProcessVersionAction,
} from '../../store/actions/process-actions';
import { searchProcessInstancesAction } from '../../store/actions/process-instance-actions';
import { Process, ProcessInstance } from '../../ts/interfaces';
import { History } from './History';
import { useForm } from 'react-hook-form';
import { Modal } from 'modeling-tool';
import { ProcessInstanceInformationSection } from './process-instance-information-section';
import { breadCrumbButtonStyles, buttonStyles, getStyles } from './process-details-styles';
import { DefaultButton, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useParams } from 'react-router';
import { useStyleProps } from '../../hooks';
import { useNavigate } from 'react-router';
import { checkPermission } from '../../config/permission-utils';
import { getFullModelByProcessId } from '../../store/actions/modeler-actions';
import SendForm from '../process-management/processes/send-form/send-form';
import { StatusTag } from '../../components/process/status-tag';
import { useBoolean } from '@fluentui/react-hooks';
import { fetchDropdownDataByType } from '../../store/actions/dropdown-data-actions';

const dropDownData = [GLOBALENUMS.DROPDOWNDATA.RESOURCES];

const ProcessDetails = (props: PropsFromRedux) => {
  const {
    ProcessReducer: { selectedProcess },
    UserReducer: { permissions, username },
    clearProcessAction,
    fetchProcessAction,
    fetchDropdownDataByType,
    searchProcessInstancesAction,
    startProcessModelingAction,
    getFullModelByProcessId,
    makeProcessVersionAction,
  } = props;

  const [isApprovedByModalVisible, { setTrue: showApprovalModal, setFalse: dismissApprovalModal }] = useBoolean(false);
  const [showApprovalStatus, { setTrue: showApprovalStatusModal, setFalse: dismissApprovalStatusModal }] =
    useBoolean(false);
  const [workingInstance, setWorkingInstance] = useState<ProcessInstance | null>();
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [isAiPanelOpen, setIsAiPanelOpen] = useState<boolean>(false);
  const [sendVisible, setSendVisible] = useState<boolean>(false);
  const [sendModel, setSendModel] = useState<boolean>(false);
  const { setBreadcrumbButtons } = useContext(UtilityContext);

  const { control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const styleProps = useStyleProps();
  const styles = getStyles(styleProps, isAiPanelOpen);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
    return () => setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
  }, []);

  useEffect(() => {
    if (params && params.id) {
      const id = parseInt(params.id);
      clearProcessAction(() => {
        fetchProcessAction(id, (res: any) => {
          getProcessInstanceByProcessState(res.data);
        });
      });
    }
    return () => {
      setWorkingInstance(undefined);
      setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
    };
  }, [params.id]);

  useEffect(() => {
    if (selectedProcess?.state?.type === GLOBALENUMS.PROCESSSTATUS.IN_APPROVAL) {
      showCheckApprovalStatusButton();
    }
    if (selectedProcess?.state?.type === GLOBALENUMS.PROCESSSTATUS.DONE) {
      showNewProcessVersionButton();
    }
  }, [workingInstance, selectedProcess]);

  useEffect(() => {
    if (checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_modelerfullmodel)) {
      if (selectedProcess?.state?.type === GLOBALENUMS.PROCESSSTATUS.IN_MODELING) {
        getFullModelByProcessId(selectedProcess.id, (res) => {
          if (
            (res?.data?.objects?.length &&
              res.data.objects[0].editors.split(',').includes(username) &&
              ![
                GLOBALENUMS.MODELSTATE.LOCKED,
                GLOBALENUMS.MODELSTATE.NO_EDITOR,
                GLOBALENUMS.MODELSTATE.IN_EDITING,
              ].includes(res.data.objects[0].modelState)) ||
            res.data.objects[0].adminUnlocked
          ) {
            showStartModelingButton(selectedProcess);
          }
        });
      }
    }
  }, [selectedProcess, permissions]);

  const getProcessInstanceByProcessState = (process: Process) => {
    if (!process) return;
    const { state, id } = process;
    let instanceFilter: string | null = null;
    switch (state.type) {
      case GLOBALENUMS.PROCESSSTATUS.IN_MODELING: {
        instanceFilter = `?source_process__id=${id}&state__type=${GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_MODELING}`;
        break;
      }
      case GLOBALENUMS.PROCESSSTATUS.IN_APPROVAL: {
        instanceFilter = `?source_process__id=${id}&get_in_approval=True`;
        break;
      }
      case GLOBALENUMS.PROCESSSTATUS.DONE: {
        instanceFilter = `?source_process__id=${id}&get_approved=True`;
        break;
      }
      default: {
        setWorkingInstance(null);
      }
    }
    if (instanceFilter) {
      searchProcessInstancesAction(instanceFilter, (res) => {
        if (res.data.objects.length) {
          setWorkingInstance(res.data.objects[0]);
        }
      });
    }
  };

  const showStartModelingButton = (process: Process) => {
    const startModelingButton = (
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <PrimaryButton
          text={__('start modeling')}
          styles={breadCrumbButtonStyles}
          iconProps={{ iconName: 'Edit' }}
          onClick={() => onStartModeling(process)}
        />
        <DefaultButton
          text={__('approve')}
          styles={breadCrumbButtonStyles}
          className={styles.approveBtn}
          onClick={showApprovalModal}
          iconProps={{ iconName: 'CheckMark' }}
        />
      </Stack>
    );

    setBreadcrumbButtons && setBreadcrumbButtons({ list: [startModelingButton] });
  };

  const showCheckApprovalStatusButton = () => {
    const approvalStatusButton = (
      <PrimaryButton
        text={__('check approval status')}
        styles={breadCrumbButtonStyles}
        iconProps={{ iconName: 'RedEye' }}
        onClick={showApprovalStatusModal}
      />
    );
    setBreadcrumbButtons && setBreadcrumbButtons({ list: [approvalStatusButton] });
  };

  const showNewProcessVersionButton = () => {
    const newVersionButton = (
      <PrimaryButton
        text={__('create new version')}
        styles={breadCrumbButtonStyles}
        iconProps={{ iconName: 'CirclePlus' }}
        onClick={() =>
          makeProcessVersionAction(selectedProcess, (res) => {
            if (res.data) {
              const redirect = `/process-management/processes/${res.data.id}`;
              navigate(redirect);
            }
          })
        }
      />
    );
    setBreadcrumbButtons && setBreadcrumbButtons({ list: [newVersionButton] });
  };
  const onStartModeling = (process: Process) => {
    startProcessModelingAction(process.id, (res: any) => {
      if (res.data) {
        const redirect = `/process-management/processes/${process.id}/modeling-tool/${res.data.id}`;
        navigate(redirect);
      }
    });
  };

  const onSendModelingTask = () => {
    setSendModel(true);
    setSendVisible(true);
  };

  const renderTopSection = () => {
    if (!selectedProcess) return <></>;
    const { type } = selectedProcess.state;
    switch (type) {
      case GLOBALENUMS.PROCESSSTATUS.NEW:
        return (
          <Stack horizontalAlign="center" className={styles.noneApprovedPage} tokens={{ childrenGap: 10 }}>
            <Stack horizontalAlign="center">
              <div className={`${styles.semiBoldText} ${styles.fontSizeLarge}`}>
                {__('this process has not yet been modeled.')}
              </div>
              <div className={`${styles.semiBoldText} ${styles.fontSizeSmall}`}>
                {__('you can make the start or invite your colleagues.')}
              </div>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <PrimaryButton
                text={__('start modeling')}
                iconProps={{ iconName: 'Edit' }}
                onClick={() => onStartModeling(selectedProcess)}
                styles={buttonStyles}
              />
              <PrimaryButton
                text={__('send process modeling')}
                className={styles.sendBtn}
                iconProps={{ iconName: 'Send' }}
                onClick={onSendModelingTask}
                styles={buttonStyles}
              />
            </Stack>
          </Stack>
        );
      case GLOBALENUMS.PROCESSSTATUS.IN_RECORDING:
        return (
          <Stack horizontalAlign="center">
            <div className={`${styles.semiBoldText} ${styles.fontSizeLarge}`}>
              {__('the process is currently in process recording.')}
            </div>
            <PrimaryButton
              text={__('view process recording')}
              iconProps={{ iconName: 'RedEye' }}
              styles={buttonStyles}
              onClick={() => navigate(`/process-management/processes/${selectedProcess.id}/applications`)}
            />
          </Stack>
        );
      default:
        return workingInstance ? (
          <ProcessRecordModeler
            control={control}
            isDetailsView={true}
            isTaskDetailsView={false}
            onShowHistoryModal={() => setShowHistoryModal(true)}
            selectedProcess={selectedProcess}
            setIsAiPanelOpen={setIsAiPanelOpen}
          />
        ) : (
          <></>
        );
    }
  };

  if (!selectedProcess) return <></>;
  if (workingInstance === undefined)
    return (
      <Stack className={styles.spinnerStack}>
        <Spinner size={SpinnerSize.xSmall} />
      </Stack>
    );
  return (
    <>
      <Stack className={styles.detailsWrapper}>
        <Stack.Item className={styles.statusTagStack}>
          <StatusTag state={selectedProcess.state} size={{ width: 120, height: 30 }} />
        </Stack.Item>
        <Stack className={styles.modelerWrapper}>{renderTopSection()}</Stack>
        {selectedProcess && (
          <ProcessInstanceInformationSection processData={selectedProcess} processInstanceData={workingInstance} />
        )}
      </Stack>
      {sendVisible && selectedProcess && (
        <SendForm
          visible={sendVisible}
          setVisible={setSendVisible}
          selected={selectedProcess}
          afterSend={() => navigate('/process-management/processes')}
          sendModel={sendModel}
        />
      )}
      {showHistoryModal && (
        <Modal
          title={__('history')}
          isModalOpen={showHistoryModal}
          hideFooter={true}
          onCancel={() => {
            setShowHistoryModal(false);
          }}
        >
          <History selectedProcess={selectedProcess} />
        </Modal>
      )}
      {workingInstance && (
        <ApprovedByModal
          selectedInstance={workingInstance}
          isApprovedByModalVisible={isApprovedByModalVisible}
          handleApprovedByCancel={dismissApprovalModal}
        />
      )}
      {showApprovalStatus && (
        <Modal
          title={__('approvers')}
          isModalOpen={showApprovalStatus}
          hideFooter={true}
          onSave={dismissApprovalStatusModal}
          onCancel={dismissApprovalStatusModal}
        >
          <ApproversList items={workingInstance?.approvedBy} />
        </Modal>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ ProcessReducer, UserReducer }: RootState) => ({ ProcessReducer, UserReducer });
const connector = connect(mapStateToProps, {
  fetchProcessAction,
  fetchDropdownDataByType,
  selectProcessAction,
  clearProcessAction,
  searchProcessInstancesAction,
  startProcessModelingAction,
  getFullModelByProcessId,
  makeProcessVersionAction,
});
export default connector(ProcessDetails);
