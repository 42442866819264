import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction((props: { height: number; width: number }) => {
  const { height, width } = props;
  return mergeStyleSets({
    tagStyles: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '3px',
      width: width,
      height: height,
      '& span': {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
  });
});
