import { Icon, IStackTokens, PrimaryButton, Stack, Text } from '@fluentui/react';
import { Login, People } from '@microsoft/mgt-react';
import { useEffect, useState } from 'react';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { getStyles } from './error-styles';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { AlertTemplate } from '../../layouts/alert-template';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { IsTeamsApp } from '../../config/config';
import * as microsoftTeams from '@microsoft/teams-js';
import { useNavigate } from 'react-router';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { getCurrentUserAction } from '../../store/actions/user-actions';

const Error = (props: PropsFromRedux) => {
  const [errorCode, setErrorCode] = useState<string>();
  const navigate = useNavigate();

  const {
    UserReducer: { admins, isAdmin, isLicenseTest },
    getCurrentUserAction,
  } = props;

  const styles = getStyles();

  const stackTokens: IStackTokens = {
    childrenGap: 5,
  };
  const sectionStackTokens: IStackTokens = { childrenGap: 10 };

  useEffect(() => {
    if (IsTeamsApp()) {
      microsoftTeams.app.notifySuccess();
    }
  }, []);

  useEffect(() => {
    getCurrentUserAction(
      () => {
        navigate(`/`);
      },
      (error) => {
        if (error.response.data.error_message) {
          const err = JSON.parse(error.response.data.error_message);
          setErrorCode(err.code);
        }
      },
    );
  }, []);

  const renderAdmins = () => {
    if (admins) {
      const adminIds = admins.split(',');
      return (
        <>
          <Stack wrap tokens={stackTokens}>
            <Stack.Item>{__('contact admins to get access')}</Stack.Item>
            <Stack.Item align="center">
              <People userIds={adminIds}></People>
            </Stack.Item>
          </Stack>
        </>
      );
    }
  };

  const renderError = () => {
    let errorText = '';
    switch (errorCode) {
      case GLOBALENUMS.AUTHERRORCODE.NO_USER:
        errorText = __('You are unauthorized to view this page.');
        break;
      case GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED:
        errorText = __('The license for your company is expired.');
        break;
      case GLOBALENUMS.AUTHERRORCODE.LICENSE_DEACTIVATED:
        errorText = __('The licence for your company is deactivated.');
        break;
      default:
        errorText = __('sorry, the page you visited does not exist.');
    }

    return (
      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <Stack.Item>
          <Icon className={styles.icon} iconName="Error" />
        </Stack.Item>
        <Stack.Item>{errorText}</Stack.Item>
      </Stack>
    );
  };

  const renderAdminLicenseExpire = () => {
    return (
      <>
        <Stack>
          <Stack.Item style={{ marginBottom: '10px' }}>
            <Text variant="xxLarge">{__('Congratulations on completing your trial with aiio!')}</Text>
          </Stack.Item>
          <Stack.Item style={{ marginBottom: '10px' }}>
            <div>
              <Text variant="mediumPlus">
                {__(`Impressive work! You've soared above the average user by now. Now, let's make it official!`)}
              </Text>
            </div>
          </Stack.Item>
          <Stack.Item style={{ marginBottom: '20px' }}>
            <Text variant="mediumPlus">
              {__(
                `Become a part of the aiio family. Choose your subscription today and continue to enjoy the benefits of aiio for you.`,
              )}
            </Text>
          </Stack.Item>
          {isLicenseTest ? (
            <Stack.Item>
              <PrimaryButton
                onClick={() => {
                  navigate(`/settings/checkout`);
                }}
              >
                {__('Start now')}
              </PrimaryButton>
            </Stack.Item>
          ) : (
            <Stack.Item>
              <Text variant="mediumPlus">{__('Please contact our support to regain access.')}</Text>
            </Stack.Item>
          )}
        </Stack>
      </>
    );
  };

  return (
    <AlertProvider
      template={AlertTemplate}
      position={positions.TOP_CENTER}
      transition={transitions.FADE}
      timeout={5000}
      offset={'10px'}
    >
      <div className={styles.body}>
        <div className={styles.backgroundImage}>
          <div className={styles.container}>
            <div className={styles.errorPage}>
              <Stack tokens={sectionStackTokens}>
                <Stack.Item className={styles.logoHolder}>
                  <img alt="aiio logo" src="/images/logo.svg" />
                </Stack.Item>

                <Stack.Item align="center">
                  <Login />
                </Stack.Item>
                {errorCode == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED ? !isAdmin && renderError() : renderError()}
                {errorCode == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED && isAdmin && renderAdminLicenseExpire()}
                {errorCode == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED ? !isAdmin && renderAdmins() : renderAdmins()}
                <Stack.Item align="center">
                  <div className={styles.copyRights}>{new Date().getFullYear()} © aiio - aiio GmbH</div>
                </Stack.Item>
              </Stack>
            </div>
          </div>
        </div>
        {errorCode == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED && (
          <Fireworks autorun={{ speed: 1, duration: 5000, delay: 5 }} />
        )}
      </div>
    </AlertProvider>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, { getCurrentUserAction });
export default connector(Error);
