import { combineReducers } from 'redux';
import UserReducer from './user-reducer';
import MenuReducer from './menu-reducer';
import ProcessReducer from './process-reducer';
import ProcessGroupReducer from './process-group-reducer';
import ProcessMapReducer from './process-map-reducer';
import StatusReducer from './status-reducer';
import DepartmentReducer from './department-reducer';
import ProcessInstanceReducer from './process-instance-reducer';
import TaskReducer from './task-reducer';
import ProcessNodeReducer from './process-node-reducer';
import LocationReducer from './location-reducer';
import OrgNodeDataReducer from './org-node-data-reducer';
import DashboardReducer from './dashboard-reducer';
import RoleReducer from './role-reducer';
import WorkingGroupReducer from './working-group-reducer';
import EmployeeReducer from './employee-reducer';
import ProcessVersionReducer from './process-version-reducer';
import UserCommentReducer from './user-comment-reducer';
import ModelerReducer from './modeler-reducer';
import TenantDashboardReducer from './tenant-dashboard-reducer';
import ProcessReminderReducer from './process-reminder-reducer';
import ResourceReducer from './resource-reducer';
import ResourceGroupReducer from './resource-group-reducer';
import DropdownDataReducer from './dropdown-data-reducer';
import LicenceCheckOutReducer from './licence-check-out-reducer';
import RequirementReducer from './requirement-reducer';
import OrgChartReducer from './org_reducer'
import InitialDataDashboardReducer from './initial-data-dashboard-reducer';

const createRootReducer = () =>
  combineReducers({
    UserReducer,
    MenuReducer,
    ProcessReducer,
    ProcessGroupReducer,
    ProcessMapReducer,
    StatusReducer,
    DepartmentReducer,
    ProcessInstanceReducer,
    ProcessNodeReducer,
    LocationReducer,
    TaskReducer,
    OrgNodeDataReducer,
    DashboardReducer,
    RoleReducer,
    WorkingGroupReducer,
    EmployeeReducer,
    ProcessVersionReducer,
    UserCommentReducer,
    ModelerReducer,
    TenantDashboardReducer,
    ProcessReminderReducer,
    ResourceReducer,
    ResourceGroupReducer,
    DropdownDataReducer,
    LicenceCheckOutReducer,
    RequirementReducer,
    OrgChartReducer,
    InitialDataDashboardReducer,
  });

export default createRootReducer;
