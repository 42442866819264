import { makeStyles } from '@fluentui/react-components';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '415px',
    alignItems: 'center',
  },
  col1Div: {
    width: '10%',
  },
  col2Div: {
    width: '30%',
    fontSize: '14px',
    fontWeight: '400',
  },
  col3Div: {
    textAlign: 'center',
    width: '30%',
    fontSize: '14px',
    fontWeight: '400',
  },
  col4Div: {
    textAlign: 'end',
    width: '30%',
    fontSize: '14px',
    fontWeight: '600',
  },
  col3DivLicenceCount: {
    textAlign: 'left',
    display: 'inline-block',
    width: '30%',
  },
  col3DivXText: {
    textAlign: 'center',
    display: 'inline-block',
    width: '15%',
  },
  col3DivLicencePrice: {
    textAlign: 'right',
    display: 'inline-block',
    width: '55%',
  },
});
