import { Checkbox, Callout, ColorPicker, IColor, getColorFromString } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { __ } from 'modeling-tool';
import { RootState } from '../../../config/store';
import { fetchModelerNodeColor, updateModelerNodeColor } from '../../../store/actions/modeler-actions';
import { ModelerNodeType } from '../../../ts/interfaces';

import { useStyles } from './modelling-styles';

interface ColoringProps {
  index: number;
  node: ModelerNodeType;
  id: string;
}

const ColoringShape = (props: PropsFromRedux & ColoringProps) => {
  const {
    index,
    node,
    id,
    ModelerReducer: { modelerNodeData },
    updateModelerNodeColor,
  } = props;

  const black: IColor = { hex: '000', str: '#000' } as IColor;
  const [selectedSvg, setSelectedSvg] = useState<string>();
  const [color, setColor] = useState<IColor>(black);
  const [isChecked, setIsChecked] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    handleMouseUp();
  }, [isChecked]);

  const handleMouseUp = () => {
    const nodeMatch = modelerNodeData && modelerNodeData.find((node) => node.nodeType === selectedSvg);
    const values = { nodeType: selectedSvg, color: color.str, fill: isChecked };
    if (nodeMatch && nodeMatch.id) {
      const updatedValues = { ...values, id: nodeMatch.id };
      updateModelerNodeColor(updatedValues);
    }
  };

  const updateColor = React.useCallback((ev: any, colorObj: IColor) => {
    setColor(colorObj);
    handleMouseUp();
  }, []);

  const onSvgClick = (item: ModelerNodeType) => {
    item.isSelected = true;
    const itemColor = getColorFromString(item.color);
    itemColor && setColor(itemColor);

    if (item.fill === true) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }

    setSelectedSvg(item.nodeType);
  };

  const onDismiss = (svg: any) => {
    svg.isSelected = false;
    setSelectedSvg(undefined);
  };

  const getScaledSizeFromSvgString = (maxHeight: number, svg: string) => {
    const parser = new DOMParser();
    const customSvg = parser.parseFromString(svg, 'image/svg+xml');
    const el = document.adoptNode(customSvg.documentElement);
    const h = el.getAttribute('height');
    const w = el.getAttribute('width');
    let height = h ? parseInt(h) : 50;
    let width = w ? parseInt(w) : 50;
    const ratio = maxHeight / height;
    height *= ratio;
    width *= ratio;
    return { width, height };
  };

  const renderPreview = () => {
    const clickedSvg = modelerNodeData?.find((svg) => svg.nodeType === selectedSvg);
    if (clickedSvg) {
      const previewSvg = clickedSvg.svgString;
      const colorUrl = renderColoredPreview(previewSvg);
      return <img className={classes.previewImg} src={colorUrl} />;
    }
  };

  const renderSvg = (svg: string) => {
    const parser = new DOMParser();
    const svgImage = parser.parseFromString(svg, 'image/svg+xml');
    const svgDocument = document.adoptNode(svgImage.documentElement);
    const { width, height } = getScaledSizeFromSvgString(55, svg);
    svgDocument.setAttribute('width', `${width}`);
    svgDocument.setAttribute('height', `${height}`);
    const svgColor = svgDocument.outerHTML;
    const blob = new Blob([svgColor], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    return url;
  };

  const renderColoredPreview = (svg: string) => {
    const parser = new DOMParser();
    const svgImage = parser.parseFromString(svg, 'image/svg+xml');
    const svgDocument = document.adoptNode(svgImage.documentElement);
    const { width, height } = getScaledSizeFromSvgString(50, svg);
    svgDocument.setAttribute('width', `${width}`);
    svgDocument.setAttribute('height', `${height}`);
    const svgColor = svgDocument.outerHTML;
    const blob = new Blob([svgColor], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    return url;
  };

  const renderCallout = () => {
    return (
      <div className={classes.colorShapeWrapper}>
        <div className={classes.previewArea}>
          <div className={classes.preview}>{renderPreview()}</div>
          <div className={classes.fillArea}>
            <Checkbox
              label={__('fill')}
              checked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
              }}
            />
          </div>
        </div>
        <div onMouseUp={handleMouseUp}>
          <ColorPicker color={color.str} onChange={updateColor} alphaType="none" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div key={index}>
        <img src={renderSvg(node.svgString)} className={classes.svg} id={id} onClick={() => onSvgClick(node)} />
        <Callout
          hidden={node.nodeType != selectedSvg}
          className={classes.callout}
          target={`#${id}`}
          onDismiss={() => onDismiss(node)}
          backgroundColor={'#ebeced'}
        >
          {selectedSvg && node.nodeType == selectedSvg && renderCallout()}
        </Callout>
      </div>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ModelerReducer }: RootState) => ({ ModelerReducer });
const connector = connect(mapStateToProps, {
  fetchModelerNodeColor,
  updateModelerNodeColor,
});

export default connector(ColoringShape);
