import React, { useEffect, useState } from 'react';
import { __ } from 'modeling-tool';
import { Pivot, PivotItem } from '@fluentui/react';
import { ResourceGroups } from './resource-groups';
import { ResourcesList } from './resources-list';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { clearResourcesAction, fetchResourcesAction, searchResourceAction } from '../../store/actions/resource-actions';
import { useLocation, useNavigate } from 'react-router';

const Resources = (props: PropsFromRedux) => {
  const { searchResourceAction, fetchResourcesAction, clearResourcesAction } = props;
  const [selectedKey, setSelectedKey] = useState<string>('0');

  const navigate = useNavigate();
  const location = useLocation();

  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [queryResource, setQueryResource] = useState<string>('');

  useEffect(() => {
    if (location.pathname.includes('resources-list')) {
      setSelectedKey('1');
      fetchResourcesAction();
    } else {
      setSelectedKey('0');
      searchResourceAction('');
      clearResourcesAction();
      setIsFilter(false);
    }
  }, [location.pathname]);

  const onTabClick = (key?: string) => {
    if (key == __('groups')) {
      navigate('/resources/groups');
      setSelectedKey('0');
    } else {
      navigate('/resources/resources-list');
      setSelectedKey('1');
    }
  };

  const onFilterResource = (id: number) => {
    let query = '';
    query += `?parent_group=${id}`;
    searchResourceAction(query, () => {
      setIsFilter(true);
      setQueryResource(query);
    });
  };

  return (
    <>
      <Pivot onLinkClick={(item) => onTabClick(item?.props.headerText)} selectedKey={selectedKey}>
        <PivotItem itemKey="0" headerText={__('groups')} alwaysRender>
          <ResourceGroups onFilterResource={onFilterResource} />
        </PivotItem>
        <PivotItem itemKey="1" headerText={__('resources list')} alwaysRender>
          <ResourcesList isFilter={isFilter} setIsFilter={setIsFilter} queryResource={queryResource} />
        </PivotItem>
      </Pivot>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ResourceReducer }: RootState) => ({ ResourceReducer });
const connector = connect(mapStateToProps, {
  searchResourceAction,
  fetchResourcesAction,
  clearResourcesAction,
});
export default connector(Resources);
