import { __ } from 'modeling-tool';
import { useStyles } from './licence-group-card-styles';
import { ActionButton, MessageBar, MessageBarType } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import {
  updateLicenceCheckOutAction,
  updateNewPlanLicenceCheckOutAction,
} from '../../../../store/actions/licence-check-out-actions';
import { LicenceCheckOut } from '../../../../ts/interfaces';
import { annualLicensePriceDetails, monthlyLicensePriceDetails } from '../checkout-constants';
import { useBoolean } from '@fluentui/react-hooks';

interface LicenceGroupCardProps {
  permissionGroup: string;
  isAnnualBillingEnabled: boolean;
}

const LicenceGroupCard = (props: PropsFromRedux & LicenceGroupCardProps) => {
  const {
    LicenceCheckOutReducer: { licenceCheckOuts, newPlanLicenceCheckOuts },
    UserReducer: { isLicenseTest },
    updateLicenceCheckOutAction,
    updateNewPlanLicenceCheckOutAction,
    permissionGroup,
    isAnnualBillingEnabled,
  } = props;

  const [counter, setCounter] = useState(0);
  const classes = useStyles();
  const [currentLicenceCheckOut, setCurrentLicenceCheckOut] = useState<LicenceCheckOut>();
  const billingDetails = isAnnualBillingEnabled ? annualLicensePriceDetails : monthlyLicensePriceDetails;
  const [showError, { setTrue: viewError, setFalse: dismissError }] = useBoolean(false);
  const decreaseLicenceCountLimit = currentLicenceCheckOut?.usedLicencesCount ?? 0;

  useEffect(() => {
    if (isLicenseTest) {
      const licenceCheckOutGroup = licenceCheckOuts.find((item) => item.groupName === permissionGroup);
      setCounter(licenceCheckOutGroup?.cartLicencesCount || 0);
      setCurrentLicenceCheckOut(licenceCheckOutGroup);
    }
  }, [licenceCheckOuts]);

  useEffect(() => {
    if (!isLicenseTest) {
      const licenceCheckOutGroup = newPlanLicenceCheckOuts.find((item) => item.groupName === permissionGroup);
      setCounter((licenceCheckOutGroup?.cartLicencesCount ?? 0) < 0 ? 0 : licenceCheckOutGroup?.cartLicencesCount ?? 0);
      setCurrentLicenceCheckOut(licenceCheckOutGroup);
    }
  }, [newPlanLicenceCheckOuts]);

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        dismissError();
      }, 3000);
    }
  }, [showError]);

  useEffect(() => {
    if (isLicenseTest) {
      updateLicenceCheckOutAction(permissionGroup, counter);
    } else {
      updateNewPlanLicenceCheckOutAction(permissionGroup, counter);
    }
  }, [counter]);

  //increase counter
  const increase = () => {
    setCounter((count) => count + 1);
  };

  //decrease counter
  const decrease = () => {
    setCounter((count) => {
      return checkLicenceCount(count);
    });
  };

  const checkLicenceCount = (currentValue: number) => {
    if (currentValue > decreaseLicenceCountLimit) {
      return currentValue - 1;
    }
    viewError();
    return currentValue;
  };

  // Handler function to update state variable on input change
  const handleChange = (event: any) => {
    // check if the entered value is negative or not
    if (Number(event.target.value) < 0) {
      const updatedCount = checkLicenceCount(Number(event.target.value));
      event.target.value = updatedCount;
      setCounter(Number(updatedCount));
    } else {
      event.target.value = Number(event.target.value);
      setCounter(Number(event.target.value));
    }
  };

  const renderCard = (): JSX.Element => {
    return (
      <>
        {showError && (
          <MessageBar
            delayedRender={false}
            messageBarType={MessageBarType.error}
            styles={{
              root: {
                position: 'absolute',
                top: '10%',
                left: '30%',
                width: 'fitContentToBounds',
              },
            }}
          >
            {<div>{__('The number of available licenses must not be less than the number of assigned licenses.')}</div>}
          </MessageBar>
        )}
        <div className={classes.groupTitle}>
          {__(permissionGroup)} <br />
          <div className={classes.licensesCountWrapper}>
            <span className={classes.cartText1}>
              {__('Licenses available:') +
                ' ' +
                (Number(currentLicenceCheckOut?.totalLicencesCount) < 0
                  ? 0
                  : Number(currentLicenceCheckOut?.totalLicencesCount))}
            </span>
            <span className={classes.cartText2}>
              {__('Currently assigned:') + ' ' + currentLicenceCheckOut?.usedLicencesCount}
            </span>
          </div>
        </div>
        <div className={classes.groupPricing}>
          <span className={classes.pricingText1}>
            {billingDetails[permissionGroup as keyof typeof billingDetails].toFixed(2)} €
          </span>{' '}
          <br />
          <span className={classes.pricingText2}>{__('Price per licence')}</span>
        </div>
        <div className={classes.groupCartUpdate}>
          <span className={classes.cartText1}>{__('Licence volume')}</span> <br />
          <span>
            <ActionButton
              iconProps={{ iconName: 'Remove' }}
              style={{ verticalAlign: 'middle' }}
              onClick={decrease}
            ></ActionButton>
            <input className={classes.cartCount} type="number" value={counter} onChange={handleChange} />
            <ActionButton
              iconProps={{ iconName: 'Add' }}
              style={{ verticalAlign: 'middle' }}
              onClick={increase}
            ></ActionButton>
          </span>
        </div>
      </>
    );
  };

  return <div className={classes.licenceGroupCard}>{renderCard()}</div>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ MenuReducer, LicenceCheckOutReducer, UserReducer }: RootState) => ({
  MenuReducer,
  LicenceCheckOutReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, { updateLicenceCheckOutAction, updateNewPlanLicenceCheckOutAction });
export default connector(LicenceGroupCard);
