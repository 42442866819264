import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  const errorFilter = (envelope: ITelemetryItem) => {
    // filter out everything ms graph related. 99% of graph errors are related to either missing permissions or missing photos
    // additionally graph requests may contain clear names, which we could see in App Insights
    if (envelope && envelope.baseData && envelope.baseData.responseCode && envelope.baseData.target) {
      if (envelope.baseData.target.includes('graph.microsoft.com')) {
        return false;
      }
    }

    return true;
  };

  /**
   * Initialize the Application Insights class
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (instrumentationKey: string) => {
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in ./config/appinsights/telemetry-provider.js');
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        extensions: [reactPlugin as any],
      },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();
    appInsights.addTelemetryInitializer(errorFilter);
  };

  return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = (): ApplicationInsights => appInsights;
