import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchResourcesAction = (min?: boolean, orderBy?: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: min ? `resource_min/?${orderBy || ''}` : `resource/?${orderBy || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_RESOURCES',
    cb,
  });

export const fetchResourceAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `resource/${id}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_RESOURCE',
    cb,
  });

export const clearResourceAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_RESOURCE',
    cb,
  });

export const addResourceAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'resource/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_RESOURCE',
    reqPayload: data,
    cb,
  });

export const updateResourceAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `resource/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PUT,
    action: 'UPDATE_RESOURCE',
    reqPayload: data,
    cb,
  });

export const selectResourceByIdAction = (id: number) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_RESOURCE_BY_ID',
    payload: id,
  });

export const clearSelectResourceAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_SELECT_RESOURCE',
  });

export const deleteResourceAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `resource/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_RESOURCE',
    payload: data.id,
    cb,
  });

export const searchResourceAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `resource/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_RESOURCE',
    cb,
  });

export const clearResourcesAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_RESOURCES',
  });
