import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    locationsListContainer: {
      paddingLeft: '15px',
      height: 'calc(100vh - 165px)',
    },
  });
});
