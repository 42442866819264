import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    addButton: {
      margin: '5px',
      float: 'right',
      zIndex: '1',
    },
    buttonArea: {
      float: 'right',
      paddingRight: '16px',
    },
    wrapper: {
      position: 'relative',
      height: `calc(100vh - 180px)`,
      width: '90%',
      margin: '5px',
    },
    normGrid: {
      flexFlow: 'wrap',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '3rem',
      marginLeft: 4,
    },
    title: {
      textAlign: 'left',
      fontSize: '20px',
      padding: '5px 0',
    },
    cardButtons: {
      justifyContent: 'space-between',
      paddingLeft: '15px',
    },

    cardsWrappedDiv: {
      marginTop: '20px',
      marginLeft: '20px',
      marginRight: '20px',
      position: 'absolute',
      width: '100%',
    },
  });
});
