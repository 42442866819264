import { Menu, Menus } from '../../ts/interfaces';

interface MenuReducerState {
  menus: Menus;
}

export default function reducer(
  state: MenuReducerState = {
    menus: [],
  },
  action: any,
): MenuReducerState {
  switch (action.type) {
    case 'FETCH_MENUS': {
      const menusObj: Menus = [];
      action.payload.objects.forEach((item: Menu) => (menusObj[item.codeid] = item));
      return Object.assign({}, state, { menus: menusObj });
    }
    default: {
      break;
    }
  }
  return state;
}
