import React, { useEffect, useState } from 'react';
import {
  DirectionalHint,
  IColumn,
  IconButton,
  PrimaryButton,
  Selection,
  TooltipHost,
  Stack,
  ActionButton,
  Link,
  ButtonType,
  ColumnActionsMode,
} from '@fluentui/react';
import { MgtTemplateProps, Person, PersonCardInteraction, ViewType } from '@microsoft/mgt-react';
import { connect, ConnectedProps } from 'react-redux';
import { PersonDisplayName } from '../../../components';
import { Popover } from 'modeling-tool';
import { RootState } from '../../../config/store';
import { __ } from 'modeling-tool';
import {
  fetchEmployeeAction,
  selectEmployeeByIdAction,
  updateEmployeeAction,
} from '../../../store/actions/employee-actions';
import { Employee, EmployeeOrgsType } from '../../../ts/interfaces';
import EmployeeForm from './employee-form';
import { getStyles } from './employee-list-styles';
import { checkPermission } from '../../../config/permission-utils';
import { BasicList, GLOBALENUMS } from 'modeling-tool';
import { useNavigate } from 'react-router';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';
import { fetchWorkingGroupsAction } from '../../../store/actions/working-group-actions';
import { updateColumnsWithLanguage } from '../../../utils';
import { Location } from '../../../ts/interfaces';
import { Avatar } from '@fluentui/react-components';

const dropDownData = [
  GLOBALENUMS.DROPDOWNDATA.ORG_UNITS,
  GLOBALENUMS.DROPDOWNDATA.ROLES,
  GLOBALENUMS.DROPDOWNDATA.LOCATIONS,
  GLOBALENUMS.DROPDOWNDATA.WORKING_GROUPS,
];

interface MGTUserDetails {
  id: string;
  displayName: string;
  email: string;
  jobTitle: string;
  businessPhones: string;
}

interface ListCookieData {
  key: string;
  index: number;
  width?: number;
}

const EmployeesList = (props: PropsFromRedux) => {
  const {
    EmployeeReducer: { employees },
    UserReducer: { language, permissions },
    fetchDropdownDataByType,
    fetchEmployeeAction,
    fetchWorkingGroupsAction,
    selectEmployeeByIdAction,
    updateEmployeeAction,
  } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [mgtUserDetails, setMGTUserDetails] = useState<MGTUserDetails[]>([]);
  const [enableDelete, setEnableDelete] = useState<boolean>(false);
  const styles = getStyles();
  const mgtUserList: MGTUserDetails[] = [];
  const userIdSet = new Set<string>();

  const navigate = useNavigate();

  const _rowsSelected: Selection = new Selection({
    onSelectionChanged: () => _getSelectionDetails(),
  });

  useEffect(() => {
    fetchEmployeeAction();
    fetchWorkingGroupsAction();
    fetchDropdownDataByType(dropDownData);
  }, []);

  const columns: IColumn[] = [
    {
      name: __('name'),
      fieldName: 'username',
      key: 'displayName',
      minWidth: 90,
      maxWidth: 120,
      showSortIconWhenUnsorted: true,
      onRender: (record: Employee) => record.username && onRenderName(record),
    },
    {
      name: __('job title'),
      fieldName: 'username',
      key: 'jobTitle',
      minWidth: 90,
      maxWidth: 120,
      showSortIconWhenUnsorted: true,
      onRender: (record: Employee) =>
        record.username && (
          <Person userId={record.username} key={`job-${record.username}`}>
            <PersonDisplayName attr="jobTitle" />
          </Person>
        ),
    },
    {
      name: __('phone number'),
      fieldName: 'username',
      key: 'businessPhones',
      minWidth: 90,
      maxWidth: 120,
      showSortIconWhenUnsorted: true,
      onRender: (record: Employee) =>
        record.username && (
          <Person userId={record.username} key={`phone-${record.username}`}>
            <PersonDisplayName attr="businessPhones" />
          </Person>
        ),
    },
    {
      name: __('email'),
      fieldName: 'username',
      key: 'email',
      minWidth: 90,
      maxWidth: 250,
      showSortIconWhenUnsorted: true,
      onRender: (record: Employee) => record.username && onRenderEmail(record),
    },
    {
      name: __('head of'),
      fieldName: 'username',
      key: 'departmentsHead',
      minWidth: 90,
      maxWidth: 160,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (record: Employee) => renderOrganizationTableEntry(record, 'departmentsHead'),
    },
    {
      name: __('departments'),
      fieldName: 'username',
      key: 'department',
      minWidth: 90,
      maxWidth: 160,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (record: Employee) => renderOrganizationTableEntry(record, 'departments'),
    },
    {
      name: __('processes'),
      fieldName: 'username',
      key: 'process',
      minWidth: 90,
      maxWidth: 160,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (record: Employee) => renderProcesses(record),
    },
    {
      name: __('roles'),
      fieldName: 'username',
      key: 'role',
      minWidth: 90,
      maxWidth: 160,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (record: Employee) => renderOrganizationTableEntry(record, 'roles'),
    },
    {
      name: __('locations'),
      fieldName: 'locations',
      key: 'location',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (record: Employee) => renderLocations(record),
    },
    {
      name: __('working groups'),
      fieldName: 'workingGroup',
      key: 'workingGroup',
      minWidth: 90,
      maxWidth: 160,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (record: Employee) => record.workingGroup && <>{renderWorkingGroup(record)}</>,
    },
    {
      name: '',
      key: 'actions',
      minWidth: 70,
      maxWidth: 90,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (record: Employee) => {
        if (checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_employee)) {
          return (
            <TooltipHost content={__('edit employee')} directionalHint={DirectionalHint.bottomCenter}>
              <IconButton onClick={() => onEmployeeEdit(record)} iconProps={{ iconName: 'Edit' }}></IconButton>
            </TooltipHost>
          );
        }
      },
    },
  ];
  const [tableColumns, setTableColumns] = useState<IColumn[]>(columns);

  //This useEffect is used to update the columns header when the language is changed
  useEffect(() => {
    const updatedColumns = updateColumnsWithLanguage(columns);
    setTableColumns(updatedColumns);
  }, [language]);

  const onDeleteEmployee = () => {
    for (const employee of selectedEmployees) {
      updateEmployeeAction(
        {
          id: employee.id,
          isEmployee: false,
        },
        () => {
          fetchEmployeeAction();
          setEnableDelete(false);
        },
      );
    }
  };

  const _getSelectionDetails = () => {
    const selectionCount = _rowsSelected.getSelectedCount();
    if (selectionCount > 0) {
      setSelectedEmployees(_rowsSelected.getSelection() as Employee[]);
      setEnableDelete(true);
    } else {
      setEnableDelete(false);
    }
  };

  const onEmployeeEdit = (record: Employee) => {
    selectEmployeeByIdAction(record.id);
    setVisible(true);
  };

  const onRenderEmail = (record: Employee) => {
    const email = document.getElementById(`email-${record.id}`)?.textContent;
    return (
      <Link id={`email-${record.id}`} onClick={() => window.open(`mailto:${email}`, '_blank')?.focus()}>
        {
          <Person userId={record.username} id={`email-${record.id}`} key={`email-${record.username}`}>
            <PersonDisplayName attr="mail" />
          </Person>
        }
      </Link>
    );
  };

  const getGivenAttributeValue = (attr: string, person: { [key: string]: unknown }) => {
    const attrValue = person[attr];
    const extractedVal = Array.isArray(attrValue) ? attrValue[0] : attrValue;
    return extractedVal ? extractedVal : '';
  };

  const getPersonAvatar = (displayName: string, personImage: string) => {
    try {
      return (
        <Avatar
          color="colorful"
          name={displayName}
          image={{
            src: personImage,
          }}
        />
      );
    } catch (error) {
      console.error('error', error);
      return <Avatar color="colorful" name="U K" />;
    }
  };

  const GetDisplayNameByUserId = (props: { attr?: string } & MgtTemplateProps) => {
    const {
      dataContext: { person },
    } = props;
    const id = person.id;
    const displayNameValue = getGivenAttributeValue('displayName', person);
    const emailValue = getGivenAttributeValue('mail', person);
    const jobTitleValue = getGivenAttributeValue('jobTitle', person);
    const businessPhonesValue = getGivenAttributeValue('businessPhones', person);
    const personImage = getGivenAttributeValue('personImage', person);

    if (!userIdSet.has(id)) {
      mgtUserList.push({
        id: id,
        displayName: displayNameValue,
        email: emailValue,
        jobTitle: jobTitleValue,
        businessPhones: businessPhonesValue,
      });
      userIdSet.add(id);
    }

    setMGTUserDetails(mgtUserList);

    return (
      <div className={styles.customPersonName}>
        <div style={{ marginRight: '3px' }}>{getPersonAvatar(displayNameValue, personImage)}</div>
        <div>{displayNameValue}</div>
      </div>
    );
  };

  const onRenderName = (record: Employee) => {
    return (
      <div id={`name-${record.id}`} className={styles.nameTable}>
        {
          <Stack wrap horizontal>
            <Person
              userId={record.username}
              id={`name-${record.id}`}
              view={ViewType.oneline}
              personCardInteraction={PersonCardInteraction.click}
              key={`name-${record.username}`}
            >
              <GetDisplayNameByUserId />
            </Person>
          </Stack>
        }
      </div>
    );
  };

  const renderOrganizationTableEntry = (record: Employee, type: string) => {
    const entries = (record[type] || []).map((i: EmployeeOrgsType) => i[1]);
    return (
      <>
        <Popover content={entries?.join(', ')} target={`${type.toLocaleLowerCase()}-${record.id}`}>
          <Stack wrap horizontal>
            <div id={`${type.toLocaleLowerCase()}-${record.id}`}>{entries?.join(', ')}</div>
          </Stack>
        </Popover>
      </>
    );
  };

  const renderProcesses = (item: Employee) => {
    if (item && item.processes) {
      return item.processes.map((process: any, index: number) => {
        if (process[2] != GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP) {
          return (
            <Link
              key={`process-${item.id}-${index}}`}
              onClick={() => {
                navigate(`/process-management/processes/${process[0]}`);
              }}
            >
              {!index ? process[1] : `, ${process[1]}`}
            </Link>
          );
        } else {
          return <div>{!index ? process[1] : `, ${process[1]}`}</div>;
        }
      });
    } else return <></>;
  };

  const renderWorkingGroup = (record: Employee) => {
    const workingGroups = record.workingGroup?.map((group) => group.title)?.join(', ');
    return (
      <Popover content={workingGroups} target={`workinggroups-${record.id}`}>
        <Stack wrap horizontal>
          <div id={`workinggroups-${record.id}`}>{workingGroups}</div>
        </Stack>
      </Popover>
    );
  };

  const renderLocations = (record: Employee) => {
    const locations = record.locations?.map((loc: Location) => loc.locationName)?.join(', ');
    return (
      <Popover content={locations} target={`locations-${record.id}`}>
        <Stack wrap horizontal>
          <div id={`locations-${record.id}`}>{locations}</div>
        </Stack>
      </Popover>
    );
  };

  const onAddEmployee = () => {
    setVisible(true);
  };

  const renderProcessesBtns = () => {
    return (
      <>
        {enableDelete && checkPermission(permissions, GLOBALENUMS.PERMISSIONS.delete_employee) && (
          <Popover
            title={__('delete selected employees')}
            content={__('are you sure you want to the selected employees?')}
            target={'delete-employees'}
            enableConfirm={true}
            onOk={() => onDeleteEmployee()}
          >
            <ActionButton id="delete-employees" iconProps={{ iconName: 'Delete' }} buttonType={ButtonType.default}>
              {__('delete')}
            </ActionButton>
          </Popover>
        )}
        {checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_employee) && (
          <PrimaryButton iconProps={{ iconName: 'AddFriend' }} allowDisabledFocus onClick={() => onAddEmployee()}>
            {__('new')}
          </PrimaryButton>
        )}
      </>
    );
  };

  function copyAndSort(
    items: Employee[],
    columnField: string,
    columnKey: string,
    isSortedDescending?: boolean,
  ): Employee[] {
    const fieldname = columnField;
    const key = columnKey;
    try {
      if (
        mgtUserDetails &&
        mgtUserDetails.length > 0 &&
        (key === 'displayName' || key === 'email' || key === 'jobTitle' || key === 'businessPhones')
      ) {
        // in this case we normally have guid and this is not sortable
        // so we need to fetch the raw property value and we can get them from the mgtUserDetails hook
        // we get the id from the items and then we search for the displayName in the mgtUserDetails
        const sortedItems = items.slice(0).sort((a, b) => {
          const aDisplayName = mgtUserDetails.find((el) => el.id === a[fieldname])?.[key] ?? '';
          const bDisplayName = mgtUserDetails.find((el) => el.id === b[fieldname])?.[key] ?? '';
          const comparison = aDisplayName.localeCompare(bDisplayName);
          return isSortedDescending ? comparison : -comparison;
        });
        return sortedItems;
      } else {
        return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
      }
    } catch (error) {
      console.error('error', error);
      return items;
    }
  }

  // A callback function to handle the column header click event
  // Especially for sorting the list items
  const onColumnHeaderClickCallBackFunction = (
    listItemsCallback: Employee[],
    columnsCallback: IColumn[],
    cookieDataCallback: ListCookieData[],
    setListColumnsCallback: React.Dispatch<React.SetStateAction<IColumn[]>>,
    mergeColumnWithCookieDataCallback: (cols: IColumn[], cData: ListCookieData[]) => IColumn[],
    setListItemsCallback: React.Dispatch<Employee[]>,
    ev?: React.MouseEvent<HTMLElement>,
    columnClicked?: IColumn,
  ): void => {
    if (columnsCallback && listItemsCallback && ev && columnClicked) {
      const newColumns: IColumn[] = columnsCallback.slice();
      const currColumn: IColumn = newColumns.filter((currCol) => columnClicked.key === currCol.key)[0];

      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });

      if (currColumn.isSorted) {
        const newItems = copyAndSort(
          listItemsCallback,
          currColumn.fieldName!,
          currColumn.key!,
          currColumn.isSortedDescending,
        );

        if (cookieDataCallback && cookieDataCallback.length) {
          setListColumnsCallback(mergeColumnWithCookieDataCallback(newColumns, cookieDataCallback));
        } else {
          setListColumnsCallback(newColumns);
        }
        setListItemsCallback(newItems);
      }
    }
  };

  return (
    <>
      <BasicList
        setKey={GLOBALENUMS.BASICLISTTYPES.EMPLOYEES_LIST}
        items={employees}
        columns={tableColumns}
        setTableColumns={setTableColumns}
        selection={_rowsSelected}
        className={styles.employeeListContainer}
        rightHeaderButtons={renderProcessesBtns()}
        onColumnHeaderClickCallBackFunction={onColumnHeaderClickCallBackFunction}
      ></BasicList>
      <EmployeeForm setVisible={setVisible} visible={visible} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ EmployeeReducer, UserReducer }: RootState) => ({
  EmployeeReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  fetchDropdownDataByType,
  fetchEmployeeAction,
  fetchWorkingGroupsAction,
  selectEmployeeByIdAction,
  updateEmployeeAction,
});
export default connector(EmployeesList);
