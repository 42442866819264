import { TeamsFx } from '@microsoft/teamsfx';
import { GLOBALENUMS, getCurrentLanguage, setCurrentLanguage } from 'modeling-tool';
import { AiioNotification, Setting, SettingGroup, Tenant, TenantMembership } from '../../ts/interfaces';

export interface UserReducerState {
  tenantMembers?: TenantMembership;
  refresh: boolean;
  notifications: AiioNotification[];
  settingsGroups: SettingGroup[];
  settingsMap: any;
  language: string;
  username: string;
  permissions: GLOBALENUMS.PERMISSIONS[];
  tenant?: Tenant;
  admins?: string;
  upvoty?: string;
  teamsfx?: TeamsFx;
  isLicenseTest?: boolean;
  licenseRemainingDays?: number;
  licenseStartDate?: string;
  licenseEndDate?: string;
  isAdmin?: boolean;
}

export default function reducer(
  state: UserReducerState = {
    refresh: false,
    notifications: [],
    settingsGroups: [],
    settingsMap: {},
    language: getCurrentLanguage(),
    username: '',
    permissions: [],
    upvoty: '',
    tenant: undefined,
    teamsfx: undefined,
    tenantMembers: undefined,
    isLicenseTest: undefined,
    licenseRemainingDays: undefined,
    licenseStartDate: undefined,
    licenseEndDate: undefined,
    isAdmin: undefined,
  },
  action: any,
): UserReducerState {
  switch (action.type) {
    // getting advertisers fro server and load it into redux state
    case 'SAVE_PROFILE': {
      return Object.assign({}, state, action.payload, { notifications: [] });
    }
    case 'GET_CURRENT_USER': {
      let settingsMap: any = {};
      const user = action.payload.objects[0];

      let settings: Setting[] = [];
      if (user.settingsGroups && user.settingsGroups.length > 0) {
        user.settingsGroups.forEach((group: SettingGroup) => {
          if (group.settings && group.settings.length > 0) {
            settings = settings.concat(group.settings);
          }
        });
        settingsMap = settings.reduce(function (map: any, obj: any) {
          map[obj.code] = obj.value;
          map[obj.code + '_default'] = obj.default;
          return map;
        }, {});
      }

      user.settingsMap = settingsMap;
      return Object.assign({}, state, user, { notifications: state.notifications || [] });
    }
    case 'GET_NOTIFICATIONS': {
      const notifications = action.payload.objects.map((item: any) => {
        try {
          item.meta = JSON.parse(item.meta.replace(/'/g, '"'));
        } catch (e) {
          item.meta = {};
        }
        return item;
      });
      return Object.assign({}, state, { notifications });
    }
    case 'ADD_NOTIFICATION': {
      const audio = new Audio('/notification.wav');
      audio.play();
      return Object.assign({}, state, { notifications: [action.payload, ...state.notifications] });
    }
    case 'CHANGE_LANGUAGE': {
      setCurrentLanguage(action.payload);
      return Object.assign({}, state, { language: action.payload });
    }
    case 'UPDATE_NOTIFICATION': {
      return Object.assign({}, state, {
        notifications: state.notifications.map((item: any) => {
          if (item.id === action.payload.id) {
            return { ...item, isRead: action.payload.isRead };
          } else {
            return item;
          }
        }),
      });
    }
    case 'UPDATE_NOTIFICATION_ALL': {
      return Object.assign({}, state, {
        notifications: state.notifications.map((item: any) => {
          return { ...item, isRead: true };
        }),
      });
    }
    case 'UPDATE_SETTING': {
      return state;
    }
    case 'UPDATE_TENANT': {
      return Object.assign({}, state, {
        tenant: action.payload,
      });
    }
    case 'SET_USER_ADMINS': {
      return Object.assign({}, state, {
        admins: action.payload,
      });
    }
    case 'Set_TEAMS_FX': {
      return Object.assign({}, state, {
        teamsfx: action.payload,
      });
    }
    case 'ADD_REQUESTS': {
      state.settingsMap.ai_limit = action.payload.ai_limit;
      return Object.assign({}, state, { settingsMap: state.settingsMap });
    }
    default: {
      break;
    }
  }
  return state;
}
