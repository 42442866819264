import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchLocationsAction = (orderBy?: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `location/?${orderBy || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_LOCATIONS',
    cb,
  });

export const selectLocationAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_LOCATION',
    payload: data,
  });

export const selectLocationByIdAction = (id: number) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_LOCATION_BY_ID',
    payload: id,
  });

export const updateLocationAction = (data: any, cb: (...args: any[]) => void) =>
  UseAction({
    url: `location/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PUT,
    action: 'UPDATE_LOCATION',
    reqPayload: data,
    cb,
  });

export const addLocationAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'location/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_LOCATION',
    reqPayload: data,
    cb,
  });

export const deleteLocationAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `location/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_LOCATION',
    payload: data.id,
    cb,
  });
