import { MessageBar, MessageBarType } from '@fluentui/react';
import { __ } from 'modeling-tool';
import { useStyles } from './group-membership-styles';
import { MemberList } from './member-list';
import { GroupMemberShipHeader } from './group-membership-header';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useBoolean } from '@fluentui/react-hooks';

const GroupMembership = () => {
  const classes = useStyles();

  const [showSuccess, { setTrue: viewSuccess, setFalse: dismissSuccess }] = useBoolean(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const successParam = queryParams.get('success');

    if (successParam == 'true') {
      viewSuccess();
    }
  }, [location]);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        dismissSuccess();
      }, 3000);
    }
  }, [showSuccess]);

  return (
    <>
      <div>
        {showSuccess && (
          <MessageBar
            delayedRender={false}
            messageBarType={MessageBarType.success}
            styles={{
              root: {
                position: 'absolute',
                top: '10%',
                left: '30%',
                width: 'fitContentToBounds',
              },
            }}
          >
            {<div>{__('Your subspription has been successfully activated.')}</div>}
          </MessageBar>
        )}
        <div className={classes.groupMembershipDiv}>
          <GroupMemberShipHeader />
          <div className={classes.membersGrid}>
            <MemberList
              permissionGroup="administrator"
              headerText={__('admins have full access and are allowed to change system relevant settings')}
            />
            <MemberList
              permissionGroup="manager"
              headerText={__('managers can create content, send tasks, and grant approvals')}
            />
            <MemberList
              permissionGroup="contributor"
              headerText={__('contributors can modify content if they have been given a task to do so')}
            />
            <MemberList permissionGroup="viewer" headerText={__('Viewers can see and comment on content')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupMembership;
