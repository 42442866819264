import React, { ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react';
import { __ } from 'modeling-tool';
import {
  addQueryChapterParamsAction,
  clearQueryChapterParamsAction,
} from '../../../../store/actions/requirement-action';
import { RootState } from '../../../../config/store';

interface ChapterSearchBoxProps extends PropsFromRedux {
  requirementId: string | undefined;
  toggleChangeParams: () => void;
}

const ChapterSearchBox = (props: ChapterSearchBoxProps) => {
  const {
    UserReducer,
    RequirementReducer: { params, filterValues },
    requirementId,
    addQueryChapterParamsAction,
    clearQueryChapterParamsAction,
    toggleChangeParams,
  } = props;

  const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 300 } };

  const onChange = (event?: ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
    if (newValue) {
      // stop other queries if putting stuff in too fast
      window.stop();

      const values = { name: newValue };
      const queryParams = setQueryParams(values, params, filterValues);
      addQueryChapterParamsAction(queryParams);
      toggleChangeParams();
    } else {
      onClear();
      toggleChangeParams();
    }
  };

  const setQueryParams = (values: any, params: any, filterValues?: any) => {
    const queryParams: any = [];
    if (filterValues) {
      for (const key in filterValues) {
        if (key != 'name') {
          values[key] = filterValues[key];
        }
      }
    }
    for (const key in values) {
      if (values[key] && values[key]?.length) {
        if (key === 'name') {
          addParam(queryParams, `${key}__icontains`, `${values[key]}`);
        }
      }
    }
    values = queryParams.length == 0 ? {} : values;
    queryParams.push({ from_requirement: `${requirementId}` });
    queryParams.push({ offset: 0 });

    queryParams.push({ filtered: true });
    const previousLimit = params.find((e: { limit: string }) => e.limit);
    queryParams.push({ limit: previousLimit ? previousLimit.limit : '10' });

    queryParams.push({ filterValues: values });
    return queryParams;
  };

  const addParam = (queryParams: any, key: string, value: string) => {
    queryParams.push({
      [key]: value,
    });
  };

  const onClear = () => {
    clearQueryChapterParamsAction();
    const queryParams = getDefaultParams(params);
    addQueryChapterParamsAction(queryParams);
  };

  const getDefaultParams = (params: any) => {
    const queryParams: any = [];
    queryParams.push({ from_requirement: `${requirementId}` });

    queryParams.push({ offset: 0 });
    queryParams.push({ level: 0 });

    const previousLimit = params.find((e: { limit: string }) => e.limit);
    queryParams.push({ limit: previousLimit ? previousLimit.limit : '10' });
    queryParams.push({ filterValues: [] });
    return queryParams;
  };

  return (
    <>
      <SearchBox styles={searchBoxStyles} placeholder={__('search chapter')} onClear={onClear} onChange={onChange} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, RequirementReducer }: RootState) => ({ UserReducer, RequirementReducer });
const connector = connect(mapStateToProps, { clearQueryChapterParamsAction, addQueryChapterParamsAction });

export default connector(ChapterSearchBox);
