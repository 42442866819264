import { MgtTemplateProps } from '@microsoft/mgt-react';
import React from 'react';
import { getStyles } from './person-display-styles';

interface PersonDisplayProps extends MgtTemplateProps {
  attr?: string;
  prefix?: string;
  postfix?: string;
  shrink?: boolean;
}

const PersonDisplayName = (props: PersonDisplayProps) => {
  const { attr, prefix, postfix, shrink } = props;
  const {
    dataContext: { person },
  } = props;

  const styles = getStyles();

  const attrValue = person[attr || 'displayName'];
  let display = Array.isArray(attrValue) ? attrValue[0] : attrValue;
  display = display ? display : '';

  return <div className={shrink ? styles.personDisplayName : ''}>{`${prefix || ``}${display}${postfix || ``}`}</div>;
};

export default PersonDisplayName;
