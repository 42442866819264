import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { ProcessRecordModeler } from '../../components';
import { RootState } from '../../config/store';
import { getRACIFieldsFormObject, getRACIFieldsObject } from '../../config/utils';
import { __ } from 'modeling-tool';
import { clearTaskAction, getNewTaskAction, getTaskAction, updateTaskAction } from '../../store/actions/task-actions';
import { ProcessInstance, ProcessInstanceFormValues } from '../../ts/interfaces';
import {
  setProcessInstancesFromTaskAction,
  updateProcessInstanceAction,
  selectProcessInstanceAction,
} from '../../store/actions/process-instance-actions';
import { useParams } from 'react-router';

const TasksDetail = (props: PropsFromRedux) => {
  const {
    TaskReducer: { selectedTask },
    clearTaskAction,
    getTaskAction,
    updateTaskAction,
    setProcessInstancesFromTaskAction,
    updateProcessInstanceAction,
    getNewTaskAction,
  } = props;

  const alert = useAlert();
  const [sendForm, setSendForm] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      clearTaskAction(() => {
        if (params.id) {
          getTaskAction(parseInt(params.id), ({ data }) => {
            if (data) {
              initFormValues(data);
            }
          });
        }
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (selectedTask) {
      setProcessInstancesFromTaskAction(selectedTask);

      if (selectedTask.isNew) {
        updateProcessInstanceAction({
          id: selectedTask?.id,
          isNew: false,
        });
      }
    }
  }, [selectedTask]);

  useEffect(() => {
    if (sendForm) {
      onSave();
    }
  }, [sendForm]);

  const initFormValues = (data: ProcessInstance) => {
    data = {
      instanceName: data.instanceName || null,
      documents: data.documents || null,
      resources: data.resources || null,
      goals: data.goals || null,
      description: data.description || null,
      message: data.message || null,
      days: data.duration?.split('-')[0] || null,
      hours: data.duration?.split('-')[1] || null,
      minutes: data.duration?.split('-')[2] || null,
      ...getRACIFieldsFormObject(data),
    };
    reset(data);
  };

  const onSave = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        let values = data as ProcessInstanceFormValues;
        const { days, hours, minutes } = values;
        const durationString = [days, hours, minutes].join('-');

        const raciValues = getRACIFieldsObject(values, selectedTask);
        values = { ...values, ...raciValues };
        updateTaskAction(
          {
            id: selectedTask?.id,
            duration: durationString,
            ...values,
          },
          (msg, withError) => {
            if (withError) {
              setShowLoading(false);
              alert.error(`${__('Error')} ${msg.response.status}`);
            } else {
              if (sendForm) {
                setShowLoading(false);
                alert.success(__('task was sent successfully!'));
                getNewTaskAction();
              } else {
                setShowLoading(false);
                alert.success(__('task was saved successfully!'));
              }
            }
          },
        );
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const onSend = () => {
    setSendForm(true);
  };

  return (
    <>
      {selectedTask && (
        <ProcessRecordModeler control={control} onSend={onSend} isTaskDetailsView={true} enableLoading={showLoading} />
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ TaskReducer }: RootState) => ({ TaskReducer });
const connector = connect(mapStateToProps, {
  clearTaskAction,
  getTaskAction,
  updateTaskAction,
  setProcessInstancesFromTaskAction,
  updateProcessInstanceAction,
  selectProcessInstanceAction,
  getNewTaskAction,
});
export default connector(TasksDetail);
