import { Meta } from '../../ts/interfaces';

export interface InitialDataDashboardReducer {
  initialObjects: any;
  meta?: Meta;
}

export default function reducer(
  state: InitialDataDashboardReducer = {
    initialObjects: {},
    meta: undefined,
  },
  action: any,
): InitialDataDashboardReducer {
  switch (action.type) {
    case 'CREATE_INITIAL_DATA': {
      const tmpStateObjects = state.initialObjects;
      Object.entries(action.payload.objects).forEach(([key, value]) => {
        if (tmpStateObjects[key] && Array.isArray(value)) {
          const existingObjects = tmpStateObjects[key];
          tmpStateObjects[key] = [...existingObjects, ...value];
        } else {
          tmpStateObjects[key] = value;
        }
      });

      return Object.assign({}, state, {
        meta: action.payload.meta,
        initialObjects: tmpStateObjects,
      });
    }
    case 'DELETE_INITIAL_DATA': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        initialObjects: action.payload.objects || {},
      });
    }
    default: {
      break;
    }
  }
  return state;
}
