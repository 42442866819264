import { GLOBALENUMS, __ } from 'modeling-tool';
import {
  Process,
  ProcessGroup,
  Resource,
  Role,
  Location,
  Department,
  Requirement,
  WorkingGroup,
  ResourceGroup,
  ExternalPartner,
  StaffPosition,
} from '../../ts/interfaces';
import { IDropdownOption } from '@fluentui/react';

export const renderSelectDropDown = (type: GLOBALENUMS.DROPDOWNDATA, data?: any) => {
  switch (type) {
    case GLOBALENUMS.DROPDOWNDATA.ORG_UNITS_AND_STAFF_POSITIONS:
      return data.map(
        (unit: any) =>
          ({ key: unit.resourceUri, text: unit.departmentName || unit.staffPositionName }) as IDropdownOption,
      );
    case GLOBALENUMS.DROPDOWNDATA.RESOURCES:
      return data.map(
        (resource: Resource) => ({ key: resource.resourceUri, text: resource.nameExtended }) as IDropdownOption,
      );
    case GLOBALENUMS.DROPDOWNDATA.LOCATIONS:
      const options = data.map((location: Location) => {
        return { key: location.resourceUri, text: location.locationName } as IDropdownOption;
      });
      return options;
    case GLOBALENUMS.DROPDOWNDATA.PROCESSES:
      return data.map(
        (process: Process) => ({ key: process.resourceUri, text: process.processName }) as IDropdownOption,
      );
    case GLOBALENUMS.DROPDOWNDATA.ROLES:
      return data.map((role: Role) => ({ key: role.resourceUri, text: role.roleName }) as IDropdownOption);
    case GLOBALENUMS.DROPDOWNDATA.ORG_UNITS:
      return data.map((unit: Department) => ({ key: unit.resourceUri, text: unit.departmentName }) as IDropdownOption);
    case GLOBALENUMS.DROPDOWNDATA.EXTERNAL_PARTNERS:
      return data.map(
        (unit: ExternalPartner) => ({ key: unit.resourceUri, text: unit.externalPartnerName }) as IDropdownOption,
      );
    case GLOBALENUMS.DROPDOWNDATA.STAFF_POSITIONS:
      return data.map(
        (unit: StaffPosition) => ({ key: unit.resourceUri, text: unit.staffPositionName }) as IDropdownOption,
      );
    case GLOBALENUMS.DROPDOWNDATA.REQUIREMENTS:
      return data.map(
        (requirement: Requirement) =>
          ({
            key: requirement.resourceUri,
            text: requirement.name,
            requirementChapters: requirement.requirementChapters,
            resourceUri: requirement.resourceUri,
          }) as IDropdownOption,
      );
    case GLOBALENUMS.DROPDOWNDATA.WORKING_GROUPS: {
      return data.map((group: WorkingGroup) => ({ key: group.resourceUri, text: group.title }) as IDropdownOption);
    }
    case GLOBALENUMS.DROPDOWNDATA.RESOURCEGROUPS: {
      return data.map(
        (resourcegroup: ResourceGroup) =>
          ({ key: resourcegroup.resourceUri, text: resourcegroup.name }) as IDropdownOption,
      );
    }
    case GLOBALENUMS.DROPDOWNDATA.STATUS: {
      return data.map(
        (status: any) => ({ key: status.resourceUri, text: __(status.projectStatusName) }) as IDropdownOption,
      );
    }
    default:
      break;
  }
};
