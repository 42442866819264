import { Meta, ProcessInstance } from '../../ts/interfaces';

interface TaskReducerState {
  tasks: ProcessInstance[];
  meta?: Meta;
  selectedTask?: ProcessInstance;
  selected: ProcessInstance[];
  newTaskCount: number;
  openTaskCount: number;
}

export default function reducer(
  state: TaskReducerState = {
    tasks: [],
    meta: undefined,
    selectedTask: undefined,
    selected: [],
    newTaskCount: 0,
    openTaskCount: 0,
  },
  action: any,
): TaskReducerState {
  switch (action.type) {
    case 'FETCH_TASKS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        tasks: action.payload.objects,
      });
    }
    case 'SEARCH_TASKS': {
      return Object.assign({}, state, {
        tasks: action.payload.objects,
      });
    }
    case 'CLEAR_TASK': {
      return Object.assign({}, state, {
        selectedTask: undefined,
      });
    }
    case 'GET_TASK': {
      return Object.assign({}, state, {
        selectedTask: action.payload,
      });
    }

    case 'SELECT_TASK': {
      return Object.assign({}, state, {
        selectedTask: action.payload,
      });
    }
    case 'SELECT_TASKS': {
      return Object.assign({}, state, {
        selected: state.tasks.filter((task) => action.payload.indexOf(task.id) !== -1),
      });
    }
    case 'ADD_SELECT_TASKS': {
      return Object.assign({}, state, {
        selected: [...state.selected, action.payload],
        tasks: [...state.tasks, action.payload],
      });
    }
    case 'UPDATE_SELECTED_TASK': {
      return Object.assign({}, state, {
        selectedTask: { ...state.selectedTask, ...action.payload },
      });
    }
    case 'GET_NEW_TASK_COUNT': {
      return Object.assign({}, state, {
        newTaskCount: action.payload.isNew,
        openTaskCount: action.payload.isOpen,
      });
    }

    case 'SELECT_TASK_BY_ID': {
      return Object.assign({}, state, {
        selectedTask: state.tasks.filter((task) => task.id === action.payload)[0],
      });
    }
    case 'UPDATE_TASK': {
      return {
        ...state,
        tasks: state.tasks.map((process) => (process.id === action.payload.id ? action.payload : process)),
        selectedTask: action.payload,
      };
    }
    case 'ADD_TASK': {
      return {
        ...state,
        tasks: state.tasks.concat(action.payload),
      };
    }
    case 'DELETE_TASK': {
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.id !== action.payload),
      };
    }

    case 'SET_META_TASK': {
      return Object.assign({}, state, {
        meta: { ...state.meta, ...action.payload },
      });
    }

    default: {
      break;
    }
  }
  return state;
}
