import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import createRootReducer from '../store/reducers';
import { IsProduction } from './config';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const logger = createLogger();

const reducers = createRootReducer(); // root reducer with router state

export default function configureStore() {
  if (!IsProduction()) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(reducers, composeEnhancers(applyMiddleware(promise, thunk, logger)));
  } else {
    const composeEnhancers = compose;
    return createStore(reducers, composeEnhancers(applyMiddleware(promise, thunk)));
  }
}

export type RootState = ReturnType<typeof reducers>;
