import React, { useState, useEffect, useContext } from 'react';
import {
  DefaultButton,
  IDropdownOption,
  IStackTokens,
  Label,
  Panel,
  PrimaryButton,
  ScrollablePane,
  Stack,
  Text,
} from '@fluentui/react';
import {
  DropdownPicker,
  DropdownWithSearch,
  GLOBALENUMS,
  HookFormProps,
  PeoplePicker,
  PeoplePickerWithTags,
  StyleContext,
  __,
} from 'modeling-tool';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../../config/store';
import { fetchDropdownDataByType } from '../../../../../store/actions/dropdown-data-actions';
import { renderSelectDropDown } from '../../../../../utils/process-management';
import { checkSetting } from '../../../../../config/utils';
import FilterCollapse from './filter-collapse';
import { getStyles } from './process-filter-form-styles';
import { DatePicker } from '../../../../../components';
import { useWatch } from 'react-hook-form';

const dropDownData = [
  GLOBALENUMS.DROPDOWNDATA.ORG_UNITS_AND_STAFF_POSITIONS,
  GLOBALENUMS.DROPDOWNDATA.ROLES,
  GLOBALENUMS.DROPDOWNDATA.EXTERNAL_PARTNERS,
  GLOBALENUMS.DROPDOWNDATA.LOCATIONS,
  GLOBALENUMS.DROPDOWNDATA.STATUS,
];

interface ProcessFilterProps extends HookFormProps, PropsFromRedux {
  handleClearFilter: () => void;
  handleFilterApply: () => void;
  toggleShowFilter: () => void;
}

const ProcessFilterForm = (props: ProcessFilterProps) => {
  const {
    DropdownDataReducer: { orgUnitsAndStaffPositions, locations, status, roles, staffPositions, externalPartners },
    UserReducer: { settingsMap },
    ProcessReducer: { filterValues },
    fetchDropdownDataByType,
    handleClearFilter,
    handleFilterApply,
    toggleShowFilter,
    control,
  } = props;

  const [filterOrgUnits, setFilterOrgUnits] = useState<IDropdownOption[]>([]);
  const [filterRoles, setFilterRoles] = useState<IDropdownOption[]>([]);
  const [filterStatus, setFilterStatus] = useState<IDropdownOption[]>([]);
  const [filterLocations, setFilterLocations] = useState<IDropdownOption[]>([]);
  const [filterStaffPositions, setFilterStaffPositions] = useState<IDropdownOption[]>([]);
  const [filterExternalPartners, setFilterExternalPartners] = useState<IDropdownOption[]>([]);
  const [selectedCurrentDropdown, setSelectedCurrentDropdown] = useState<string>('');

  const stackTokens: IStackTokens = {
    childrenGap: '2%',
    padding: 10,
  };
  const { useStyleProps } = useContext(StyleContext);
  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);
  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
  }, []);

  useEffect(() => {
    orgUnitsAndStaffPositions &&
      setFilterOrgUnits(renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, orgUnitsAndStaffPositions));
    roles && setFilterRoles(renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ROLES, roles));
    locations && setFilterLocations(renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.LOCATIONS, locations));
    status && setFilterStatus(renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.STATUS, status));
    staffPositions &&
      setFilterStaffPositions(renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.STAFF_POSITIONS, staffPositions));
    externalPartners &&
      setFilterExternalPartners(renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.EXTERNAL_PARTNERS, externalPartners));
  }, [orgUnitsAndStaffPositions, locations, status, roles, staffPositions, externalPartners]);

  const fieldsToWatch = useWatch({
    control,
    name: ['owner', 'ownerRole', 'ownerDepartment', 'ownerStaffPositions', 'ownerExternalPartner'],
  });

  enum DropDownKey {
    PERSON = 'owner',
    ROLE = 'ownerRole',
    DEPARTMENT = 'ownerDepartment',
    STAFFPOSITION = 'ownerStaffPositions',
    EXTERNALPARTNER = 'ownerExternalPartner',
  }

  useEffect(() => {
    const [owner, ownerRole, ownerDepartment, ownerStaffPositions, ownerExternalPartner] = fieldsToWatch;

    setSelectedCurrentDropdown(
      owner?.length
        ? DropDownKey.PERSON
        : ownerDepartment?.length
        ? DropDownKey.DEPARTMENT
        : ownerRole?.length
        ? DropDownKey.ROLE
        : ownerStaffPositions?.length
        ? DropDownKey.STAFFPOSITION
        : ownerExternalPartner?.length
        ? DropDownKey.EXTERNALPARTNER
        : '',
    );
  }, [fieldsToWatch]);

  const DateFilter = () => {
    return (
      <Stack horizontal tokens={stackTokens} className={styles.formElement}>
        <Stack grow>
          <Text variant="medium">{__('from')}</Text>
          <DatePicker
            className={styles.datepicker}
            name="fromDate"
            control={control}
            defaultValue={filterValues?.fromDate ? filterValues.fromDate : undefined}
          />
        </Stack>
        <Stack grow>
          <Text variant="medium">{__('to')}</Text>
          <DatePicker
            className={styles.datepicker}
            name="toDate"
            control={control}
            defaultValue={filterValues?.toDate ? filterValues.toDate : undefined}
          />
        </Stack>
      </Stack>
    );
  };

  const DropdownPickerFilter = (props: { label: string; name: string; options: any }) => {
    const { label, name, options } = props;
    return (
      <Stack className={styles.formElement}>
        <DropdownPicker
          control={control}
          name={name}
          options={options}
          labelTitle={__(`${label}`)}
          placeholder={__(`please select ${label}`)}
          multiSelect={true}
          separator
        />
      </Stack>
    );
  };

  const renderFormButtonsHolder = () => {
    return (
      <div className={styles.filterBtnsHolder}>
        <div className={styles.filterBtns}>
          <DefaultButton onClick={handleClearFilter} className={styles.filterBtnsGap}>
            {__('reset')}
          </DefaultButton>
          <PrimaryButton onClick={handleFilterApply} className={styles.filterBtnsGap}>
            {__('apply')}
          </PrimaryButton>
        </div>
      </div>
    );
  };

  const isDropdownDisabled = (dropdownName: string): boolean => {
    return !!selectedCurrentDropdown && selectedCurrentDropdown !== dropdownName;
  };

  const ProcessDropdownPickerFilter = (props: { label: string; name: string; options: any }) => {
    const { label, name, options } = props;
    return (
      <div className={styles.formElement}>
        <DropdownPicker
          control={control}
          name={name}
          options={options}
          placeholder={__(`provide ${label}`)}
          multiSelect
          disabled={isDropdownDisabled(name)}
        />
      </div>
    );
  };

  const PeopleFilter = (props: { label: string; name: string }): JSX.Element => {
    const { label, name } = props;
    const { useStyleProps } = useContext(StyleContext);
    const styleProps = useStyleProps();
    const styles = getStyles(styleProps);
    return (
      <>
        <div className={styles.formElement}>
          <Label className={styles.horizontalSeparator}>{__(`${label}`)}</Label>

          <PeoplePicker control={control} selectionMode="multiple" name={name} />
        </div>
      </>
    );
  };

  const renderOwnerFilterField = () => {
    return (
      <>
        <PeoplePickerWithTags
          control={control}
          selectionMode="multiple"
          name={'owner'}
          disabled={isDropdownDisabled('owner')}
          labelTitle={__('owner')}
        />
        <ProcessDropdownPickerFilter name="ownerRole" label={'role'} options={filterRoles} />
        <ProcessDropdownPickerFilter name="ownerDepartment" label={'department'} options={filterOrgUnits} />
        <ProcessDropdownPickerFilter
          name="ownerStaffPositions"
          label={'staff position'}
          options={filterStaffPositions}
        />
        <ProcessDropdownPickerFilter
          name="ownerExternalPartner"
          label={'ext. partner'}
          options={filterExternalPartners}
        />
      </>
    );
  };

  const renderFilterForm = () => {
    return (
      <>
        <DateFilter />
        {renderOwnerFilterField()}

        <PeopleFilter label={'created by'} name={'createdBy'} />
        <PeopleFilter label={'sent to'} name={'sendTo'} />
        <DropdownPickerFilter name="orgUnitsAndStaffPositions" label="department" options={filterOrgUnits} />
        <DropdownPickerFilter name={'processLocations'} label="location" options={filterLocations} />
        <DropdownPickerFilter name={'status'} label={'status'} options={filterStatus} />

        {checkSetting(settingsMap, 'enable_raci', 'true') && (
          <>
            <Label className={styles.horizontalSeparator}>{__('RACI')}</Label>
            {['responsible', 'accountable', 'consulted', 'informed'].map((raci) => (
              <Stack tokens={stackTokens} key={`${raci}_collapsible`}>
                <FilterCollapse fieldName={raci}>
                  <>
                    <PeopleFilter label={__('employee')} name={`${raci}__employees`} />
                    {orgUnitsAndStaffPositions?.length > 0 && (
                      <DropdownPickerFilter label="department" name={`${raci}__departments`} options={filterOrgUnits} />
                    )}
                    {roles?.length > 0 && (
                      <DropdownPickerFilter label="role" name={`${raci}__roles`} options={filterRoles} />
                    )}
                  </>
                </FilterCollapse>
              </Stack>
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Panel headerText={__('filter processes')} isOpen onDismiss={toggleShowFilter} isLightDismiss={true}>
        <ScrollablePane
          className={styles.formContainer}
          scrollContainerFocus={true}
          scrollContainerAriaLabel="Sticky component example"
        >
          {renderFilterForm()}
        </ScrollablePane>
        {renderFormButtonsHolder()}
      </Panel>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ DropdownDataReducer, UserReducer, ProcessReducer }: RootState) => ({
  DropdownDataReducer,
  UserReducer,
  ProcessReducer,
});

const connector = connect(mapStateToProps, { fetchDropdownDataByType });

export default connector(ProcessFilterForm);
