import { IosChevronRight20Filled, AddCircle32Regular } from '@fluentui/react-icons';
import { useStyles } from './entity-card-styles';
import { Parser } from 'html-to-react';
import { mergeClasses } from '@fluentui/react-components';
import { useState } from 'react';
import { LogoSection } from './logo-section';
import { FileListModal, IconSvg, Modal, __ } from 'modeling-tool';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../config/store';
import { fetchLibrariesAction, fetchLibraryPictureAction, fetchSitesAction } from '../../../store/actions/file-actions';
import { EntityCardType } from '../../../ts/enums';

interface EntityCardProps {
  name: string;
  description?: string;
  logo?: string;
  readonly?: boolean;
  selected?: boolean;
  showPicturePopup: boolean;
  cardType: string;
  setImageUrl?(value: string[]): void;
  handleOnClick: (e: any) => void;
  handleOpenCard: (e: any) => void;
  onClickAddLogo: (e: any) => void;
  handleCancelAddLogoModal: () => void;
  handleSave: () => void;
}

const EntityCard = (props: EntityCardProps & PropsFromRedux) => {
  const {
    name,
    description,
    logo,
    readonly,
    selected,
    showPicturePopup,
    cardType,
    setImageUrl,
    handleOpenCard,
    handleOnClick,
    onClickAddLogo,
    handleCancelAddLogoModal,
    handleSave,
    fetchLibrariesAction,
    fetchSitesAction,
  } = props;

  const classes = useStyles();
  const [files, setFiles] = useState<string[]>([]);
  const fileExt = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

  const shouldRenderLogo = () => {
    if (logo) {
      return logo;
    } else {
      return null;
    }
  };
  const logoToRender = shouldRenderLogo();

  const renderDefaultIcon = () => {
    switch (cardType) {
      case EntityCardType.PROCESS_MAP:
        return <IconSvg svgName="DefaultLogoProcessMap" />;
      case EntityCardType.ORG_CHART:
        return <IconSvg svgName="DefaultLogoOrgChart" />;
      default:
        return null;
    }
  };

  const renderDefaultLogoSection = () => {
    return (
      <>
        {readonly ? (
          <div className={classes.logoContainer}>{renderDefaultIcon()}</div>
        ) : (
          <div className={classes.logoContainer} onClick={onClickAddLogo}>
            <AddCircle32Regular className={classes.addCircleIcon} />
            <span className={classes.text}>{__('add logo')}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={mergeClasses(selected ? classes.cardSelected : classes.cardNotSelected, classes.card)}
        onClick={handleOnClick}
      >
        {logoToRender ? <LogoSection logo={logoToRender} /> : renderDefaultLogoSection()}
        <div className={classes.textWrapper}>
          <span className={classes.title}>{name || __('untitled')}</span>
          {description ? (
            <span className={classes.descriptionWithText}>{Parser().parse(description)}</span>
          ) : (
            <>{!readonly && <span className={classes.descriptionWithoutText}>{__('no description provided')}</span>}</>
          )}
          {
            <div className={classes.openButton} onClick={handleOpenCard}>
              <span>{__('open')}</span>
              <IosChevronRight20Filled />
            </div>
          }
        </div>
      </div>
      <Modal
        title={__('documents')}
        isModalOpen={showPicturePopup}
        onCancel={handleCancelAddLogoModal}
        onSave={handleSave}
      >
        <FileListModal
          fileList={files}
          setFileList={setFiles}
          setImageUrl={setImageUrl}
          fileExt={fileExt}
          fetchLibrariesAction={fetchLibrariesAction}
          fetchSitesAction={fetchSitesAction}
        />
      </Modal>
    </>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({}: RootState) => ({});
const connector = connect(mapStateToProps, {
  fetchLibraryPictureAction,
  fetchLibrariesAction,
  fetchSitesAction,
});
export default connector(EntityCard);
