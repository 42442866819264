import React, { useCallback, useState } from 'react';
import moment from 'moment';
import {
  ProcessInstanceFormValues,
  ProcessInstance,
  RACIField,
  Process,
  Resource,
  TenantMembership,
} from '../ts/interfaces/models';
import { GLOBALENUMS } from 'modeling-tool';
import { IPublicClientApplication } from '@azure/msal-browser';

export const RACIFields: string[] = [
  'responsible',
  'accountable',
  'consulted',
  'informed',
  'approvedBy',
  'assignTo',
  'owner',
];

export const addQuery = (props: any, query: any, pathname?: string, replaceAll?: any) => {
  pathname = pathname || props.location.pathname;
  const queryObj = new URLSearchParams(props.location.search.replace(/\?/g, ''));
  const searchParams = replaceAll ? query : { ...queryObj, ...query };
  // returns the existing query string: '?type=fiction&author=fahid'
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key] === undefined || searchParams[key] === '') {
      delete searchParams[key];
    }
  });
  props.history.push({
    pathname: pathname,
    search: queryObj.toString(),
  });
};

export const getQueryParams = (props: any) => {
  return new URLSearchParams(props.location.search.replace(/\?/g, ''));
};

export const getQueryParam = (key: string, defaultValue: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(key) ? urlParams.get(key) : defaultValue;
};

export const getApiLink = (id: number, objectName: string) => {
  return `/api/v1/${objectName}/${id}/`;
};

export const getUserIdsPerGroup = (tenantMembers: TenantMembership, groups: GLOBALENUMS.GROUPNAMES[]) => {
  if (tenantMembers) {
    const result: string[] = [];

    groups.forEach((group) => result.push(...tenantMembers[group]));

    return result;
  }
};

export const getRACIRelationsObject = (raciParent: ProcessInstance | Process) => {
  const raciValues: RACIField = {} as RACIField;
  RACIFields.forEach((field) => {
    const obj: RACIField = {} as RACIField;
    if (raciParent[field]) {
      obj.employees = raciParent[field].employees;
      obj.departments = raciParent[field].departments;
      obj.roles = raciParent[field].roles;
      obj.id = raciParent[field].id;
      raciValues[field] = obj;
    }
  });
  return raciValues;
};

export const getRACIFieldsObject = (
  values: ProcessInstanceFormValues | Resource,
  selected?: ProcessInstance | Process | Resource,
): { [key: string]: RACIField | null } => {
  const raciValues: { [key: string]: RACIField | null } = {};

  const isFieldEmpty = (field: RACIField): boolean => {
    return (
      !field.employees &&
      !field.departments?.length &&
      !field.roles?.length &&
      !field.staffPositions?.length &&
      !field.externalPartners?.length &&
      !field.workingGroups?.length
    );
  };
  RACIFields.forEach((field) => {
    const obj: RACIField = {
      employees: values[field] ?? null,
      departments: values[`${field}Departments`] ?? [],
      roles: values[`${field}Roles`] ?? [],
      staffPositions: values[`${field}StaffPositions`] ?? [],
      externalPartners: values[`${field}ExternalPartners`] ?? [],
      workingGroups: values[`${field}WorkingGroups`] ?? [],
      id: selected?.[field]?.id ?? null,
    } as RACIField;

    if (isFieldEmpty(obj)) {
      raciValues[field] = null;
    } else {
      raciValues[field] = obj;
    }
  });

  return raciValues;
};

export const getRACIFieldsFormObject = (data: any) => {
  const values: any = {};

  const processResourceUris = (items: any[]): any[] => {
    if (!items) return [];
    return items.some((item) => isObject(item)) ? items.map((item) => item.resourceUri) : items;
  };

  const isObject = (value: any): boolean => {
    return typeof value === 'object' && value !== null;
  };

  RACIFields.forEach((field) => {
    const fieldData = data[field];

    values[field] = fieldData?.employees ?? null;
    values[`${field}Departments`] = processResourceUris(fieldData?.departments);
    values[`${field}Roles`] = processResourceUris(fieldData?.roles);
    values[`${field}StaffPositions`] = fieldData?.staffPositions ?? [];
    values[`${field}ExternalPartners`] = fieldData?.externalPartners ?? [];
    values[`${field}WorkingGroups`] = processResourceUris(fieldData?.workingGroups);
  });
  return values;
};

export const getDate = (date: Date) => {
  return moment.utc(date).local();
};

export const getUtcDate = (date: Date) => {
  return moment.utc(moment(date)).format();
};

export const getDateFormatted = (date?: Date, format?: string): string => {
  format = format ? format : 'll';
  if (date) {
    return getDate(date).format(format);
  }
  return '';
};

export const getDateFromNow = (date: Date): string => {
  return getDate(date).fromNow();
};

export const getSettingValue = (map: any, key: string): string | undefined => {
  return map && map[key] ? map[key] : undefined;
};

export const checkSetting = (map: any, key: string, value: string): boolean => {
  return map && map[key] === value;
};

export const createUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const useCenteredTree = (
  defaultTranslate: { x: number; y: number } = { x: 0, y: 0 },
): [{ x: number; y: number }, (...args: any) => void] => {
  const [translate, setTranslate] = useState<{ x: number; y: number }>(defaultTranslate);
  const containerRef = useCallback((containerElem: any) => {
    if (containerElem !== null) {
      const { width, height }: { width: number; height: number } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 2 });
    }
  }, []);
  return [translate, containerRef];
};

export const initializePendo = (msalInstance: IPublicClientApplication) => {
  const accounts = msalInstance.getAllAccounts();
  const currentAcc = accounts[0];

  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function in your authentication promise handler or callback when your visitor and account id values are available
  // Please use Strings, Numbers, or Bools for value types.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  pendo.initialize({
    visitor: {
      id: currentAcc.homeAccountId, // Required if user is logged in, default creates anonymous ID
      email: currentAcc.username, // Recommended if using Pendo Feedback, or NPS Email
      full_name: currentAcc.name, // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: currentAcc.homeAccountId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      name: currentAcc.name, // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
};
