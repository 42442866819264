import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  addLocationAction,
  fetchLocationsAction,
  deleteLocationAction,
  selectLocationByIdAction,
  updateLocationAction,
  selectLocationAction,
} from '../../store/actions/location-actions';
import { RootState } from '../../config/store';
import { __ } from 'modeling-tool';
import { checkPermission } from '../../config/permission-utils';
import { getStyles } from './locations-styles';
import { searchProcessesAction } from '../../store/actions/process-actions';
import { fetchDropdownDataByType } from '../../store/actions/dropdown-data-actions';
import {
  IColumn,
  PrimaryButton,
  Selection,
  Stack,
  ActionButton,
  TooltipHost,
  IconButton,
  DirectionalHint,
  Pivot,
  PivotItem,
} from '@fluentui/react';
import { Location } from '../../ts/interfaces';
import { LocationForm } from './location-form';
import { Person } from '@microsoft/mgt-react';
import { Popover, BasicList, GLOBALENUMS } from 'modeling-tool';
import { updateColumnsWithLanguage } from '../../utils';

const dropDownData = [GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, GLOBALENUMS.DROPDOWNDATA.WORKING_GROUPS];

const Locations = (props: PropsFromRedux) => {
  const {
    LocationReducer: { locations },
    UserReducer: { language, permissions },
    addLocationAction,
    deleteLocationAction,
    fetchDropdownDataByType,
    fetchLocationsAction,
    searchProcessesAction,
    selectLocationAction,
    selectLocationByIdAction,
    updateLocationAction,
  } = props;

  const [locationModal, setLocationModal] = useState<boolean>(false);
  const [selectedlocations, setSelectedLocations] = useState<Location[]>([]);
  const [enableDelete, setEnableDelete] = useState<boolean>(false);
  const [orderByColumns, setOrderByColumns] = useState<string>();

  useEffect(() => {
    if (orderByColumns) {
      fetchLocationsAction(orderByColumns);
    }
  }, [orderByColumns]);

  const styles = getStyles();

  const _rowsSelected: Selection = new Selection({
    onSelectionChanged: () => _getSelectionDetails(),
  });

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
    fetchLocationsAction();
    searchProcessesAction('?done_and_groups=true');
  }, []);

  const showLocationModal = () => {
    selectLocationAction(undefined);
    setLocationModal(true);
  };

  const columns: IColumn[] = [
    {
      key: 'location_name',
      name: __('location'),
      fieldName: 'location',
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      minWidth: 150,
      maxWidth: 180,
      onRender: (location: Location) => location.locationName,
    },
    {
      name: __('departments'),
      fieldName: 'username',
      key: 'departments',
      minWidth: 150,
      maxWidth: 160,
      isResizable: true,
      onRender: (location: Location) => renderDepartments(location),
    },
    {
      name: __('employees'),
      fieldName: 'employees',
      key: 'employees',
      minWidth: 150,
      maxWidth: 160,
      isResizable: true,
      onRender: ({ employees }: Location) =>
        employees && employees.length
          ? employees.map((employee) => {
              return employee.username && <Person userId={employee.username}></Person>;
            })
          : '',
    },
    {
      name: __('working groups'),
      fieldName: 'workingGroup',
      key: 'workingGroup',
      minWidth: 150,
      maxWidth: 160,
      isResizable: true,
      onRender: (location: Location) => renderWorkingGroup(location),
    },
    {
      name: '',
      key: 'actions',
      minWidth: 70,
      maxWidth: 90,
      onRender: (location: Location) => {
        if (checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_location)) {
          return (
            <TooltipHost content={__('edit location')} directionalHint={DirectionalHint.bottomCenter}>
              <IconButton onClick={() => onLocationEdit(location)} iconProps={{ iconName: 'Edit' }}></IconButton>
            </TooltipHost>
          );
        }
      },
    },
  ];
  const [tableColumns, setTableColumns] = useState<IColumn[]>(columns);

  //This useEffect is used to update the table columns
  useEffect(() => {
    const updatedColumns = updateColumnsWithLanguage(columns);
    setTableColumns(updatedColumns);
  }, [language]);

  const renderDepartments = (record: Location) => {
    const departments = record?.departments?.map((depar) => depar.departmentName)?.join(', ');
    return (
      <Popover content={departments} target={`department-${record.id}`}>
        <Stack wrap horizontal>
          <div id={`department-${record.id}`}>{departments}</div>
        </Stack>
      </Popover>
    );
  };

  const onLocationEdit = (location: Location) => {
    selectLocationByIdAction(location.id);
    setLocationModal(true);
  };

  const renderWorkingGroup = (record: Location) => {
    const workingGroups = record.workingGroup?.map((group) => group.title)?.join(', ');
    return (
      <Popover content={workingGroups} target={`workinggroups-${record.id}`}>
        <Stack wrap horizontal>
          <div id={`workinggroups-${record.id}`}>{workingGroups}</div>
        </Stack>
      </Popover>
    );
  };

  const _getSelectionDetails = () => {
    const selectionCount = _rowsSelected.getSelectedCount();
    if (selectionCount > 0) {
      setSelectedLocations(_rowsSelected.getSelection() as Location[]);
      setEnableDelete(true);
    } else {
      setEnableDelete(false);
    }
  };

  const onDeleteLocations = () => {
    for (const location of selectedlocations) {
      deleteLocationAction(location);
    }
    setEnableDelete(false);
  };

  const renderProcessesBtns = () => {
    return (
      <>
        {enableDelete && checkPermission(permissions, GLOBALENUMS.PERMISSIONS.delete_employee) && (
          <Popover
            title={__('delete selected location')}
            content={__('are you sure to delete this location?')}
            target={'delete-locations'}
            enableConfirm={true}
            onOk={() => onDeleteLocations()}
          >
            <ActionButton id="delete-locations" iconProps={{ iconName: 'Delete' }} style={{ float: 'right' }}>
              {__('delete')}
            </ActionButton>
          </Popover>
        )}
        {checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_location) && (
          <PrimaryButton
            onClick={() => showLocationModal()}
            style={{ margin: '5px', float: 'right' }}
            iconProps={{ iconName: 'Add' }}
          >
            {__('new')}
          </PrimaryButton>
        )}
      </>
    );
  };

  return (
    <>
      <Pivot>
        <PivotItem
          key="1"
          headerText={__('location')}
          headerButtonProps={{
            'data-order': 1,
            key: 'location',
          }}
        >
          <BasicList
            setKey={GLOBALENUMS.BASICLISTTYPES.LOCATIONS_LIST}
            items={locations}
            columns={tableColumns}
            setTableColumns={setTableColumns}
            setOrderByColumns={setOrderByColumns}
            selection={_rowsSelected}
            className={styles.locationsListContainer}
            rightHeaderButtons={renderProcessesBtns()}
          ></BasicList>
          {locationModal && (
            <LocationForm
              visible={locationModal}
              setVisible={setLocationModal}
              add={addLocationAction}
              update={updateLocationAction}
              select={selectLocationAction}
            />
          )}
        </PivotItem>
      </Pivot>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, LocationReducer }: RootState) => ({
  UserReducer,
  LocationReducer,
});
const connector = connect(mapStateToProps, {
  addLocationAction,
  deleteLocationAction,
  fetchDropdownDataByType,
  fetchLocationsAction,
  searchProcessesAction,
  selectLocationAction,
  selectLocationByIdAction,
  updateLocationAction,
});
export default connector(Locations);
