import React, { useContext, useEffect, useState } from 'react';
import { Icon, Stack, useTheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { GLOBALENUMS, UtilityContext, __ } from 'modeling-tool';
import { useLocation } from 'react-router';
import { Control } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { Comments } from '../..';
import { RootState } from '../../../config/store';
import { useStyleProps } from '../../../hooks';
import {
  getFullModelByInstanceId,
  updateFullModelAction,
  updateGraphData,
} from '../../../store/actions/modeler-actions';
import {
  clearProcessInstanceAction,
  clearProcessInstancesAction,
  selectProcessInstanceByIdAction,
} from '../../../store/actions/process-instance-actions';
import { getProposalsForProcessAction, addProposalForProcessAction } from '../../../store/actions/process-actions';
import { Process } from '../../../ts/interfaces';
import { getStyles } from './process-record-modeler-styles';
import { RecordingButtonSection } from './recording-buttons-section';
import { ProcessReviewList } from './process-review-list';
import {
  addCommentAction,
  clearComments,
  getCommentsByProcessInstanceIdAction,
} from '../../../store/actions/comment-actions';
import { ReportProblemModal } from '../report-problem-modal';
import { IsTeamsApp } from '../../../config/config';
import * as microsoftTeams from '@microsoft/teams-js';
import ProcessRecordModelerContextWrapper from './process-record-modeler-context-wrapper';

interface ProcessRecordModelerProps {
  selectedProcess?: Process;
  control: Control<any>;
  enableLoading?: boolean;
  isApplicationsView?: boolean;
  isDetailsView?: boolean;
  isTaskDetailsView?: boolean;
  onAccept?(): void;
  onApprove?(): void;
  onSend?(): void;
  onSendBack?(rejectionMessage: string): void;
  onShowHistoryModal?(): void;
  setIsAiPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProcessRecordModeler = (props: ProcessRecordModelerProps & PropsFromRedux) => {
  const {
    ProcessInstanceReducer: { instances, selectedInstance },
    UserCommentReducer: { comments },
    isTaskDetailsView = false,
    isDetailsView = false,
    isApplicationsView = false,
    addCommentAction,
    clearComments,
    clearProcessInstanceAction,
    clearProcessInstancesAction,
    getCommentsByProcessInstanceIdAction,
    onAccept,
    onApprove,
    onSendBack,
    onShowHistoryModal,
    selectProcessInstanceByIdAction,
    setIsAiPanelOpen,
  } = props;

  const { setBreadcrumbButtons } = useContext(UtilityContext);
  const [showInstanceListContainer, { toggle: toggleInstanceListContainer }] = useBoolean(true);
  const [showComments, { toggle: toggleShowComments, setFalse: closeComments }] = useBoolean(false);
  const [problemModalVisible, { setTrue: showProblemModal, setFalse: closeProblemModal }] = useBoolean(false);
  const [hasUrgentProblems, setHasUrgentProblems] = useState<boolean>(false);
  const [problemModalReadOnly, setProblemModalReadOnly] = useState<boolean>(false);
  const [hasSeenProblem, setHasSeenProblem] = useState<boolean>(false);

  const theme = useTheme();
  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  const location = useLocation();

  useEffect(() => {
    !isDetailsView &&
      setBreadcrumbButtons &&
      setBreadcrumbButtons({
        list: [
          <RecordingButtonSection
            key="recordButtons"
            onSend={onSend}
            onAccept={onAccept}
            onApprove={onApprove}
            onSendBack={onSendBack}
            selectedInstance={selectedInstance}
            isTaskDetailsView={isTaskDetailsView}
          />,
        ],
      });
    return () => {
      !isDetailsView && setBreadcrumbButtons && setBreadcrumbButtons({ list: [] });
    };
  }, [selectedInstance]);

  useEffect(() => {
    return () => {
      clearProcessInstancesAction();
      clearComments();
    };
  }, []);

  useEffect(() => {
    if (!isTaskDetailsView && !isApplicationsView) {
      if (instances?.length) {
        selectProcessInstanceByIdAction(instances[instances.length - 1]?.id);
      } else {
        clearProcessInstanceAction();
      }
    }
  }, [instances]);

  useEffect(() => {
    if (isDetailsView) {
      if (selectedInstance?.resourceUri) {
        getCommentsByProcessInstanceIdAction(selectedInstance?.id);
      }
    }
  }, [selectedInstance]);

  useEffect(() => {
    if (selectedInstance && comments) {
      const urgentComments = comments.some((item) => {
        return item.isUrgent;
      });

      if (urgentComments) {
        setHasUrgentProblems(true);

        if (!hasSeenProblem && selectedInstance.relevantUser && location.search.indexOf('print') == -1) {
          openProblemModal(true);
          setHasSeenProblem(true);
        }
      } else {
        setHasUrgentProblems(false);
      }
    }
  }, [comments]);

  const onSend = () => {
    props.onSend && props.onSend();
  };

  const isReadOnly = (): boolean => {
    return selectedInstance?.state?.type !== GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_NEW_ACCEPTED;
  };

  const openProblemModal = (readonly: boolean) => {
    showProblemModal();
    setProblemModalReadOnly(readonly);
  };
  const renderModelerControl = () => {
    if (selectedInstance && selectedInstance.id) {
      return (
        <ProcessRecordModelerContextWrapper
          onApprove={onApprove}
          onShowHistoryModal={onShowHistoryModal}
          isDetailsView={isDetailsView}
          setIsAiPanelOpen={setIsAiPanelOpen}
          theme={theme}
          openProblemModal={openProblemModal}
          hasUrgentProblems={hasUrgentProblems}
          toggleShowComments={toggleShowComments}
          handleBlockTeams={handleBlockTeams}
          readOnly={isReadOnly()}
        />
      );
    }
  };

  const renderProcessRecordModeler = () => {
    return (
      <>
        <Stack className={styles.card}>
          <Stack horizontal className={styles.detailsContainer}>
            {!isTaskDetailsView && !isDetailsView && (
              <Icon
                iconName={showInstanceListContainer ? 'CaretLeftSolid8' : 'CaretRightSolid8'}
                onClick={toggleInstanceListContainer}
                className={styles.caretContainer}
              />
            )}
            {showInstanceListContainer && !isTaskDetailsView && !isDetailsView && instances.length > 0 && (
              <>
                <Stack className={styles.instanceListContainer} styles={{ root: { height: `calc(100%)` } }}>
                  <Stack.Item>
                    <ProcessReviewList items={instances} />
                  </Stack.Item>
                </Stack>
              </>
            )}
            <Stack horizontal>
              {showComments && (
                <Comments selectedProcessInstance={selectedInstance} closeComments={closeComments}></Comments>
              )}
            </Stack>
            <Stack.Item grow className={styles.processTreeContainer}>
              {renderModelerControl()}
            </Stack.Item>
          </Stack>
        </Stack>
      </>
    );
  };

  const handleBlockTeams = () => {
    if (IsTeamsApp()) {
      microsoftTeams.teamsCore.registerBeforeUnloadHandler((readyToUnload) => {
        if (window.confirm(__('do you want to leave this page without saving? all unsaved data will be lost.'))) {
          readyToUnload();
          return true;
        } else {
          return false;
        }
      });
    }
  };
  return (
    <>
      {renderProcessRecordModeler()}
      {selectedInstance && (
        <ReportProblemModal
          readonly={problemModalReadOnly}
          instance={selectedInstance}
          visible={problemModalVisible}
          closeProblemModal={closeProblemModal}
          addProblem={addCommentAction}
        ></ReportProblemModal>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessInstanceReducer, UserCommentReducer, UserReducer }: RootState) => ({
  ProcessInstanceReducer,
  UserCommentReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  selectProcessInstanceByIdAction,
  clearProcessInstanceAction,
  getFullModelByInstanceId,
  getProposalsForProcessAction,
  addProposalForProcessAction,
  updateFullModelAction,
  updateGraphData,
  addCommentAction,
  getCommentsByProcessInstanceIdAction,
  clearProcessInstancesAction,
  clearComments,
});
export default connector(ProcessRecordModeler);
