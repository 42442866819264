import { IIconProps, memoizeFunction, mergeStyleSets } from '@fluentui/react';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    buttonHolder: {
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      paddingRight: '16px',
    },
  });
});

export const addToIcon: IIconProps = {
  iconName: 'AddTo',
  styles: {
    root: {
      fontSize: '22px',
    },
  },
};
