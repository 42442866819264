import { Theme } from '@fluentui/react';
import { Modeler, ModelerContext } from 'modeling-tool';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IsTeamsApp } from '../../../config/config';
import { RootState } from '../../../config/store';
import { checkSetting, getSettingValue } from '../../../config/utils';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';
import { fetchLibrariesAction, fetchSitesAction } from '../../../store/actions/file-actions';
import {
  getFullModelByInstanceId,
  getModelOptimizations,
  updateFullModelAction,
  updateGraphData,
} from '../../../store/actions/modeler-actions';
import { addProposalForProcessAction, getProposalsForProcessAction } from '../../../store/actions/process-actions';
import { increaseAiRequestAction } from '../../../store/actions/user-actions';
import { redirectToProcess } from '../../../utils';

interface ProcessRecordModelerContextWrapper {
  hasUrgentProblems: boolean;
  isAiPanelOpen?: boolean;
  isDetailsView?: boolean;
  readOnly?: boolean;
  theme: Theme;
  handleBlockTeams: () => void;
  onApprove?(): void;
  onShowHistoryModal?(): void;
  openProblemModal: (readonly: boolean) => void;
  setIsAiPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleShowComments: () => void;
}

const ProcessRecordModelerContextWrapper = (props: ProcessRecordModelerContextWrapper & PropsFromRedux) => {
  const {
    ProcessInstanceReducer: { selectedInstance },
    UserReducer: { permissions, settingsMap },
    hasUrgentProblems,
    isDetailsView = false,
    readOnly,
    theme,
    fetchDropdownDataByType,
    fetchLibrariesAction,
    fetchSitesAction,
    getFullModelByInstanceId,
    handleBlockTeams,
    increaseAiRequestAction,
    onApprove,
    onShowHistoryModal,
    openProblemModal,
    setIsAiPanelOpen,
    toggleShowComments,
    updateGraphData,
  } = props;

  const modelerContextValues = {
    enableApproversList: false,
    enableDelete: false,
    hasUrgentProblems: hasUrgentProblems,
    isAiIOptimizationAllowed: checkSetting(settingsMap, 'enable_ai_optimizations', 'true'),
    isAiIOptimizationAtLimit: getSettingValue(settingsMap, 'ai_limit') == '0',
    permissions: permissions,
    processinstanceId: selectedInstance?.id,
    readOnly: readOnly,
    theme: theme,
    addProposalForProcessAction: addProposalForProcessAction,
    fetchLibrariesAction: fetchLibrariesAction,
    fetchModelerMetaData: fetchDropdownDataByType,
    fetchSitesAction: fetchSitesAction,
    getFullModelByInstanceId: getFullModelByInstanceId,
    getModelOptimizations: getModelOptimizations,
    getProposalsForProcessAction: getProposalsForProcessAction,
    handleBlockTeams: IsTeamsApp() ? handleBlockTeams : undefined,
    increaseAiRequestAction: increaseAiRequestAction,
    onDeleteModel: () => {},
    onModelApprove: onApprove,
    onShowHistoryModal: onShowHistoryModal,
    redirectToProcess: redirectToProcess,
    setIsAiPanelOpen: setIsAiPanelOpen,
    showApproversList: () => {},
    showProblemModal: isDetailsView ? openProblemModal : undefined,
    toggleShowComments: toggleShowComments,
    updateFullModelAction: updateFullModelAction,
    updateGraphData: updateGraphData,
  };

  return (
    <ModelerContext.Provider value={modelerContextValues}>
      <Modeler key={selectedInstance?.id} />
    </ModelerContext.Provider>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessInstanceReducer, UserReducer }: RootState) => ({
  ProcessInstanceReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  fetchDropdownDataByType,
  getFullModelByInstanceId,
  getProposalsForProcessAction,
  addProposalForProcessAction,
  updateFullModelAction,
  updateGraphData,
  fetchLibrariesAction,
  fetchSitesAction,
  getModelOptimizations,
  increaseAiRequestAction,
});
export default connector(ProcessRecordModelerContextWrapper);
