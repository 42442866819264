import React, { Dispatch, useState } from 'react';
import { IconButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { getIconButtonStyles, getStyles } from './expanded-icon-styles';
import { Process, RequirementChapter } from '../../../ts/interfaces';
import { BasicListActionEnum } from '../../../ts/enums';
import { BasicListAction } from '../reducer/basic-list-reducer';
import { RootState } from '../../../config/store';
import { ConnectedProps, connect } from 'react-redux';

interface IExpandedIconProps {
  record: RequirementChapter | Process;
  isExpanded?: boolean;
  updateRows: Dispatch<BasicListAction>;
  fetchChildren?: (
    id: number,
    nextLevel?: number | undefined,
    orderBy?: string | undefined,
    cb?: ((...args: any[]) => void) | undefined,
  ) => void;
  refresh?: boolean;
}

const ExpandedIcon = (props: PropsFromRedux & IExpandedIconProps) => {
  const {
    ProcessReducer: { orderByColumns },
    RequirementReducer: { orderByChapterColumns },
    record,
    isExpanded,
    fetchChildren,
    updateRows,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const styles = getStyles();
  const iconButtonStyles = getIconButtonStyles();

  // We need the reducer otherwise orderBy is only passed on the first render. Therefore use the weird type checking for now
  const getOrderBy = (record: any) => {
    if (record.processName) {
      return orderByColumns;
    } else {
      return orderByChapterColumns;
    }
  };

  const onClick = () => {
    setIsLoading(true);
    if (record.childrenFetched) {
      setIsLoading(false);
      return updateRows({ id: record.id, level: record.level, type: BasicListActionEnum.TOGGLESUBROW });
    } else {
      return (
        fetchChildren &&
        fetchChildren(record.id, record.level + 1, getOrderBy(record), (res: any) => {
          setIsLoading(false);
          updateRows({
            id: record.id,
            level: record.level,
            type: BasicListActionEnum.TOGGLESUBROW,
            children: res.data.objects,
          });
        })
      );
    }
  };
  return (
    <>
      <Stack styles={{ root: { visibility: !!record?.children?.length ? 'visible' : 'hidden' } }}>
        {!isLoading && (
          <IconButton
            id={`expand-${record.id}`}
            iconProps={{ iconName: isExpanded ? 'ChevronDown' : 'ChevronRight', color: '#8a2be2' }}
            styles={iconButtonStyles}
            onClick={onClick}
          />
        )}
        {isLoading && <Spinner className={styles.spinnerStyle} size={SpinnerSize.xSmall}></Spinner>}
      </Stack>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessReducer, RequirementReducer }: RootState) => ({
  ProcessReducer,
  RequirementReducer,
});
const connector = connect(mapStateToProps, {});
export default connector(ExpandedIcon);
