import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IconButton, Label, Stack } from '@fluentui/react';
import { __ } from 'modeling-tool';
import { getStyles } from './process-form-styles';

export interface ListSections {
  sectionKey: number;
  title: string;
  isExpanded: boolean;
}

const SectionHeader = (props: {
  sectionKey: number;
  listSections: ListSections[];
  setListSections: Dispatch<SetStateAction<ListSections[]>>;
  subSection?: boolean;
}) => {
  const { sectionKey, listSections, setListSections, subSection } = props;
  const [currentGroup, setCurrentGroup] = useState<ListSections>();
  const styles = getStyles();
  useEffect(() => {
    setCurrentGroup(listSections.find((group) => group.sectionKey === sectionKey));
  }, [sectionKey, listSections]);

  const toggleExpand = () => {
    if (currentGroup) {
      currentGroup.isExpanded = !currentGroup.isExpanded;
      const currentItem = listSections.find((group) => group.sectionKey === sectionKey);
      if (currentItem) {
        currentItem.isExpanded = currentGroup.isExpanded;
      }
      setListSections([...listSections]);
    }
  };

  return (
    <Stack horizontal horizontalAlign="space-between" className={`${subSection && styles.headerContainer}`}>
      <Label styles={{ root: { fontWeight: subSection ? 400 : 600 } }}>{__(`${currentGroup?.title}`)}</Label>
      <IconButton
        iconProps={{ iconName: currentGroup?.isExpanded ? 'ChevronUp' : 'ChevronDown' }}
        onClick={toggleExpand}
      />
    </Stack>
  );
};

export default SectionHeader;
