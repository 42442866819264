import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PeopleDeptRoleInput } from '../../../../components';
import { GLOBALENUMS, Modal, TextField } from 'modeling-tool';
import { __ } from 'modeling-tool';
import { Process } from '../../../../ts/interfaces/models/process';
import { useAlert } from 'react-alert';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import { fetchDepartmentsAction } from '../../../../store/actions/department-actions';
import { fetchRolesAction } from '../../../../store/actions/role-actions';
import { fetchWorkingGroupsAction } from '../../../../store/actions/working-group-actions';
import {
  selectProcessAction,
  shareModelTaskAction,
  shareProcessRecordingAction,
} from '../../../../store/actions/process-actions';
import { getUserIdsPerGroup } from '../../../../config/utils';
import { mapRaciFields } from '../../../../utils';

interface ISendForm {
  visible: boolean;
  setVisible: (arg: boolean) => void;
  selected: Process;
  afterSend: () => void;
  sendModel: boolean;
}

const SendForm = (props: ISendForm & PropsFromRedux) => {
  const {
    visible,
    setVisible,
    selected,
    afterSend,
    fetchDepartmentsAction,
    fetchRolesAction,
    fetchWorkingGroupsAction,
    shareProcessRecordingAction,
    selectProcessAction,
    sendModel,
    UserReducer: { tenantMembers },
  } = props;
  const alert = useAlert();
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    fetchDepartmentsAction();
    fetchRolesAction();
    fetchWorkingGroupsAction();
  }, []);

  const handleOk = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleCancel = () => {
    setVisible(false);
    reset();
    // selectProcessAction(false);
  };

  const onFinish = (values: any) => {
    const { resourceUri, description, processName } = selected;

    const [accountable, consulted, informed, responsible] = mapRaciFields(selected);

    const data = {
      ...values,
      responsible: responsible ? { ...responsible, id: null } : null,
      accountable: accountable ? { ...accountable, id: null } : null,
      informed: informed ? { ...informed, id: null } : null,
      consulted: consulted ? { ...consulted, id: null } : null,
      sourceProcess: resourceUri,
      description: description ? description : null,
      instanceName: processName,
      message: values.message ? values.message : null,
      sendModelingTask: sendModel,
    };

    const sendEntity: string = sendModel ? 'modeling task' : 'process';
    shareProcessRecordingAction(data, (msg?: any, withError?: boolean) => {
      if (withError) {
        setShowLoading(false);

        alert.error(`${__(`${sendEntity} sent failed with Error:`)} ${msg.response.status}`);
        console.log(msg);
      } else {
        alert.success(__(`${sendEntity} sent successfully`));
        setVisible(false);
        setShowLoading(false);
        reset();
        afterSend && afterSend();
      }
    });
  };
  return (
    <>
      <Modal
        title={`${__('process recording')} - ${selected && selected.processName}`}
        saveButtonText={__('send')}
        isModalOpen={visible}
        onSave={handleOk}
        onCancel={handleCancel}
        enableProgress={showLoading}
      >
        <form>
          {tenantMembers && (
            <PeopleDeptRoleInput
              field="participant"
              showWorkingGroups={true}
              readOnly={false}
              control={control}
              userIds={getUserIdsPerGroup(tenantMembers, [
                GLOBALENUMS.GROUPNAMES.ADMINISTRATOR,
                GLOBALENUMS.GROUPNAMES.CONTRIBUTOR,
                GLOBALENUMS.GROUPNAMES.MANAGER,
              ])}
            />
          )}
          <TextField label={__('message')} control={control} name={'message'} multiline rows={3} />
        </form>
      </Modal>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });

const connector = connect(mapStateToProps, {
  fetchDepartmentsAction,
  fetchRolesAction,
  fetchWorkingGroupsAction,
  shareProcessRecordingAction,
  shareModelTaskAction,
  selectProcessAction,
});

export default connector(SendForm);
