import { Department, Meta } from '../../ts/interfaces';

export interface DepartmentStateReducer {
  departments: Department[];
  meta?: Meta;
}

export default function reducer(
  state: DepartmentStateReducer = {
    departments: [],
    meta: undefined,
  },
  action: any,
): DepartmentStateReducer {
  switch (action.type) {
    case 'FETCH_DEPARTMENTS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        departments: action.payload.objects,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
