import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  licenceGroupCard: {
    height: '350px',
    backgroundColor: '#F6F6F6',
    ...shorthands.margin('10px', '0px'),
    ...shorthands.border('1px', 'solid', '#EEE'),
    alignItems: 'center',
    textAlign: 'center',
  },
  groupTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: tokens.colorBrandBackground,
    marginTop: '45px',
  },
  groupPricing: {
    marginTop: '65px',
  },
  pricingText1: {
    fontSize: '14px',
    fontWeight: '600',
    color: tokens.colorBrandBackground,
  },
  pricingText2: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(17, 17, 17, 0.60)',
  },
  groupCartUpdate: {
    marginTop: '45px',
  },
  cartText1: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(17, 17, 17, 0.60)',
    paddingTop: '5px',
  },
  cartText2: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(17, 17, 17, 0.60)',
  },
  buttonStyle: {
    width: '12px',
    height: '12px',
  },
  cartCount: {
    fontSize: '14px',
    fontWeight: '600',
    color: tokens.colorBrandBackground,
    backgroundColor: '#fff',
    width: '45px',
    height: '30px',
    textAlign: 'center',
    ...shorthands.border('none'),
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      ...shorthands.margin('0'),
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      ...shorthands.margin('0'),
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  licensesCountWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
