import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    personDisplayName: {
      textOverflow: 'ellipsis',
      width: '110px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });
});
