import React from 'react';
import { AlertTemplateProps, AlertOptions } from 'react-alert';
import { MessageBar, MessageBarType } from '@fluentui/react';

const AlertTemplate = (alertProps: AlertTemplateProps) => {
  const { style, options, message } = alertProps;

  const showDefaultAlert = (message: React.ReactNode) => (
    <MessageBar>
      <>{message}</>
    </MessageBar>
  );

  const showErrorAlert = (message: React.ReactNode) => (
    <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
      <>{message}</>
    </MessageBar>
  );

  const showSuccessAlert = (message: React.ReactNode) => (
    <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
      <>{message}</>
    </MessageBar>
  );

  const renderAlert = (message: React.ReactNode, options: AlertOptions) => {
    switch (options.type) {
      case 'success':
        return showSuccessAlert(message);
      case 'error':
        return showErrorAlert(message);
      default:
        return showDefaultAlert(message);
    }
  };

  return <div style={style}>{renderAlert(message, options)}</div>;
};

export default AlertTemplate;
