import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    notifiMsg: {
      color: '#a19f9d',
      height: '100%',
    },
    approversRowDiv: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '8px 0',
    },
    dropdownDiv1: {
      flex: '1 0 auto',
      width: 320,
      marginRight: '15px',
      height: '60px',
    },
    dropdownDiv2: {
      flex: '1 0 auto',
      width: 320,
      marginLeft: '15px',
      height: '60px',
    },
    dropDownStyles: {
      '& > div, span': {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        lineHeight: 0,
        height: 38,
        flexGrow: 1,
      },
      '& .ms-Dropdown-title': {
        paddingLeft: '8px',
      },
    },
    inputFieldDiv: {
      flexGrow: 1,
      height: '100%',
      width: '670px',
    },
  });
});
