import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchTenantsBuyerAction = (orderBy?: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `tenantdashboard/?buyer&${orderBy || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_TENANTS_BUYER',
    cb,
  });

export const fetchTenantsAction = (orderBy?: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `tenantdashboard/?tester&${orderBy || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_TENANTS',
    cb,
  });

export const fetchTenantsExpiredAction = (orderBy?: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `tenantdashboard/?expired&${orderBy || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_TENANTS_EXPIRED',
    cb,
  });

export const fetchTenantsTerminatedAction = (orderBy?: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `tenantdashboard/?terminated&${orderBy || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_TENANTS_TERMINATED',
    cb,
  });

export const updateTenantAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `tenantdashboard/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_TENANT',
    reqPayload: data,
    cb,
  });

export const updateLicenseAction = (data: any, cb?: (...args: any[]) => void, ecb?: (...args: any[]) => void) =>
  UseAction({
    url: `license/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PUT,
    action: 'UPDATE_LICENSE',
    reqPayload: data,
    cb,
    ecb,
  });
