import { Meta, Process } from '../../ts/interfaces';

interface ProcessReducerState {
  processes: Process[];
  meta?: Meta;
  selectedProcess?: Process;
  outdatedProcess?: Process[];
  params: any;
  filterValues: any;
  orderByColumns: string;
  processChildren?: any;
}

export const setFilterValues = (currentValues: any, action: any) => {
  if (action && action.payload) {
    const values = action.payload.find((e: { filterValues: any }) => e.filterValues);
    return values ? values.filterValues : currentValues;
  }
};

export default function reducer(
  state: ProcessReducerState = {
    processes: [],
    meta: undefined,
    selectedProcess: undefined,
    outdatedProcess: [],
    params: [],
    filterValues: {},
    processChildren: [],
    orderByColumns: 'order_by=process_name',
  },
  action: any,
): ProcessReducerState {
  switch (action.type) {
    case 'FETCH_PROCESSES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        processes: action.payload.objects,
      });
    }
    case 'FETCH_PROCESS': {
      return Object.assign({}, state, {
        selectedProcess: action.payload,
      });
    }
    case 'FETCH_PROCESS_FOR_LIST': {
      return Object.assign({}, state, {
        ...state,
        processes: state.processes.map((process) => (process.id === action.payload.id ? action.payload : process)),
      });
    }
    case 'FETCH_OUTDATED_PROCESS': {
      return Object.assign({}, state, {
        outdatedProcess: action.payload,
      });
    }
    case 'SEARCH_PROCESSES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        processes: action.payload.objects,
      });
    }
    case 'CLEAR_PROCESS': {
      return Object.assign({}, state, {
        selectedProcess: undefined,
        processChildren: undefined,
      });
    }
    case 'SELECT_PROCESS': {
      return Object.assign({}, state, {
        selectedProcess: action.payload,
      });
    }
    case 'CLEAR_SELECT_PROCESS': {
      return Object.assign({}, state, {
        selectedProcess: undefined,
      });
    }
    case 'SELECT_PROCESS_BY_ID': {
      let selectProcess = state.processes.find((process) => process.id === action.payload);
      if (!selectProcess) {
        selectProcess = state.processChildren?.objects?.find((process: Process) => process.id === action.payload);
      }
      return Object.assign({}, state, {
        selectedProcess: selectProcess,
      });
    }
    case 'UPDATE_PROCESS': {
      return {
        ...state,
        processes: state.processes.map((process) => (process.id === action.payload.id ? action.payload : process)),
      };
    }
    case 'COPY_PROCESS': {
      state.processes.unshift(action.payload);
      return {
        ...state,
        processes: state.processes,
      };
    }
    case 'ADD_PROCESS': {
      state.processes.unshift(action.payload);
      return {
        ...state,
        processes: state.processes,
      };
    }
    case 'DELETE_PROCESS': {
      return {
        ...state,
        processes: state.processes.filter((process) => process.id !== action.payload),
      };
    }
    case 'SET_META_PROCESS': {
      return Object.assign({}, state, {
        meta: { ...state.meta, ...action.payload },
      });
    }
    case 'CLEAR_PROCESSES': {
      return {
        ...state,
        processes: (state.processes = []),
      };
    }
    case 'ADD_QUERY_PARAMS': {
      return {
        ...state,
        params: action.payload,
        filterValues: setFilterValues(state.filterValues, action),
      };
    }
    case 'CLEAR_QUERY_PARAMS': {
      return {
        ...state,
        params: (state.params = undefined),
        filterValues: (state.filterValues = undefined),
      };
    }
    case 'FETCH_PROCESS_CHILDREN': {
      return Object.assign({}, state, {
        processChildren: action.payload,
      });
    }
    case 'SET_ORDER_BY_COLUMNS': {
      return Object.assign({}, state, {
        orderByColumns: action.payload,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
