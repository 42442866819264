import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    cellText: {
      lineHeight: '35px',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    highlightedText: {
      lineHeight: '35px',
      textAlign: 'left',
      fontWeight: 'bold',
      color: 'red',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    header: {
      padding: '10px',
    },

    logo: {
      img: {
        height: '40px',
        marginTop: '5px',
      },
    },
    listContainer: {
      height: `calc(100vh - 70px)`,
      padding: '0px 10px',
    },
  });
});
