import { WorkingGroup, Meta } from '../../ts/interfaces';

export interface WorkingGroupReducerState {
  workinggroups: WorkingGroup[];
  meta?: Meta;
  selectedWorkinggroup?: WorkingGroup;
}

export default function reducer(
  state: WorkingGroupReducerState = {
    workinggroups: [],
    meta: undefined,
    selectedWorkinggroup: undefined,
  },
  action: any,
): WorkingGroupReducerState {
  switch (action.type) {
    case 'FETCH_WORKING_GROUPS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        workinggroups: action.payload.objects,
      });
    }
    case 'UPDATE_WORKING_GROUP': {
      return {
        ...state,
        workinggroups: state.workinggroups.map((process) =>
          process.id === action.payload.id ? action.payload : process,
        ),
      };
    }
    case 'ADD_WORKING_GROUP': {
      return {
        ...state,
        workinggroups: state.workinggroups.concat(action.payload),
      };
    }
    case 'SELECT_WORKING_GROUP_BY_ID': {
      return Object.assign({}, state, {
        selectedWorkinggroup: state.workinggroups.filter((workinggroup) => workinggroup.id === action.payload)[0],
      });
    }
    case 'DELETE_WORKING_GROUP': {
      return {
        ...state,
        workinggroups: state.workinggroups.filter((workinggroup) => workinggroup.id !== action.payload),
      };
    }
    case 'SET_META_WORKING_GROUP': {
      return Object.assign({}, state, {
        meta: { ...state.meta, ...action.payload },
      });
    }
    default: {
      break;
    }
  }
  return state;
}
