import {
  mergeStyleSets,
  IDetailsColumnStyles,
  ITextFieldStyleProps,
  ITextFieldStyles,
  keyframes,
} from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../ts/interfaces';

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    card: {
      height: '100%',
      width: '100%',
      margin: '0px',
      border: '0px solid #ccc',
      borderRadius: '2px',
    },
    cardHeader: {
      width: '100%',
      height: '80px',
      zIndex: '100',
      background: theme.palette.white,
      borderTop: `1px solid ${theme.palette.neutralLight}`,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      position: 'relative',
    },
    caretContainer: {
      cursor: 'pointer',
      color: theme.palette.themePrimary,
      zIndex: 300,
      paddingTop: 5,
      position: 'absolute',
    },
    processTreeContainer: {
      width: '70%',
    },
    detailsContainer: {
      width: '100%',
      height: '100%',
    },
    instanceListContainer: {
      width: '400px',
      maxWidth: '400px',
      border: `1px solid ${theme.palette.neutralLight}`,
      backgroundColor: theme.palette.white,
      zIndex: 2,
      bottom: 0,
    },
    durationMissing: {
      border: theme.palette.themePrimary,
    },
    tableHeader: {
      width: '335px',
      height: '79px',
      marginRight: '0px',
      marginTop: '-79px',
      borderRight: '0px solid #ccc',
      padding: '26px',
      background: theme.palette.white,

      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '27px',
    },

    blinkHint: {
      animation: 'blinker 1s linear',
      color: theme.palette.themePrimary,
      animationName: fadeIn,
      '&:hover': {
        color: theme.palette.themePrimary,
      },
    },
    blinkHintAccept: {
      animation: 'blinker 1s linear 2',
      color: theme.palette.themePrimary,
      '&:hover': {
        color: theme.palette.themePrimary,
      },
      animationName: fadeIn,
    },
    headericon: { marginTop: '5px' },

    acceptButtonSection: {
      width: '180px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    topRightButtonsSection: {
      height: '100%',
      width: '420px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    treeButtonsSection: {
      height: '100%',
      width: '400px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    generalInformationContainer: {
      width: '600px',
      height: '100%',
      zIndex: '30',
      position: 'relative',
    },

    fullScreenFixed: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
    root: {
      background: 'rgba(0, 0, 0, 0.2)',
      bottom: '0',
      left: '0',
      position: 'fixed',
      right: '0',
      top: '0',
    },
    content: {
      background: theme.palette.white,
      left: '50%',
      maxWidth: '400px',
      padding: '0 2em 2em',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  });
});

export const headerStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    color: '#3B3A39',
    marginLeft: '0px',
    paddingLeft: '10px',
  },
};
export const statusStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    color: '#3B3A39',
    marginLeft: '5px',
    paddingLeft: '10px',
  },
};
export const attachmentStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: '5px',
    color: '#3B3A39',
    paddingLeft: '10px',
  },
};

export const text = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  return {
    fieldGroup: {
      border: '1px solid #8A8886',
    },
  };
};
