import { IDetailsColumnStyles } from '@fluentui/react';

export const headerStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    color: '#3B3A39',
    marginLeft: '0px',
    paddingLeft: '10px',
  },
};

export const attachmentStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: '5px',
    color: '#3B3A39',
    paddingLeft: '10px',
  },
};

export const statusStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    color: '#3B3A39',
    marginLeft: '5px',
    paddingLeft: '10px',
  },
};
