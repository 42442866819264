import { Location, Meta } from '../../ts/interfaces';

interface LocationReducerState {
  locations: Location[];
  meta?: Meta;
  selectedLocation?: Location;
}

export default function reducer(
  state: LocationReducerState = {
    locations: [],
    meta: undefined,
    selectedLocation: undefined,
  },
  action: any,
): LocationReducerState {
  switch (action.type) {
    case 'FETCH_LOCATIONS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        locations: action.payload.objects,
      });
    }

    case 'SELECT_LOCATION': {
      return Object.assign({}, state, {
        selectedLocation: action.payload,
      });
    }
    case 'SELECT_LOCATION_BY_ID': {
      return Object.assign({}, state, {
        selectedLocation: state.locations.filter((location) => location.id === action.payload)[0],
      });
    }
    case 'UPDATE_LOCATION': {
      return {
        ...state,
        locations: state.locations.map((process) => (process.id === action.payload.id ? action.payload : process)),
      };
    }
    case 'ADD_LOCATION': {
      return {
        ...state,
        locations: state.locations.concat(action.payload),
      };
    }
    case 'DELETE_LOCATION': {
      return {
        ...state,
        locations: state.locations.filter((location) => location.id !== action.payload),
      };
    }

    default: {
      break;
    }
  }
  return state;
}
