import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    controllSection: {
      paddingTop: '5px',
      paddingRight: '150px',
      alignItems: 'center',
      height: '35px',
    },
    dropdownStyles: {
      color: '#F6F6F6',
      fontSize: '14px',
      background: `${theme.palette.neutralLight}`,

      '.ms-Dropdown-title': {
        border: `0px solid${theme.palette.white}`,
        background: `${theme.palette.neutralLight}`,
        padding: '0px 28px 0px 14.91px',
        justifyContent: 'center',
      },

      '.ms-Dropdown-caretDownWrapper': {
        justifyContent: 'center',
      },
    },
    paginationLink: {
      fontWeight: 'bold',
    },
    pagginationBttns: {
      display: 'flex',
      listStyle: 'none',
      cursor: 'pointer',
      a: {
        margin: '3px',
        background: `${theme.palette.neutralLight}`,
        padding: '5.67px 11.23px',
        fontSize: '14px',
      },
    },
    paginationLinkActive: {
      a: {
        color: '#111111',
        background: `${theme.palette.neutralLighter}`,
        border: `1px solid ${theme.palette.themePrimary}`,
      },
    },
    paginationLinkDisabled: {
      a: {
        color: '#111111',
        border: '1px solid white',
      },
    },
  });
});
