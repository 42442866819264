import React from 'react';
import './index.less';

interface IndexProps {
  children: JSX.Element;
}

const Index = ({ children }: IndexProps) => {
  return <>{children}</>;
};

export default Index;
