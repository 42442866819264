import { ISearchBoxProps, ISearchBoxStyles, SearchBox, Text } from '@fluentui/react';
import { HookFormProps, __ } from 'modeling-tool';
import { Controller } from 'react-hook-form';

const SearchBoxControlled = (
  props: {
    onChange: (newValue: string | undefined) => void;
    textChanged: () => void;
  } & ISearchBoxProps,
) => {
  const { onChange, textChanged, value } = props;
  const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 300 } };
  const onTextChanged = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
    // stop other queries if putting stuff in too fast
    newValue && window.stop();
    onChange(newValue);
    textChanged();
  };

  return (
    <SearchBox styles={searchBoxStyles} placeholder={__('search processes')} onChange={onTextChanged} value={value} />
  );
};

const ProcessSearchBox = (props: { textChanged: () => void } & HookFormProps & ISearchBoxProps) => {
  return (
    <Controller
      name={props.name ? props.name : ''}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <SearchBoxControlled onChange={onChange} textChanged={props.textChanged} value={value} />
          {error && (
            <Text variant="smallPlus" style={{ color: '#a4262c' }}>
              {error.message}
            </Text>
          )}
        </>
      )}
    />
  );
};

export default ProcessSearchBox;
