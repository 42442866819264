import { makeResetStyles, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { FluentIconsProps } from '@fluentui/react-icons';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    backgroundColor: tokens.colorPalettePurpleForeground2,
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  logo: {
    marginLeft: '9px',
    flexGrow: 1,
    '& img': {
      height: '30px',
      verticalAlign: 'bottom',
    },
  },
  testPhaseDiv: {
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
  },
  testPhaseP1: {
    fontSize: '14px',
    fontWeight: 600,
    width: '213px',
  },
  testPhaseAnchor: {
    color: 'white',
    ...shorthands.textDecoration('underline'),
  },
  userArea: {
    alignItems: 'center',
    height: '100%',
    display: 'flex',
  },
  personDisplayNameBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',

    '& mgt-person': {
      backgroundColor: tokens.colorPalettePurpleForeground2,
      ...shorthands.padding('8px'),
      ':hover': {
        ...shorthands.borderRadius('2px'),
        backgroundColor: 'rgb(93,72,116)',
      },
    },
  },
  loginBtn: {
    '--button-color': tokens.colorBrandForeground1,
    '--initials-background-color': 'black',
    '--presence-background-color': 'black',
    '--avatar-size': '32px',
    '--padding': '0px 0px 0px 0px',
    '--margin': '0px 13px 0px 0px',
  },
});

export const useHeaderBarIconStyles = makeResetStyles({
  height: '100%',
  width: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':hover': {
    ...shorthands.padding('0px'),
    ...shorthands.margin('0px'),
    backgroundColor: '#5d4874',
    cursor: 'pointer',
  },
});

export const iconStyleProps: FluentIconsProps = {
  primaryFill: tokens.colorNeutralForegroundOnBrand,
  className: 'iconClass',
};
export const menuIconsProps: FluentIconsProps = {
  primaryFill: tokens.colorBrandForeground1,
  className: 'iconClass',
};
