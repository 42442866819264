import { extractNumericIdFromUrl } from '.';

export const isDescendant = (item: any, selectedItem: any, items: any[]): boolean => {
  const processParentId = item.parent
    ? extractNumericIdFromUrl(typeof item.parent == 'string' ? item.parent : item.parent.resourceUri)
    : undefined;
  const selectedResourceId = extractNumericIdFromUrl(selectedItem.resourceUri);

  if (processParentId === selectedResourceId) {
    return true;
  }

  const parent = items.find((i: any) => {
    const parentId = extractNumericIdFromUrl(i.resourceUri);
    return parentId === processParentId;
  });

  return parent ? isDescendant(parent, selectedItem, items) : false;
};

export const redirectToProcess = (resourceUri: string) => {
  const id = resourceUri.split('/').slice(-2)[0];
  const url = `${window.location.origin}/process-management/processes/${id}`;
  window.open(url, '_blank');
};
