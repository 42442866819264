import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { Process, ProcessInstance } from '../../../ts/interfaces';
import { searchHistoryProcessInstancesAction } from '../../../store/actions/process-instance-actions';
import { MessageBar } from '@fluentui/react';
import { ProcessVersionsHistory } from '../../../components/process/process-versions-history';
import { useLocation } from 'react-router';

interface IHistory {
  selectedProcess?: Process;
}

const History = (props: PropsFromRedux & IHistory) => {
  const { selectedProcess, searchHistoryProcessInstancesAction } = props;

  const [selectedInstance, setSelectedInstance] = useState<ProcessInstance>();

  const location = useLocation();

  useEffect(() => {
    getInstances(selectedProcess?.id);
  }, [selectedProcess]);

  const getInstances = (id?: number) => {
    const url = `${location.search}${
      location.search === '' ? `?dashboard=true&source_process=${id}` : `&dashboard=true&source_process=${id}`
    }`;
    // }&is_sent=true&is_model_approved=false`;??????
    searchHistoryProcessInstancesAction(url, (res: any) => {
      const instances = res.data.objects;
      setSelectedInstance(instances.filter((item: ProcessInstance) => item.isInitialApproval)[0]);
    });
  };

  return (
    <div>
      {selectedProcess?.rootVersion !== null && <ProcessVersionsHistory selectedProcess={selectedProcess} />}

      {selectedProcess?.rootVersion === null && (
        <MessageBar>There is no previous versions for this process!</MessageBar>
      )}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({}: RootState) => ({});
const connector = connect(mapStateToProps, {
  searchHistoryProcessInstancesAction,
});
export default connector(History);
