import React, { useEffect, useState } from 'react';
import { __ } from 'modeling-tool';
import { connect, ConnectedProps } from 'react-redux';
import ResourcesButtons from '../resources-buttons/resources-buttons';
import { RootState } from '../../../config/store';
import {
  fetchResourceGroupsAction,
  clearResourceGroupAction,
  selectResourceGroupByIdAction,
  deleteResourceGroupAction,
} from '../../../store/actions/resource-group-actions';
import { getStyles } from './resource-groups-styles';
import { ResourceGroup } from '../../../ts/interfaces';
import { Card, GLOBALENUMS, Modal } from 'modeling-tool';
import { Text } from '@fluentui/react';
import { fetchResourcesAction } from '../../../store/actions/resource-actions';
import { ResourceGroupForm } from '../resource-group-form';
import { checkPermission } from '../../../config/permission-utils';
import { useNavigate } from 'react-router';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';

interface IResourceGroups extends PropsFromRedux {
  onFilterResource(id: number): void;
}

const dropDownData = [GLOBALENUMS.DROPDOWNDATA.RESOURCEGROUPS];

const ResourceGroups = (props: IResourceGroups) => {
  const {
    UserReducer,
    ResourceGroupReducer: { resourcegroups, selectedResourcegroup },
    clearResourceGroupAction,
    deleteResourceGroupAction,
    fetchDropdownDataByType,
    fetchResourceGroupsAction,
    fetchResourcesAction,
    onFilterResource,
    selectResourceGroupByIdAction,
  } = props;

  const styles = getStyles();
  const navigate = useNavigate();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

  useEffect(() => {
    fetchResourceGroupsAction();
    clearResourceGroupAction();
  }, []);

  const onSelectCard = (resourcegroup: ResourceGroup) => {
    onFilterResource(resourcegroup.id);
    navigate('/resources/resources-list');
  };

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
    clearResourceGroupAction();
  };

  const onDelete = (resourcegroup: ResourceGroup) => {
    selectResourceGroupByIdAction(resourcegroup.id);
    setIsDeleteModalVisible(true);
  };

  const handleDelete = () => {
    if (selectedResourcegroup) {
      deleteResourceGroupAction(selectedResourcegroup, () => {
        clearResourceGroupAction();
        fetchResourceGroupsAction();
        setIsDeleteModalVisible(false);
        fetchResourcesAction();
        fetchResourceGroupsAction();
      });
      fetchDropdownDataByType(dropDownData);
    }
  };

  const onCardEdit = (resourcegroup: ResourceGroup) => {
    selectResourceGroupByIdAction(resourcegroup.id);
    setModalVisible(true);
  };
  return (
    <>
      <ResourcesButtons isGroupsView />
      <div className={styles.cardLayout}>
        {resourcegroups
          .sort(({ id: previousID }, { id: currentID }) => currentID - previousID)
          .map((resourcegroup: any) => (
            <Card
              key={`id-${resourcegroup.id}`}
              title={resourcegroup.name}
              onselectCard={() => onSelectCard(resourcegroup)}
              onEdit={() => onCardEdit(resourcegroup)}
              onDelete={() => onDelete(resourcegroup)}
              canEdit={checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.change_resourcegroup)}
              canDelete={checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.delete_resourcegroup)}
              cardStyles={styles.card}
              titleStyles={styles.title}
            >
              {resourcegroup.childrenResourcesCount} {__('resources')}
            </Card>
          ))}
      </div>

      <Modal
        title={__('confirm delete')}
        isModalOpen={isDeleteModalVisible}
        onSave={handleDelete}
        onCancel={handleCancel}
        saveButtonText={'Ok'}
      >
        <>
          <Text>{__('are you sure you want to delete this group?')}</Text>
        </>
      </Modal>
      <ResourceGroupForm setModalVisible={setModalVisible} isModalVisible={isModalVisible} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ResourceGroupReducer, UserReducer }: RootState) => ({
  ResourceGroupReducer,
  UserReducer,
}); //UserReducer is a needed state for translation. Otherwise the component is not re-rendered when the language is changed.Reducer is a needed state for translation. Otherwise the component is not re-rendered when the language is changed.
const connector = connect(mapStateToProps, {
  clearResourceGroupAction,
  deleteResourceGroupAction,
  fetchDropdownDataByType,
  fetchResourceGroupsAction,
  fetchResourcesAction,
  selectResourceGroupByIdAction,
});
export default connector(ResourceGroups);
