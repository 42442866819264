import { Meta, Process } from '../../ts/interfaces';

interface ProcessVersionReducerState {
  processes: Process[];
  meta?: Meta;
  selectedProcess?: Process;
}

export default function reducer(
  state: ProcessVersionReducerState = {
    processes: [],
    meta: undefined,
    selectedProcess: undefined,
  },
  action: any,
): ProcessVersionReducerState {
  switch (action.type) {
    case 'SEARCH_PROCESSES_VERSIONS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        processes: action.payload.objects,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
