import { Icon, IStackTokens, PrimaryButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../config/store';
import { __ } from 'modeling-tool';
import { updateTenantAction } from '../../../../store/actions/user-actions';
import { Modal } from 'modeling-tool';
import { getStyles } from './welcome-popup-styles';

interface welcomePopupProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const WelcomePopup = (props: welcomePopupProps & PropsFromRedux) => {
  const { visible, setVisible } = props;

  const stackTokens: IStackTokens = {
    childrenGap: 20,
    padding: 15,
  };

  const styles = getStyles();

  const downloadUrl =
    'https://uploads-ssl.webflow.com/61b8746e0e120e69f52883cf/626bf88147427249f1b344c6_Mit%20aiio%20in%202%20Minuten%20starten.pdf';

  const onDimissClick = () => {
    setVisible(false);
  };

  const onDownloadGuide = () => {
    window.open(downloadUrl);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onDownloadTeams = () => {
    window.open('https://aiiodownload.blob.core.windows.net/teams/aiio_teams_app.zip');
  };

  const renderWelcomePopup = () => {
    return (
      <Modal title={__('hello!')} hideFooter={true} isModalOpen={visible} onCancel={handleCancel}>
        <>
          <Stack tokens={stackTokens}>
            <Stack.Item align="center">
              <Text variant="xxLarge">{__('welcome to aiio')}</Text>
            </Stack.Item>
            <Stack.Item align="center">
              <div className={styles.textClickStyles}>
                <Text variant="mediumPlus">{__('please click on the button to download the start guide')}</Text>
              </div>
            </Stack.Item>
            <Stack.Item align="center">
              <Stack horizontal>
                <Text variant="mediumPlus">{__('if you would like to do it later just click on the help button')}</Text>
                <Icon iconName="help" />
              </Stack>
            </Stack.Item>
            <Stack.Item align="center">
              <Stack horizontal>
                <PrimaryButton type="submit" onClick={onDownloadGuide}>
                  {__('download aiio start guide here')}
                </PrimaryButton>
              </Stack>
            </Stack.Item>
            <Stack.Item align="center">
              <PrimaryButton onClick={onDownloadTeams}>{__('download aiio teams app')}</PrimaryButton>
            </Stack.Item>
            <Stack.Item align="center">
              <PrimaryButton onClick={onDimissClick}>{__("thanks, l'll do it later")}</PrimaryButton>
            </Stack.Item>
          </Stack>
        </>
      </Modal>
    );
  };
  return <>{renderWelcomePopup()}</>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ MenuReducer }: RootState) => ({ MenuReducer });
const connector = connect(mapStateToProps);
export default connector(WelcomePopup);
