import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  Selection,
  CheckboxVisibility,
  DetailsRow,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  IconButton,
  Link,
  PrimaryButton,
  Stack,
  Text,
  Spinner,
  SpinnerSize,
  IStackTokens,
} from '@fluentui/react';
import { File } from '@microsoft/mgt-react';
import { BasicList, GLOBALENUMS, Modal, StyleContext, __ } from 'modeling-tool';
import { getStyles } from './requirement-chapter-list-styles';
import { Process, RequirementChapter } from '../../../ts/interfaces';
import { useNavigate } from 'react-router';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../config/store';
import {
  addQueryChapterParamsAction,
  clearQueryChapterParamsAction,
  clearSelectRequirementChapterAction,
  deleteRequirementChapterAction,
  fetchChapterChildrenAction,
  fetchOpenRequirementByIdAction,
  searchRequirementChaptersAction,
  setChapterOrderByColumnsAction,
  updateRequirementChapterAction,
} from '../../../store/actions/requirement-action';
import { checkPermission } from '../../../config/permission-utils';
import { useBoolean } from '@fluentui/react-hooks';
import { ChapterForm } from './chapter-form';
import { BasicListActionEnum, ChapterModalActions } from '../../../ts/enums';
import { fetchProcessAction } from '../../../store/actions/process-actions';
import { isDescendant, onRenderQuery, updateColumnsWithLanguage } from '../../../utils';
import { ChapterMenu } from '../../../components/requirement';
import { ExpandedIcon, basicListReducer } from '../../../components/basic-list';
import { Pagination } from './pagination';
import { useParams } from 'react-router';
import { ChapterSearchBox } from './chapter-search-box';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';
import ClickableDocument from '../../../components/shared/clickable-document';

const dropDownData = [GLOBALENUMS.DROPDOWNDATA.PROCESSES];

const RequirementChapterList = (props: PropsFromRedux) => {
  const {
    UserReducer: { language, permissions },
    RequirementReducer: { openRequirement, requirementChapters, orderByChapterColumns, params },
    DropdownDataReducer: { processes },
    addQueryChapterParamsAction,
    clearQueryChapterParamsAction,
    deleteRequirementChapterAction,
    fetchChapterChildrenAction,
    fetchOpenRequirementByIdAction,
    searchRequirementChaptersAction,
    setChapterOrderByColumnsAction,
    updateRequirementChapterAction,
    fetchDropdownDataByType,
    clearSelectRequirementChapterAction,
  } = props;
  const navigate = useNavigate();
  const param = useParams();
  const [modalVisible, { setTrue: showModal, setFalse: closeModal }] = useBoolean(false);
  const [modalActionType, setModalActionType] = useState<ChapterModalActions>();
  const [selectedChapters, setSelectedChapters] = useState<RequirementChapter[]>([]);
  const [enableDelete, setEnableDelete] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [changeParams, { toggle: toggleChangeParams }] = useBoolean(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState<string>('20');

  const { useStyleProps } = useContext(StyleContext);
  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  const [rows, updateRows] = useReducer(basicListReducer, []);
  const [loadingDone, setLoadingDone] = useState<boolean>(false);

  useEffect(() => {
    if (param.id) {
      addQueryChapterParamsAction([
        { from_requirement: `${param.id}` },
        { offset: currentOffset },
        { limit: itemsPerPage },
        { level: 0 },
      ]);
      fetchOpenRequirementByIdAction(parseInt(param.id), false);
      fetchDropdownDataByType(dropDownData);
    }
    return () => {
      clearQueryChapterParamsAction();
      const queryParams: any = [];
      queryParams.push({ offset: 0 });
      queryParams.push({ limit: 20 });
      queryParams.push({ level: 0 });
      addQueryChapterParamsAction(queryParams);
    };
  }, []);

  useEffect(() => {
    if (params && params.length > 0) {
      getRequirementChapters();
    }
  }, [params, changeParams, itemsPerPage, orderByChapterColumns]);

  useEffect(() => {
    const filtered = params ? !!params.find((q: any) => q.filtered) : false;
    updateRows({ type: BasicListActionEnum.UPDATE_ITEMS, payload: requirementChapters, filtered: filtered });
  }, [requirementChapters]);

  const getRequirementChapters = () => {
    const query = onRenderQuery(params);
    // searchRequirementChaptersAction(query, orderByChapterColumns);
    searchRequirementChaptersAction(query, orderByChapterColumns, () => {
      setLoadingDone(true);
    });
  };

  const _rowsSelected: Selection = new Selection({
    onSelectionChanged: () => _getSelectionDetails(),
  });

  const _getSelectionDetails = () => {
    const selectionCount = _rowsSelected.getSelectedCount();
    if (selectionCount > 0) {
      setSelectedChapters(_rowsSelected.getSelection() as RequirementChapter[]);
      setEnableDelete(true);
    } else {
      setSelectedChapters([]);
      setEnableDelete(false);
    }
  };

  const columns: IColumn[] = [
    {
      key: 'name',
      name: __('title'),
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      headerClassName: styles.header,
      className: styles.name,
      showSortIconWhenUnsorted: true,
      onRender: (record: RequirementChapter) => renderTitle(record),
    },
    {
      name: __('assigned processes'),
      fieldName: 'process',
      key: 'process',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: ({ linkedProcesses }: RequirementChapter) => renderProcesses(linkedProcesses),
    },
    {
      name: __('link'),
      fieldName: 'link',
      key: 'link_text',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: ({ linkText, linkUrl }: RequirementChapter) => (
        <Link variant="medium" onClick={() => onClickLink(linkUrl)}>
          {linkText ? linkText : linkUrl}
        </Link>
      ),
    },
    {
      name: __('document'),
      fieldName: 'document',
      key: 'document',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: ({ attachments }: RequirementChapter) =>
        attachments &&
        attachments
          .split('|')
          .map((f: string) => f.split('::'))
          .map((fileData: string[]) => (
            <File siteId={fileData[0]} driveId={fileData[1]} itemId={fileData[2]}>
              <ClickableDocument siteId={fileData[0]} driveId={fileData[1]} itemId={fileData[2]} />
            </File>
          )),
    },
  ];

  const renderTitle = (record: RequirementChapter) => {
    const [displayContextualMenu, setDisplayContextualMenu] = useState(false);
    // use effect call to re-render the selected row after deleting, to ensure that the context menu targets the correct model
    useEffect(() => {
      _getSelectionDetails();
    }, [displayContextualMenu]);
    return (
      <>
        {record && (
          <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className={styles.nameStack}>
            <Stack
              className={styles.cell}
              horizontal
              verticalAlign="center"
              horizontalAlign="start"
              style={{ paddingLeft: record.level * 15 }}
            >
              <Stack horizontal verticalAlign="center">
                <ExpandedIcon
                  record={record}
                  isExpanded={record.isExpanded === true}
                  refresh={record.refresh}
                  updateRows={updateRows}
                  fetchChildren={fetchChapterChildrenAction}
                />
              </Stack>
              <Stack grow disableShrink className={!record.children?.length ? styles.nameContainer : ''}>
                {record.name}
              </Stack>
            </Stack>
            <Stack
              horizontal
              horizontalAlign="end"
              verticalAlign="center"
              disableShrink
              styles={{ root: { width: 50, flexShrink: 100 } }}
              className={styles.iconContainer}
            >
              <IconButton
                id={`more-options-menu-${record.id}`}
                iconProps={{ iconName: 'More', color: '#8a2be2' }}
                className={record.iconTrigger ? styles.iconButton : styles.iconButtonHidden}
                onClick={() => setDisplayContextualMenu(true)}
              />
              <ChapterMenu
                record={record}
                displayContextualMenu={displayContextualMenu}
                setDisplayContextualMenu={setDisplayContextualMenu}
                setShowDeleteModal={setShowDeleteModal}
                showModal={showModal}
                setModalActionType={setModalActionType}
              />
            </Stack>
          </Stack>
        )}
      </>
    );
  };
  const renderProcesses = (linkedProcesses: any) => {
    return (
      <Stack wrap horizontal>
        {linkedProcesses?.map((process: Process, index: number) => {
          return (
            <>
              {index !== 0 && <p>,&nbsp;</p>}
              {process?.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS ? (
                <Link
                  key={index}
                  onClick={() => {
                    navigate(`/process-management/processes/${process?.id}`);
                  }}
                >
                  {process?.processName}
                </Link>
              ) : (
                <div key={index}>{process?.processName}</div>
              )}
            </>
          );
        })}
      </Stack>
    );
  };

  const [tableColumns, setTableColumns] = useState<IColumn[]>(columns);

  //This useEffect is used to update the columns header when the language is changed
  useEffect(() => {
    const updatedColumns = updateColumnsWithLanguage(columns);
    setTableColumns(updatedColumns);
  }, [language]);

  const onClickLink = (link?: string) => {
    window.open(link?.toString(), '_blank')?.focus();
  };

  const renderButtons = () => {
    return (
      <>
        {checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_requirementchapter) && (
          <PrimaryButton
            onClick={() => {
              setModalActionType(ChapterModalActions.NEW_CHAPTER);
              showModal();
            }}
          >
            {__('create new chapter')}
          </PrimaryButton>
        )}
      </>
    );
  };

  const stackTokens: IStackTokens = { childrenGap: 10 };
  const renderLeftHeader = () => {
    return (
      <Stack verticalFill>
        <Stack className={styles.requirementName}>{openRequirement?.name}</Stack>
        <Stack horizontal tokens={stackTokens}>
          <ChapterSearchBox toggleChangeParams={toggleChangeParams} requirementId={param.id} />
          {!loadingDone && <Spinner size={SpinnerSize.large} />}
        </Stack>
      </Stack>
    );
  };

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props: any): JSX.Element | null => {
    const setIconTrigger = (trigger: boolean, rows: Process[]) => {
      if (rows) {
        updateRows({ type: BasicListActionEnum.ON_OVER_ITEM, id: props.item.id, trigger: trigger });
      }
    };

    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      customStyles.root = {
        zIndex: 10000 - props.itemIndex,
        transition: 'z-index 0s',
        height: '48px',
      };

      return (
        <>
          {!props.item.isHidden && (
            <div
              onMouseOver={() => setIconTrigger(true, rows)}
              onMouseLeave={() => setIconTrigger(false, rows)}
              onClick={() => setIconTrigger(false, rows)}
            >
              <DetailsRow {...props} styles={customStyles} />
            </div>
          )}
        </>
      );
    }
    return null;
  };

  const onDeleteChapter = () => {
    for (const chapter of selectedChapters) {
      deleteRequirementChapterAction(chapter, () => {
        updateRows({ type: BasicListActionEnum.DELETE_ITEM_ROW, id: chapter.id });
      });
    }
    setEnableDelete(false);
  };

  // Drag and Drop
  const [draggedItem, setDraggedItem] = useState<RequirementChapter | undefined>(undefined);

  const dragDropEvents = () => {
    return {
      canDrop: () => {
        return true;
      },
      canDrag: () => {
        return true;
      },
      onDragLeave: () => {
        return;
      },
      onDrop: (item: RequirementChapter) => {
        onDropItem(item);
      },
      onDragStart: (item: RequirementChapter) => {
        setDraggedItem(item);
      },
      onDragEnd: () => {
        setDraggedItem(undefined);
      },
    };
  };

  const onDropItem = (item: RequirementChapter) => {
    if (draggedItem && item && draggedItem.id !== item.id) {
      if (item.parent && isDescendant(item, draggedItem, rows)) return;

      const values = { ...draggedItem, parent: item.resourceUri };
      updateRequirementChapterAction(values, (result) => {
        updateRows({ type: BasicListActionEnum.UPDATE_ITEM_ROW, payload: result.data, id: draggedItem.id });
        clearSelectRequirementChapterAction();
      });
    }
  };
  // End of Drag and Drop

  return (
    <>
      <Stack>
        <BasicList
          setKey={GLOBALENUMS.BASICLISTTYPES.REQUIREMENT_DETAILS_LIST}
          columns={tableColumns}
          setTableColumns={setTableColumns}
          items={rows}
          className={styles.listContainer}
          checkboxVisibility={CheckboxVisibility.hidden}
          rightHeaderButtons={renderButtons()}
          leftHeaderButtons={renderLeftHeader()}
          onRenderRow={onRenderRow}
          selection={_rowsSelected}
          dragDropEvents={
            checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_requirementchapter) && dragDropEvents
          }
          setOrderByColumns={setChapterOrderByColumnsAction}
          defaultOrderByColumns={'-date_created'}
        />
      </Stack>
      <Stack horizontal horizontalAlign="end">
        {loadingDone && (
          <Pagination
            toggleChangeParams={toggleChangeParams}
            currentOffset={currentOffset}
            itemsPerPage={itemsPerPage}
            setCurrentOffset={setCurrentOffset}
            setItemsPerPage={setItemsPerPage}
            search={location.search}
            openRequirement={openRequirement}
          />
        )}
      </Stack>
      {modalVisible && (
        <ChapterForm
          modalActionType={modalActionType}
          modalVisible={modalVisible}
          closeModal={closeModal}
          updateRows={updateRows}
        />
      )}
      {showDeleteModal && (
        <Modal
          title={__('delete selected chapter')}
          isModalOpen={showDeleteModal}
          onSave={() => {
            onDeleteChapter();
            setShowDeleteModal(false);
          }}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          saveButtonText={__('yes')}
        >
          <Text>{__('are you sure to delete this chapter?')}</Text>
        </Modal>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ RequirementReducer, UserReducer, DropdownDataReducer }: RootState) => ({
  RequirementReducer,
  UserReducer,
  DropdownDataReducer,
});
const connector = connect(mapStateToProps, {
  addQueryChapterParamsAction,
  clearQueryChapterParamsAction,
  deleteRequirementChapterAction,
  fetchChapterChildrenAction,
  fetchOpenRequirementByIdAction,
  fetchProcessAction,
  searchRequirementChaptersAction,
  setChapterOrderByColumnsAction,
  updateRequirementChapterAction,
  fetchDropdownDataByType,
  clearSelectRequirementChapterAction,
});

export default connector(RequirementChapterList);
