import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    newDivCard: {
      width: '281px',
      height: '231px',
      backgroundColor: '#F6f6f6',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      border: '1px solid transparent !important',
      selectors: {
        ':hover': {
          border: '1px solid #000000 !important',
        },
      },
    },
    divcard: {
      width: '281px',
      height: '231px',
      backgroundColor: '#F6f6f6',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      border: '1px solid transparent !important',
      selectors: {
        ':hover': {
          border: '1px solid #000000 !important',
        },
      },
      ':hover *': {
        visibility: 'visible',
      },
    },
    divcardSelected: {
      border: '1px solid #000000 !important',
    },
    divCardHeader: {
      marginLeft: '20px',
    },
    divCardTitle: {
      fontSize: '20px',
    },
    divCardBody: {
      fontSize: '14px',
      marginLeft: '20px',
      marginRight: '20px',
      height: '60px',
      overflowY: 'scroll',
      display: '-webkit-box',
      '-webkit-line-clamp': '3 !important',
      '-webkit-box-orient': 'vertical',
      '& > p': {
        marginTop: '0px !important',
      },
    },
    divCardBodyProcesses: {
      fontSize: '14px',
      marginLeft: '20px',
      marginRight: '20px',
    },
    divCardFooter: {
      marginLeft: '20px',
      marginBottom: '10px',
    },
    personIcon: {
      marginRight: '5px !important',
      marginTop: '5px !important',
    },
    personTitle: {
      marginTop: '6px !important',
      fontSize: '14px',
      fontWeight: '400',
    },
    footerTextRight: {
      visibility: 'hidden',
      cursor: 'pointer',
      float: 'right',
      marginLeft: 'auto !important',
      marginRight: '13px !important',
      fontWeight: '600',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
});
