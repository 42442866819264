import { memoizeFunction, mergeStyleSets } from '@fluentui/react';
import { StyleProp } from '../../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    deleteButton: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: theme.palette.neutralLighter,
      border: 'none',
      ':hover': {
        backgroundColor: theme.palette.neutralLight,
      },
    },
    labelStyle: { fontWeight: '400', fontSize: 14, marginTop: 7 },
  });
});
