import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    basicList: {
      '.ms-DetailsRow': {
        borderBottom: '1px solid ' + theme.palette.neutralLight,
      },
      '& [role=grid]': {
        height: 'auto !important',
      },
    },
    descriptionElement: {
      p: {
        margin: 0,
        fontSize: 14,
      },
    },
    svgIconStack: {
      width: 50,
      display: 'flex',
      height: 'auto',
    },

    iconDiv: {
      width: 31,
      display: 'flex',
    },
    iconText: {
      fontWeight: 400,
    },

    rowStyle: {
      overflowWrap: 'break-word',
      justifyContent: 'flex-start',
      whiteSpace: 'normal',
    },
    responsibleStack: {
      paddingBottom: 8,
      height: 'auto',
    },

    linkText: {
      marginLeft: 4,
      fontSize: 14,
    },
    assignmentElements: {
      alignItems: 'center',
      height: 'auto !important',
    },
  });
});
