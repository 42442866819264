import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    personStyle: {
      borderRight: '5px',
      borderLeft: '5px',
      marginTop: '20px',
    },
  });
});
