import React from 'react';
import { DropdownWithSearch, GLOBALENUMS, __ } from 'modeling-tool';
import { renderSelectDropDown } from '../../../utils/process-management';
import { Department, ExternalPartner, Role, StaffPosition } from '../../../ts/interfaces';
import { useFormContext } from 'react-hook-form';

interface OrganizationSectionProps {
  orgUnits: Department[];
  roles: Role[];
  staffPositions: StaffPosition[];
  externalPartners: ExternalPartner[];
}

const OrganizationSection = (props: OrganizationSectionProps) => {
  const { orgUnits, roles, staffPositions, externalPartners } = props;
  const { control } = useFormContext();

  return (
    <>
      <DropdownWithSearch
        labelTitle={__('organizational unit')}
        options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, orgUnits)}
        control={control}
        name={'department'}
        placeholder={__('please select department')}
        multiSelect
      />
      <DropdownWithSearch
        labelTitle={__('role')}
        options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ROLES, roles)}
        control={control}
        name={'role'}
        placeholder={__('please select role')}
        multiSelect
      />
      <DropdownWithSearch
        labelTitle={__('staffposition')}
        options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.STAFF_POSITIONS, staffPositions)}
        control={control}
        name={'staffPosition'}
        placeholder={__('please select staff position')}
        multiSelect
      />
      <DropdownWithSearch
        labelTitle={__('externalpartner')}
        options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.EXTERNAL_PARTNERS, externalPartners)}
        control={control}
        name={'externalPartner'}
        placeholder={__('please select external partner')}
        multiSelect
      />
    </>
  );
};

export default OrganizationSection;
