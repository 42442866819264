import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Checkbox, Label } from '@fluentui/react';
import { RootState } from '../../../config/store';
import { updateSettingAction, getCurrentUserAction } from '../../../store/actions/user-actions';
import { __ } from 'modeling-tool';
import { SettingGroup, Setting } from '../../../ts/interfaces';
import { useStyles } from './tenant-settings-styles';

const TenantSettings = (props: PropsFromRedux) => {
  const {
    UserReducer: { settingsGroups },
    updateSettingAction,
    getCurrentUserAction,
  } = props;

  const classes = useStyles();

  const _onCheckboxChange = (
    group: SettingGroup,
    setting: Setting,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean,
  ) => {
    const data = { ...setting, value: isChecked?.toString() } as Setting;
    updateSettingAction(data, () => {
      getCurrentUserAction();
    });
  };

  const renderSettings = (group: SettingGroup) => {
    const { settings } = group;
    if (settings && settings.length > 0) {
      return settings.map((setting: Setting) => {
        if (setting.type === 'checkbox') {
          return (
            <Checkbox
              key={`${setting.id}-checkbox-group`}
              checked={setting.value === 'true'}
              label={__(setting.title)}
              onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) =>
                _onCheckboxChange(group, setting, ev, isChecked)
              }
            />
          );
        } else if (setting.type === 'text') {
          return <div key={`${setting.id}`}>{`${setting.value}/${setting.default} ${__(setting.title)}`}</div>;
        }
      });
    }
    return null;
  };

  const renderSettingsGroups = () => {
    if (settingsGroups && settingsGroups.length > 0) {
      return settingsGroups.map((group: SettingGroup) => (
        <div key={group.id} className={classes.settingItemsHolder}>
          <Label>{__(group.title)}</Label>
          {renderSettings(group)}
        </div>
      ));
    }
    return null;
  };

  return <>{renderSettingsGroups()}</>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, { updateSettingAction, getCurrentUserAction });
export default connector(TenantSettings);
