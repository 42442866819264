import { __ } from 'modeling-tool';
import { Pivot, PivotItem } from '@fluentui/react';
import { GroupMembership } from './group-membership';
import { TenantSettings } from './tenant-settings';
import { Modelling } from './modelling';
import { TenantPermissions } from './tenant-permissions';

const Settings = () => {
  return (
    <Pivot>
      <PivotItem
        key="1"
        headerText={__('group membership')}
        headerButtonProps={{
          'data-order': 1,
        }}
      >
        <GroupMembership />
      </PivotItem>
      <PivotItem
        key="2"
        headerText={__('tenant settings')}
        headerButtonProps={{
          'data-order': 2,
        }}
      >
        <TenantSettings />
      </PivotItem>
      <PivotItem
        key="3"
        headerText={__('modeling')}
        headerButtonProps={{
          'data-order': 3,
        }}
      >
        <Modelling />
      </PivotItem>
      <PivotItem
        key="4"
        headerText={__('permissions')}
        headerButtonProps={{
          'data-order': 4,
        }}
      >
        <TenantPermissions />
      </PivotItem>
    </Pivot>
  );
};

export default Settings;
