import React, { Dispatch, SetStateAction } from 'react';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react';
import { ConnectedProps, connect } from 'react-redux';
import {
  fetchProcessAction,
  makeProcessVersionAction,
  startProcessModelingAction,
} from '../../../store/actions/process-actions';
import { RootState } from '../../../config/store';
import { GLOBALENUMS, __, openProcessDetailsPrintPage } from 'modeling-tool';
import { checkPermission } from '../../../config/permission-utils';
import { getStyles } from './process-menu-styles';
import { Action, Process } from '../../../ts/interfaces';
import { useNavigate } from 'react-router';
import { BasicListActionEnum, ProcessModalActions } from '../../../ts/enums';
import {
  getProcessMapFullModelByParamsAction,
  addProcessMapFullModel,
  enableEditMode,
} from '../../../store/actions/process-map-actions';

interface IProcessNameProps {
  displayContextualMenu: boolean;
  hasReminder: boolean;
  record: Process;
  setCreateAsChildModal: (visible: boolean) => void;
  setCreateAsCopy: (visible: boolean) => void;
  setDisplayContextualMenu: (visible: boolean) => void;
  showProcessModal: (actionType: ProcessModalActions) => void;
  setReminderModalVisible: (visible: boolean) => void;
  setSendModel: (visible: boolean) => void;
  setSendVisible: (visible: boolean) => void;
  getProcesses: () => void;
  setShowSpinner: (visible: boolean) => void;
  setShowProcessHistoryModal: (visible: boolean) => void;
  setShowDeleteModal: (visible: boolean) => void;
  setProcessMapType: Dispatch<SetStateAction<GLOBALENUMS.PROCESSMAPNODETYPE>>;
  updateRows: Dispatch<Action>;
}

const ProcessMenu = (props: PropsFromRedux & IProcessNameProps) => {
  const {
    UserReducer,
    ProcessReducer: { processes },
    displayContextualMenu,
    hasReminder,
    makeProcessVersionAction,
    record,
    addProcessMapFullModel,
    fetchProcessAction,
    getProcessMapFullModelByParamsAction,
    setCreateAsChildModal,
    setCreateAsCopy,
    setDisplayContextualMenu,
    showProcessModal,
    setReminderModalVisible,
    setSendModel,
    setSendVisible,
    setShowSpinner,
    startProcessModelingAction,
    setShowProcessHistoryModal,
    setShowDeleteModal,
    setProcessMapType,
    enableEditMode,
    updateRows,
  } = props;

  const navigate = useNavigate();
  const styles = getStyles();

  const renderMoreOptionsMenu = (record: Process) => {
    return (
      <React.Fragment>
        {getMoreOptionsMenuItems(record).map((item: IContextualMenuItem) => (
          <div key={record.id}>
            {item.itemType !== ContextualMenuItemType.Divider && item.text}
            {item.itemType === ContextualMenuItemType.Divider && <hr />}
          </div>
        ))}
      </React.Fragment>
    );
  };

  const getMoreOptionsMenuItems = (record: Process): IContextualMenuItem[] => {
    let isInRecording = true;
    let isNew = true;
    let isInModeling = false;
    let isDone = true;
    let isModelingApproved = false;

    if (record.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS) {
      isInRecording = record.state.type === GLOBALENUMS.PROCESSSTATUS.IN_RECORDING;
      isNew = record.state.type === GLOBALENUMS.PROCESSSTATUS.NEW;
      isInModeling = record.state.type === GLOBALENUMS.PROCESSSTATUS.IN_MODELING;
      isDone = record.state.type === GLOBALENUMS.PROCESSSTATUS.DONE;
    }

    isModelingApproved = record.state.type === GLOBALENUMS.PROCESSSTATUS.DONE;

    const processOptionKeys = [
      'edit',
      'start-process-modelling',
      'send-process-modelling',
      'create-sub-process',
      'schedule-reminder',
      'create-new-version',
      'create-copy',
      'export-as-pdf',
      'version-history',
      'delete',
    ];
    const processGroupOptionKeys = [
      'edit',
      'start-process-group-modelling',
      'create-sub-process',
      'create-sub-group',
      'create-copy',
      'delete',
    ];

    const fullOptionsList = [
      {
        key: 'edit',
        text: __('edit'),
        onClick: () => {
          onProcessEdit(
            record,
            record.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS
              ? ProcessModalActions.EDIT_PROCESS
              : ProcessModalActions.EDIT_PROCESS_GROUP,
          );
        },
        disabled: !(isInRecording || isNew || isInModeling),
      },
      {
        key: 'start-process-modelling',
        text: __('start modeling'),
        onClick: () => {
          recordIsEditable(record) && startModeling(record);
        },
        disabled: !(isInModeling || isNew),
      },
      {
        key: 'start-process-group-modelling',
        text: __('start modeling'),
        onClick: () => {
          startProcessGroupModeling(record);
        },
        disabled: false,
      },
      {
        key: 'send-process-modelling',
        text: __('send process modeling'),
        onClick: () => {
          recordIsEditable(record) && onModelingSend(record);
        },
        disabled: !(isInRecording || isNew),
      },
      { key: 'divider_1', className: styles.divider, itemType: ContextualMenuItemType.Divider },
      {
        key: 'create-sub-process',
        text: __('create subprocess'),
        onClick: () => {
          setCreateAsChildModal(true);
          onProcessEdit(record, ProcessModalActions.NEW_SUBPROCESS);
        },
        disabled: !checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_process),
      },
      {
        key: 'create-sub-group',
        text: __('create subgroup'),
        onClick: () => {
          setCreateAsChildModal(true);
          onProcessEdit(record, ProcessModalActions.NEW_SUBPROCESS_GROUP);
        },
        disabled: !checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_process),
      },
      { key: 'divider_2', className: styles.divider, itemType: ContextualMenuItemType.Divider },
      {
        key: 'schedule-reminder',
        text: __('Schedule reminder'),
        onClick: () => {
          setReminder(record);
        },
        disabled: !(isDone && !hasReminder),
      },
      { key: 'divider_3', className: styles.divider, itemType: ContextualMenuItemType.Divider },
      {
        key: 'create-new-version',
        text: __('Create new version'),
        onClick: () => {
          record.state && !hasCopiedProcess(record) && onMakeVersion(record);
        },
        disabled: !isDone,
      },
      {
        key: 'create-copy',
        text: __('create copy'),
        onClick: () => {
          setCreateAsCopy(true);
          onProcessEdit(
            record,
            record.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS
              ? ProcessModalActions.COPY_PROCESS
              : ProcessModalActions.COPY_PROCESS_GROUP,
          );
        },
        disabled: !checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_process),
      },
      {
        key: 'export-as-pdf',
        text: __('export as pdf'),
        onClick: () => {
          openProcessDetailsPrintPage(
            record.sourceProcess ? record.sourceProcess.processName : 'aiio',
            `${location.href}/${record.id}`,
          );
        },
        disabled: !isModelingApproved,
      },
      {
        key: 'version-history',
        text: __('Version history'),
        onClick: () => {
          fetchProcessAction(record.id, () => {
            setShowProcessHistoryModal(true);
          });
        },
      },
      { key: 'divider_4', className: styles.divider, itemType: ContextualMenuItemType.Divider },
      {
        key: 'delete',
        text: __('Delete'),
        onClick: () => {
          setShowDeleteModal(true);
        },
        disabled: !checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_process),
      },
    ];

    let resultList: IContextualMenuItem[] = [];
    if (record.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP) {
      resultList = fullOptionsList.filter(
        (item: IContextualMenuItem) =>
          processGroupOptionKeys.includes(item.key) ||
          (item.key.includes('divider') && !item.key.includes('divider_3')),
      );
    } else {
      resultList = fullOptionsList.filter(
        (item: IContextualMenuItem) =>
          processOptionKeys.includes(item.key) || (item.key.includes('divider') && !(item.key === 'divider_3')),
      );
    }
    return resultList;
  };

  const onProcessEdit = (item: Process, modalActionType: ProcessModalActions) => {
    if (modalActionType === ProcessModalActions.NEW_PROCESS) {
      setProcessMapType(GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS);
    } else if (modalActionType === ProcessModalActions.NEW_PROCESS_GROUP) {
      setProcessMapType(GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP);
    } else {
      setProcessMapType(item.processMapType);
    }
    fetchProcessAction(item.id, () => {
      showProcessModal(modalActionType);
    });
  };

  const recordIsEditable = (process: Process) => {
    return process.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS
      ? !(
          process.state.type === GLOBALENUMS.PROCESSSTATUS.DONE ||
          process.state.type === GLOBALENUMS.PROCESSSTATUS.REJECTED ||
          process.state.type === GLOBALENUMS.PROCESSSTATUS.IN_APPROVAL
        )
      : true;
  };

  const startProcessGroupModeling = (process: Process) => {
    getProcessMapFullModelByParamsAction(process, (res: any) => {
      if (res?.data?.objects?.length) {
        const url = `/process-management/processmap/process-group/${res.data.objects[0].fromProcessGroup?.id}`;
        enableEditMode();
        navigate(url);
      } else {
        addProcessMapFullModel({ fromProcessGroup: `/api/v1/process/${process.id}/` }, (res: any) => {
          if (res?.data?.fromProcessGroup?.id) {
            const url = `/process-management/processmap/process-group/${res.data.fromProcessGroup.id}`;
            enableEditMode();
            navigate(url);
          }
        });
      }
    });
  };

  const startModeling = (process: Process) => {
    startProcessModelingAction(process.id, (res: any) => {
      if (res.data) {
        const redirect = `/process-management/processes/${process.id}/modeling-tool/${res.data.id}`;
        navigate(redirect);
      }
    });
  };

  const onModelingSend = (item: Process): void => {
    fetchProcessAction(item.id, () => {
      setSendModel(true);
      setSendVisible(true);
    });
  };

  const setReminder = (item: Process) => {
    fetchProcessAction(item.id, () => {
      setReminderModalVisible(true);
    });
  };

  /**
   * checks if
   * @param record a process in the process list
   * @returns
   */
  const hasCopiedProcess = (record: Process): boolean => {
    const unDoneCopy = processes.find((process) => {
      if (process && !process.rootVersion) {
        return false;
      }
      return (
        (process?.rootVersion?.id === record.id || process?.rootVersion?.id === record?.rootVersion?.id) &&
        process.state.type !== GLOBALENUMS.PROCESSSTATUS.DONE
      );
    });
    return unDoneCopy ? true : false;
  };

  const onMakeVersion = (record: Process) => {
    if (record.approvedBy) {
      delete record.approvedBy;
    }
    setShowSpinner(true);
    makeProcessVersionAction(record, (result) => {
      updateRows({ type: BasicListActionEnum.INSERT_ITEM_ROW, payload: result.data });
      setShowSpinner(false);
      navigate(`/process-management/processes/${result.data.id}`);
    });
  };

  return (
    <>
      {displayContextualMenu && (
        <>
          <ContextualMenu
            calloutProps={renderMoreOptionsMenu(record)}
            target={`#more-options-menu-${record.id}`}
            className={styles.contextMenu}
            items={getMoreOptionsMenuItems(record)}
            onItemClick={() => setDisplayContextualMenu(false)}
            onDismiss={() => setDisplayContextualMenu(false)}
          />
        </>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, ProcessReducer }: RootState) => ({ UserReducer, ProcessReducer });

const connector = connect(mapStateToProps, {
  addProcessMapFullModel,
  enableEditMode,
  fetchProcessAction,
  getProcessMapFullModelByParamsAction,
  makeProcessVersionAction,
  startProcessModelingAction,
});

export default connector(ProcessMenu);
