import { memoizeFunction, mergeStyleSets } from '@fluentui/react';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    blinkHint: {
      animation: 'blinker 1.5s linear infinite',
    },
    resourcesListContainer: {
      paddingLeft: '15px',
      height: 'calc(100vh - 170px)',
    },
  });
});
