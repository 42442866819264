import { ProcessReminder, Meta } from '../../ts/interfaces';

export interface ProcessReminderReducer {
  processreminders: ProcessReminder[];
  meta?: Meta;
  selectedProcessReminder?: ProcessReminder;
}

export default function reducer(
  state: ProcessReminderReducer = {
    processreminders: [],
    meta: undefined,
    selectedProcessReminder: undefined,
  },
  action: any,
): ProcessReminderReducer {
  switch (action.type) {
    case 'FETCH_PROCESS_Reminder': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        processreminders: action.payload.objects,
      });
    }
    case 'ADD_PROCESS_REMINDER': {
      return {
        ...state,
        processreminders: state.processreminders.concat(action.payload),
      };
    }
    case 'UPDATE_PROCESS_REMINDER': {
      return {
        ...state,
        processreminders: state.processreminders.map((reminder) =>
          reminder.id === action.payload.id ? action.payload : reminder,
        ),
      };
    }
    case 'SELECT_PROCESS_REMINDER': {
      return Object.assign({}, state, {
        selectedProcessReminder: action.payload,
      });
    }
    case 'DELETE_PROCESS_REMINDER': {
      return {
        ...state,
        processreminders: state.processreminders.filter((process) => process.id !== action.payload),
      };
    }
    case 'SELECT_PROCESS_REMINDER_BY_ID': {
      return Object.assign({}, state, {
        selectedProcessReminder: state.processreminders.find((process) => process.id === action.payload),
      });
    }
    default: {
      break;
    }
  }
  return state;
}
