import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './telemetry-service';

export interface TelemetryProviderProps {
  children: JSX.Element;
  instrumentationKey: string;
  after(): void;
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<TelemetryProviderProps> {
  state = {
    initialized: false,
  };

  componentDidMount() {
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
    if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey)) {
      ai.initialize(AppInsightsInstrumentationKey);
      this.setState({ initialized: true });
    }

    this.props.after();
  }

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);
