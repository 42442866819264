import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  dashboardPage: {
    marginTop: '0px',
    marginLeft: '57px',
    marginBottom: '0px',
    marginRight: '57px',
    height: `calc(100vh - 110px)`,
    ...shorthands.overflow('scroll'),
  },
  tilesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    gridColumnGap: '26px',
    gridRowGap: '10px',
  },
  bottomTilesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
    gridColumnGap: '26px',
    gridRowGap: '10px',
    marginTop: '15px',
  },
});
