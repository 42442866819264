//** visit <url>/initial-data to see the configartion dashboard */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Slider, SliderOnChangeData, Spinner, mergeClasses } from '@fluentui/react-components';
import { createInitialDataAction, deleteInitialDataAction } from '../../store/actions/initial-data-dashboard-actions';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../config/store';
import { useBoolean } from '@fluentui/react-hooks';
import { SavingNotification, __ } from 'modeling-tool';
import { getStyles } from './initial-data-configuration-dashboard-styles';
import { useForm } from 'react-hook-form';
import { InitialDataField } from './initial-data-field';

const systemSizes = ['small', 'medium', 'large'];

enum InitialDataType {
  DEPARTMENT = 'department',
  ROLE = 'role',
  STAFF_POSITION = 'staff position',
  EXTERNAL_PARTNER = 'external partner',
  REQUIREMENT = 'requirement',
  REQUIREMENT_CHAPTER = 'requirement chapter',
  RESOURCE_GROUP = 'resource group',
  RESOURCE = 'resource',
  LOCATION = 'location',
  WORKING_GROUP = 'working group',
  PROCESS = 'process',
  PROCESSGROUP = 'process group',
}

interface InitialDataFormFields {
  department: number;
  role: number;
  staffPosition: number;
  externalPartner: number;
  requirement: number;
  requirementChapter: number;
  resourceGroup: number;
  resource: number;
  location: number;
  workingGroup: number;
  process: number;
  processGroup: number;
}

const dataPerSystemSize: { [key: string]: InitialDataFormFields } = {
  small: {
    department: 3,
    role: 3,
    staffPosition: 2,
    externalPartner: 2,
    requirement: 5,
    requirementChapter: 15,
    resourceGroup: 3,
    resource: 9,
    location: 3,
    workingGroup: 2,
    process: 3,
    processGroup: 3,
  },
  medium: {
    department: 8,
    role: 12,
    staffPosition: 4,
    externalPartner: 5,
    requirement: 20,
    requirementChapter: 15,
    resourceGroup: 8,
    resource: 15,
    location: 7,
    workingGroup: 8,
    process: 50,
    processGroup: 10,
  },
  large: {
    department: 15,
    role: 20,
    staffPosition: 5,
    externalPartner: 10,
    requirement: 30,
    requirementChapter: 50,
    resourceGroup: 12,
    resource: 20,
    location: 10,
    workingGroup: 15,
    process: 100,
    processGroup: 30,
  },
};

const groupedDataStructure: { [key: string]: string[] } = {
  organizationalUnits: [
    InitialDataType.DEPARTMENT,
    InitialDataType.ROLE,
    InitialDataType.STAFF_POSITION,
    InitialDataType.EXTERNAL_PARTNER,
  ],
  requirements: [InitialDataType.REQUIREMENT, InitialDataType.REQUIREMENT_CHAPTER],
  resources: [InitialDataType.RESOURCE_GROUP, InitialDataType.RESOURCE],
  other: [
    InitialDataType.LOCATION,
    InitialDataType.WORKING_GROUP,
    InitialDataType.PROCESS,
    InitialDataType.PROCESSGROUP,
  ],
};

const InitialDataConfigurationDashboard = (props: PropsFromRedux) => {
  const {
    InitialDataDashboardReducer: { initialObjects },
    createInitialDataAction,
    deleteInitialDataAction,
  } = props;
  const [showSuccess, { setTrue: viewSuccess, setFalse: dismissSuccess }] = useBoolean(false);
  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [systemSize, setSystemSize] = useState<string>('small');
  const { control, reset, handleSubmit } = useForm();
  const styles = getStyles();
  const cautionBoxStyles = mergeClasses(
    styles.cautionBox,
    systemSize == 'small' ? styles.cautionBoxVisibilityHidden : styles.cautionBoxVisibilityVisible,
  );
  const MAX_VALUE = 500;

  useEffect(() => {
    reset(dataPerSystemSize[systemSize]);
  }, [systemSize]);

  useEffect(() => {
    if (message) viewSuccess();
  }, [message]);

  useEffect(() => {
    if (!showSuccess) {
      setMessage(undefined);
    }
  }, [showSuccess]);

  const onClickCreateData = () => {
    handleSubmit((data) => {
      setLoadingIndicator(true);
      createInitialDataAction(data, (res: any) => {
        if (res?.data) {
          const { message } = res.data;
          setMessage(message);
        }
        setLoadingIndicator(false);
      });
    })();
  };

  const onClickDeleteData = () => {
    setLoadingIndicator(true);
    deleteInitialDataAction(initialObjects, (res: any) => {
      if (res?.data) {
        setMessage(res.data.message);
      }
      setLoadingIndicator(false);
    });
  };

  const onSliderChanged = (ev: ChangeEvent<HTMLInputElement>, data: SliderOnChangeData) => {
    setSystemSize(systemSizes[data.value]);
  };

  const getSectionTitle = (key: string) => {
    switch (key) {
      case 'organizationalUnits':
        return 'organizational units';
      case 'requirements':
        return 'requirements';
      case 'resources':
        return 'resources';
      case 'other':
        return 'other';
      default:
        return '';
    }
  };

  return (
    <form>
      <div className={styles.initialDataDashboardContainer}>
        <div className={styles.initialDataDashboardHeader}>
          <h1>{__('initial data configuration dashboard')}</h1>
          <div className={styles.sliderSection}>
            <span>{__('create an initial system of size...')}</span>
            <h2>{__(`${systemSize}`)}</h2>
            <Slider
              className={styles.sliderStyles}
              min={0}
              max={2}
              step={1}
              defaultValue={0}
              onChange={onSliderChanged}
              size="medium"
              rail={{ style: { height: 8 } }}
              thumb={{ style: { height: 32, width: 32 } }}
            />
          </div>
          <div className={cautionBoxStyles}>
            <div className={styles.cautionText}>{__('caution!!!')}</div>
            <div> {__('the creation of medium and particularly large systems might take some time.')}</div>
          </div>
          <div className={styles.buttonSection}>
            <Button onClick={onClickCreateData}>{__('create data')}</Button>
            <Button onClick={onClickDeleteData}>{__('delete data')}</Button>
          </div>
          {loadingIndicator && <Spinner />}
          <div className={styles.inputFieldContainer}>
            {Object.entries(groupedDataStructure).map(([key, values], idx) => {
              return (
                <div key={`assosiatedObject-${idx}`} className={styles.associatedSection}>
                  <h3>{__(getSectionTitle(key))}</h3>
                  {values?.map((type) => {
                    const camelCaseType = type
                      .split(' ')
                      .map((word, idx) => (idx ? word.charAt(0).toUpperCase() + word.slice(1) : word))
                      .join('');
                    const upperCaseType = type
                      .split(' ')
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ');
                    return (
                      <InitialDataField
                        key={`${camelCaseType}-${idx}`}
                        label={__(upperCaseType)}
                        name={camelCaseType}
                        control={control}
                        rules={{
                          max: {
                            value: MAX_VALUE,
                            message: __(`maximum value must be <= %s`, null, [`${MAX_VALUE}`]),
                          },
                          min: {
                            value: 0,
                            message: __(`value must be >= 0`),
                          },
                        }}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <SavingNotification showSuccess={showSuccess} dismissSuccess={dismissSuccess} message={message} />
      </div>
    </form>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ InitialDataDashboardReducer }: RootState) => ({
  InitialDataDashboardReducer,
});
const connector = connect(mapStateToProps, {
  createInitialDataAction,
  deleteInitialDataAction,
});
export default connector(InitialDataConfigurationDashboard);
