import { makeResetStyles, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { FluentIconsProps } from '@fluentui/react-icons';

export const useStyles = makeStyles({
  badge: {
    position: 'absolute',
    top: '5px',
    marginLeft: '14px',
    minWidth: '18px',
    height: '18px',
    backgroundColor: 'red',
    ...shorthands.borderRadius('50%'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: tokens.colorNeutralBackground1,
    fontSize: tokens.fontSizeBase200,
    lineHeight: '14px',
    minWidth: '18px',
    textAlign: 'center',
  },
  notificationMessages: {
    height: '550px',
    width: '787px',
    position: 'relative',
    '& mgt-person': {
      '--font-weight': '400',
    },
  },
  messageItem: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  messageItemNotSeen: {
    backgroundColor: '#F7F4F9',
  },
  scrollableContainer: {
    position: 'absolute',
    overflowY: 'auto',
    height: 'inherit',
  },
  popoverSurface: {
    ...shorthands.padding('0px'),
    height: '550px',
    width: '787px',
  },
  noticationContent: {
    justifyContent: 'space-between',
  },
  horizontal: {
    display: 'flex',
  },
  iconAiio: {
    width: '25px',
    height: '25px',
    paddingRight: '4px',
  },
  messageText: {
    paddingBottom: '1px',
    paddingLeft: '4px',
    alignSelf: 'center',
  },
  messageItemDate: {
    paddingLeft: '25px',
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase100,
    color: '#868686',
    alignSelf: 'center',
  },
  buttonMessages: {
    marginLeft: '3px',
    marginTop: '5px',
    width: '99%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonMailCheckmark: {
    ...shorthands.border('none'),
    boxShadow: 'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px',
  },
});

export const useBaseMessageItemClassname = makeResetStyles({
  fontSize: '14px',
  ...shorthands.borderBottom('1px', 'solid', '#E7E7E7'),
  ...shorthands.padding('16px'),
  cursor: 'pointer',
  width: '755px',
});

export const mailCheckIconStyleProps: FluentIconsProps = {
  primaryFill: 'black',
  className: 'iconClass',
};
