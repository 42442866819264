import { useEffect } from 'react';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { useStyles } from './checkout-styles';
import { Toggle } from '@fluentui/react';
import { LicenceGroupCard } from './licence-group-card';
import { LicenceCartCalculationCard } from './licence-cart-calculation-card';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../config/store';
import {
  initLicenceCheckOutAction,
  initNewPlanLicenceCheckOutAction,
  updateLicenceCheckOutAction,
  updateNewPlanLicenceCheckOutAction,
  initLicenceCheckOutLicenseExpiryAction,
  initNewPlanLicenceCheckOutLicenseExpiryAction,
} from '../../../store/actions/licence-check-out-actions';
import { LicencePayment } from './licence-payment';
import { useLocation } from 'react-router-dom';

const Checkout = (props: PropsFromRedux) => {
  const {
    initLicenceCheckOutAction,
    updateLicenceCheckOutAction,
    initNewPlanLicenceCheckOutAction,
    updateNewPlanLicenceCheckOutAction,
    initLicenceCheckOutLicenseExpiryAction,
    initNewPlanLicenceCheckOutLicenseExpiryAction,
    UserReducer: { isLicenseTest },
    LicenceCheckOutReducer: { paymentIntervalType },
  } = props;

  const [isAnnualBillingEnabled, setIsAnnualBillingEnabled] = React.useState<boolean>(true);
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupNames = ['administrator', 'manager', 'contributor', 'viewer'];

  useEffect(() => {
    const adminParam = parseInteger(queryParams.get(groupNames[0]));
    const managerParam = parseInteger(queryParams.get(groupNames[1]));
    const contributorParam = parseInteger(queryParams.get(groupNames[2]));
    const viewerParam = parseInteger(queryParams.get(groupNames[3]));
    const type = queryParams.get('type');

    if (type) {
      setIsAnnualBillingEnabled(type != 'monthly');
    }

    initLicenceCheckOutAction(
      (response) => {
        if (adminParam != 0) {
          updateLicenceCheckOutAction(groupNames[0], adminParam);
        }
        if (managerParam != 0) {
          updateLicenceCheckOutAction(groupNames[1], managerParam);
        }
        if (contributorParam != 0) {
          updateLicenceCheckOutAction(groupNames[2], contributorParam);
        }
        if (viewerParam != 0) {
          updateLicenceCheckOutAction(groupNames[3], viewerParam);
        }
      },
      (error) => {
        if (error.response.data.error_message) {
          const err = JSON.parse(error.response.data.error_message);

          if (err.code == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED) {
            initLicenceCheckOutLicenseExpiryAction();
          }
        }
      },
    );

    initNewPlanLicenceCheckOutAction(
      (response) => {
        if (adminParam != 0) {
          updateNewPlanLicenceCheckOutAction(groupNames[0], adminParam);
        }
        if (managerParam != 0) {
          updateNewPlanLicenceCheckOutAction(groupNames[1], managerParam);
        }
        if (contributorParam != 0) {
          updateNewPlanLicenceCheckOutAction(groupNames[2], contributorParam);
        }
        if (viewerParam != 0) {
          updateNewPlanLicenceCheckOutAction(groupNames[3], viewerParam);
        }
      },
      (error) => {
        if (error.response.data.error_message) {
          const err = JSON.parse(error.response.data.error_message);

          if (err.code == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED) {
            initNewPlanLicenceCheckOutLicenseExpiryAction();
          }
        }
      },
    );
  }, []);

  useEffect(() => {
    setIsAnnualBillingEnabled(paymentIntervalType != 'month');
  }, [paymentIntervalType]);

  function parseInteger(value: string | null | undefined): number {
    if (value == null) {
      return 0;
    }
    const parsed = parseInt(value, 10);
    if (isNaN(parsed)) {
      return 0;
    }
    return parsed;
  }

  function _onBillingToggleChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    setIsAnnualBillingEnabled(checked === true);
  }

  return (
    <div className={classes.container}>
      <div className={classes.licenceDiv}>
        <div>
          <div className={classes.licenceHeaderDiv}>
            <div className={classes.buyLicenceTextDiv}>
              <span>{__('Buy licenses')}</span>
            </div>
            <div className={classes.licencesTextGrid}>
              <div className={classes.chooseLicenceTextDiv}>{__('Choose licences')}</div>
              <div className={classes.billingLicenceTextDiv}>
                <Toggle
                  label={__('Billing period:')}
                  inlineLabel
                  onText={__('Annual')}
                  offText={__('Monthly')}
                  onChange={_onBillingToggleChange}
                  checked={isAnnualBillingEnabled}
                  disabled={!isLicenseTest}
                />
              </div>
            </div>
          </div>
          <div className={classes.memberShipGrid}>
            <LicenceGroupCard permissionGroup="administrator" isAnnualBillingEnabled={isAnnualBillingEnabled} />
            <LicenceGroupCard permissionGroup="manager" isAnnualBillingEnabled={isAnnualBillingEnabled} />
            <LicenceGroupCard permissionGroup="contributor" isAnnualBillingEnabled={isAnnualBillingEnabled} />
            <LicenceGroupCard permissionGroup="viewer" isAnnualBillingEnabled={isAnnualBillingEnabled} />
          </div>
        </div>
      </div>
      <div className={classes.summaryDiv}>
        {isLicenseTest ? (
          <div className={classes.summaryDivCommon} style={{ padding: '10px', width: 'fit-content' }}>
            <div className={classes.summaryHeaderTextDiv}>
              <span className={classes.summaryLicenceSpan}>{__('Summary')}</span>
              {isAnnualBillingEnabled ? (
                <span className={classes.discountLicenceSpan}>{__('10% discount from annual payment included')}</span>
              ) : (
                <> </>
              )}
            </div>
          </div>
        ) : null}

        <div className={classes.summaryDivCommon}>
          <div className={classes.pricingCalculatorDiv} style={{ opacity: !isLicenseTest ? '60%' : '100%' }}>
            {!isLicenseTest ? (
              <div className={classes.planTextDiv}>
                <span>{__('Current plan')}</span>
              </div>
            ) : null}
            <LicenceCartCalculationCard
              permissionGroup="administrator"
              isAnnualBillingEnabled={isAnnualBillingEnabled}
              isNormalPlan={true}
            />
            <LicenceCartCalculationCard
              permissionGroup="manager"
              isAnnualBillingEnabled={isAnnualBillingEnabled}
              isNormalPlan={true}
            />
            <LicenceCartCalculationCard
              permissionGroup="contributor"
              isAnnualBillingEnabled={isAnnualBillingEnabled}
              isNormalPlan={true}
            />
            <LicenceCartCalculationCard
              permissionGroup="viewer"
              isAnnualBillingEnabled={isAnnualBillingEnabled}
              isNormalPlan={true}
            />
            <div className={classes.paymentDiv}>
              <LicencePayment isAnnualBillingEnabled={isAnnualBillingEnabled} isNormalPlan={true} />
            </div>
          </div>
        </div>

        {!isLicenseTest ? (
          <div className={classes.summaryDivCommon} style={{ borderTop: '1px solid #000000' }}>
            <div className={classes.newPriceCalculatorDiv}>
              <div className={classes.planTextDiv}>
                <span>{__('New plan')}</span>
              </div>
              <LicenceCartCalculationCard
                permissionGroup="administrator"
                isAnnualBillingEnabled={isAnnualBillingEnabled}
                isNormalPlan={false}
              />
              <LicenceCartCalculationCard
                permissionGroup="manager"
                isAnnualBillingEnabled={isAnnualBillingEnabled}
                isNormalPlan={false}
              />
              <LicenceCartCalculationCard
                permissionGroup="contributor"
                isAnnualBillingEnabled={isAnnualBillingEnabled}
                isNormalPlan={false}
              />
              <LicenceCartCalculationCard
                permissionGroup="viewer"
                isAnnualBillingEnabled={isAnnualBillingEnabled}
                isNormalPlan={false}
              />
              <div className={classes.paymentDiv}>
                <LicencePayment isAnnualBillingEnabled={isAnnualBillingEnabled} isNormalPlan={false} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, LicenceCheckOutReducer }: RootState) => ({
  UserReducer,
  LicenceCheckOutReducer,
});
const connector = connect(mapStateToProps, {
  initLicenceCheckOutAction,
  updateLicenceCheckOutAction,
  initNewPlanLicenceCheckOutAction,
  updateNewPlanLicenceCheckOutAction,
  initLicenceCheckOutLicenseExpiryAction,
  initNewPlanLicenceCheckOutLicenseExpiryAction,
});
export default connector(Checkout);
