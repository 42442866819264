import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  label: {
    fontWeight: tokens.fontSizeBase400,
    fontSize: '14px',
    marginTop: '12px',
    marginBottom: '5px',
    color: '#323130',
    display: 'inline-block',
  },
  customLineColor: {
    '::before': {
      ...shorthands.borderColor(tokens.colorNeutralBackground2),
    },
    '::after': {
      ...shorthands.borderColor(tokens.colorNeutralBackground2),
    },
    marginBottom: '12px',
    marginTop: '12px',
  },
  wrapper: {
    marginTop: '8px',
  },
  deleteButton: {
    width: '100%',
    backgroundColor: tokens.colorNeutralBackground2,
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground5,
    },
  },
});
