import React, { useEffect } from 'react';
import { ActionButton } from '@fluentui/react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import { fetchDropdownDataByType } from '../../../../store/actions/dropdown-data-actions';
import { useForm } from 'react-hook-form';
import { useBoolean } from '@fluentui/react-hooks';
import { ProcessFilterForm } from './process-filter-form';
import { getDefaultParams, setQueryParams } from './process-filter-utility/process-filter-utils';
import { addQueryParamsAction, clearQueryParamsAction } from '../../../../store/actions/process-actions';
import _ from 'lodash';
import { ProcessSearchBox } from './process-search-box';

interface ProcessFilterProps extends PropsFromRedux {
  toggleChangeParams: () => void;
  setCurrentOffset: (arg: number) => void;
  disabled?: boolean;
}

const ProcessFilter = (props: ProcessFilterProps) => {
  const {
    ProcessReducer: { params, filterValues },
    addQueryParamsAction,
    toggleChangeParams,
    setCurrentOffset,
    disabled,
  } = props;
  const [showFilter, { toggle: toggleShowFilter, setFalse: closeFilterForm }] = useBoolean(false);
  const [searchBoxChanged, { setTrue: textChanged, setFalse: textUnchanged }] = useBoolean(false);

  const { handleSubmit, control, reset } = useForm({});

  useEffect(() => {
    searchBoxChanged && handleFilterApply();
  }, [searchBoxChanged]);

  useEffect(() => {
    if (filterValues && !_.isEmpty(filterValues)) {
      reset(filterValues);
    } else {
      // without this the processName is not removed correctly
      reset({ processName: '' });
    }
  }, [filterValues]);

  const handleFilterApply = () => {
    handleSubmit(
      (values) => {
        const queryParams = setQueryParams(values, params);
        addQueryParamsAction(queryParams);
        toggleChangeParams();
        setCurrentOffset(0);
        closeFilterForm();
        textUnchanged();
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleClearFilter = () => {
    reset();
    const queryParams = getDefaultParams(params);
    addQueryParamsAction(queryParams);
    toggleChangeParams();
    closeFilterForm();
  };

  return (
    <>
      <form>
        <ProcessSearchBox name="processName" control={control} textChanged={textChanged} />
        {showFilter && (
          <ProcessFilterForm
            handleClearFilter={handleClearFilter}
            handleFilterApply={handleFilterApply}
            control={control}
            toggleShowFilter={toggleShowFilter}
          />
        )}
      </form>
      <ActionButton
        onClick={toggleShowFilter}
        iconProps={{
          iconName:
            params &&
            Object.keys(params).some((index) => Object.keys(params[index]).some((prop) => prop.includes('__')))
              ? 'FilterSolid'
              : 'Filter',
        }}
        disabled={disabled}
      />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ DropdownDataReducer, UserReducer, ProcessReducer }: RootState) => ({
  DropdownDataReducer,
  UserReducer,
  ProcessReducer,
});

const connector = connect(mapStateToProps, { addQueryParamsAction, clearQueryParamsAction, fetchDropdownDataByType });

export default connector(ProcessFilter);
