import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const searchOrgNodesDataAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `orgnodedata/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_ORG_NODES_DATA',
    cb,
  });
