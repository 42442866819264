export enum SVGNameEnum {
  AIIOORG = 'AiioOrg',
  AIIOPEOPLE = 'AiioPeople',
  CONTAINERSHAPE = 'ContainerShape',
  DELETE = 'Delete',
  DIGIBRAIN = 'DigiBrain',
  DISCARD = 'Discard',
  DOCUMENT = 'Document',
  EXTERNALPARTNERSHAPE = 'ExternalPartnerShape',
  EXTPARTNERNODEICON = 'ExtPartnerNodeIcon',
  LAPTOP = 'Laptop',
  LINK = 'Link',
  LOGOICON = 'LogoIcon',
  ORGUNITSHAPE = 'OrgUnitShape',
  PICTURESHAPE = 'PictureShape',
  PROCESSBROWSERTOGGLE = 'ProcessBrowserToggle',
  PROCESSGROUPSHAPE = 'ProcessGroupShape',
  PROCESSSHAPE = 'ProcessShape',
  ROLEHAT = 'RoleHat',
  ROLESHAPE = 'RoleShape',
  STAFFPOSITIONSHAPE = 'StaffPositionShape',
  TEXTSHAPE = 'TextShape',
  HAMMER = 'Hammer',
  ALLSEEN = 'AllSeen',
  CREDITCARD = 'CreditCard',
  SEARCH = 'Search',
  NEWNORMPLUS = 'NewNormPlus',
  FIRSTLETTERCIRCLE = 'FirstLetterCircle',
  INVOICE = 'Invoice',
}
