import { ProcessNode, Meta } from '../../ts/interfaces';

interface ProcessNodeReducerState {
  nodes: ProcessNode[];
  meta?: Meta;
  selectedNode?: ProcessNode;
}

export default function reducer(
  state: ProcessNodeReducerState = {
    nodes: [],
    meta: undefined,
    selectedNode: undefined,
  },
  action: any,
): ProcessNodeReducerState {
  switch (action.type) {
    case 'FETCH_PROCESS_NODES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        nodes: action.payload.objects,
      });
    }
    case 'SELECT_PROCESS_NODE': {
      return Object.assign({}, state, {
        selectedNode: action.payload,
      });
    }
    case 'SELECT_PROCESS_NODE_BY_ID': {
      return Object.assign({}, state, {
        selectedNode: state.nodes.filter((node) => node.id === action.payload)[0],
      });
    }
    case 'UPDATE_PROCESS_NODE': {
      return {
        ...state,
        nodes: state.nodes.map((node) => (node.id === action.payload.id ? action.payload : node)),
      };
    }
    case 'ADD_PROCESS_NODE': {
      return {
        ...state,
        nodes: state.nodes.concat(action.payload),
      };
    }
    case 'DELETE_PROCESS_NODE': {
      return {
        ...state,
        nodes: state.nodes.filter((node) => node.id !== action.payload),
      };
    }

    case 'SET_META_PROCESS_NODE': {
      return Object.assign({}, state, {
        meta: { ...state.meta, ...action.payload },
      });
    }
    default: {
      break;
    }
  }
  return state;
}
