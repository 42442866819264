import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  card: {
    width: '350px',
    height: '182px',
    ...shorthands.borderRadius('8px'),
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.borderStyle('solid'),
    ...shorthands.borderWidth('1px'),
    cursor: 'pointer',
    backgroundColor: tokens.colorNeutralBackground2,
    paddingTop: '20px',
    paddingRight: '24px',
    paddingBottom: '36px',
    paddingLeft: '24px',
    position: 'relative',
    ...shorthands.margin('20px'),
    ':hover': {
      cursor: 'pointer',
      ...shorthands.borderColor(tokens.colorNeutralForeground1),
      ...shorthands.borderStyle('solid'),
      ...shorthands.borderWidth('1px'),
    },
    ':hover *': {
      visibility: 'visible',
    },
    justifyContent: 'space-around',
  },
  cardSelected: {
    ...shorthands.borderColor(tokens.colorNeutralForeground1),
  },
  cardNotSelected: {
    ...shorthands.borderColor(tokens.colorNeutralBackground2),
  },
  textWrapper: { height: '98px' },
  title: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    color: tokens.colorBrandForeground1,
    width: '100%',
    height: '32px',
    marginBottom: '8px',
    ...shorthands.overflow('hidden'),
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
  },
  descriptionWithoutText: {
    fontStyle: 'italic',
    color: tokens.colorBrandBackground2,
    opacity: '0.4',
  },
  descriptionWithText: {
    color: tokens.colorBrandBackground2,
    opacity: '0.6',
    width: '100%',
    height: 'auto',
    overflowY: 'scroll',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    '& > *': {
      ...shorthands.margin('0px'),
    },
  },
  openButton: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: '15px',
    right: '15px',
    color: tokens.colorBrandForeground1,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: tokens.colorNeutralStroke1Hover,
  },
  logoContainer: { width: '160px', height: '72px', display: 'flex', alignItems: 'center' },
  addCircleIcon: {
    marginRight: '5px',
    height: '48px',
    width: '48px',
    color: tokens.colorNeutralStroke2,
  },
});
