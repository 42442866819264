import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchDropdownDataByType = (data?: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `dropdowndata/fetchdropdowndata/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'FETCH_DROPDOWN_DATA_BY_TYPE',
    reqPayload: data,
    cb,
  });
