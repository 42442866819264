import React, { useEffect, useState } from 'react';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { Modal, TextField } from 'modeling-tool';
import { useForm } from 'react-hook-form';
import { ResourceGroup } from '../../../ts/interfaces/models/resourcegroup';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import {
  updateResourceGroupAction,
  clearSelectResourceGroupAction,
} from '../../../store/actions/resource-group-actions';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';

interface IResourceGroupForm extends PropsFromRedux {
  setModalVisible(arg: boolean): void;
  isModalVisible: boolean;
  add?: (resourcegroup: ResourceGroup, cb: () => void) => void;
  update?: (resourcegroup: ResourceGroup, cb?: () => void) => void;
}

const dropDownData = [GLOBALENUMS.DROPDOWNDATA.RESOURCEGROUPS];

const ResourceGroupForm = (props: IResourceGroupForm) => {
  const {
    ResourceGroupReducer: { selectedResourcegroup },
    isModalVisible,
    add,
    clearSelectResourceGroupAction,
    fetchDropdownDataByType,
    setModalVisible,
    updateResourceGroupAction,
  } = props;

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    if (selectedResourcegroup) {
      const data = {
        ...selectedResourcegroup,
      };
      reset(data);
      return () => {
        reset();
        clearSelectResourceGroupAction();
      };
    }
  }, [selectedResourcegroup]);

  const handleResourceGroupsCancel = () => {
    clearSelectResourceGroupAction();
    setModalVisible(false);
    reset();
  };

  const onFinish = (values: any) => {
    values = {
      ...values,
    };
    if (selectedResourcegroup?.id) {
      values = { ...values, id: selectedResourcegroup.id };
      updateResourceGroupAction(values, () => {
        setShowLoading(false);
        setModalVisible(false);
        clearSelectResourceGroupAction();
        reset();
      });
    } else {
      add &&
        add(values, () => {
          setShowLoading(false);
          setModalVisible(false);
          reset();
        });
    }
  };

  const handleResourceGroupsOk = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
        setModalVisible(false);
        fetchDropdownDataByType(dropDownData);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  return (
    <Modal
      title={!selectedResourcegroup ? __('create new resource group') : __('edit resource group name')}
      isModalOpen={isModalVisible}
      onCancel={handleResourceGroupsCancel}
      onSave={handleResourceGroupsOk}
      enableProgress={showLoading}
      saveButtonText={__('save')}
    >
      <form>
        <TextField
          label={__('new resource group name')}
          control={control}
          name={'name'}
          required={true}
          rules={{ required: __('This field is required') }}
        />
      </form>
    </Modal>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ResourceGroupReducer }: RootState) => ({ ResourceGroupReducer });

const connector = connect(mapStateToProps, {
  clearSelectResourceGroupAction,
  fetchDropdownDataByType,
  updateResourceGroupAction,
});

export default connector(ResourceGroupForm);
