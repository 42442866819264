import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  wrapper: {
    height: 'calc(100vh - 304px)',
    position: 'relative',
    maxHeight: 'inherit',
    ...shorthands.borderLeft('0px'),
    marginTop: '10px',
  },
  permissionListContainer: {
    height: 'calc(100vh - 240px)',
  },
});
