import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  activityListItem: {
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground5),
    ...shorthands.padding('22px', '0px', '22px', '0px'),
    cursor: 'pointer',
  },
  departmentActivities: {
    height: '510px',
    maxWidth: '100%',
    ...shorthands.overflow('auto'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground5),
    backgroundColor: '#FAF9F8',
    ...shorthands.padding('24px', '24px', '0px', '24px'),
  },
  departmentActivitiesTitle: {
    fontSize: tokens.fontSizeBase400,
    ...shorthands.margin('0'),
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorBrandBackground,
  },
  departmentActivitiesText: {
    color: 'red',
    fontSize: tokens.fontSizeBase200,
    marginRight: '10px',
    '--avatar-size': '40px',
  },
  userNameDateStack: {
    marginLeft: '1px',
  },
  userNamePersonText: {
    fontWeight: tokens.fontWeightBold,
  },
  activityType: {
    ...shorthands.padding('4px', '8px', '4px', '8px'),
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
    marginRight: '10px',
  },
  activityTypeProcess: {
    backgroundColor: '#F7F4FB',
    color: tokens.colorBrandForeground1,
  },
  activityTypeEmployee: {
    backgroundColor: '#09CE641A',
    color: '#09CE64',
  },
  descriptionSpan: {
    ...shorthands.padding('8px', '0px', '0px', '0px'),
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightRegular,
  },
  listWrapper: {
    height: '460px',
    position: 'relative',
    maxHeight: 'inherit',
    overflowY: 'auto',
  },
  horizontalAlign: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
