import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import {
  Dashboard,
  Index,
  OrganizationCharts,
  ProcessManagement,
  ProcessDetails,
  ProcessApplications,
  Error,
  Login,
  TasksDetail,
  Employee,
  Tasks,
  ProcessModeler,
  Settings,
  TasksApproval,
  Welcome,
  VendorDashboard,
  Resources,
  Locations,
  ProcessMapAppModeler,
  Checkout,
  Requirements,
  RequirementChapterList,
  OrganizationModeler,
  InitialDataConfigurationDashboard,
} from './pages';
import { AppLayout, ContentLayout } from './layouts';
import {
  getCurrentUserAction,
  setUserAdminsAction,
  getCurrentUserLicenseExpiredAction,
} from './store/actions/user-actions';
import { useBoolean } from '@fluentui/react-hooks';
import { RootState } from './config/store';
import { IsTeamsApp } from './config/config';
import { useTeams } from 'msteams-react-base-component';
import { GLOBALENUMS } from 'modeling-tool';

const Router = (props: PropsFromRedux) => {
  const {
    UserReducer: { isAdmin, isLicenseTest },
    getCurrentUserAction,
    setUserAdminsAction,
    getCurrentUserLicenseExpiredAction,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [isUser, { setTrue: setIsUser }] = useBoolean(false);
  const [errorCode, setErrorCode] = useState<string>();
  const [showContentOnly, setShowContentOnly] = useState<boolean>(false);

  useEffect(() => {
    setLayout();
    getCurrentUserAction(
      () => {
        setIsUser();
      },
      (error) => {
        if (error.response.data.error_message) {
          const err = JSON.parse(error.response.data.error_message);
          if (err.admins) {
            setUserAdminsAction(err.admins);
          }

          if (err.code == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED) {
            getCurrentUserLicenseExpiredAction(() => {
              setErrorCode(err.code);
            });
            setIsUser();
          } else {
            setErrorCode(err.code);
          }
        }
      },
    );
  }, []);

  const setLayout = () => {
    if (location.search.indexOf('contentonly') > 0) {
      sessionStorage.setItem('contentOnly', 'true');
    }

    if (location.search.indexOf('contentonly') > 0 || IsTeamsApp()) {
      setShowContentOnly(true);
    } else {
      sessionStorage.removeItem('contentOnly');
    }
  };

  // teams related redirection
  const [{ inTeams, context }] = useTeams();
  useEffect(() => {
    if (context && context.page && context.page.subPageId) {
      const [type, id] = context.page.subPageId.split(';');

      switch (type) {
        case 'approval':
          navigate(`/tasks/approval/${id}`);
          break;
        case 'task':
          navigate(`/tasks/process-recording/${id}`);
      }
    }
  }, [context]);

  const renderRoutes = () => {
    return [
      <Route key="root" path="" element={<Dashboard />} />,
      <Route key="root_home" path="home" element={<Dashboard />} />,
      <Route key="vendor" path="vendor-dashboard" element={<VendorDashboard />} />,
      <Route key="initial_data" path="initial-data" element={<InitialDataConfigurationDashboard />} />,

      <Route key="organization" path="organization">
        <Route path="" element={<OrganizationCharts />} />
        <Route path=":id" element={<OrganizationModeler />} />
      </Route>,

      <Route key="process-management" path="process-management">
        <Route path="" element={<ProcessManagement />} />
        <Route path="processmap">
          <Route path="" element={<ProcessManagement />} />
          <Route path=":processMapId/*" element={<ProcessMapAppModeler key={window.location.pathname} />} />
        </Route>
        <Route path="processes">
          <Route path="" element={<ProcessManagement />} />
          {/* <Route path=":id" element={<ProcessDetails />} /> */}
          <Route path=":id" element={<ProcessDetails />} />
          <Route path=":id/applications" element={<ProcessApplications />} />
          {/* <Route path=":pid/modeling-tool/" render={() => <Navigate to="process-management/processes/" />} /> */}
          <Route path=":pid/modeling-tool/:id" element={<ProcessModeler />} />
        </Route>
      </Route>,

      <Route key="tasks" path="tasks">
        <Route path="" element={<Tasks />} />
        <Route path="process-recording" element={<Tasks />} />
        <Route path="approval" element={<Tasks />} />
        <Route path="process-recording/:id" element={<TasksDetail />} />
        <Route path="approval/:id" element={<TasksApproval />} />
      </Route>,

      <Route key="requirements" path="requirements">
        <Route path="" element={<Requirements />} />
        <Route path=":id" element={<RequirementChapterList />} />
        <Route path="rules-group" element={<Requirements />} />
      </Route>,

      <Route key="employee" path="employee" element={<Employee />}></Route>,

      <Route key="modeling-tool" path="modeling-tool">
        <Route path=":id" element={<ProcessModeler />} />
      </Route>,

      <Route key="resources" path="resources">
        <Route path="" element={<Resources />} />
        <Route path="groups" element={<Resources />} />
        <Route path="resources-list" element={<Resources />} />
      </Route>,

      <Route key="settings" path="settings">
        <Route path="" element={<Settings />} />
        <Route path="checkout" element={<Checkout />} />
      </Route>,

      <Route key="location" path="/location" element={<Locations />} />,

      <Route key="login" path={'login'} element={<Login />} />,
      <Route key="welcome" path={'welcome'} element={<Welcome />} />,
      <Route key="error" path={'error/:id'} element={<Error />} />,

      <Route key="*" element={<Error />} />,
    ];
  };

  return (
    <>
      {isUser && (
        <>
          {showContentOnly && (
            <ContentLayout>
              <></>
              <Index>
                <Routes>{renderRoutes()}</Routes>
              </Index>
            </ContentLayout>
          )}
          {!showContentOnly && (
            <AppLayout>
              <></>
              <Index>
                <Routes>{renderRoutes()}</Routes>
              </Index>
            </AppLayout>
          )}
        </>
      )}
      {!isUser && (
        <Routes>
          <Route path={'login'} element={<Login />} />
          <Route path={'welcome'} element={<Welcome />} />
          <Route path={'error/:id'} element={<Error />} />
        </Routes>
      )}

      {errorCode == GLOBALENUMS.AUTHERRORCODE.NO_TENANT && <Navigate to={'welcome'} />}
      {errorCode == GLOBALENUMS.AUTHERRORCODE.NO_USER && <Navigate to={'error/' + errorCode} />}
      {errorCode == GLOBALENUMS.AUTHERRORCODE.LICENSE_DEACTIVATED && <Navigate to={'error/' + errorCode} />}
      {errorCode == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED &&
        isAdmin &&
        isLicenseTest &&
        !location.pathname.includes('/settings/checkout') && <Navigate to={'error/' + errorCode} />}
      {errorCode == GLOBALENUMS.AUTHERRORCODE.LICENSE_EXPIRED && (!isAdmin || !isLicenseTest) && (
        <Navigate to={'error/' + errorCode} />
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer }: RootState) => ({ UserReducer });
const connector = connect(mapStateToProps, {
  getCurrentUserAction,
  setUserAdminsAction,
  getCurrentUserLicenseExpiredAction,
});
export default connector(Router);
