import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';

import { ProcessRecordModeler, ApprovedByModal } from '../../components';
import { RootState } from '../../config/store';
import { getRACIFieldsFormObject, getRACIFieldsObject } from '../../config/utils';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { ProcessInstance, ProcessInstanceFormValues } from '../../ts/interfaces';
import {
  getProcessInstanceAction,
  selectProcessInstanceAction,
  updateProcessInstanceAction,
} from '../../store/actions/process-instance-actions';
import { useParams } from 'react-router';

const TasksApproval = (props: PropsFromRedux) => {
  const {
    ProcessInstanceReducer: { selectedInstance },
    getProcessInstanceAction,
    selectProcessInstanceAction,
    updateProcessInstanceAction,
  } = props;

  const alert = useAlert();
  const [isApprovedByModalVisible, setIsApprovedByModalVisible] = useState<boolean>(false);
  const [instanceFormValues, setInstanceFormValues] = useState<ProcessInstanceFormValues>(
    {} as ProcessInstanceFormValues,
  );

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const params = useParams();

  useEffect(() => {
    if (params && params.id) {
      selectProcessInstanceAction({});
      getProcessInstanceAction(parseInt(params.id as string));
    }
  }, []);

  useEffect(() => {
    if (selectedInstance) {
      const selected = selectedInstance;
      if (selected) {
        reset({});
        const data = {
          instanceName: selected.instanceName || null,
          message: selected.message || null,
          participant: selected.participant || null,
          documents: selected.documents || null,
          resources: selected.resources || null,
          goals: selected.goals || null,
          description: selected.description || null,
          days: selected.duration?.split('-')[0] || null,
          hours: selected.duration?.split('-')[1] || null,
          minutes: selected.duration?.split('-')[2] || null,
          ...getRACIFieldsFormObject(selected),
        };
        reset(data);
      }
    }
  }, [selectedInstance]);

  const onApprove = () => {
    handleSubmit(
      (data) => {
        setInstanceFormValues(data as ProcessInstanceFormValues);
      },
      (err) => {
        console.log(err);
      },
    )();
    setIsApprovedByModalVisible(true);
  };

  const onSave = () => {
    handleSubmit(
      (data) => {
        let values = data as ProcessInstanceFormValues;
        setInstanceFormValues(values);
        const { days, hours, minutes } = values;
        const durationString = [days, hours, minutes].join('-');

        const raciValues = getRACIFieldsObject(values, selectedInstance);
        values = { ...values, ...raciValues };
        delete values['approvedBy'];
        delete values['approvedByDepartments'];
        delete values['approvedByRoles'];
        updateProcessInstanceAction(
          {
            id: selectedInstance?.id,
            duration: durationString,
            isInitialApproval: false,
            approvedBy: selectedInstance?.approvedBy,
            ...values,
          },
          () => {
            alert.success(__('process instance was saved successfully!'));
          },
        );
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleApprovedByCancel = () => {
    setIsApprovedByModalVisible(false);
  };
  return (
    <>
      {selectedInstance && <ProcessRecordModeler control={control} isTaskDetailsView={true} onApprove={onApprove} />}
      {isApprovedByModalVisible && selectedInstance && (
        <ApprovedByModal
          selectedInstance={selectedInstance as ProcessInstance}
          isApprovedByModalVisible={isApprovedByModalVisible}
          handleApprovedByCancel={handleApprovedByCancel}
          instanceFormValues={instanceFormValues}
        />
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessInstanceReducer, ProcessReducer }: RootState) => ({
  ProcessInstanceReducer,
  ProcessReducer,
});
const connector = connect(mapStateToProps, {
  selectProcessInstanceAction,
  getProcessInstanceAction,
  updateProcessInstanceAction,
});
export default connector(TasksApproval);
