import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { __ } from 'modeling-tool';
import { PrimaryButton, ScrollablePane, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { getStyles } from './requirements-list-styles';
import { checkPermission } from '../../../config/permission-utils';
import { GLOBALENUMS } from 'modeling-tool';
import { Requirement } from '../../../ts/interfaces';
import { useId } from '@fluentui/react-hooks';
import { useNavigate } from 'react-router';
import {
  selectSelectedRequirementByIdAction,
  clearRequirementsAction,
  clearSelectedRequirement,
  selectOpenRequirementByIdAction,
  addRequirementAction,
  fetchRequirementsWithProcessAction,
} from '../../../store/actions/requirement-action';
import { RequirementTile } from './requirement-tile';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';

interface NormsProps {
  renderDetailsBar: (value: Requirement) => void;
}

const dropDownData = [
  GLOBALENUMS.DROPDOWNDATA.PROCESSES,
  GLOBALENUMS.DROPDOWNDATA.REQUIREMENT_CHAPTERS,
  GLOBALENUMS.DROPDOWNDATA.ROLES,
  GLOBALENUMS.DROPDOWNDATA.ORG_UNITS,
  GLOBALENUMS.DROPDOWNDATA.STAFF_POSITIONS,
  GLOBALENUMS.DROPDOWNDATA.EXTERNAL_PARTNERS,
];

const RequirementsList = (props: PropsFromRedux & NormsProps) => {
  const {
    RequirementReducer: { requirements, meta },
    UserReducer,
    addRequirementAction,
    clearRequirementsAction,
    clearSelectedRequirement,
    fetchRequirementsWithProcessAction,
    selectSelectedRequirementByIdAction,
    selectOpenRequirementByIdAction,
    renderDetailsBar,
    fetchDropdownDataByType,
  } = props;

  const [selectedRequirement, setSelectedRequirement] = useState<Requirement | undefined>(undefined);
  const [loadingDone, setLoadingDone] = useState<boolean>(false);

  const navigate = useNavigate();
  const styles = getStyles();

  useEffect(() => {
    fetchRequirementsWithProcessAction(() => {
      setLoadingDone(true);
    });
    fetchDropdownDataByType(dropDownData);
    return () => {
      clearRequirementsAction();
      clearSelectedRequirement();
    };
  }, []);

  const onClickAdd = () => {
    clearSelectedRequirement();
  };

  const actions = [];
  {
    if (checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_requirement)) {
      const buttonId = useId('add-button');
      actions.push(
        <PrimaryButton
          key={buttonId}
          iconProps={{ iconName: 'Add' }}
          className={styles.addButton}
          allowDisabledFocus
          onClick={onClickAdd}
        >
          {__('new')}
        </PrimaryButton>,
      );
    }
  }

  if (!meta) {
    return <Spinner size={SpinnerSize.large} />;
  }

  const selectRequirement = (record: number) => {
    selectOpenRequirementByIdAction(record);
    navigate(`/requirements/${record}`);
  };

  const handleClick = (record: Requirement) => {
    selectSelectedRequirementByIdAction(record.id);
    renderDetailsBar(record);
  };

  const handleNewClick = () => {
    const values = {
      name: '',
      description: '',
      linkedProcesses: [],
      linkedRequirementChapters: [],
    };
    addRequirementAction(values);
  };

  return (
    <>
      <div className={styles.cardsWrappedDiv}>
        <section className={styles.wrapper}>
          <ScrollablePane>
            <Stack className={styles.normGrid}>
              {loadingDone &&
                requirements &&
                requirements.map((requirement) => (
                  <RequirementTile
                    requirement={requirement}
                    selectedRequirement={selectedRequirement}
                    setSelectRequirementTile={setSelectedRequirement}
                    onSelectRequirement={() => handleClick(requirement)}
                    onOpenRequirement={() => selectRequirement(requirement.id)}
                  />
                ))}
              {loadingDone && <RequirementTile onNewRequirement={handleNewClick} />}
              {!loadingDone && <Spinner size={SpinnerSize.large} />}
            </Stack>
          </ScrollablePane>
        </section>
      </div>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ProcessReducer, RequirementReducer, UserReducer }: RootState) => ({
  ProcessReducer,
  RequirementReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  addRequirementAction,
  selectSelectedRequirementByIdAction,
  clearRequirementsAction,
  clearSelectedRequirement,
  selectOpenRequirementByIdAction,
  fetchRequirementsWithProcessAction,
  fetchDropdownDataByType,
});

export default React.memo(connector(RequirementsList));
