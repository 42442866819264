import { Meta, Resource } from '../../ts/interfaces';

interface ResourceGroupReducerState {
  resourcegroups: Resource[];
  meta?: Meta;
  selectedResourcegroup?: Resource;
}

export default function reducer(
  state: ResourceGroupReducerState = {
    resourcegroups: [],
    meta: undefined,
    selectedResourcegroup: undefined,
  },
  action: any,
): ResourceGroupReducerState {
  switch (action.type) {
    case 'FETCH_RESOURCEGROUPS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        resourcegroups: action.payload.objects,
      });
    }
    case 'FETCH_RESOURCEGROUP': {
      return Object.assign({}, state, {
        selectedResourcegroup: action.payload,
      });
    }
    case 'CLEAR_RESOURCEGROUP': {
      return Object.assign({}, state, {
        selectedResourcegroup: undefined,
      });
    }
    case 'ADD_RESOURCEGROUP': {
      return {
        ...state,
        resourcegroups: state.resourcegroups.concat(action.payload),
      };
    }
    case 'UPDATE_RESOURCEGROUP': {
      return {
        ...state,
        resourcegroups: state.resourcegroups.map((resourcegroup) =>
          resourcegroup.id === action.payload.id ? action.payload : resourcegroup,
        ),
      };
    }
    case 'SELECT_RESOURCEGROUP_BY_ID': {
      return Object.assign({}, state, {
        selectedResourcegroup: state.resourcegroups.find((resourcegroup) => resourcegroup.id === action.payload),
      });
    }
    case 'CLEAR_RESOURCEGROUP': {
      return Object.assign({}, state, {
        selectedResourcegroup: undefined,
      });
    }
    case 'DELETE_RESOURCEGROUP': {
      return {
        ...state,
        resourcegroups: state.resourcegroups.filter((resourcegroup) => resourcegroup.id !== action.payload),
      };
    }
    default: {
      break;
    }
  }
  return state;
}
