import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchWorkingGroupsAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `workinggroup/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_WORKING_GROUPS',
    cb,
  });

export const updateWorkingGroupAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `workinggroup/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PUT,
    action: 'UPDATE_WORKING_GROUP',
    reqPayload: data,
    cb,
  });

export const addWorkingGroupAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'workinggroup/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_WORKING_GROUP',
    reqPayload: data,
    cb,
  });

export const selectWorkingGroupByIdAction = (id: number | boolean) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_WORKING_GROUP_BY_ID',
    payload: id,
  });

export const deleteWorkingGroupAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `workinggroup/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_WORKING_GROUP',
    payload: data.id,
    cb,
  });
