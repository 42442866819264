import { Employee, Meta } from '../../ts/interfaces';

export interface EmployeeReducerState {
  employees: Employee[];
  meta?: Meta;
  selectedEmployee?: Employee;
}

export default function reducer(
  state: EmployeeReducerState = {
    employees: [],
    meta: undefined,
    selectedEmployee: undefined,
  },
  action: any,
): EmployeeReducerState {
  switch (action.type) {
    case 'FETCH_EMPLOYEES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        employees: action.payload.objects,
      });
    }
    case 'SEARCH_EMPLOYEES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        employees: action.payload.objects,
      });
    }
    case 'CLEAR_EMPLOYEES': {
      return Object.assign({}, state, {
        meta: undefined,
        employees: [],
      });
    }
    case 'FETCH_EMPLOYEES_BY_GROUP': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        employees: action.payload.objects,
      });
    }
    case 'ADD_EMPLOYEE': {
      return {
        ...state,
        employees: state.employees.concat(action.payload),
      };
    }
    case 'ADD_EMPLOYEE_BULK': {
      return {
        ...state,
        employees: state.employees.concat(action.payload),
      };
    }
    case 'UPDATE_EMPLOYEE': {
      return {
        ...state,
        employees: state.employees.map((process) => (process.id === action.payload.id ? action.payload : process)),
      };
    }
    case 'SELECT_EMPLOYEE_BY_ID': {
      return Object.assign({}, state, {
        selectedEmployee: state.employees.filter((employee) => employee.id === action.payload)[0],
      });
    }
    case 'SET_META_EMPLOYEE': {
      return Object.assign({}, state, {
        meta: { ...state.meta, ...action.payload },
      });
    }
    default: {
      break;
    }
  }
  return state;
}
