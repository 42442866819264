import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const searchProcessVersionsAction = (search: string, cb: (...args: any[]) => void) =>
  UseAction({
    url: `process-version/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_PROCESSES_VERSIONS',
    cb,
  });
