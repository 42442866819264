import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    
    textClickStyles: {
      width: '300px',
      textAlign: 'center',
    },
    helpBtn: {
      paddingTop: '10px',
    },
  });
});