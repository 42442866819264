import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp, isSidebarOpen: boolean) => {
  const { theme } = props;
  return mergeStyleSets({
    buttonSection: { paddingRight: 12, root: { width: 'auto' } },
    appLayout: {
      marginLeft: props.isTeamsApp ? '51px' : '0px',
      backgroundColor: `${theme.palette.white} !important`,
      height: `calc(100vh)`,
    },
    container: {
      width: isSidebarOpen ? 'calc(100vw - 365px)' : '100%',
    },
    mainBackground: {
      backgroundColor: theme.palette.white,
    },
    componentContainer: {
      padding: '0px',
      border: 'initial',
      width: '100%',
      height: `calc(100vh - 40px)`,
    },
    utilitySideBar: {
      position: 'fixed',
      right: '0px',
      height: `calc(100vh)`,
      borderLeft: '1px solid rgba(112, 112, 112, 0.14)',
      backgroundColor: '#fff !important',
    },
    breadCrumbStack: {
      width: '100%',
    },
  });
});
