import { Checkbox, Label, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '../../../components';
import { Tenant } from '../../../ts/interfaces';
import { ConnectedProps, connect } from 'react-redux';
import { Modal, SpinButton, TextField } from 'modeling-tool';
import { __ } from 'modeling-tool';
import moment from 'moment';
import { RootState } from '../../../config/store';
import { updateLicenseAction } from '../../../store/actions/tenant-dasboard-actions';
interface ITenantEditForm extends PropsFromRedux {
  tenant?: Tenant;
  visible: boolean;
  setVisible: (arg: boolean) => void;
}

const TenantEditForm = (props: ITenantEditForm) => {
  const { tenant, visible, setVisible, updateLicenseAction } = props;
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isActiveChecked, setActiveIsChecked] = React.useState<boolean>(false);
  const [isPurchasedChecked, setIsPurchasedChecked] = React.useState<boolean>(false);
  const [isTerminatedChecked, setIsTerminatedChecked] = React.useState<boolean>(false);

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const watchFields = watch(['contributorLimit', 'managerLimit', 'administratorLimit']);

  useEffect(() => {
    reset({});
    if (tenant) {
      const { license } = tenant;
      const data = {
        title: tenant.title,
        description: license.description,
        stripeId: tenant.stripeId,
        isActive: license.isActive,
        purchased: license.purchased,
        terminated: license.terminated,
        validUntil: new Date(license.validUntil),
        contractStartDate: new Date(license.contractStartDate),
        viewerLimit: license.viewerLimit,
        contributorLimit: license.contributorLimit,
        managerLimit: license.managerLimit,
        administratorLimit: license.administratorLimit,
        aiValue: license.aiLimitValue,
        aiLimit: license.aiLimitDefault,
      };

      setActiveIsChecked(license.isActive);
      setIsPurchasedChecked(license.purchased);
      setIsTerminatedChecked(license.terminated);

      reset(data);
    }
  }, [tenant]);

  useEffect(() => {
    let new_ai_limit = 0;
    watchFields.forEach((item: any, i: number) => {
      if (item != -1) {
        new_ai_limit += item * 20;
      }
    });

    setValue('aiLimit', new_ai_limit);
  }, [watchFields]);

  const onChangeActive = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, checked?: boolean) => {
    setActiveIsChecked(!!checked);
  }, []);

  const onChangePurchased = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, checked?: boolean) => {
    setIsPurchasedChecked(!!checked);
  }, []);

  const onChangeTerminated = React.useCallback((ev?: React.SyntheticEvent<HTMLElement>, checked?: boolean) => {
    setIsTerminatedChecked(!!checked);
  }, []);

  const handleTenantEditOk = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const onFinish = (values: any) => {
    if (tenant) {
      const { license } = tenant;
      const {
        description,
        stripeId,
        validUntil,
        contractStartDate,
        viewerLimit,
        contributorLimit,
        managerLimit,
        administratorLimit,
        aiValue,
        aiLimit,
      } = values;

      const data = {
        id: license.id,
        isActive: isActiveChecked,
        purchased: isPurchasedChecked,
        terminated: isTerminatedChecked,
        description,
        stripeId,
        validUntil: moment(validUntil).format('YYYY-MM-DDThh:mm:ss'),
        contractStartDate: moment(contractStartDate).format('YYYY-MM-DDThh:mm:ss'),
        viewerLimit,
        contributorLimit,
        managerLimit,
        administratorLimit,
        aiLimitValue: aiValue,
        aiLimitDefault: aiLimit,
      };

      updateLicenseAction(
        data,
        () => {
          setShowLoading(false);
          setVisible(false);
        },
        (error) => {
          setShowLoading(false);
          setVisible(false);
        },
      );
    }
  };

  const handleTenantEditCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal
        title={'Tenant edit form'}
        isModalOpen={visible}
        onSave={handleTenantEditOk}
        onCancel={handleTenantEditCancel}
        enableProgress={showLoading}
      >
        <form>
          <TextField label={__('tenant name')} control={control} name={'title'} disabled />
          <TextField label={__('description')} control={control} name={'description'} multiline />
          <TextField label={__('stripe customer id')} control={control} name={'stripeId'} />
          <Label>{__('valid until')}</Label>
          <DatePicker control={control} name={__('validUntil')} />
          <Label>{__('contract start date')}</Label>
          <DatePicker control={control} name={__('contractStartDate')} />
          <Label>{__('is active')}</Label>
          <Checkbox checked={isActiveChecked} onChange={onChangeActive}></Checkbox>
          <Label>{__('purchased')}</Label>
          <Checkbox checked={isPurchasedChecked} onChange={onChangePurchased}></Checkbox>
          <Label>{__('terminated')}</Label>
          <Checkbox checked={isTerminatedChecked} onChange={onChangeTerminated}></Checkbox>
          <SpinButton label={__('Viewer limit')} control={control} name={'viewerLimit'} />
          <SpinButton label={__('Contributor limit')} control={control} name={'contributorLimit'} />
          <SpinButton label={__('Manager limit')} control={control} name={'managerLimit'} />
          <SpinButton label={__('Administrator limit')} control={control} name={'administratorLimit'} />
          <Label>{__('process optimization')}</Label>
          <Stack horizontal>
            <SpinButton label={__('ai limit')} control={control} name={'aiValue'} />
            <SpinButton label={__('ai limit default')} control={control} name={'aiLimit'} />
          </Stack>
        </form>
      </Modal>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ TenantDashboardReducer }: RootState) => ({ TenantDashboardReducer });
const connector = connect(mapStateToProps, {
  updateLicenseAction,
  // fetchTenantsBuyerAction,
  // fetchTenantsAction,
});
export default connector(TenantEditForm);
