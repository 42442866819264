import { GLOBALENUMS } from 'modeling-tool';
import { ProcessInstance } from '../../ts/interfaces';
import { UseAction, UseRequestOnlyAction } from './base-actions';

export const fetchProcessInstancesAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processinstance/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_PROCESS_INSTANCES',
    cb,
  });

export const searchDirectProcessInstancesAction = (search: string, cb?: (...args: any[]) => void) =>
  UseRequestOnlyAction({
    url: `processinstance/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    cb,
  });

export const searchProcessInstancesAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processinstance/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_PROCESS_INSTANCES',
    cb,
  });

export const searchHistoryProcessInstancesAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processinstance/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_PROCESS_HISTORY_INSTANCES',
    cb,
  });

export const getProcessInstanceAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processinstance/${id}/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_PROCESS_INSTANCE',
    cb,
  });

export const selectProcessInstanceAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_PROCESS_INSTANCE',
    payload: data,
  });

export const selectProcessInstanceByIdAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_PROCESS_INSTANCE_BY_ID',
    payload: id,
    cb,
  });

export const selectProcessInstancesAction = (ids: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_PROCESS_INSTANCES',
    payload: ids,
  });

export const setProcessInstancesFromTaskAction = (task?: ProcessInstance) =>
  UseAction({
    normalAction: true,
    action: 'SET_PROCESS_INSTANCE_FROM_TASK',
    payload: task,
  });

export const addSelectedProcessInstancesAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'ADD_SELECT_PROCESS_INSTANCES',
    payload: data,
  });

export const updateAcceptedProcessInstancesAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'UPDATE_ACCEPTED_PROCESS_INSTANCE',
    payload: data,
  });

export const updateSelectedProcessInstanceAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'UPDATE_SELECTED_PROCESS_INSTANCE',
    payload: data,
  });

export const updateProcessInstanceAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processinstance/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_PROCESS_INSTANCE',
    reqPayload: data,
    cb,
  });

export const addProcessInstanceAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'processinstance/',
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'ADD_PROCESS_INSTANCE',
    reqPayload: data,
    cb,
  });

export const deleteProcessInstanceAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processinstance/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_PROCESS_INSTANCE',
    payload: data.id,
    cb,
  });

export const setMetaAction = (payload: any, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SET_META_PROCESS_INSTANCE',
    payload: payload,
    cb,
  });

export const clearProcessInstancesAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_PROCESS_INSTANCES',
    cb,
  });

export const clearProcessInstanceAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_PROCESS_INSTANCE',
    cb,
  });
