import { Meta, Role } from '../../ts/interfaces';

export interface RolesReducerState {
  roles: Role[];
  meta?: Meta;
}

export default function reducer(
  state: RolesReducerState = {
    roles: [],
    meta: undefined,
  },
  action: any,
): RolesReducerState {
  switch (action.type) {
    case 'FETCH_ROLES': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        roles: action.payload.objects,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
