import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchMenusAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `menu/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_MENUS',
    cb,
  });
