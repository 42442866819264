export const annualLicensePriceDetails = {
    "administrator": 238.80,
    "manager": 154.80,
    "contributor": 82.80,
    "viewer": 10.80,
};

export const monthlyLicensePriceDetails = {
    "administrator": 22.11,
    "manager": 14.33,
    "contributor": 7.66,
    "viewer": 1.00,
};