import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  return mergeStyleSets({
    cell: {
      width: '100%',
    },
    header: {
      span: { paddingLeft: '10px' },
      border: '1p solid red',
    },
    name: {
      paddingLeft: '0px !important',
    },
    nameContainer: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    spinnerOverlay: {
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: '0.5',
      zIndex: '90000',
    },
    spinner: {
      position: 'fixed',
      left: '50%',
      top: '50%',
    },
    listContainer: {
      paddingTop: 14,
      paddingLeft: 15,
      height: 'calc(100vh - 210px)',
      position: 'relative',
      '& .ms-List-cell': { minHeight: '0px !important' },
    },
    iconContainer: {
      position: 'absolute',
      right: '0px',
    },
    iconButton: {
      '&:hover': {
        cursor: 'pointer',
        background: '#E6E6E6',
        color: '#800080',
      },
    },
    iconButtonHidden: {
      visibility: 'hidden',
    },

    nameStack: {
      height: 'auto',
      '& a:link': {
        textDecoration: 'none',
        color: props.theme.palette.themePrimary,
      },
    },
    requirementName: { fontSize: 24, fontWeight: 600, marginBottom: 15 },
    fileDisplay: {
      '--font-size': '14px',
      '--line2-font-size': '9px',
    },
  });
});
