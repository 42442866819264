import {
  Department,
  ExternalPartner,
  Location,
  ModelerNodeType,
  Process,
  ProcessGroup,
  Requirement,
  RequirementChapter,
  Resource,
  ResourceGroup,
  Role,
  StaffPosition,
  Status,
  WorkingGroup,
} from '../../ts/interfaces';

interface DropdownDataReducerState {
  locations: Location[];
  nodeTypes: ModelerNodeType[];
  orgUnits: Department[];
  orgUnitsAndStaffPositions: any[];
  status: Status[];
  processes: Process[];
  processesDone: Process[];
  processesUndone: Process[];
  processGroups: ProcessGroup[];
  processGroupsAndDoneProcesses: Process[];
  requirementChapters: RequirementChapter[];
  requirements: Requirement[];
  resourceGroups: ResourceGroup[];
  resources: Resource[];
  roles: Role[];
  workingGroups: WorkingGroup[];
  externalPartners: ExternalPartner[];
  staffPositions: StaffPosition[];
}

export default function reducer(
  state: DropdownDataReducerState = {
    locations: [],
    nodeTypes: [],
    orgUnits: [],
    orgUnitsAndStaffPositions: [],
    status: [],
    processes: [],
    processesDone: [],
    processesUndone: [],
    processGroups: [],
    processGroupsAndDoneProcesses: [],
    requirementChapters: [],
    requirements: [],
    resourceGroups: [],
    resources: [],
    roles: [],
    workingGroups: [],
    externalPartners: [],
    staffPositions: [],
  },
  action: any,
): DropdownDataReducerState {
  switch (action.type) {
    case 'FETCH_DROPDOWN_DATA_BY_TYPE': {
      return Object.assign({}, state, {
        locations: action.payload.locations || [],
        nodeTypes: action.payload.nodeTypes || [],
        norms: action.payload.norms || [],
        orgUnits: action.payload.departments || [],
        orgUnitsAndStaffPositions: action.payload.orgUnitsAndStaffPositions || [],
        status: action.payload.status || [],
        processes: action.payload.processes || [],
        processesDone: action.payload.processesDone || [],
        processesUndone: action.payload.processesUndone || [],
        processGroups: action.payload.processGroups || [],
        processGroupsAndDoneProcesses: action.payload.processGroupsAndDoneProcesses || [],
        requirementChapters: action.payload.requirementChapters || [],
        requirements: action.payload.requirements || [],
        resourceGroups: action.payload.resourceGroups || [],
        resources: action.payload.resources || [],
        roles: action.payload.roles || [],
        workingGroups: action.payload.workingGroups || [],
        externalPartners: action.payload.externalPartners || [],
        staffPositions: action.payload.staffPositions || [],
      });
    }
    default: {
      break;
    }
  }
  return state;
}
