import { UserComment, Meta } from '../../ts/interfaces';

export interface UserCommentReducerState {
  comments: UserComment[];
  meta?: Meta;
  selectedComment?: UserComment;
}

export default function reducer(
  state: UserCommentReducerState = {
    comments: [],
    meta: undefined,
    selectedComment: undefined,
  },
  action: any,
): UserCommentReducerState {
  switch (action.type) {
    case 'FETCH_COMMENTS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        comments: action.payload.objects,
      });
    }
    case 'FETCH_INSTANCE_COMMENTS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        comments: action.payload.objects,
      });
    }
    case 'ADD_COMMENT': {
      return {
        ...state,
        comments: state.comments.concat(action.payload),
      };
    }
    case 'UPDATE_RATING_COMMENT': {
      return {
        ...state,
        comments: state.comments.map((comment) => (comment.id === action.payload.id ? action.payload : comment)),
      };
    }
    case 'DELETE_COMMENT': {
      return {
        ...state,
        comments: state.comments.filter((comment) => comment.id !== action.payload),
      };
    }
    case 'CLEAR_COMMENTS': {
      return {
        comments: [],
        meta: undefined,
        selectedComment: undefined,
      };
    }

    default: {
      break;
    }
  }
  return state;
}
