import { toSnake } from 'modeling-tool';
import moment from 'moment';

const addParam = (queryParams: any, key: string, value: string) => {
  queryParams.push({
    [key]: value,
  });
};

const getIdFromUri = (uri: string): number | undefined => {
  if (uri) return parseInt(uri.split('/').slice(-2)[0]);
};

export const setQueryParams = (values: any, params: any, filterValues?: any) => {
  const queryParams: any = [];

  if (filterValues) {
    for (const key in filterValues) {
      if (key != 'processName') {
        values[key] = filterValues[key];
      }
    }
  }

  for (const key in values) {
    const camelKey = toSnake(key);
    if (values[key] && values[key]?.length) {
      if (['group', 'state'].indexOf(key) !== -1) {
        const val = values[key].map((item: string) => getIdFromUri(item)).join(',');
        addParam(queryParams, `${camelKey}__id__in`, `${val}`);
      } else if (key === 'orgUnitsAndStaffPositions') {
        const departments: number[] = values[key]
          .filter((unit: string) => unit.includes('department'))
          .map((uri: string) => getIdFromUri(uri));
        const staffPosition: number[] = values[key]
          .filter((unit: string) => unit.includes('staff_position'))
          .map((uri: string) => getIdFromUri(uri));
        if (departments?.length) {
          addParam(queryParams, `department__id__in`, `${[departments.join(',')]}`);
        }
        if (staffPosition?.length) {
          addParam(queryParams, `staff_position__id__in`, `${[staffPosition.join(',')]}`);
        }
      } else if (key === 'owner' || key === 'sendTo') {
        const users = values[key].replace(',', '|');
        addParam(queryParams, `${camelKey}__employees__regex`, `${users}`);
      } else if (key === 'ownerRole') {
        const roles: string[] = values[key].map((uri: string) => getIdFromUri(uri));
        addParam(queryParams, `owner__roles__id__in`, `${[roles.join(',')]}`);
      } else if (key === 'ownerDepartment') {
        const departments: string[] = values[key].map((uri: string) => getIdFromUri(uri));
        addParam(queryParams, `owner__departments__id__in`, `${[departments.join(',')]}`);
      } else if (key === 'ownerStaffPositions') {
        const staffPositions: string[] = values[key].map((uri: string) => getIdFromUri(uri));
        addParam(queryParams, `owner__staff_positions__id__in`, `${[staffPositions.join(',')]}`);
      } else if (key === 'ownerExternalPartner') {
        const externalPartners: string[] = values[key].map((uri: string) => getIdFromUri(uri));
        addParam(queryParams, `owner__external_partners__id__in`, `${[externalPartners.join(',')]}`);
      } else if (key === 'processLocations') {
        const locations: number[] = values[key]
          .filter((unit: string) => unit.includes('location'))
          .map((uri: string) => getIdFromUri(uri));
        addParam(queryParams, `${camelKey}__id__in`, `${[locations.join(',')]}`);
      } else if (key === 'createdBy') {
        addParam(queryParams, `${camelKey}__username__in`, `${[values[key]]}`);
      } else if (key.includes('__departments') || key.includes('__roles')) {
        addParam(queryParams, `${camelKey}__id__in`, `${values[key].map((uri: string) => getIdFromUri(uri))}`);
      } else if (key.includes('__employees')) {
        const users = values[camelKey].replace(',', '|');
        addParam(queryParams, `${camelKey}__regex`, `${users}`);
      } else if (key === 'processName') {
        addParam(queryParams, `${camelKey}__icontains`, `${values[key]}`);
      }
    }
  }
  if (values['fromDate']) {
    const valueGte = `${moment(values['fromDate']).startOf('day').format('YYYY-MM-DD')}`;
    addParam(queryParams, 'date_created__gte', valueGte);
  }
  if (values['toDate']) {
    const valueLte = `${moment(values['toDate']).add(1, 'day').startOf('day').format('YYYY-MM-DD')}`;
    addParam(queryParams, 'date_created__lte', valueLte);
  }

  // if no query params are set, clear the values object for the filter icon
  values = queryParams.length == 0 ? {} : values;

  queryParams.push({ outdated: 'false' });
  queryParams.push({ offset: 0 });

  // if there are more than 2 params, a filter must be active. Therefore leave the level out of it
  if (queryParams.length === 2) {
    queryParams.push({ level: 0 });
  } else {
    queryParams.push({ filtered: true });
  }

  const previousLimit = params.find((e: { limit: string }) => e.limit);
  queryParams.push({ limit: previousLimit ? previousLimit.limit : '10' });

  // remember the full form for later easier resetting the form
  queryParams.push({ filterValues: values });

  return queryParams;
};

export const getDefaultParams = (params: any) => {
  const queryParams: any = [];
  queryParams.push({ outdated: 'false' });
  queryParams.push({ offset: 0 });
  queryParams.push({ level: 0 });

  const previousLimit = params.find((e: { limit: string }) => e.limit);
  queryParams.push({ limit: previousLimit ? previousLimit.limit : '10' });
  queryParams.push({ filterValues: [] });
  return queryParams;
};
