import { GLOBALENUMS } from 'modeling-tool';
import { UseRequestOnlyAction } from './base-actions';

export const createTenant = async (cb?: (...args: any[]) => void) => {
  UseRequestOnlyAction({
    url: `createTenant`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    cb,
  });
};
