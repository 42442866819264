import { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Department, WorkingGroup } from '../../../ts/interfaces';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { useForm } from 'react-hook-form';
import { Label } from '@fluentui/react';
import { Modal, DropdownWithSearch, PeoplePicker, TextField } from 'modeling-tool';
import { RootState } from '../../../config/store';
import { getLabelStyles } from './location-form-styles';
import { renderSelectDropDown } from '../../../utils/process-management';

interface EmployeeLocation {
  username?: string;
  locations?: string[];
}
interface ILocationForm extends PropsFromRedux {
  visible: boolean;
  setVisible: (arg: boolean) => void;
  add: (location: Location, cb: () => void) => void;
  update: (location: Location, cb: () => void) => void;
  select: (id: boolean) => void;
  employees?: EmployeeLocation[];
}

const LocationForm = (props: ILocationForm) => {
  const {
    LocationReducer: { selectedLocation },
    DropdownDataReducer: { orgUnits, workingGroups },
    visible,
    add,
    select,
    setVisible,
    update,
  } = props;

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const labelStyles = getLabelStyles();

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    if (selectedLocation) {
      const usernames = selectedLocation.employees.map((item) => item.username);
      const data = {
        ...selectedLocation,
        username: usernames.length > 0 ? usernames.join(',') : null,
        workingGroup: selectedLocation.workingGroup.map((workingGroup: WorkingGroup) => workingGroup.resourceUri),
        departments: selectedLocation.departments.map((department: Department) => department.resourceUri),
      };
      reset(data);
    } else {
      reset({});
    }
  }, [selectedLocation]);

  const handleLocationOk = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleLocationCancel = () => {
    setVisible(false);
    reset();
  };

  const onFinish = (values: any) => {
    const usernames = values.username;
    delete values.username;
    const employees = usernames ? updateEmployeeLocations(usernames.split(',')) : [];
    if (selectedLocation?.id) {
      values = { ...values, id: selectedLocation.id, employees };
      update(values, () => {
        setShowLoading(false);
        setVisible(false);
        select(false);
        reset();
      });
    } else {
      values = {
        ...values,
        employees,
      };
      add(values, () => {
        setShowLoading(false);
        setVisible(false);
        reset();
      });
    }
  };

  const updateEmployeeLocations = (usernames: string[]) => {
    if (!selectedLocation) {
      return usernames.map((username) => {
        return {
          username: username,
        };
      });
    }
    return usernames.map((username) => {
      return {
        username: username,
      };
    });
  };

  return (
    <>
      <Modal
        title={!selectedLocation ? __('create new location') : __('edit location')}
        isModalOpen={visible}
        onSave={handleLocationOk}
        onCancel={handleLocationCancel}
        enableProgress={showLoading}
      >
        <form>
          <TextField
            label={__('title')}
            control={control}
            name={'locationName'}
            required={true}
            rules={{ required: __('This field is required') }}
          />
          <Label styles={labelStyles}>{__('employees')}</Label>
          <PeoplePicker selectionMode="multiple" name={'username'} control={control} />
          <DropdownWithSearch
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, orgUnits)}
            control={control}
            name={'departments'}
            placeholder={__('please select department')}
            multiSelect
            labelTitle={__('departments')}
          />
          <DropdownWithSearch
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.WORKING_GROUPS, workingGroups)}
            control={control}
            name={'workingGroup'}
            placeholder={__('please select working group')}
            multiSelect
            labelTitle={__('working Groups')}
          />
        </form>
      </Modal>
    </>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ LocationReducer, DropdownDataReducer }: RootState) => ({
  LocationReducer,
  DropdownDataReducer,
});
const connector = connect(mapStateToProps, {});
export default connector(LocationForm);
