import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchRolesAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `role/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_ROLES',
    cb,
  });
