import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  updateWorkingGroupAction,
  addWorkingGroupAction,
  selectWorkingGroupByIdAction,
} from '../../../store/actions/working-group-actions';
import { RootState } from '../../../config/store';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { Dropdown } from '../../../components';
import { Modal, DropdownWithSearch, PeoplePicker, TextField } from 'modeling-tool';
import { Label } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import { Location } from '../../../ts/interfaces';
import { StatusOptions } from './working-group-list';
import { renderSelectDropDown } from '../../../utils/process-management';

interface WorkGroupFormProps extends PropsFromRedux {
  visible: boolean;
  setModalVisible(visible: boolean): void;
  groupLocations: Location[];
}

interface EmployeeWorkingGroup {
  username?: string;
  workingGroup?: string[];
}

interface WorkGroupFormValues {
  id?: number;
  title?: string;
  description?: string;
  username?: string;
  employees?: EmployeeWorkingGroup[];
  status?: GLOBALENUMS.WORKINGGROUPSTATUS;
  groupLocations?: string[];
}

const WorkingGroupForm = (props: WorkGroupFormProps) => {
  const {
    WorkingGroupReducer: { selectedWorkinggroup },
    DropdownDataReducer: { locations },
    visible,
    addWorkingGroupAction,
    selectWorkingGroupByIdAction,
    setModalVisible,
    updateWorkingGroupAction,
  } = props;
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    if (selectedWorkinggroup) {
      const usernames = selectedWorkinggroup.employees.map((item) => item.username);

      const data = {
        ...selectedWorkinggroup,
        username: usernames.length > 0 ? usernames.join(',') : null,
        groupLocations:
          (selectedWorkinggroup.groupLocations &&
            selectedWorkinggroup.groupLocations.map((item: Location) => item.resourceUri)) ||
          null,
      };
      reset(data);
    }
  }, [selectedWorkinggroup]);

  const hideDialog = () => {
    reset();
    selectWorkingGroupByIdAction(-1);
    setModalVisible(false);
  };

  const saveForm = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const onFinish = (values: WorkGroupFormValues) => {
    const usernames = values.username;
    delete values.username;
    const employees = usernames ? updateEmployeeWorkingGroup(usernames.split(',')) : [];
    if (selectedWorkinggroup?.id) {
      values = { ...values, id: selectedWorkinggroup.id, employees };
      updateWorkingGroupAction(values, () => {
        setShowLoading(false);
        setModalVisible(false);
        selectWorkingGroupByIdAction(-1);
        reset();
      });
    } else {
      addWorkingGroupAction({ ...values, employees }, () => {
        setShowLoading(false);
        setModalVisible(false);
        reset();
      });
    }
  };

  const updateEmployeeWorkingGroup = (usernames: string[]) => {
    if (!selectedWorkinggroup) {
      return usernames.map((username) => {
        return {
          username: username,
        };
      });
    }
    return usernames.map((username) => {
      return {
        username: username,
        workingGroup: [selectedWorkinggroup.resourceUri],
      };
    });
  };

  return (
    <Modal
      title={!selectedWorkinggroup ? __('add a new working group') : __('edit working group')}
      isModalOpen={visible}
      onSave={saveForm}
      onCancel={() => hideDialog()}
      enableProgress={showLoading}
    >
      <form>
        <TextField
          required
          name="title"
          label={__('title')}
          control={control}
          rules={{ required: __('This field is required') }}
        ></TextField>
        <TextField name="description" label={__('description')} control={control}></TextField>
        <Label>{__('employees')}</Label>
        <PeoplePicker selectionMode="multiple" name={'username'} control={control} />
        <Label>{__('locations')}</Label>
        <DropdownWithSearch
          options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.LOCATIONS, locations)}
          control={control}
          name={'groupLocations'}
          placeholder={__('please select a location')}
          multiSelect
        />
        <Dropdown
          options={StatusOptions}
          label={__('status')}
          control={control}
          name={'status'}
          placeholder={__('please select status')}
          required={true}
          rules={{ required: __('This field is required') }}
        />
      </form>
    </Modal>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ WorkingGroupReducer, DropdownDataReducer }: RootState) => ({
  WorkingGroupReducer,
  DropdownDataReducer,
});
const connector = connect(mapStateToProps, {
  addWorkingGroupAction,
  selectWorkingGroupByIdAction,
  updateWorkingGroupAction,
});
export default connector(WorkingGroupForm);
