import React from 'react';
import { __ } from 'modeling-tool';
import { EmployeesList } from './employees-list';
import { WorkingGroupsList } from './working-groups-list';
import { Pivot, PivotItem } from '@fluentui/react';

const Employee = () => {
  return (
    <Pivot>
      <PivotItem
        key="1"
        headerText={__('employees list')}
        headerButtonProps={{
          'data-order': 1,
        }}
      >
        <EmployeesList />
      </PivotItem>
      <PivotItem
        key="2"
        headerText={__('working group')}
        headerButtonProps={{
          'data-order': 2,
        }}
      >
        <WorkingGroupsList />
      </PivotItem>
    </Pivot>
  );
};

export default Employee;
