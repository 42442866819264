import { Depths, FontSizes, FontWeights, memoizeFunction, mergeStyleSets } from '@fluentui/react';
import { StyleProp } from '../../../../../ts/interfaces';

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    horizontalSeparator: {
      borderBottom: `1px solid ${theme.palette.neutralLighter}`,
      marginBottom: 8,
      padding: '4px 0px',
    },
    formContainer: {
      top: '50px',
      margin: '10px 25px 0px 30px',
      padding: '5px',
    },
    formElement: {
      height: 'auto !important',
      marginBottom: 10,
    },
    filterBtnsHolder: {
      width: '100%',
      margin: '2px 2px 0 2px',
      position: 'fixed',
      bottom: 0,
      zIndex: 10,
      background: '#fff',
      right: 0,
      boxShadow: Depths.depth4,
      display: 'flex',
    },
    datepicker: {
      minWidth: 100,
    },

    filterBtns: {
      width: '100%',
      float: 'right',
      padding: '10px',
    },
    filterBtnsGap: {
      margin: '5px',
      float: 'right',
    },
  });
});

export const getCollapseStyles = memoizeFunction(() => {
  return mergeStyleSets({
    filterCollapseHeaderText: {
      fontWeight: 'bold',
      marginTop: '5px',
    },
    iconStyles: {
      fontWeight: FontWeights.light,
      fontSize: FontSizes.size14,
      float: 'right',
      padding: '6px 0 0 0',
      cursor: 'pointer',
    },
  });
});
