import React from 'react';
import { connect } from 'react-redux';
import { doNothingAction } from '../../../store/actions/file-actions';

// due to some issues with the redux store we need to keep this dummy version of the file list modal with the connect function
// even if we can use the libraries FileListModal otherwise the app will crash. Couldn't find a reason right now.
const FileListModal = (props: any) => {
  return <></>;
};
connect(({}: any) => {}, { doNothingAction });
export default FileListModal;
