import { IButtonStyles, mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../ts/interfaces';

export const getStyles = memoizeFunction((styleProps: StyleProp, isAiPanelOpen: boolean) => {
  const { theme } = styleProps;
  const windowHeight = window.innerHeight;
  return mergeStyleSets({
    modelerWrapper: {
      minHeight: '62vh',
      '& #process-modeler': {
        height: isAiPanelOpen ? `${windowHeight * 0.6}px` : '60vh',
        breakAfter: 'page',
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    detailsWrapper: {
      height: 'calc(100vh - 104px)',
      display: 'block',
      position: 'relative',
    },
    noneApprovedPage: {
      width: 1000,
    },
    sendBtn: {
      backgroundColor: theme.palette.themeLighter,
      color: theme.palette.themePrimary,
      border: 'none',
    },
    semiBoldText: {
      fontWeight: 600,
    },
    fontSizeSmall: {
      fontSize: 16,
      opacity: 0.6,
    },
    fontSizeLarge: {
      fontSize: 26,
      padding: '12px 0 12px 0',
    },
    statusTagStack: {
      position: 'absolute',
      zIndex: 10,
      paddingLeft: 8,
      paddingTop: 8,
    },
    approveBtn: {
      backgroundColor: '#259510',
      border: 'none',
      color: '#fff',
      ':hover': {
        backgroundColor: '#259510',
        color: '#fff',
      },
    },
    spinnerStack: {
      height: '100%',
      justifyContent: 'center',
    },
  });
});

export const buttonStyles: IButtonStyles = {
  root: {
    width: 272,
    height: 32,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginRight: 8,
  },
};
export const breadCrumbButtonStyles: IButtonStyles = {
  root: {
    width: 250,
    height: 32,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginRight: 8,
  },
};
