import React, { Dispatch } from 'react';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../config/store';
import { GLOBALENUMS, __ } from 'modeling-tool';
import { checkPermission } from '../../config/permission-utils';
import { getStyles } from './chapter-menu-styles';
import { RequirementChapter } from '../../ts/interfaces';
import { fetchRequirementChapterByIdAction } from '../../store/actions/requirement-action';
import { ChapterModalActions } from '../../ts/enums';

interface IChapterNameProps {
  displayContextualMenu: boolean;
  record: RequirementChapter;
  showModal: () => void;
  setModalActionType: Dispatch<React.SetStateAction<ChapterModalActions | undefined>>;
  setDisplayContextualMenu: (visible: boolean) => void;
  setShowDeleteModal: (visible: boolean) => void;
}

const ChapterMenu = (props: PropsFromRedux & IChapterNameProps) => {
  const {
    UserReducer,
    displayContextualMenu,
    record,
    showModal,
    setModalActionType,
    setDisplayContextualMenu,
    setShowDeleteModal,
    fetchRequirementChapterByIdAction,
  } = props;

  const styles = getStyles();

  const renderMoreOptionsMenu = (record: RequirementChapter) => {
    return (
      <React.Fragment>
        {optionsMenuItems.map((item: IContextualMenuItem) => (
          <div key={record.id}>
            {item.itemType !== ContextualMenuItemType.Divider && item.text}
            {item.itemType === ContextualMenuItemType.Divider && <hr />}
          </div>
        ))}
      </React.Fragment>
    );
  };

  const optionsMenuItems: IContextualMenuItem[] = [
    {
      key: 'edit',
      text: __('edit'),
      onClick: () => {
        editChapter(record, ChapterModalActions.EDIT_CHAPTER);
      },
      disabled: !checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_requirementchapter),
    },
    {
      key: 'create-subchapter',
      text: __('create subchapter'),
      onClick: () => {
        editChapter(record, ChapterModalActions.CREATE_SUBCHAPTER);
      },
      disabled: !checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_requirementchapter),
    },
    { key: 'divider_1', className: styles.divider, itemType: ContextualMenuItemType.Divider },
    {
      key: 'delete',
      text: __('Delete'),
      onClick: () => {
        setShowDeleteModal(true);
      },
      disabled: !checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.add_requirementchapter),
    },
  ];

  const editChapter = (record: RequirementChapter, menu: ChapterModalActions) => {
    if (record.id !== undefined) {
      fetchRequirementChapterByIdAction(record.id, () => {
        showModal();
        setModalActionType(menu);
      });
    }
  };

  return (
    <>
      {displayContextualMenu && (
        <>
          <ContextualMenu
            calloutProps={renderMoreOptionsMenu(record)}
            target={`#more-options-menu-${record.id}`}
            className={styles.contextMenu}
            items={optionsMenuItems}
            onItemClick={() => setDisplayContextualMenu(false)}
            onDismiss={() => setDisplayContextualMenu(false)}
          />
        </>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, ProcessReducer }: RootState) => ({ UserReducer, ProcessReducer });

const connector = connect(mapStateToProps, { fetchRequirementChapterByIdAction });

export default connector(ChapterMenu);
