import { Depths } from '@fluentui/react';

import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  employeeCard: {
    height: '640px',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    ...shorthands.margin('10px', '0px'),
    boxShadow: Depths.depth4,
  },
  cardTitle: {
    fontSize: '16px',
    ...shorthands.borderTop('3px'),
    ...shorthands.padding('15px', '15px', '15px', '15px'),
    height: '100px',
    fontWeight: '600',
    color: tokens.colorBrandBackground,
    backgroundColor: 'rgba(92, 46, 145, 0.05)',
  },
  cardTitleFlexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  cardTitleParagraph: {
    textAlign: 'left',
    color: '#8D8D8D',
    fontSize: '14px',
    fontWeight: '400',
  },
  cardBody: {
    fontSize: '16px',
    ...shorthands.borderTop('3px'),
    ...shorthands.padding('15px', '15px', '15px', '15px'),
    fontWeight: '600',
    color: tokens.colorBrandBackground,
  },
  nameTable: {
    color: 'rgb(92, 46, 145)',
  },
  tableRowStyle: {
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  memberListActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  peoplePicker: {
    'mgt-people-picker': {
      '--input-border': '1px solid #E1E1E1',
      '--input-border-color--hover': '1px solid #E1E1E1',
      '--input-background-color': '#FFF',
    },
  },
  placeholder: {
    pointerEvents: 'none',
    position: 'absolute',
    top: '40%',
    ...shorthands.margin('0px', '0px', '0px', '6px'),
    fontSize: tokens.fontSizeBase500,
    color: '#111',
    transform: 'translate(0, -117%)',
  },
  placeholderDiv: {
    position: 'sticky',
  },
  cardFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '-webkit-fill-available',
  },
  peoplePickerContainer: {
    display: 'flex',
    ...shorthands.border('1px', 'solid', '#E1E1E1'),
    backgroundColor: '#FFF',
    ':hover': {
      ...shorthands.borderColor(tokens.colorBrandBackground),
    },
    paddingLeft: '5px',
  },
  peoplePickerIcon: {
    ...shorthands.flex(0, 0, '5%'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  peoplePickerWrapper: {
    ...shorthands.flex('1'),
    '& mgt-people-picker': {
      '--input-border': '0',
      '--input-border-color--hover': '0',
    },
  },
  basicListWrapper: {
    width: '100%',
    height: '400px',
    ...shorthands.overflow('auto'),
    paddingTop: '10px',
  },
});
