import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const updateProcessMapAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processmap/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_PROCESS_MAP',
    reqPayload: data,
    cb,
  });

export const updateProcessMapStateAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processmap/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_PROCESS_MAP',
    reqPayload: { updateState: true, processMapState: data.processMapState },
    cb,
  });

export const getProcessMapAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processmap/${id}/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_PROCESS_MAP',
    cb,
  });

export const fetchProcessMapsAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processmap/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_PROCESS_MAPS',
    cb,
  });

export const addProcessMapAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'processmap/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_PROCESS_MAP',
    reqPayload: data,
    cb,
  });

export const checkForProcessMaps = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processmap/check/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'CHECK_FOR_PROCESS_MAPS',
    cb,
  });

export const selectProcessMapAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_PROCESS_MAP',
    payload: data,
  });

export const clearSelectProcessMapAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_SELECT_PROCESS_MAP',
  });

export const deleteProcessMapAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processmap/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_PROCESS_MAP',
    payload: data.id,
    cb,
  });

export const updateProcessMapGraphData = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processmapfullmodel/updateprocessmapgraphdata/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_PROCESS_MAP_GRAPH_DATA',
    reqPayload: data,
    cb,
  });

export const getProcessMapFullModelByParamsAction = (data: any, cb?: (...args: any[]) => void) => {
  const search = data.isProcessMap ? `?from_process_map=${data.id}` : `?from_process_group=${data.id}`;
  return UseAction({
    url: `processmapfullmodel${search}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'GET_PROCESS_MAP_FULL_MODEL',
    cb,
  });
};

export const addProcessMapFullModel = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processmapfullmodel/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_ORG_FULL_MODEL',
    reqPayload: data,
    cb,
  });

export const enableEditMode = () => ({
  type: 'ENABLE_EDIT_MODE',
});

export const toggleEditMode = () => ({
  type: 'TOGGLE_EDIT_MODE',
});
