import { Stack, Text } from '@fluentui/react';
import { getStyles } from './status-tag-styles';
import { Status } from '../../../ts/interfaces';
import { __ } from 'modeling-tool';

interface StatusTagProps {
  state: Status;
  size: { width: number; height: number };
}

const StatusTag = (props: StatusTagProps) => {
  const { state, size } = props;
  const styles = getStyles(size);
  return (
    <Stack.Item className={styles.tagStyles} style={{ background: state.color }}>
      <Text variant={'smallPlus'}>{__(state.projectStatusName)}</Text>
    </Stack.Item>
  );
};

export default StatusTag;
