import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  wrapper: {
    paddingLeft: '12px',
    display: 'flex',
  },
  colorShapeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  previewArea: {
    height: '50%',
    paddingLeft: '8px',
    paddingTop: '17px',
  },
  fillArea: {
    marginTop: '137px !important',
  },
  preview: {
    position: 'absolute',
    height: '130px',
    width: '40%',
    backgroundColor: 'white',
    ...shorthands.borderRadius('5px'),
  },
  svg: {
    paddingRight: '10px',
    paddingTop: '20px',
  },
  previewImg: {
    transform: 'scale(1.75)',
    position: 'absolute',
    ...shorthands.margin('auto'),
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  colorDisplay: {
    ...shorthands.padding('20px'),
  },
  colorCode: {
    height: '20px',
    width: '50px',
    backgroundColor: '#fff',
    paddingLeft: '5px',
    marginLeft: '5px',
  },
  colorCircle: {
    transform: 'scale(1.8)',
    paddingLeft: '20px',
    paddingTop: '5px',
  },
  callout: {
    height: '340px',
    width: '500px',
  },
});
