import { Controller } from 'react-hook-form';
import { Text, TimePicker as FluentTimePicker, ITimePickerProps, IComboBoxStyles, ITimeRange } from '@fluentui/react';
import * as React from 'react';
import { HookFormProps } from './hook-form-props';
import moment from 'moment';

export const TimePicker = (props: HookFormProps & ITimePickerProps) => {
  const timePickerStyles: Partial<IComboBoxStyles> = {
    optionsContainerWrapper: {
      height: '300px',
    },
  };

  const timeRange: ITimeRange = {
    start: 0,
    end: 23,
  };

  return (
    <Controller
      name={props.name ? props.name : ''}
      control={props.control}
      rules={props.rules}
      defaultValue={''}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <>
          <FluentTimePicker
            {...props}
            styles={timePickerStyles}
            onChange={(event, time: Date) => {
              onChange(moment(time).toDate());
            }}
            //increments={30}
            autoComplete="on"
            onBlur={onBlur}
            useHour12={false}
            //timeRange={timeRange}
            defaultValue={value ? value : undefined}
            useComboBoxAsMenuWidth
            allowFreeform
          />
          {error && (
            <Text variant="smallPlus" style={{ color: '#a4262c' }}>
              {error.message}
            </Text>
          )}
        </>
      )}
    />
  );
};
