import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { Process } from '../../../ts/interfaces';
import { __ } from 'modeling-tool';
import { searchProcessVersionsAction } from '../../../store/actions/process-version-actions';
import { getDateFormatted } from '../../../config/utils';
import { IColumn, DocumentCard, ScrollablePane, IconButton, Selection } from '@fluentui/react';
import { Tooltip } from '@fluentui/react-components';
import { tabsStyles } from './process-versions-history.styles';
import { searchOutdatedProcessesAction, deleteProcessAction } from '../../../store/actions/process-actions';
import { Popover } from 'modeling-tool';
import { checkPermission } from '../../../config/permission-utils';
import { BasicList, GLOBALENUMS } from 'modeling-tool';
import { useLocation } from 'react-router-dom';

interface ProcessVersionsHistoryProps {
  i?: Process;
  selectedProcess?: Process;
}

const ProcessVersionsHistory = (props: PropsFromRedux & ProcessVersionsHistoryProps) => {
  const {
    ProcessReducer: { outdatedProcess },
    selectedProcess,
    UserReducer,
  } = props;

  const [fetchedProcesses, setFetchedProcesses] = useState<Process[]>([]);
  const [selectedProcesses, setSelectedProcesses] = useState<Process[]>([]);
  const [enableDelete, setEnableDelete] = useState<boolean>(false);

  const location = useLocation();
  const styles = tabsStyles();

  const _rowsSelected: Selection = new Selection({
    onSelectionChanged: () => _getSelectionDetails(),
  });
  const clickProcess = (item?: Process) => {
    const url = `/process-management/processes/${item?.id}`;
    window.open(url, '_blank')?.focus();
  };

  const columnsList: IColumn[] = [
    {
      key: 'processName',
      name: __('process'),
      fieldName: 'processName',
      minWidth: 90,
      maxWidth: 90,
      onRender: (record: Process) =>
        record && (
          <div className={styles.cellText} onClick={() => clickProcess(record)}>
            {record.processName}
          </div>
        ),
    },
    {
      key: 'version',
      name: __('version'),
      fieldName: 'version',
      minWidth: 30,
      maxWidth: 60,
      onRender: (record: Process) => record && <div className={styles.cellText}>{record.versionNumber}</div>,
    },
    {
      key: 'changesMade',
      name: __('changes made'),
      fieldName: 'changesMade',
      minWidth: 220,
      maxWidth: 250,
      onRender: (record: Process) =>
        record && (
          <Tooltip content={`${record.changesMade}`} relationship="description" positioning="below">
            <div className={styles.cellText}>{record.changesMade}</div>
          </Tooltip>
        ),
    },
    {
      key: 'dateCreated',
      name: __('created at'),
      fieldName: 'dateCreated',
      minWidth: 90,
      maxWidth: 120,
      onRender: ({ dateCreated }: Process) => (
        <div className={styles.cellText}>{dateCreated && getDateFormatted(dateCreated, 'lll')}</div>
      ),
    },
  ];

  useEffect(() => {
    fetchProcessesVersions();
  }, [selectedProcess]);

  const fetchProcessesVersions = () => {
    if (selectedProcess && selectedProcess.id) {
      if (selectedProcess.rootVersion) {
        getVersions(selectedProcess.rootVersion.id.toString());
      } else {
        getVersions(selectedProcess.id.toString());
      }
    } else {
      getVersions('');
    }
  };

  const getVersions = (id: string) => {
    if (selectedProcess) {
      const url = `${location.search}?version=${id}`;
      props.searchOutdatedProcessesAction(url, (res: any) => {
        getOutDatedVersions(res.data.objects as Process[]);
      });
    } else {
      const url = `?outdated=true&limit=0&order_by=-date_created`;
      props.searchOutdatedProcessesAction(url, (res: any) => {
        getOutDatedVersions(res.data.objects as Process[]);
      });
    }
  };
  const getOutDatedVersions = (processes: Process[]) => {
    if (processes.length > 0) {
      const filtered = selectedProcess
        ? processes.filter(
            (i: Process) => i.id != selectedProcess?.id && i.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS,
          )
        : processes.filter(() => outdatedProcess);
      setFetchedProcesses(filtered);
    } else {
      setFetchedProcesses([]);
    }
  };

  const onDeleteProcesses = () => {
    for (const process of selectedProcesses) {
      deleteProcessAction(process, () => {
        fetchProcessesVersions();
      });
    }
    setEnableDelete(false);
  };

  const _getSelectionDetails = () => {
    const selectionCount = _rowsSelected.getSelectedCount();
    if (selectionCount > 0) {
      setSelectedProcesses(_rowsSelected.getSelection() as Process[]);
      setEnableDelete(true);
    } else {
      setSelectedProcesses([]);
      setEnableDelete(false);
    }
  };
  const renderList = () => {
    if (fetchedProcesses) {
      return (
        <>
          {enableDelete && checkPermission(UserReducer.permissions, GLOBALENUMS.PERMISSIONS.delete_process) && (
            <Popover
              title={__('delete selected processes')}
              content={__('are you sure to delete this processes?')}
              target={'delete-processes-history'}
              enableConfirm={true}
              onOk={() => onDeleteProcesses()}
            >
              <IconButton
                id="delete-processes-history"
                iconProps={{ iconName: 'Delete' }}
                style={{ margin: '5px', float: 'right' }}
              >
                {__('delete')}
              </IconButton>
            </Popover>
          )}

          <BasicList
            setKey={GLOBALENUMS.BASICLISTTYPES.PROCESSVERSIONS_LIST}
            items={fetchedProcesses}
            columns={columnsList}
            selection={_rowsSelected}
          />
        </>
      );
    }
    return <></>;
  };

  const renderTable = () => {
    return (
      <DocumentCard className={styles.cardStyles}>
        <ScrollablePane scrollContainerFocus={true} scrollContainerAriaLabel="Sticky component example">
          {renderList()}
        </ScrollablePane>
      </DocumentCard>
    );
  };

  return <>{renderTable()}</>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ ProcessVersionReducer, DepartmentReducer, ProcessReducer, UserReducer }: RootState) => ({
  ProcessVersionReducer,
  DepartmentReducer,
  ProcessReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  searchProcessVersionsAction,
  searchOutdatedProcessesAction,
});
export default connector(ProcessVersionsHistory);
