import React, { useEffect, useState } from 'react';
import { GLOBALENUMS, Modal, TextField, __, FileList, TextEditor } from 'modeling-tool';
import { PeopleDeptRoleInput } from '../../../components';
import { DropdownWithSearch } from 'modeling-tool';
import { useForm } from 'react-hook-form';
import { Label, Stack } from '@fluentui/react';
import { Process, ResourceGroup, Resource } from '../../../ts/interfaces';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import { fetchResourceGroupsAction } from '../../../store/actions/resource-group-actions';
import {
  addResourceAction,
  clearSelectResourceAction,
  searchResourceAction,
  updateResourceAction,
} from '../../../store/actions/resource-actions';
import { getStyles } from './resource-form-styles';
import { getRACIFieldsFormObject, getRACIFieldsObject } from '../../../config/utils';
import { fetchLibrariesAction, fetchSitesAction } from '../../../store/actions/file-actions';
import { renderSelectDropDown } from '../../../utils/process-management';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';

interface IResourceForm extends PropsFromRedux {
  setModalVisible(arg: boolean): void;
  isModalVisible: boolean;
  selectedResource?: Resource;
  queryResource?: string;
}

const dropDownData = [GLOBALENUMS.DROPDOWNDATA.RESOURCEGROUPS, GLOBALENUMS.DROPDOWNDATA.PROCESSES];

const ResourceForm = (props: IResourceForm) => {
  const {
    DropdownDataReducer: { processes, resourceGroups },
    isModalVisible,
    selectedResource,
    queryResource,
    addResourceAction,
    clearSelectResourceAction,
    fetchDropdownDataByType,
    fetchLibrariesAction,
    fetchResourceGroupsAction,
    fetchSitesAction,
    searchResourceAction,
    setModalVisible,
    updateResourceAction,
  } = props;
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const styles = getStyles();

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
  }, []);

  useEffect(() => {
    if (selectedResource) {
      const data = {
        ...selectedResource,
        ...getRACIFieldsFormObject(selectedResource),
        parentGroup: selectedResource.parentGroup?.map((item: ResourceGroup) => item.resourceUri) || null,
        processLink: selectedResource.processLink?.map((item: Process) => item.resourceUri) || null,
        attachments: selectedResource.attachments?.length ? selectedResource.attachments?.split('|') : [],
      };

      reset(data);
      return () => {
        reset();
        clearSelectResourceAction();
        queryResource && searchResourceAction(queryResource);
      };
    }
  }, [selectedResource]);

  const handleResourceCancel = () => {
    clearSelectResourceAction();
    setModalVisible(false);
    reset();
  };

  const onFinish = (values: any) => {
    if (selectedResource && selectedResource.id) {
      const raciValues = getRACIFieldsObject(values, selectedResource);
      values = {
        ...values,
        ...raciValues,
        id: selectedResource.id,
        description: values.description,
        attachments: values.attachments?.length ? values.attachments.join('|') : null,
      };
      updateResourceAction(values, () => {
        setShowLoading(false);
        setModalVisible(false);
        clearSelectResourceAction();
        reset();
      });
    } else {
      const raciValues = getRACIFieldsObject(values);
      values = {
        ...values,
        ...raciValues,
        description: values.description,
        attachments: values.attachments?.length ? values.attachments.join('|') : null,
        processLink: values.processLink,
      };
      addResourceAction(values, () => {
        setShowLoading(false);
        setModalVisible(false);
        reset();
      });
    }
  };

  const handleResourceOk = () => {
    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
        setModalVisible(false);
        fetchResourceGroupsAction();
        queryResource && searchResourceAction(queryResource);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  return (
    <div>
      <Modal
        title={!selectedResource ? __('add resource') : __('edit resource')}
        isModalOpen={isModalVisible}
        onCancel={handleResourceCancel}
        saveButtonText={__('save')}
        onSave={handleResourceOk}
        enableProgress={showLoading}
      >
        <form>
          <TextField
            label={__('resource name')}
            control={control}
            name={'name'}
            required={true}
            rules={{ required: __('This field is required') }}
          />
          <DropdownWithSearch
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.RESOURCEGROUPS, resourceGroups)}
            control={control}
            name={'parentGroup'}
            placeholder={__('please select resource group')}
            required={true}
            rules={{ required: __('This field is required') }}
            multiSelect
            labelTitle={__('parent group')}
          />
          <PeopleDeptRoleInput
            field="responsible"
            showWorkingGroups={true}
            readOnly={false}
            control={control}
            isFoldable={true}
          />
          <Label styles={{ root: { fontWeight: 400 } }}>{__('description')}</Label>
          <TextEditor control={control} name={'description'} placeholder={__('provide description')} />
          <DropdownWithSearch
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.PROCESSES, processes)}
            control={control}
            name={'processLink'}
            placeholder={__('please select a process')}
            multiSelect
            labelTitle={__('linked processes')}
          />
          <Stack grow tokens={{ childrenGap: 5 }}>
            <TextField label={__('link')} placeholder={__('display a text here')} control={control} name={'linkText'} />
            <TextField
              rules={{
                pattern: {
                  value:
                    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                  message: __('this is not a valid link'),
                },
              }}
              name={'linkUrl'}
              control={control}
              placeholder={__('enter a link here')}
            />
          </Stack>
          <Label styles={{ root: { fontWeight: 400 } }} className={styles.label}>
            {__('documents')}
          </Label>
          <FileList
            control={control}
            name={'attachments'}
            fetchLibrariesAction={fetchLibrariesAction}
            fetchSitesAction={fetchSitesAction}
          />
        </form>
      </Modal>
    </div>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ ResourceGroupReducer, DropdownDataReducer }: RootState) => ({
  ResourceGroupReducer,
  DropdownDataReducer,
});

const connector = connect(mapStateToProps, {
  fetchResourceGroupsAction,
  addResourceAction,
  clearSelectResourceAction,
  updateResourceAction,
  searchResourceAction,
  fetchLibrariesAction,
  fetchSitesAction,
  fetchDropdownDataByType,
});
export default connector(ResourceForm);
