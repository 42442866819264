import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { __ } from 'modeling-tool';
import { searchTasksAction, setMetaAction, clearTaskAction } from '../../store/actions/task-actions';
import { Stack, Pivot, PivotItem, TooltipHost, ActionButton, DirectionalHint, ScrollablePane } from '@fluentui/react';
import { getStyles } from './tasks-styles';
import { searchProcessInstancesAction } from '../../store/actions/process-instance-actions';
import { Dropdown } from '../../components';
import { GLOBALENUMS, Modal } from 'modeling-tool';
import { useForm } from 'react-hook-form';
import { useStyleProps } from '../../hooks';
import TasksCardArea from './tasks-card-area';
import { useNavigate, useLocation } from 'react-router';

enum FormStatus {
  APPROVAL,
  RECORDING,
}

const Tasks = (props: PropsFromRedux) => {
  const {
    TaskReducer: { tasks },
    ProcessInstanceReducer: { instances },
    clearTaskAction,
  } = props;
  const [selectedKey, setSelectedKey] = useState<string>('0');
  const [isModalVisibleTasks, setIsModalVisibleTasks] = useState<boolean>(false);
  const [isModalVisibleApproval, setIsModalVisibleApproval] = useState<boolean>(false);
  const [selectedProcessStatus, setSelectedProcessStatus] = useState<GLOBALENUMS.PROCESSSTATUS[]>([
    GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_RECORDING,
    GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_NEW_ACCEPTED,
    GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IN_REVIEW,
    GLOBALENUMS.PROCESSSTATUS.RECORDING_APPROVED,
    GLOBALENUMS.PROCESSSTATUS.INSTANCE_REJECTED,
  ]);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState<GLOBALENUMS.APPROVALSTATUS[]>([
    GLOBALENUMS.APPROVALSTATUS.IN_APPROVAL,
    GLOBALENUMS.APPROVALSTATUS.APPROVED,
    GLOBALENUMS.APPROVALSTATUS.REJECTED,
  ]);

  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);
  const navigate = useNavigate();
  const location = useLocation();

  const { handleSubmit, control, reset } = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    if (location.pathname.includes('approval')) {
      setSelectedKey('1');
      props.searchProcessInstancesAction('?is_task_details_view=true');
    } else {
      props.searchTasksAction(location.search);
      setSelectedKey('0');
    }
  }, [location.pathname]);

  useEffect(() => {
    clearTaskAction();
  }, []);

  const saveFormTasks = () => {
    handleSubmit(
      (data) => {
        setIsModalVisibleTasks(false);
        setSelectedProcessStatus(data['state']);
        reset();
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleCancel = () => {
    setIsModalVisibleTasks(false);
  };

  const saveFormApprovalTasks = () => {
    handleSubmit(
      (data) => {
        setIsModalVisibleApproval(false);
        setSelectedApprovalStatus(data['status']);
        reset();
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleCancelApproval = () => {
    setIsModalVisibleApproval(false);
  };

  const renderTasks = () => {
    if (tasks && tasks.length > 0) {
      {
        return (
          <>
            <Stack enableScopedSelectors className={styles.tasksWrapper}>
              <ScrollablePane className={styles.paneWrapper}>
                <Stack horizontal className={styles.taskHorizWrapper}>
                  {selectedProcessStatus.includes(GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_RECORDING) && (
                    <TasksCardArea
                      processStates={[GLOBALENUMS.PROCESSSTATUS.INSTANCE_IN_RECORDING]}
                      title={'in process recording'}
                    />
                  )}
                  {selectedProcessStatus.includes(GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IN_REVIEW) && (
                    <TasksCardArea
                      processStates={[GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IN_REVIEW]}
                      title={'in review'}
                    />
                  )}
                  {selectedProcessStatus.includes(GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_NEW_ACCEPTED) && (
                    <TasksCardArea
                      processStates={[GLOBALENUMS.PROCESSSTATUS.INSTANCE_RECORDING_IS_NEW_ACCEPTED]}
                      title={'accepted'}
                    />
                  )}
                </Stack>
              </ScrollablePane>
            </Stack>
          </>
        );
      }
    }
    return <div className={styles.noTasksMsg}>{__("you don't have any tasks")}</div>;
  };

  const renderApprovalTasks = () => {
    if (instances && instances.length > 0) {
      return (
        <>
          <Stack className={styles.tasksWrapper}>
            <ScrollablePane className={styles.paneWrapper}>
              <Stack horizontal className={styles.taskHorizWrapper}>
                {selectedApprovalStatus.includes(GLOBALENUMS.APPROVALSTATUS.IN_APPROVAL) && (
                  <TasksCardArea approvalState={GLOBALENUMS.APPROVALSTATUS.IN_APPROVAL} title={'in approval'} />
                )}
                {selectedApprovalStatus.includes(GLOBALENUMS.APPROVALSTATUS.APPROVED) && (
                  <TasksCardArea approvalState={GLOBALENUMS.APPROVALSTATUS.APPROVED} title={'approved by you'} />
                )}
                {selectedApprovalStatus.includes(GLOBALENUMS.APPROVALSTATUS.REJECTED) && (
                  <TasksCardArea approvalState={GLOBALENUMS.APPROVALSTATUS.REJECTED} title={'rejected'} />
                )}
              </Stack>
            </ScrollablePane>
          </Stack>
        </>
      );
    }
    return <div className={styles.noTasksMsg}>{__("you don't have any tasks")}</div>;
  };

  const onTabClick = (key?: string) => {
    if (key === __('approval')) {
      navigate(`/tasks/approval`);
    } else {
      navigate(`/tasks/process-recording`);
    }
  };

  const optionsTasksStatus = [
    { key: 'in process recording', text: __('in process recording') },
    { key: 'in review', text: __('in review') },
    { key: 'done', text: __('done') },
    { key: 'rejected', text: __('rejected') },
  ];
  const optionsApprovalStatus = [
    { key: 'in_approval', text: __('in_approval') },
    { key: 'approved', text: __('approved by you') },
    { key: 'rejected', text: __('rejected') },
  ];
  const renderFilterButton = (type: FormStatus) => {
    return (
      <TooltipHost content={__('filter')} directionalHint={DirectionalHint.bottomAutoEdge}>
        <ActionButton
          iconProps={{ iconName: 'Filter' }}
          className={styles.filterButton}
          onClick={() =>
            type === FormStatus.RECORDING ? setIsModalVisibleTasks(true) : setIsModalVisibleApproval(true)
          }
        />
      </TooltipHost>
    );
  };
  const renderFormStatus = (type: FormStatus) => {
    return (
      <form>
        <Dropdown
          options={type === FormStatus.RECORDING ? optionsTasksStatus : optionsApprovalStatus}
          multiSelect
          label={__('status')}
          control={control}
          name={'status'}
        />
      </form>
    );
  };
  return (
    <Pivot onLinkClick={(item, _) => onTabClick(item?.props.headerText)} selectedKey={selectedKey}>
      <PivotItem headerText={__('process recording')} itemKey="0">
        <div className={styles.header}></div>
        {tasks && tasks.length > 0 && renderFilterButton(FormStatus.RECORDING)}
        <Modal title={__('filter')} isModalOpen={isModalVisibleTasks} onSave={saveFormTasks} onCancel={handleCancel}>
          {renderFormStatus(FormStatus.RECORDING)}
        </Modal>
        {renderTasks()}
      </PivotItem>
      <PivotItem headerText={__('approval')} itemKey="1">
        <div className={styles.header}></div>
        {instances && instances.length > 0 && renderFilterButton(FormStatus.APPROVAL)}
        <Modal
          title={__('filter')}
          isModalOpen={isModalVisibleApproval}
          onSave={saveFormApprovalTasks}
          onCancel={handleCancelApproval}
        >
          {renderFormStatus(FormStatus.APPROVAL)}
        </Modal>
        {renderApprovalTasks()}
      </PivotItem>
    </Pivot>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ TaskReducer, ProcessInstanceReducer }: RootState) => ({
  TaskReducer,
  ProcessInstanceReducer,
});
const connector = connect(mapStateToProps, {
  searchTasksAction,
  setMetaAction,
  searchProcessInstancesAction,
  clearTaskAction,
});
export default connector(Tasks);
