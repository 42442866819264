import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    clickableDocument: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  });
});
