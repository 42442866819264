import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  groupMembershipDiv: {
    ...shorthands.margin('0px', '15px', '0px', '15px'),
  },
  membersGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gridColumnGap: '20px',
    gridRowGap: '10px',
  },
});
