import { PrimaryButton, Stack, useTheme } from '@fluentui/react';
import { Person } from '@microsoft/mgt-react';
import { Card, GLOBALENUMS, Modal, __ } from 'modeling-tool';
import React, { useEffect, useState } from 'react';
import { ApproversList, PersonDisplayName } from '../../../components';
import { ProcessReviewList } from '../../../components/process/process-record-modeler/process-review-list';
import { getDateFormatted } from '../../../config/utils';
import { searchDirectProcessInstancesAction } from '../../../store/actions/process-instance-actions';
import { Process, ProcessInstance } from '../../../ts/interfaces';
import { cardDetailsStyles, cardStyles2 } from '../tasks-styles';

interface TaskCardProps {
  item: ProcessInstance;
  clickTask: (item: ProcessInstance) => void;
  processRecording?: boolean;
}

const TaskCard = (props: TaskCardProps) => {
  const { item, clickTask, processRecording } = props;
  const theme = useTheme();
  const styles = cardStyles2(theme);
  const sentDate = (item?.startDate && getDateFormatted(item?.startDate)) as string;
  const [visible, setVisible] = useState<boolean>(false);

  const [fetchedTasks, setFetchedTasks] = useState<Process[]>([]);

  const renderDataRows = (leftValue: any, rightValue?: string | JSX.Element, title?: boolean) => {
    return (
      <Stack horizontal horizontalAlign="space-around" className={styles.dataRow} style={{ width: '100%' }}>
        <Stack.Item className={cardDetailsStyles(title).leftDetailsStyles}>
          <div className={cardDetailsStyles(title).leftValue}>{leftValue && leftValue}</div>
        </Stack.Item>
        <Stack.Item className={cardDetailsStyles(title).rightDetailsStyles}>{rightValue}</Stack.Item>
      </Stack>
    );
  };

  return (
    <>
      <Card
        cardStyles={styles.cardStyles}
        onselectCard={() => clickTask(item)}
        title={item?.instanceName}
        titleStyles={styles.titleStyles}
      >
        <Stack className={styles.cardStack}>
          <Stack verticalAlign="space-around" className={styles.detailsStack}>
            <Stack.Item className={styles.stackRow}>
              {renderDataRows(
                __('created by'),
                <>
                  <Person userId={props.item?.createdBy.username}></Person>
                  <Person userId={props.item?.createdBy.username} className={styles.personName}>
                    <PersonDisplayName shrink={true} />
                  </Person>
                </>,
              )}
            </Stack.Item>
            <Stack.Item className={styles.stackRow}>{renderDataRows(__('sent on'), sentDate)}</Stack.Item>
            <Stack.Item className={styles.stackRow}>
              {renderDataRows(
                __('feedback'),
                processRecording
                  ? `${item?.numberOfFeedback} of ${item?.numberOfTasks}`
                  : `${item?.numberOfApprovalFeedback} of ${item?.numberOfApprovals}`,
              )}
            </Stack.Item>
          </Stack>
          <Stack.Item align="end" className={styles.buttonStack}>
            <PrimaryButton
              text={__('view status')}
              onClick={(event: any) => {
                event?.stopPropagation();

                if (processRecording) {
                  searchDirectProcessInstancesAction(`?source_process=${item?.sourceProcess.id}`, (res) => {
                    setFetchedTasks(res);
                    setVisible(true);
                  });
                } else {
                  setVisible(true);
                }
              }}
            />
            {visible && (
              <Modal
                title={processRecording ? __('status overview') : __('approvers')}
                isModalOpen={visible}
                hideFooter={true}
                onSave={() => setVisible(false)}
                onCancel={() => setVisible(false)}
              >
                <>
                  {processRecording ? (
                    <ProcessReviewList items={fetchedTasks} tasksModal={true} />
                  ) : (
                    <ApproversList items={item?.approvedBy} />
                  )}
                </>
              </Modal>
            )}
          </Stack.Item>
        </Stack>
      </Card>
    </>
  );
};

export default TaskCard;
