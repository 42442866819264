import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    contextMenu: {
      '& .ms-ContextualMenu-link': {
        padding: '0px',
        fontColor: '#424242',

        height: 'inherit',
        lineHeight: 'inherit',
      },
      '& .ms-ContextualMenu-linkContent': {
        margin: '0px',
        padding: '0px',
      },

      '& .ms-ContextualMenu-itemText': {
        margin: '0px',
        padding: '8px 23px',
        fontSize: '14px',
        fontWeight: 400,
      },
      maxWidth: '305px',
      padding: '8px 0px',
      background: '#FFFFFF',
      boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.16)',
      boxSizing: 'border-box',
    },
    divider: {
      margin: '0px 19px',
      padding: 'solid #D8D8D8',
    },
    callout: {
      display: 'flex',
      flexDirection: 'column',
      padding: '15px 10px',
      background: '#FFFFFF',
    },
  });
});
