import { IRatingStyles, mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../ts/interfaces';
export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme, isContentOnly, isTeamsApp } = props;
  return mergeStyleSets({
    commentsContainer: {
      zIndex: '50',
      height: '100%',
      borderRight: `1px solid ${theme.palette.neutralLight}`,
    },
    globalCardStyles: {
      width: 800,
      height: '100%',
      background: theme.palette.white,
      border: 'none',
    },
    dismissCaret: {
      position: 'absolute',
      right: 0,
      top: 0,
    },

    wrapper: {
      height: isContentOnly || isTeamsApp ? `calc(100vh - 215px)` : `calc(100vh - 275px)`,
      position: 'relative',
      maxHeight: 'inherit',
      overflowY: 'scroll',
    },
    personStyle: {
      borderRight: '15px',
      borderLeft: '15px',
      borderBottom: '15px',
    },

    description: {
      width: '100%',
      height: '100%',
      minWidth: 100,
      marginRight: '15px',
      marginTop: '5px',
      marginLeft: '10px',
    },
    rating: {
      marginLeft: '8px',
    },
    titleStyles: {
      display: 'flex',
      justifyContent: 'center',
      height: '40px',
      paddingTop: '13px',
      background: theme.palette.white,
    },
    titleText: {
      color: `${theme.palette.themePrimary}`,
    },
    newCommentButton: {
      alignContent: 'center',
      '& > span > ms-Button-textContainer': {
        flexGrow: '0',
      },
    },
    buttonSend: {
      height: '30px',
      border: `1px solid ${theme.palette.themePrimary}`,
      color: `${theme.palette.themePrimary}`,
    },
    disabledButtonSend: {
      height: '30px',
      border: `1px solid ${theme.palette.themePrimary}`,
      color: `grey`,
    },
    comment: {
      paddingTop: '10px',
      paddingLeft: '8px',
      paddingBottom: '5px',
    },
  });
});

export const ratingStarFront: Partial<IRatingStyles> = {
  ratingStarFront: {
    color: '#FCE100',
  },
};
