import { makeStyles, shorthands } from '@fluentui/react-components';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '-webkit-fill-available',
    ...shorthands.margin('10px', '10px', '0px', '45px'),
  },
  licenceDiv: {
    ...shorthands.flex('63%'),
  },
  summaryDiv: {
    ...shorthands.flex('37%'),
    marginLeft: '100px',
  },
  licenceHeaderDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
  buyLicenceTextDiv: {
    fontSize: '24px',
    fontWeight: '600',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('0'),
  },
  licencesTextGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    ...shorthands.margin('40px', '0px', '0px', '0px'),
    ...shorthands.borderBottom('1px solid #f0f0f0'),
  },
  chooseLicenceTextDiv: {
    ...shorthands.flex('0 0 45%'),
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    fontWeight: '600',
    justifyContent: 'center',
  },
  billingLicenceTextDiv: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '-8px', // This has been added because for the child element of this div, the margin-bottom is set to 8px by fluent-ui
  },
  memberShipGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gridColumnGap: '26px',
    gridRowGap: '10px',
    marginTop: '20px',
  },
  summaryDivCommon: {
    ...shorthands.padding('10px', '10px', '10px', '10px'),
    width: 'fit-content',
  },
  summaryHeaderTextDiv: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('0'),
    height: '53px',
    marginTop: '30px',
  },
  summaryLicenceSpan: {
    fontSize: '24px',
    fontWeight: '600',
  },
  discountLicenceSpan: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(17, 17, 17, 0.60)',
  },
  pricingCalculatorDiv: {
    marginTop: '50px',
    marginBottom: '35px',
    display: 'inline-block',
  },
  newPriceCalculatorDiv: {
    marginTop: '25px',
    display: 'inline-block',
  },
  paymentDiv: {
    ...shorthands.borderTop('1px solid #000'),
    marginTop: '20px',
    paddingTop: '20px',
  },
  planTextDiv: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '20px',
  },
});
