import { Theme } from '@fluentui/react';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { checkSetting, getSettingValue } from '../../config/utils';
import { fetchDropdownDataByType } from '../../store/actions/dropdown-data-actions';
import { fetchLibrariesAction, fetchSitesAction } from '../../store/actions/file-actions';
import {
  getFullModelByInstanceId,
  updateFullModelAction,
  updateGraphData,
  getModelOptimizations,
} from '../../store/actions/modeler-actions';
import { setNewProcessInstanceStateAction } from '../../store/actions/process-state-actions';
import { getNewTaskAction } from '../../store/actions/task-actions';
import { getProposalsForProcessAction, addProposalForProcessAction } from '../../store/actions/process-actions';
import { increaseAiRequestAction } from '../../store/actions/user-actions';
import { ProcessInstance } from '../../ts/interfaces';
import { RootState } from '../../config/store';
import { GLOBALENUMS, Modeler, ModelerContext } from 'modeling-tool';
import { redirectToProcess } from '../../utils';

interface AppPMContextWrapperProps extends PropsFromRedux {
  theme: Theme;
  processinstanceId: number;
  hasUrgentProblems: boolean;
  onApproveModel: (instance: ProcessInstance) => void;
  openProblemModal: (readonly: boolean) => void;
  onDeleteModel: () => void;
  showApproverListModal: () => void;
}

const AppPMContextWrapper = (props: AppPMContextWrapperProps) => {
  const {
    theme,
    processinstanceId,
    hasUrgentProblems,
    onApproveModel,
    openProblemModal,
    onDeleteModel,
    showApproverListModal,
    ProcessInstanceReducer: { selectedInstance },
    UserReducer: { permissions, settingsMap },
    getFullModelByInstanceId,
    updateFullModelAction,
    getNewTaskAction,
    fetchLibrariesAction,
    fetchSitesAction,
    fetchDropdownDataByType,
    updateGraphData,
    increaseAiRequestAction,
    setNewProcessInstanceStateAction,
  } = props;

  const modelerContextValues = {
    theme: theme,
    increaseAiRequestAction: increaseAiRequestAction,
    fetchLibrariesActionContext: fetchLibrariesAction,
    fetchSitesActionContext: fetchSitesAction,
    processinstanceId: processinstanceId,
    updateFullModelAction: updateFullModelAction,
    updateGraphData: updateGraphData,
    hasUrgentProblems: hasUrgentProblems,
    permissions: permissions,
    enableDelete:
      selectedInstance &&
      ![GLOBALENUMS.PROCESSSTATUS.INSTANCE_MODELING_IN_APPROVAL, GLOBALENUMS.PROCESSSTATUS.MODELING_APPROVED].includes(
        selectedInstance?.state?.type,
      ),
    redirectToProcess: redirectToProcess,
    getNewTaskAction: getNewTaskAction,
    onModelApprove: onApproveModel,
    getFullModelByInstanceId: getFullModelByInstanceId,
    showProblemModal: openProblemModal,
    onDeleteModel: onDeleteModel,
    showApproversList: showApproverListModal,
    fetchModelerMetaData: fetchDropdownDataByType,
    getProposalsForProcessAction: getProposalsForProcessAction,
    addProposalForProcessAction: addProposalForProcessAction,
    getModelOptimizations: getModelOptimizations,
    setNewProcessInstanceStateAction: setNewProcessInstanceStateAction,
    isAiIOptimizationAtLimit: getSettingValue(settingsMap, 'ai_limit') == '0',
    isAiIOptimizationAllowed: checkSetting(settingsMap, 'enable_ai_optimizations', 'true'),
    enableApproversList:
      selectedInstance &&
      selectedInstance?.approvedBy?.length > 0 &&
      [GLOBALENUMS.PROCESSSTATUS.INSTANCE_MODELING_IN_APPROVAL, GLOBALENUMS.PROCESSSTATUS.MODELING_APPROVED].includes(
        selectedInstance?.state?.type,
      ),
  };

  return (
    <ModelerContext.Provider value={modelerContextValues}>
      <Modeler />
    </ModelerContext.Provider>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ UserReducer, ProcessInstanceReducer, UserCommentReducer }: RootState) => ({
  UserReducer,
  ProcessInstanceReducer,
  UserCommentReducer,
});

const connector = connect(mapStateToProps, {
  updateGraphData,
  fetchDropdownDataByType,
  getFullModelByInstanceId,
  updateFullModelAction,
  getNewTaskAction,
  fetchLibrariesAction,
  fetchSitesAction,
  increaseAiRequestAction,
  setNewProcessInstanceStateAction,
});

export default connector(AppPMContextWrapper);
