import React, { Dispatch, useEffect, useState } from 'react';
import { getRACIFieldsObject, getRACIFieldsFormObject, checkSetting } from '../../../../config/utils';
import { connect, ConnectedProps } from 'react-redux';
import { Process, Location, Resource, ExternalPartner } from '../../../../ts/interfaces';
import { GLOBALENUMS, __, FileList, TextEditor } from 'modeling-tool';
import { FormProvider, useForm } from 'react-hook-form';
import { IDropdownOption, Label, Stack } from '@fluentui/react';
import { RaciInformation, PeopleDeptRoleInput, OrganizationSection } from '../../../../components';
import { Modal, RequirementsDropDown, DropdownWithSearch, LinkInput, TextField } from 'modeling-tool';
import { RootState } from '../../../../config/store';
import {
  addProcessAction,
  updateProcessAction,
  selectProcessAction,
  copyProcessAction,
} from '../../../../store/actions/process-actions';
import { fetchLibrariesAction, fetchSitesAction } from '../../../../store/actions/file-actions';
import { BasicListActionEnum, ProcessModalActions } from '../../../../ts/enums';
import SectionHeader, { ListSections } from './section-header';
import { renderSelectDropDown } from '../../../../utils/process-management';
import { isDescendant } from '../../../../utils';
import { BasicListAction } from '../../../../components/basic-list';
import ProcessOwner from './process-owner';
import { useNavigate } from 'react-router';

interface IProcessForm {
  createAsChild: boolean;
  createAsCopy: boolean;
  modalActionType: ProcessModalActions;
  processMapType: GLOBALENUMS.PROCESSMAPNODETYPE;
  selected?: Process;
  visible: boolean;
  closeModal: () => void;
  setCreateAsChild: (arg: boolean) => void;
  setCreateAsCopy: (arg: boolean) => void;
  updateRows: Dispatch<BasicListAction>;
}

const ProcessForm = (props: IProcessForm & PropsFromRedux) => {
  const {
    DropdownDataReducer: {
      externalPartners,
      locations,
      orgUnits,
      processes,
      requirements,
      resources,
      roles,
      staffPositions,
    },
    UserReducer: { settingsMap },
    createAsChild,
    createAsCopy,
    modalActionType,
    processMapType,
    selected,
    visible,
    addProcessAction,
    closeModal,
    copyProcessAction,
    fetchLibrariesAction,
    fetchSitesAction,
    selectProcessAction,
    setCreateAsChild,
    setCreateAsCopy,
    updateRows,
  } = props;

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [listSections, setListSections] = useState<ListSections[]>([
    { sectionKey: 1, title: 'general information', isExpanded: true },
    { sectionKey: 2, title: 'organization', isExpanded: true },
    { sectionKey: 3, title: 'description', isExpanded: true },
    { sectionKey: 4, title: 'assignments', isExpanded: false },
    { sectionKey: 5, title: 'raci', isExpanded: false },
    { sectionKey: 6, title: 'approval', isExpanded: false },
  ]);

  const methods = useForm({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const { control, handleSubmit, reset, formState: isValid } = methods;
  const navigate = useNavigate();

  useEffect(() => {
    if (selected) {
      reset();
      const resources = selected.resources && selected.resources.map((item: Resource) => item.resourceUri);
      let data = null;
      if (!createAsChild) {
        data = {
          ...selected,
          ...getRACIFieldsFormObject(selected),
          attachments: selected.attachments?.length ? selected.attachments?.split('|') : [],
          parent: (selected.parent && selected.parent.resourceUri) || null,
          department: selected.department.map((item: { resourceUri: string }) => item.resourceUri) || null,
          staffPosition: selected.staffPosition?.map((item: { resourceUri: string }) => item.resourceUri) || null,
          role: selected.role?.map((item: { resourceUri: string }) => item.resourceUri) || null,
          externalPartner: selected.externalPartner?.map((item: { resourceUri: string }) => item.resourceUri) || null,
          group: (selected.group && [selected.group.resourceUri]) || null,
          state: selected.state || null,
          createdBy: (selected.createdBy && selected.createdBy.id) || null,
          resources: resources || null,
          processLocations:
            (selected.processLocations && selected.processLocations.map((item: Location) => item.resourceUri)) || null,
          ownerDepartements:
            selected.owner?.departments?.map((item: { resourceUri: string }) => item.resourceUri) || '',
          ownerRoles: selected.owner?.roles?.map((item: { resourceUri: string }) => item.resourceUri) || '',
          ownerStaffPositions:
            selected.owner?.staffPositions?.map((item: { resourceUri: string }) => item.resourceUri) || '',
          ownerExternalPartners:
            selected.owner?.externalPartners?.map((item: ExternalPartner) =>
              item.resourceUri ? item.resourceUri : '',
            ) || [],
        };
      } else {
        data = {
          parent: [selected && processes.find((p) => p.id == selected.id)?.resourceUri] || null,
          group: (selected.group && [selected.group.resourceUri]) || null,
          processLocations:
            (selected.processLocations && selected.processLocations.map((item: Location) => item.resourceUri)) || null,
          department: selected.department.map((item: { resourceUri: string }) => item.resourceUri) || null,
          staffPosition: selected.staffPosition.map((item: { resourceUri: string }) => item.resourceUri) || null,
          role: selected.role.map((item: { resourceUri: string }) => item.resourceUri) || null,
          externalPartner: selected.externalPartner.map((item: { resourceUri: string }) => item.resourceUri) || null,
          attachments: selected.attachments?.length ? selected.attachments?.split('|') : null,
          ownerDepartements:
            selected.owner?.departments?.map((item: { resourceUri: string }) => item.resourceUri) || '',
          ownerRoles: selected.owner?.roles?.map((item: { resourceUri: string }) => item.resourceUri) || '',
          ownerStaffPositions:
            selected.owner?.staffPositions?.map((item: { resourceUri: string }) => item.resourceUri) || '',
          ownerExternalPartners:
            selected.owner?.externalPartners?.map((item: ExternalPartner) =>
              item.resourceUri ? item.resourceUri : '',
            ) || [],
        };
      }
      reset(data);
    } else {
      reset();
    }
  }, [selected, processes]);

  const handleProcessOk = () => {
    if (!isValid) {
      return;
    }

    handleSubmit(
      (data) => {
        setShowLoading(true);
        onFinish(data);
      },
      (err) => {
        console.log(err);
      },
    )();
  };

  const handleProcessCancel = () => {
    closeModal();
    setCreateAsChild(false);
    reset();
    selectProcessAction(false);
  };

  const onFinish = (values: any) => {
    if (selected && selected.id && !createAsChild) {
      const raciValues = getRACIFieldsObject(values, selected);

      values = {
        ...values,
        ...raciValues,
        description: values.description,
        parent:
          (values.parent && Array.isArray(values.parent) && values.parent[0]) ||
          (values.parent && values.parent.length > 0 && values.parent) ||
          null,
        group: (values.group && values.group[0]) || null,
        id: selected.id,
        attachments: values.attachments?.length ? values.attachments.join('|') : null,
        requirementsList: values.requirementsList,
      };

      // remove unnecessary fields for update > would result in error
      delete values['lastVersion'];
      delete values['rootVersion'];
      delete values['requirements'];
      delete values['requirementChapters'];

      if (createAsCopy) {
        copyProcessAction(values, (result) => {
          updateRows({ type: BasicListActionEnum.INSERT_ITEM_ROW, payload: result.data });
          setShowLoading(false);
          closeModal();
          setCreateAsCopy(false);
          reset();
          selectProcessAction(false);
          navigate(`/process-management/processes/${result.data.id}`);
        });
      } else {
        updateProcessAction(values, (result) => {
          updateRows({ type: BasicListActionEnum.UPDATE_ITEM_ROW, payload: result, id: selected.id });
          setShowLoading(false);
          closeModal();
          reset();
          selectProcessAction(false);
        });
      }
    } else {
      const raciValues = getRACIFieldsObject(values);

      values = {
        ...values,
        ...raciValues,
        description: values.description,
        parent: (values.parent && values.parent[0]) || null,
        group: (values.group && values.group[0]) || null,
        attachments: values.attachments?.length ? values.attachments.join('|') : null,
        rootVersion: null,
        lastVersion: null,
        processMapType: processMapType,
        requirementsList: values.requirementsList,
      };

      addProcessAction(values, (result) => {
        updateRows({ type: BasicListActionEnum.INSERT_ITEM_ROW, payload: result.data });
        setShowLoading(false);
        closeModal();
        setCreateAsChild(false);
        reset();
        selectProcessAction(false);
        navigate(`/process-management/processes/${result.data.id}`);
      });
    }
  };

  const filterProcesses = (processes: Process[], selected: Process): Process[] => {
    const isProcessGroup = selected.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP;

    const filterCondition = (process: Process): boolean => {
      const filterSelected =
        process.id !== selected?.id ||
        modalActionType === ProcessModalActions.NEW_SUBPROCESS_GROUP ||
        modalActionType === ProcessModalActions.NEW_SUBPROCESS;
      if (isProcessGroup) {
        return (
          process.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP &&
          !isDescendant(process, selected, processes) &&
          filterSelected
        );
      }
      return filterSelected && !isDescendant(process, selected, processes);
    };

    return processes.filter(filterCondition);
  };

  const renderSelectProcess = () => {
    if (!selected) {
      if (modalActionType === ProcessModalActions.NEW_PROCESS) {
        return processes.map(
          (process: Process) => ({ key: process.resourceUri, text: process.processName }) as IDropdownOption,
        );
      } else {
        return processes
          .filter((process: Process) => process.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP)
          .map((process: Process) => ({ key: process.resourceUri, text: process.processName }) as IDropdownOption);
      }
    }
    const filteredProcesses = filterProcesses(processes, selected);
    return filteredProcesses.map(
      (process: Process) => ({ key: process.resourceUri, text: process.processName }) as IDropdownOption,
    );
  };

  const getModalTitle = () => {
    switch (modalActionType) {
      case ProcessModalActions.NEW_PROCESS:
      case ProcessModalActions.NEW_SUBPROCESS:
        return __('create new process');
      case ProcessModalActions.NEW_PROCESS_GROUP:
      case ProcessModalActions.NEW_SUBPROCESS_GROUP:
        return __('create new process group');
      case ProcessModalActions.COPY_PROCESS:
        return __('create process copy');
      case ProcessModalActions.COPY_PROCESS_GROUP:
        return __('create process group copy');
      case ProcessModalActions.EDIT_PROCESS:
        return __('edit process');
      case ProcessModalActions.EDIT_PROCESS_GROUP:
        return __('edit process group');
      default:
        return '';
    }
  };

  const showGroup = (sectionKey: number): boolean => {
    return listSections.find((group) => group.sectionKey === sectionKey)?.isExpanded || false;
  };

  return (
    <>
      <Modal
        title={getModalTitle()}
        isModalOpen={visible}
        onSave={handleProcessOk}
        onCancel={handleProcessCancel}
        enableProgress={showLoading}
        minWidth={550}
      >
        <FormProvider {...methods}>
          <form>
            <Stack tokens={{ childrenGap: 15 }}>
              <Stack.Item>
                <SectionHeader sectionKey={1} listSections={listSections} setListSections={setListSections} />
                {showGroup(1) && (
                  <>
                    <TextField
                      label={__('title')}
                      control={control}
                      name={'processName'}
                      required={true}
                      rules={{
                        required: __('This field is required'),
                        maxLength: { value: 255, message: __('too many characters') },
                      }}
                    />
                    <DropdownWithSearch
                      labelTitle={__('parent process')}
                      options={renderSelectProcess()}
                      control={control}
                      name={'parent'}
                      placeholder={__('please select process')}
                      styles={{ root: { heigth: 38 } }}
                    />
                    <Label styles={{ root: { fontWeight: 400 } }}>{__('owner')}</Label>
                    <ProcessOwner control={control} renderSelectDropDown={renderSelectDropDown} field="owner" />
                    <SectionHeader
                      sectionKey={2}
                      listSections={listSections}
                      setListSections={setListSections}
                      subSection={true}
                    />
                    {showGroup(2) && (
                      <OrganizationSection
                        orgUnits={orgUnits}
                        roles={roles}
                        staffPositions={staffPositions}
                        externalPartners={externalPartners}
                      />
                    )}
                    <SectionHeader
                      sectionKey={3}
                      listSections={listSections}
                      setListSections={setListSections}
                      subSection={true}
                    />
                    {showGroup(3) && (
                      <TextEditor control={control} name={'description'} placeholder={__('provide description')} />
                    )}
                  </>
                )}
              </Stack.Item>
              <Stack.Item>
                <SectionHeader sectionKey={4} listSections={listSections} setListSections={setListSections} />
                {showGroup(4) && (
                  <>
                    <RequirementsDropDown
                      labelTitle={__('requirements')}
                      placeholder={__('please select requirements')}
                      control={control}
                      name={'requirementsList'}
                      requirements={requirements}
                      multiSelect
                    />
                    <DropdownWithSearch
                      labelTitle={__('resources')}
                      options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.RESOURCES, resources)}
                      control={control}
                      name={'resources'}
                      placeholder={__('please select resource')}
                      multiSelect
                    />
                    <DropdownWithSearch
                      labelTitle={__('locations')}
                      options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.LOCATIONS, locations)}
                      control={control}
                      name={'processLocations'}
                      placeholder={__('please select a location')}
                      multiSelect
                    />
                    <LinkInput control={control} readOnly={false} horizontal />
                    <Label styles={{ root: { fontWeight: 400 } }}>{__('documents')}</Label>
                    <FileList
                      control={control}
                      name={'attachments'}
                      fetchLibrariesAction={fetchLibrariesAction}
                      fetchSitesAction={fetchSitesAction}
                    />
                  </>
                )}
              </Stack.Item>
              {checkSetting(settingsMap, 'enable_raci', 'true') && (
                <Stack.Item>
                  <SectionHeader sectionKey={5} listSections={listSections} setListSections={setListSections} />
                  {showGroup(5) && <RaciInformation readOnly={false} control={control} />}
                </Stack.Item>
              )}
              <Stack.Item>
                <SectionHeader sectionKey={6} listSections={listSections} setListSections={setListSections} />
                {showGroup(6) && (
                  <PeopleDeptRoleInput
                    field="approvedBy"
                    showWorkingGroups={true}
                    readOnly={false}
                    control={control}
                    isFoldable={true}
                  />
                )}
              </Stack.Item>
            </Stack>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, DropdownDataReducer }: RootState) => ({
  UserReducer,
  DropdownDataReducer,
});

const connector = connect(mapStateToProps, {
  addProcessAction,
  selectProcessAction,
  fetchLibrariesAction,
  fetchSitesAction,
  copyProcessAction,
});

export default connector(ProcessForm);
