import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  tableRow: {
    borderBottomColor: tokens.colorNeutralBackground5,
  },
  listItems: {
    fontSize: tokens.fontSizeBase300,
  },
  openTaskCardTitle: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorBrandBackground,
    ...shorthands.margin('0px'),
  },
  openTaskCard: {
    height: '510px',
    maxWidth: '100%',
    ...shorthands.overflow('auto'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground5),
    backgroundColor: tokens.colorNeutralBackground3,
    ...shorthands.padding('24px', '24px', '0px', '24px'),
  },
  headerTaskCard: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tasksListWrapper: {
    ...shorthands.padding('0px', '0px', '15px', '0px'),
    maxHeight: 'calc(100% - 5px)',
  },
  tasksList: {
    height: '460px',
    position: 'relative',
    maxHeight: 'inherit',
    overflowY: 'auto',
  },
  tableHeaderCell: {
    fontWeight: tokens.fontWeightSemibold,
    height: '42px',
    paddingTop: '16px',
  },
});
