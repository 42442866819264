import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import { GLOBALENUMS, OrgChartModeler, __ } from 'modeling-tool';
import { fetchDropdownDataByType } from '../../store/actions/dropdown-data-actions';
import {
  addOrgFullModel,
  fetchOrgChartsAction,
  getOrgChartFullModelByParamsAction,
  toggleEditMode,
  updateOrgFullModel,
} from '../../store/actions/org-action';
import { IsTeamsApp } from '../../config/config';
import * as microsoftTeams from '@microsoft/teams-js';
import { fetchLibrariesAction, fetchSitesAction, fetchLibraryPictureAction } from '../../store/actions/file-actions';
import { checkPermission } from '../../config/permission-utils';
import { redirectToProcess } from '../../utils';
import { useParams } from 'react-router-dom';

const OrganizationModeler = (props: PropsFromRedux) => {
  const {
    UserReducer: { permissions },
    OrgChartReducer: { editMode },
    updateOrgFullModel,
    addOrgFullModel,
    fetchLibrariesAction,
    fetchSitesAction,
    fetchDropdownDataByType,
    getOrgChartFullModelByParamsAction,
    fetchOrgChartsAction,
    toggleEditMode,
  } = props;

  const params = useParams();
  const [fullModel, setFullModel] = useState();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [readonly, setReadonly] = useState<boolean | undefined>(true);
  const userHasPermission = checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_orgfullmodel);

  useEffect(() => {
    editMode ? setReadonly(!userHasPermission) : setReadonly(true);
  }, [editMode]);

  useEffect(() => {
    if (params['id']) {
      getOrCreateOrgChartModel(parseInt(params['id']));
    }
  }, [params['id']]);

  const handleBlockTeams = () => {
    if (IsTeamsApp()) {
      microsoftTeams.teamsCore.registerBeforeUnloadHandler((readyToUnload) => {
        if (window.confirm(__('do you want to leave this page without saving? all unsaved data will be lost.'))) {
          readyToUnload();
          return true;
        } else {
          return false;
        }
      });
    }
  };

  const getOrCreateOrgChartModel = (id: number) => {
    getOrgChartFullModelByParamsAction({ id: id }, (res: any) => {
      if (res?.data?.objects?.length) {
        assignFullModelWithData(res.data.objects[0]);
        fetchOrgChartsAction();
      } else {
        addOrgFullModel({ fromOrgChart: `/api/v1/orgchart/${id}/` }, (res: any) => {
          setFullModel(res.data);
          fetchOrgChartsAction();
        });
      }
    });
  };

  const assignFullModelWithData = (data: any) => {
    // for the pictures of the model, we need to fetch it from sharepoint
    // to make sure the load times are not than long, the pictures are fetched in parallel
    const fullModels = data;
    const nodes = fullModels.nodes;

    // create a promise function for getting the picture, other nodes are immediately resolved
    const getPicturesForNodes = (node: any) => {
      return new Promise((resolve) => {
        if (
          node.nodeType === GLOBALENUMS.ORGANIZATIONCHART.PICTURE &&
          node.nodeData &&
          node.nodeData.length > 0 &&
          node.nodeData[0].picture
        ) {
          try {
            const ids = node.nodeData[0].picture.split('|');
            fetchLibraryPictureAction(ids[0], ids[1], ids[2], (res: any) => {
              node.nodeData[0].localPicture = res;
              resolve(node);
            });
          } catch {
            // picture is not available, resolve the node
            resolve(node);
          }
        } else {
          resolve(node);
        }
      });
    };

    // create the promises for each node
    const promises: any = nodes.map((node: any) => getPicturesForNodes(node));

    // when all promisses are fulfilled, continue
    const processedModel = Promise.all(promises);
    processedModel.then((res) => {
      const result = { ...fullModels, nodes: res };
      setFullModel(result);
      setIsSaving(false);
    });
  };

  return (
    <>
      {fullModel && (
        <OrgChartModeler
          updateOrgFullModel={updateOrgFullModel}
          assignFullModelWithData={assignFullModelWithData}
          fullModel={fullModel}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          fetchDropdownDataByType={fetchDropdownDataByType}
          redirectToProcess={redirectToProcess}
          handleBlockTeams={IsTeamsApp() ? handleBlockTeams : undefined}
          fetchLibrariesAction={fetchLibrariesAction}
          fetchSitesAction={fetchSitesAction}
          fetchLibraryPictureAction={fetchLibraryPictureAction}
          readonly={readonly}
          userHasPermission={userHasPermission}
          toggleEditMode={toggleEditMode}
          editMode={editMode}
        />
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, OrgChartReducer }: RootState) => ({ UserReducer, OrgChartReducer });

const connector = connect(mapStateToProps, {
  updateOrgFullModel,
  addOrgFullModel,
  fetchLibrariesAction,
  fetchSitesAction,
  fetchDropdownDataByType,
  getOrgChartFullModelByParamsAction,
  fetchOrgChartsAction,
  toggleEditMode,
});

export default connector(OrganizationModeler);
