import { IButtonStyles, memoizeFunction, mergeStyleSets } from '@fluentui/react';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    spinnerStyle: {
      paddingRight: '10px',
      paddingLeft: '10px',
    },
  });
});

export const getIconButtonStyles = memoizeFunction(() => {
  return {
    rootDisabled: {
      backgroundColor: 'transparent',
    },
    icon: {
      fontSize: 10,
    },
    rootHovered: {
      backgroundColor: 'transparent',
    },
    rootPressed: {
      backgroundColor: 'transparent',
    },
  } as Partial<IButtonStyles>;
});
