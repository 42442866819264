import { IOrgChart, Meta } from '../../ts/interfaces';

interface OrgChartReducerState {
  orgcharts: IOrgChart[];
  meta?: Meta;
  selectedOrgChart?: IOrgChart;
  editMode?: boolean;
}

export default function reducer(
  state: OrgChartReducerState = {
    orgcharts: [],
    meta: undefined,
    selectedOrgChart: undefined,
    editMode: false,
  },
  action: any,
): OrgChartReducerState {
  switch (action.type) {
    case 'FETCH_ORG_CHARTS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        orgcharts: action.payload.objects,
      });
    }
    case 'SELECT_ORG_CHART': {
      return Object.assign({}, state, {
        selectedOrgChart: action.payload,
      });
    }
    case 'ADD_ORG_CHART': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        orgcharts: [...state.orgcharts, action.payload],
        selectedOrgChart: action.payload,
      });
    }
    case 'UPDATE_ORG_CHART': {
      return {
        ...state,
        orgcharts: state.orgcharts.map((orgchart) => (orgchart.id === action.payload.id ? action.payload : orgchart)),
      };
    }
    case 'CLEAR_ORG_CHART_REDUCER': {
      return Object.assign({}, state, {
        meta: undefined,
        selectedOrgChart: undefined,
        orgcharts: [],
      });
    }
    case 'CLEAR_SELECT_ORG_CHART': {
      return Object.assign({}, state, {
        selectedOrgChart: undefined,
      });
    }
    case 'DELETE_ORG_CHART': {
      return {
        ...state,
        orgcharts: state.orgcharts.filter((orgchart) => orgchart.id !== action.payload),
      };
    }
    case 'ENABLE_EDIT_MODE': {
      return {
        ...state,
        editMode: true,
      };
    }
    case 'TOGGLE_EDIT_MODE': {
      return {
        ...state,
        editMode: !state.editMode,
      };
    }
    default: {
      break;
    }
  }
  return state;
}
