import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  appLayout: {
    backgroundColor: tokens.colorNeutralForegroundOnBrand,
    height: 'calc(100vh)',
  },
  topSection: {
    position: 'relative',
    width: '100%',
  },
  mainBackground: {
    backgroundColor: tokens.colorNeutralForegroundOnBrand,
    height: `calc(100vh - 48px)`, //window height minus header
    display: 'flex',
  },
  mainLayout: {
    marginLeft: '50px',
    height: '100%',
    width: '100%',
    marginTop: '48px',
    display: 'flex',
  },
  containerWithSidebarOpen: {
    width: 'calc(100vw - 363px)',
    height: '100%',
    top: '40px',
  },
  container: {
    width: '100%',
    height: '100%',
    top: '40px',
  },
  breadCrumbStack: {
    width: '100%',
  },
  buttonSection: { paddingRight: '12px', width: 'auto' },
  componentContainer: {
    ...shorthands.padding('0px'),
    width: '100%',
    height: `calc(100vh - 105px)`,
  },
  utilitySideBar: {
    position: 'fixed',
    right: '0px',
    height: `calc(100vh - 48px)`,
    ...shorthands.borderLeft('1px', 'solid', 'rgba(112, 112, 112, 0.14)'),
    backgroundColor: '#fff !important',
    zIndex: 1000,
  },
  breadCrumbWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
