import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    breadcrumbs: {
      margin: 0,
      height: 56,
      display: 'flex',
      alignItems: 'center',
    },
    headerWrapper: {
      zIndex: 1,
    },
  });
});
