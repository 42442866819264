import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Checkbox,
  IColumn,
  SelectionMode,
  TooltipHost,
  PrimaryButton,
  DirectionalHint,
  Spinner,
  SpinnerSize,
  Text,
} from '@fluentui/react';
import { RootState } from '../../../config/store';
import { __ } from 'modeling-tool';
import { msalInstance } from '../../..';
import { useStyles } from './tenant-permissions-styles';
import { Providers } from '@microsoft/mgt-element';
import { GetRedirectUri } from '../../../config/config';
import { BasicList, GLOBALENUMS } from 'modeling-tool';

interface Permission {
  permission: string;
  description: string;
  state: boolean;
}
const TenantPermissions = (props: PropsFromRedux) => {
  const {} = props;
  const classes = useStyles();

  const [permissions, setPermissions] = useState<Permission[]>([
    { permission: 'User.Read', description: __('User.Read-permission'), state: false },
    { permission: 'User.ReadBasic.All', description: __('User.ReadBasic.All-permission'), state: false },
    { permission: 'User.Read.All', description: __('User.Read.All-permission'), state: false },
    { permission: 'People.Read', description: __('People.Read-permission'), state: false },
    { permission: 'Group.Read.All', description: __('Group.Read.All-permission'), state: false },
    { permission: 'Sites.Read.All', description: __('Sites.Read.All-permission'), state: false },
    { permission: 'Sites.ReadWrite.All', description: __('Sites.ReadWrite.All-permission'), state: false },
    { permission: 'Mail.Send', description: __('Mail.Send-permission'), state: false },
    { permission: 'TeamsActivity.Send', description: __('TeamsActivity.Send-permission'), state: false },
  ]);

  const [done, setDone] = useState<boolean>(false);

  useEffect(() => {
    checkGrantPermission(permissions, 0);
  }, []);

  const sendPermission = (permission: string) => {
    const account = msalInstance.getAllAccounts();
    const tenantId = account[0]['tenantId'];
    const tokenObj = account[0]['idTokenClaims'];
    const clientId = tokenObj && tokenObj['aud'];

    const w = 600;
    const h = 700;
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;

    tokenObj &&
      window.open(
        'https://login.microsoftonline.com/' +
          tenantId +
          '/v2.0/adminconsent?client_id=' +
          clientId +
          `&redirect_uri=${GetRedirectUri()}&scope=https://graph.microsoft.com/` +
          permission,
        '_blank',
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
          w +
          ', height=' +
          h +
          ', top=' +
          top +
          ', left=' +
          left,
      );
  };

  const checkGrantPermission = (list: Permission[], index: number) => {
    if (list && list.length > index) {
      const scopes: string[] = [list[index]['permission']];
      Providers.globalProvider.getAccessTokenForScopes(...scopes).then(function (loginResponse) {
        if (loginResponse !== null) {
          list[index]['state'] = true;
        }
        checkGrantPermission(list, index + 1);
      });
    } else {
      setPermissions(list);
      setDone(true);
    }
  };

  const columns: IColumn[] = [
    {
      key: 'permission',
      name: __('permission'),
      fieldName: 'permission',
      minWidth: 170,
      maxWidth: 200,
      isResizable: true,
      onRender: ({ permission }: Permission) => {
        return permission && <>{permission}</>;
      },
    },
    {
      key: 'description',
      name: __('description'),
      fieldName: 'description',
      minWidth: 230,
      maxWidth: 250,
      isResizable: true,
      onRender: ({ description }: Permission) => {
        return description && <>{description}</>;
      },
    },
    {
      key: 'state',
      name: __('state'),
      fieldName: 'state',
      minWidth: 130,
      maxWidth: 150,
      isResizable: true,
      onRender: ({ state }: Permission) => {
        return <Checkbox checked={state} disabled={true} />;
      },
    },
    {
      name: '',
      key: 'actions',
      minWidth: 140,
      maxWidth: 170,
      onRender: (item) => {
        if (!item.state) {
          return (
            <TooltipHost content={__('grant permission')} directionalHint={DirectionalHint.bottomCenter}>
              <PrimaryButton onClick={() => sendPermission(item.permission)}>{__('grant permission')}</PrimaryButton>
            </TooltipHost>
          );
        } else return <></>;
      },
    },
  ];

  return (
    <div className={classes.wrapper}>
      <div>
        <Text>
          {__(
            'You have to be either Application Administrator or Global Administrator in the tenant, to grant permissions.',
          )}
        </Text>
      </div>
      {!done && (
        <div>
          <Spinner size={SpinnerSize.large} />
        </div>
      )}

      {done && (
        <>
          <BasicList
            setKey={GLOBALENUMS.BASICLISTTYPES.PERMISSIONS_LIST}
            items={permissions}
            columns={columns}
            selectionMode={SelectionMode.none}
            className={classes.permissionListContainer}
          />
        </>
      )}
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({}: RootState) => ({});
const connector = connect(mapStateToProps);
export default connector(TenantPermissions);
