import { GLOBALENUMS, toCamel } from 'modeling-tool';
import { Process, RequirementChapter } from '../../../ts/interfaces';
import { BasicListActionEnum } from '../../../ts/enums';
import { extractNumericIdFromUrl, mapUriListToIdList } from '../../../utils';

export interface BasicListAction {
  type?: string;
  id?: number;
  level?: number;
  payload?: RequirementChapter[] | Process[];
  children?: any;
  filtered?: boolean;
  orderByColumns?: string;
  trigger?: boolean;
}

export interface Row {
  id: number;
  parent: RequirementChapter[] | Process[] | null | any;
  children?: RequirementChapter[] | Process[];
  isExpanded?: boolean;
  isHidden: boolean;
  iconTrigger?: boolean;
  childrenFetched?: boolean;
  level: number;
  refresh: boolean;
}
export const basicListReducer = (rows: any, action: BasicListAction) => {
  switch (action.type) {
    case BasicListActionEnum.TOGGLESUBROW:
      return toggleSubRow(rows, action.id ? action.id : 0, action.level ? action.level : 0, action.children);
    case BasicListActionEnum.UPDATE_ITEMS:
      return updateItemList(rows, action.payload, action.filtered, action.orderByColumns);
    case BasicListActionEnum.ON_OVER_ITEM:
      return setTrigger(rows, action.id, action.trigger);
    case BasicListActionEnum.INSERT_ITEM_ROW:
      return insertItemRow(rows, action.payload);
    case BasicListActionEnum.UPDATE_ITEM_ROW:
      return updateItemRow(rows, action.id, action.payload);
    case BasicListActionEnum.DELETE_ITEM_ROW:
      return removeItemRow(rows, true, action.id);
    default:
      return rows;
  }
};

const toggleSubRow = (rows: Row[], id: number, level: number, children?: any): Row[] => {
  const rowIndex = rows.findIndex((row: Row) => row.id === id && row.level === level);
  const row = rows[rowIndex];
  const newRowChildren = getChildren(row);

  if (!row.isExpanded) {
    rows.map((row: Row) => {
      if (row.level === level + 1 && newRowChildren.includes(row.id)) {
        row.isHidden = false;
      }
    });

    if (children) {
      const newRows = [...rows.slice(0, rowIndex + 1), ...children, ...rows.slice(rowIndex + 1)];
      newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, childrenFetched: true };
      rows = newRows;
    } else {
      rows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
    }
  } else {
    setHiddenProperty(rows, newRowChildren, level);
    rows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
  }
  return rows;
};

const updateItemList = (rows: Row[], payload: any, filtered?: boolean, orderByColumns?: string): Row[] | undefined => {
  // if a filter is active, display all items from the filter response as if they are level 0
  if (filtered && payload) {
    payload.map((item: any) => {
      item.level = 0;
    });
    return payload;
  } else {
    const result = payload?.filter((item: any) => item.parent === null);

    // when doing any reloads, check if there is any item open and if so, keep it open

    result?.map((item: any) => {
      const rowElement = rows.find((row) => row.id === item.id);
      item.isExpanded = rowElement && rowElement.isExpanded;
      item.childrenFetched = rowElement && rowElement.isExpanded;
      return item;
    });

    // additionally insert the fetched children back in to the list
    const expandedRows = result?.filter((item: any) => item.isExpanded);
    if (expandedRows && result) {
      expandedRows.forEach((element: Row) => {
        const rowIndexStart = rows.findIndex((row: Row) => row.id === element.id);
        let nextParentIndex = -1;
        nextParentIndex = getParentIndex(rows, rowIndexStart, element);

        let elementsToInsert = rows.slice(rowIndexStart + 1, nextParentIndex);
        const newRowIndexStart = result.findIndex((row: Row) => row.id === element.id);

        // is some sorting is applied, we sort the data in the frontend, since there is no paging or anything children
        elementsToInsert = sortChildrenRows(elementsToInsert, orderByColumns);
        result.splice(newRowIndexStart + 1, 0, ...elementsToInsert);
      });
    }

    return result;
  }
};

const sortChildrenRows = (rows: any, orderByColumns?: string) => {
  if (rows.length > 0 && orderByColumns) {
    const columns = orderByColumns.split('&');

    // a column always looks like order_by=[-][columnName]; with no sorting on process list its the second column
    const column = columns.length == 1 ? columns[0].split('=') : columns[1].split('=');
    if (column.length == 2) {
      const columnDefiner = column[1].split('-');

      // if a "-"" exists its descending, otherwise ascending
      let sortColumn = columnDefiner.length == 2 ? columnDefiner[1] : columnDefiner[0];
      const sortDirection = columnDefiner.length == 2 ? 'desc' : 'asc';

      // for nested values we need to search for "__" and then split it by object and name
      const subColumns = sortColumn.split('__');
      let sortObject = '';
      if (subColumns.length > 1) {
        sortColumn = toCamel(subColumns[1]);
        sortObject = toCamel(subColumns[0]);
      } else {
        sortColumn = toCamel(sortColumn);
      }

      if (sortColumn && sortDirection) {
        rows.sort((a: any, b: any) => {
          const aValue: any = sortObject ? a[sortObject][sortColumn] : a[sortColumn];
          const bValue: any = sortObject ? b[sortObject][sortColumn] : b[sortColumn];

          if (aValue && bValue) {
            if (aValue < bValue) {
              return sortDirection === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
              return sortDirection === 'asc' ? 1 : -1;
            }
          }
          return 0;
        });
      }
    }
  }

  return rows;
};

const insertItemRow = (rows: any[], payload: any) => {
  // if there are rows, insert the rows depending on the parent, otherwise just add the new row
  if (rows.length > 0) {
    if (payload.parent) {
      rows = insertWithParent(rows, payload);
    }
    // if new group or parent does not have a parent
    else if (!payload.parent) {
      rows = insertWithoutParent(rows, false, payload);
    }
  } else if (rows.length == 0) {
    // if there is no row, just add either a group or a item to the list
    rows.push(payload);
  }
  return rows;
};

const removeItemRow = (rows: any[], moveChildren: boolean, id?: number) => {
  // on remove, check if the item has a parent. If so, remove the item from the children of the parent
  const item = rows.find((row: Row) => row.id == id);
  if (item && item.parent) {
    const parent = rows.find((row: any) => row.id == item?.parent?.id);
    const parentIndex = rows.findIndex((row) => row.id == item?.parent?.id);
    if (parent) {
      parent.children = parent.children?.filter(
        (child: any) => extractNumericIdFromUrl(child) != extractNumericIdFromUrl(item.resourceUri),
      );

      if (moveChildren) {
        rows = moveChildrenUp(rows, item, parent);
      }

      parent.isExpanded = parent?.children?.length ? parent.children.length > 0 : false;
      parent.childrenFetched = parent?.children?.length ? parent.childrenFetched : false;
      parent.refresh = !parent.refresh;
      rows[parentIndex] = parent;
    }
  } else if (item && moveChildren) {
    rows = moveChildrenUp(rows, item);
  }

  // remove the row of the item
  rows = rows.filter((row: Row) => row.id !== id);

  return rows;
};

const moveChildrenUp = (rows: any[], item: Row, parent?: Row) => {
  // if the item has children, move them up to the level of the parent and set the new parent
  // and collapse opened subitems
  if (item && item.childrenFetched && item.children) {
    item.children.forEach((child: any) => {
      const childRow = rows.find((row) => row.id == extractNumericIdFromUrl(child));
      if (childRow) {
        childRow.parent = parent ? parent : undefined;
        childRow.level = item ? item.level : 0;
        childRow.isExpanded = false;
        childRow.childrenFetched = false;
        childRow.refresh = !childRow.refresh;
        rows = updateItemRow(rows, childRow.id, childRow);
      }
    });

    // find and remove rows where level to parent level does not fit anymore
    const findLevelWrong = (row: Row) => {
      const parent = rows.find((p) => row.parent?.id == p.id);
      if (parent) return row.level - parent.level == 1;
      else return true;
    };
    rows = rows.filter(findLevelWrong);
  }

  return rows;
};

const setHiddenProperty = (rows: Row[], newRowChildren: number[], level: number) => {
  rows.forEach((row) => {
    // when filtering there is the possibility, that the same item appears more than ones, due to grouping
    // we want to avoid that all items are hidden > only the ones of the group we actually collapsed.
    // therefore we check on which level the click occured, since it can't be the same level for all
    if (row.level === level + 1 && newRowChildren.includes(row.id)) {
      row.isHidden = true;
      row.isExpanded = false;
      if (row.children) {
        setHiddenProperty(rows, getChildren(row), row.level);
      }
    }
  });
};

const getChildren = (item: any): number[] => {
  const childList: number[] = mapUriListToIdList(item.children);
  return childList;
};

const setTrigger = (rows: Row[], id?: number, trigger?: boolean) => {
  rows = rows.map((row: any) => {
    if (row.id === id) {
      row.iconTrigger = trigger;
    }
    return row;
  });
  return rows;
};

const insertWithParent = (rows: Row[], payload?: any) => {
  const targetParent = rows.find((row) => row.id == payload.parent.id);
  const targetParentIndex = rows.findIndex((row) => row.id == payload.parent.id);
  // if target parent not on page, or not visible (deeper down the tree) do nothing
  if (targetParentIndex != -1 && targetParent) {
    // put the rows between the target parent and the next on the same level in one array if children of this parent are fetched
    if (targetParent.childrenFetched) {
      if (!targetParent.isExpanded) {
        payload.isHidden = true;
      }

      let nextParentIndex = -1;

      nextParentIndex = getParentIndex(rows, targetParentIndex, targetParent);

      // take those rows, and insert the new element as if it was on root level
      let rowsInBetween: any = rows.slice(targetParentIndex + 1, nextParentIndex);
      rowsInBetween = insertWithoutParent(rowsInBetween, true, payload);

      // replace the rows in between with the new rows
      rows.splice(targetParentIndex + 1, nextParentIndex - targetParentIndex - 1, ...rowsInBetween);
    }

    // add new process to the children of the parent and force expander icon to refresh
    const minResource =
      payload.resourceUri.indexOf('process_min') > -1
        ? payload.resourceUri
        : payload.resourceUri.replace('process', 'process_min');
    rows[targetParentIndex].children?.push(minResource as never);
    rows[targetParentIndex].refresh = !rows[targetParentIndex].refresh;
  }
  return rows;
};

const insertWithoutParent = (rows: any, isSubRows: boolean, payload?: any) => {
  rows.unshift(payload);
  return rows;
};

const updateItemRow = (rows: Row[], id?: number, payload?: any) => {
  payload.isExpanded = false;
  payload.childrenFetched = false;
  payload.refresh = !payload.refresh;
  rows = removeItemRow(rows, false, id);
  rows = insertItemRow(rows, payload);

  // if the parent changend, remove the children and collapse moved item
  if (payload && payload?.children) {
    payload.children.forEach((child: string) => {
      const childRow = rows.find((row) => row.id == extractNumericIdFromUrl(child));
      if (childRow) {
        // filter every child item that has the childRow as parent
        rows = updateItemRow(rows, childRow.id, childRow);
        rows = rows.filter((row) => row.id !== childRow.id);
      }
    });
  }
  const findParent = (row: any) => {
    if (row.parent) {
      return !!rows.find((p) => row.parent?.id == p.id);
    }
    return true;
  };
  rows = rows.filter(findParent);
  return rows;
};

const getParentIndex = (rows: Row[], indexStart: any, element: Row) => {
  let nextParentIndex = -1;

  // otherwise take everything between the target parent and the next on the same level
  for (let next = indexStart + 1; next < rows.length; next++) {
    if (rows[next].level <= element.level) {
      nextParentIndex = next;
      break;
    }
  }

  // if the target parent is the last, take everything between the target parent and the end of the list
  if (nextParentIndex == -1) {
    nextParentIndex = rows.length;
  }
  return nextParentIndex;
};
