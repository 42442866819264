import React, { useEffect, useReducer, useRef, useState, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { BasicList, GLOBALENUMS, PeopleWithPopover, StyleContext } from 'modeling-tool';
import { checkPermission } from '../../../config/permission-utils';
import { RootState } from '../../../config/store';
import { getDateFormatted } from '../../../config/utils';
import { __ } from 'modeling-tool';
import {
  clearSelectProcessAction,
  deleteProcessAction,
  searchProcessesAction,
  selectProcessByIdAction,
  searchMinProcessesAction,
  fetchProcessForListAction,
  updateProcessAction,
  fetchProcessChildrenAction,
  setOrderByColumnsAction,
  addQueryParamsAction,
} from '../../../store/actions/process-actions';
import { fetchProcessGroupsAction } from '../../../store/actions/process-group-actions';
import {
  fetchProcessReminderAction,
  selectProcessReminderByIdAction,
  deleteProcessReminderAction,
} from '../../../store/actions/process-reminder-actions';
import { ExternalPartner, Process, Role, StaffPosition } from '../../../ts/interfaces';
import { ProcessForm } from './process-form';
import { SendForm } from './send-form';
import { ProcessReminderForm } from './process-reminder-form';
import { PaginationProcess } from './pagination-process';
import {
  Selection,
  IColumn,
  IconButton,
  PrimaryButton,
  Text,
  Stack,
  TooltipHost,
  DirectionalHint,
  ActionButton,
  Link,
  Spinner,
  SpinnerSize,
  DefaultButton,
  IStackTokens,
  CheckboxVisibility,
  DetailsRow,
  IDetailsRowStyles,
  IDetailsListProps,
  Shimmer,
  Icon,
} from '@fluentui/react';
import { ProcessVersionsHistory } from '../../../components/process/process-versions-history';
import { getStyles } from './processes-styles';
import { useBoolean } from '@fluentui/react-hooks';
import moment from 'moment';
import { Modal, Popover } from 'modeling-tool';
import { History } from '../../process-details/History';
import { RenderDynamicContent } from '../../../components';
import ProcessMenu from '../../../components/process/process-list/process-menu';
import { BasicListActionEnum, ProcessModalActions } from '../../../ts/enums';
import { useLocation, useNavigate } from 'react-router';
import { isDescendant, onRenderQuery, updateColumnsWithLanguage } from '../../../utils/shared/';
import { ExpandedIcon, basicListReducer } from '../../../components/basic-list';
import { ProcessFilter } from './process-filter';
import { setQueryParams } from './process-filter/process-filter-utility/process-filter-utils';
import { StatusTag } from '../../../components/process/status-tag';
import { fetchDropdownDataByType } from '../../../store/actions/dropdown-data-actions';

interface ProcessesProps {
  hasReminder: boolean;
}
type OrganizationUnit = StaffPosition | Role | ExternalPartner;

const dropDownData = [
  GLOBALENUMS.DROPDOWNDATA.ORG_UNITS_AND_STAFF_POSITIONS,
  GLOBALENUMS.DROPDOWNDATA.ROLES,
  GLOBALENUMS.DROPDOWNDATA.WORKING_GROUPS,
  GLOBALENUMS.DROPDOWNDATA.LOCATIONS,
  GLOBALENUMS.DROPDOWNDATA.PROCESSES,
  GLOBALENUMS.DROPDOWNDATA.RESOURCES,
  GLOBALENUMS.DROPDOWNDATA.REQUIREMENTS,
  GLOBALENUMS.DROPDOWNDATA.EXTERNAL_PARTNERS,
  GLOBALENUMS.DROPDOWNDATA.ORG_UNITS,
  GLOBALENUMS.DROPDOWNDATA.STAFF_POSITIONS,
];

const Processes = (props: PropsFromRedux & ProcessesProps) => {
  const {
    ProcessReducer: { processes, selectedProcess, params, orderByColumns, filterValues },
    ProcessReminderReducer: { processreminders, selectedProcessReminder },
    UserReducer: { permissions, language },
    selectProcessByIdAction,
    clearSelectProcessAction,
    searchProcessesAction,
    fetchProcessReminderAction,
    selectProcessReminderByIdAction,
    deleteProcessReminderAction,
    fetchProcessChildrenAction,
    setOrderByColumnsAction,
    fetchDropdownDataByType,
    hasReminder,
  } = props;

  const [modalVisible, { setTrue: showModal, setFalse: dismissModal }] = useBoolean(false);
  const [createAsChildModal, setCreateAsChildModal] = useState(false);
  const [createAsCopy, setCreateAsCopy] = useState(false);
  const [draggedItem, setDraggedItem] = useState<Process | undefined>(undefined);
  const [sendVisible, setSendVisible] = useState(false);
  const [selectedProcesses, setSelectedProcesses] = useState<Process[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState<string>('20');
  const [currentOffset, setCurrentOffset] = useState(0);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [showProcessHistoryModal, setShowProcessHistoryModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [changeParams, { toggle: toggleChangeParams }] = useBoolean(false);
  const [reminderModalVisible, setReminderModalVisible] = useState<boolean>(false);
  const [editReminderVisible, setEditReminderVisible] = useState<boolean>(false);
  const [sendModel, setSendModel] = useState<boolean>(false);
  const [processMapType, setProcessMapType] = useState<GLOBALENUMS.PROCESSMAPNODETYPE>(
    GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS,
  );
  const [modalActionType, setModalActionType] = useState<ProcessModalActions>(ProcessModalActions.NEW_PROCESS);

  const { useStyleProps } = useContext(StyleContext);
  const styleProps = useStyleProps();
  const processesStyles = getStyles(styleProps);

  const navigate = useNavigate();
  const location = useLocation();

  const departmentCellWidthRef = useRef<number>(250);
  const locationCellWidthRef = useRef<number>(250);
  const ownerCellWidthRef = useRef<number>(200);

  const buttonStackTokens: IStackTokens = { childrenGap: 20, padding: 5 };

  const [rows, updateRows] = useReducer(basicListReducer, []);

  useEffect(() => {
    fetchDropdownDataByType(dropDownData);
  }, []);

  useEffect(() => {
    clearSelectProcessAction();
    setOrderByColumnsAction('order_by=-process_map_type&order_by=process_name');
    fetchProcessReminderAction();
  }, []);

  const _rowsSelected: Selection = new Selection({
    onSelectionChanged: () => _getSelectionDetails(),
  });

  const _getSelectionDetails = () => {
    const selectionCount = _rowsSelected.getSelectedCount();
    if (selectionCount > 0) {
      setSelectedProcesses(_rowsSelected.getSelection() as Process[]);
    } else {
      setSelectedProcesses([]);
    }
  };

  const renderWithShimmer = (record: Process, child: JSX.Element) => {
    if (record.resourceUri && record.resourceUri.indexOf('_min') > -1) {
      return (
        <div className={processesStyles.cellShimmer}>
          <Shimmer />
        </div>
      );
    } else {
      return <>{child}</>;
    }
  };

  const columns: IColumn[] = [
    {
      key: 'process_name',
      name: __('title'),
      fieldName: 'process_name',
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      headerClassName: processesStyles.processHeader,
      className: processesStyles.processName,
      showSortIconWhenUnsorted: true,
      onRender: (record: Process) => {
        const [displayContextualMenu, setDisplayContextualMenu] = useState(false);
        // use effect call to rerender the selected row after deleting, to ensure that the context menu targets the correct model
        useEffect(() => {
          _getSelectionDetails();
        }, [displayContextualMenu]);
        return (
          <>
            {record && (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                className={processesStyles.processNameStack}
              >
                <Stack
                  className={processesStyles.cell}
                  horizontal
                  verticalAlign="center"
                  horizontalAlign="start"
                  style={{ paddingLeft: record.level * 15 }}
                >
                  <Stack horizontal verticalAlign="center">
                    <ExpandedIcon
                      record={record}
                      isExpanded={record.isExpanded === true}
                      refresh={record.refresh}
                      fetchChildren={fetchProcessChildrenAction}
                      updateRows={updateRows}
                    />
                  </Stack>
                  <Stack
                    grow
                    disableShrink
                    className={!record.children?.length ? processesStyles.processNameContainer : ''}
                  >
                    {record.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS ? (
                      <Link
                        className={`${processesStyles.cellText} `}
                        onClick={() => {
                          record.children?.length && fetchProcessChildrenAction(record.id);
                          navigate(`/process-management/processes/${record.id}`);
                        }}
                      >
                        {record.processName}
                      </Link>
                    ) : (
                      <div className={processesStyles.processGroupName}>{record.processName}</div>
                    )}
                  </Stack>
                </Stack>

                <Stack
                  horizontal
                  horizontalAlign="end"
                  verticalAlign="center"
                  disableShrink
                  styles={{ root: { width: 50, flexShrink: 100 } }}
                  className={processesStyles.iconContainer}
                >
                  <IconButton
                    id={`more-options-menu-${record.id}`}
                    iconProps={{ iconName: 'More', color: '#8a2be2' }}
                    className={record.iconTrigger ? processesStyles.iconButton : processesStyles.iconButtonHidden}
                    onClick={() => setDisplayContextualMenu(true)}
                  />
                  <ProcessMenu
                    record={record}
                    displayContextualMenu={displayContextualMenu}
                    showProcessModal={showProcessModal}
                    setDisplayContextualMenu={setDisplayContextualMenu}
                    setSendVisible={setSendVisible}
                    setCreateAsChildModal={setCreateAsChildModal}
                    setCreateAsCopy={setCreateAsCopy}
                    setReminderModalVisible={setReminderModalVisible}
                    hasReminder={hasReminder}
                    setShowSpinner={setShowSpinner}
                    setSendModel={setSendModel}
                    getProcesses={getProcesses}
                    setShowProcessHistoryModal={setShowProcessHistoryModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setProcessMapType={setProcessMapType}
                    updateRows={updateRows}
                  />
                </Stack>
              </Stack>
            )}
          </>
        );
      },
    },
    {
      name: __('organization'),
      fieldName: 'department',
      key: 'department',
      minWidth: 90,
      maxWidth: 250,
      isResizable: true,
      onColumnResize: (width: number | undefined) => {
        if (width) {
          departmentCellWidthRef.current = width;
        }
      },
      onRender: (record: Process & { departmentCellWidth: number }) => renderOrganizationUnits(record),
    },
    {
      name: __('owner'),
      fieldName: 'processOwner',
      key: 'processOwner',
      minWidth: 90,
      maxWidth: 200,
      isResizable: true,
      onColumnResize: (width: number | undefined) => {
        if (width) {
          ownerCellWidthRef.current = width;
        }
      },
      onRender: (record: Process) => onRenderProcessOwner(record),
    },
    {
      name: __('location'),
      fieldName: 'location',
      key: 'processLocations',
      minWidth: 90,
      maxWidth: 250,
      isResizable: true,
      onColumnResize: (width: number | undefined) => {
        if (width) {
          locationCellWidthRef.current = width;
        }
      },
      onRender: (record: Process) =>
        renderWithShimmer(
          record,
          <RenderDynamicContent
            id={record.id}
            record={record.processLocations}
            cellWidth={locationCellWidthRef}
            isDepartment={false}
          />,
        ),
    },
    {
      name: __('status'),
      fieldName: 'state',
      key: 'state__project_status_name',
      minWidth: 185,
      maxWidth: 200,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: (record: Process) => {
        return (
          <Stack wrap horizontal>
            {record.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS ? (
              <>
                <StatusTag state={record.state} size={{ width: 150, height: 24 }} />
                {record.state &&
                  checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_process) &&
                  record.state.type === GLOBALENUMS.PROCESSSTATUS.IN_RECORDING && (
                    <Stack.Item className={processesStyles.reviewIcon}>
                      <TooltipHost
                        content={__('view the process review')}
                        directionalHint={DirectionalHint.bottomCenter}
                      >
                        <Link
                          onClick={() => {
                            navigate(`/process-management/processes/${record.id}/applications`);
                          }}
                        >
                          <Icon iconName="SingleColumnEdit" />
                        </Link>
                      </TooltipHost>
                    </Stack.Item>
                  )}
              </>
            ) : (
              <></>
            )}
          </Stack>
        );
      },
    },
    {
      name: '',
      key: 'actions',
      minWidth: 30,
      maxWidth: 30,
      onRender: (record: Process) => {
        return (
          <>
            {record.state &&
              checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_processreminder) &&
              record.state.type === GLOBALENUMS.PROCESSSTATUS.DONE &&
              hasReminderProcess(record) && (
                <TooltipHost content={onRenderDeadline(record)} directionalHint={DirectionalHint.bottomCenter}>
                  <IconButton
                    id={`processreminder-${record.id}`}
                    iconProps={{ iconName: 'Timer' }}
                    style={{ color: isDeadline(record) ? '#d13438' : '#498205' }}
                    onClick={() => setEditReminderVisible(true)}
                  ></IconButton>
                </TooltipHost>
              )}
          </>
        );
      },
    },
    {
      name: __('version'),
      fieldName: 'versionNumber',
      key: 'version_number',
      minWidth: 30,
      maxWidth: 60,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: (record: Process) => (
        <div className={processesStyles.cellText}>
          {record.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS ? record.versionNumber : ''}
        </div>
      ),
    },
    {
      name: __('last changed'),
      fieldName: 'lastChanged',
      key: 'last_changed',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ lastChanged }: Process) => (
        <div className={processesStyles.cellText}>{lastChanged && getDateFormatted(lastChanged, 'lll')}</div>
      ),
    },
    {
      name: __('last modified by'),
      fieldName: 'lastModifiedBy',
      key: 'last_modified_by',
      minWidth: 90,
      maxWidth: 200,
      isResizable: true,
      onRender: ({ lastModifiedBy, id }: Process) =>
        lastModifiedBy && lastModifiedBy.length ? (
          <PeopleWithPopover employees={lastModifiedBy} id={id} field={'lastModifiedBy'} />
        ) : (
          ''
        ),
    },
  ];

  const [tableColumns, setTableColumns] = useState<IColumn[]>(columns);

  //This useEffect is used to update the table columns
  useEffect(() => {
    const updatedColumns = updateColumnsWithLanguage(columns);
    setTableColumns(updatedColumns);
  }, [language]);

  //#region: Loading processes
  useEffect(() => {
    const queryParams = setQueryParams(filterValues, params);
    addQueryParamsAction(queryParams);
  }, [filterValues]);

  useEffect(() => {
    if (params && params.length > 0) {
      getProcesses();
    }
  }, [location.search, changeParams, orderByColumns, itemsPerPage]);

  useEffect(() => {
    const filtered = params ? !!params.find((q: any) => q.filtered) : false;
    updateRows({
      type: BasicListActionEnum.UPDATE_ITEMS,
      payload: processes,
      filtered: filtered,
      orderByColumns: orderByColumns,
    });
  }, [processes]);

  const renderOrganizationUnits = (record: Process) => {
    const items: OrganizationUnit[] = [
      record.department,
      record.role,
      record.staffPosition,
      record.externalPartner,
    ].flatMap((field) => field ?? []);

    return renderWithShimmer(
      record,
      <RenderDynamicContent id={record.id} record={items} cellWidth={departmentCellWidthRef} isDepartment={true} />,
    );
  };

  const getProcesses = () => {
    const query = onRenderQuery(params);
    const newQuery = query.substring(0, query.length - 1);
    const querySplited = query.split('?')[1];
    const finalQuery = location.search === '' ? newQuery : `${location.search}${querySplited}`;

    searchProcessesAction(finalQuery, orderByColumns);
  };
  //#endregion

  const hasReminderProcess = (selected: Process): boolean => {
    const hasReminder = processreminders.find((reminder: any) => {
      if (reminder && reminder.process && selected.id === reminder.process.id) {
        return true;
      }
    });
    return hasReminder ? true : false;
  };

  const isDeadline = (selected: Process): boolean => {
    const todayDate = moment().format('YYYY-MM-DD HH:mm');
    const deadlineReached = processreminders.find((reminder: any) => {
      if (reminder && reminder.process && selected.id === reminder.process.id) {
        if (
          moment(todayDate).isSame(moment.utc(reminder.deadline).local()) ||
          moment(todayDate).isAfter(moment.utc(reminder.deadline).local())
        )
          return true;
      }
    });
    return deadlineReached ? true : false;
  };

  const showProcessModal = (processModalAction: ProcessModalActions) => {
    setModalActionType(processModalAction);
    setProcessMapType(
      processModalAction === ProcessModalActions.NEW_PROCESS_GROUP ||
        processModalAction === ProcessModalActions.NEW_SUBPROCESS_GROUP
        ? GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP
        : GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS,
    );
    if (
      processModalAction === ProcessModalActions.NEW_PROCESS_GROUP ||
      processModalAction === ProcessModalActions.NEW_PROCESS
    ) {
      selectProcessByIdAction(0);
    }
    showModal();
  };

  const onDeleteProcesses = () => {
    for (const process of selectedProcesses) {
      deleteProcessAction(process, () => {
        updateRows({ type: BasicListActionEnum.DELETE_ITEM_ROW, id: process.id });
      });
    }
  };

  const findReminderByProcess = (process: Process): any => {
    for (let i = 0; i < processreminders.length; i++) {
      if (processreminders[i].process.id == process.id) {
        return processreminders[i];
      }
    }
    return 0;
  };

  const onRenderDeadline = (record: Process): string => {
    const reminder = findReminderByProcess(record);
    const strDeadline = reminder.deadline.toString();
    return getDateFormatted(strDeadline);
  };

  const onProcessReminderEdit = () => {
    selectProcessByIdAction(selectedProcesses[0].id);
    const reminderId = findReminderByProcess(selectedProcesses[0]).id;
    selectProcessReminderByIdAction(reminderId);
    setReminderModalVisible(true);
    setEditReminderVisible(false);
  };

  const onDeleteRemider = () => {
    const reminderId = findReminderByProcess(selectedProcesses[0]).id;
    deleteProcessReminderAction(reminderId);
    setEditReminderVisible(false);
  };

  const handleEditCancel = () => {
    setEditReminderVisible(false);
  };

  const onRenderProcessOwner = (record: Process) => {
    const { owner, id } = record;
    const sections = [{ key: 'departments' }, { key: 'roles' }, { key: 'staffPositions' }, { key: 'externalPartners' }];

    const renderSection = (item: any[], isDepartment: boolean) =>
      renderWithShimmer(
        record,
        <RenderDynamicContent id={id} record={item} cellWidth={ownerCellWidthRef} isDepartment={isDepartment} />,
      );

    return (
      <>
        {owner?.employees && <PeopleWithPopover employees={owner.employees} id={id} field={'owner'} />}
        {sections.map(({ key }) => {
          const item = owner?.[key];
          return item && !!item.length && renderSection(item, true);
        })}
      </>
    );
  };

  const renderProcessesBtns = () => {
    return (
      <>
        {
          <TooltipHost content={__('History')} directionalHint={DirectionalHint.bottomCenter}>
            <ActionButton iconProps={{ iconName: 'History' }} onClick={() => setShowHistoryModal(true)} />
            {showHistoryModal && (
              <Modal
                title={__('history')}
                isModalOpen={showHistoryModal}
                hideFooter={true}
                onCancel={() => {
                  setShowHistoryModal(false);
                }}
              >
                <ProcessVersionsHistory />
              </Modal>
            )}
          </TooltipHost>
        }
        {checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_process) && (
          <>
            <DefaultButton
              onClick={() => showProcessModal(ProcessModalActions.NEW_PROCESS_GROUP)}
              iconProps={{ iconName: 'Add' }}
            >
              {__('new process group')}
            </DefaultButton>
            <PrimaryButton
              onClick={() => showProcessModal(ProcessModalActions.NEW_PROCESS)}
              iconProps={{ iconName: 'Add' }}
            >
              {__('new process')}
            </PrimaryButton>
          </>
        )}
      </>
    );
  };

  const renderFilterButtons = () => {
    return <>{<ProcessFilter toggleChangeParams={toggleChangeParams} setCurrentOffset={setCurrentOffset} />}</>;
  };

  const renderModalFooter = () => {
    return (
      <Stack horizontal tokens={buttonStackTokens}>
        <Stack.Item>
          <Popover
            title={__('delete process reminder')}
            content={__('are you sure you want delete this reminder?')}
            target={'delete-reminder'}
            onOk={() => onDeleteRemider()}
            enableConfirm={true}
          >
            <PrimaryButton id="delete-reminder" text={__('delete')} />
          </Popover>
        </Stack.Item>
        <Stack.Item>
          <DefaultButton onClick={() => onProcessReminderEdit()} text={__('modify')} />
        </Stack.Item>
      </Stack>
    );
  };

  const onRenderEditReminderModal = () => {
    return (
      <Modal
        title={__('process reminder')}
        isModalOpen={editReminderVisible}
        customFooter={renderModalFooter()}
        hideFooter={true}
        onCancel={handleEditCancel}
      >
        <>
          <Stack>
            <div>{__('please select how you wish to interact with the timer')}</div>
          </Stack>
        </>
      </Modal>
    );
  };

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props: any): JSX.Element | null => {
    const setIconTrigger = (trigger: boolean, rows: Process[]) => {
      if (rows) {
        updateRows({ type: BasicListActionEnum.ON_OVER_ITEM, id: props.item.id, trigger: trigger });
      }
    };

    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      customStyles.root = {
        zIndex: 10000 - props.itemIndex,
        transition: 'z-index 0s',
        height: '48px',
      };

      return (
        <>
          {!props.item.isHidden && (
            <div
              onMouseOver={() => setIconTrigger(true, rows)}
              onMouseLeave={() => setIconTrigger(false, rows)}
              onClick={() => setIconTrigger(false, rows)}
            >
              <DetailsRow {...props} styles={customStyles} />
            </div>
          )}
        </>
      );
    }
    return null;
  };

  const dragDropEvents = () => {
    return {
      canDrop: () => {
        return true;
      },
      canDrag: () => {
        return true;
      },
      onDragLeave: () => {
        return;
      },
      onDrop: (item: Process) => {
        onDropItem(item);
      },
      onDragStart: (item: Process) => {
        setDraggedItem(item);
      },
      onDragEnd: () => {
        setDraggedItem(undefined);
      },
    };
  };

  const onDropItem = (item: Process) => {
    if (draggedItem && item && draggedItem.id !== item.id) {
      // disable drop of group onto process
      if (
        draggedItem.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESSGROUP &&
        item.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS
      ) {
        return;
      }

      if (item.parent && isDescendant(item, draggedItem, rows)) return;

      // keine Prozessgruppe (draggedItem) darf unter einen prozess (item)
      // Man darf kein Element in ein Kindelement schieben egal wie
      const values = { ...draggedItem, parent: item.resourceUri.replace('process_min', 'process') };

      updateProcessAction(values, (result) => {
        updateRows({ type: BasicListActionEnum.UPDATE_ITEM_ROW, payload: result, id: draggedItem.id });
      });
    }
  };

  return (
    <>
      <Stack>
        <BasicList
          setKey={GLOBALENUMS.BASICLISTTYPES.PROCESSES_LIST}
          items={rows}
          columns={tableColumns}
          dragDropEvents={checkPermission(permissions, GLOBALENUMS.PERMISSIONS.add_process) && dragDropEvents}
          setTableColumns={setTableColumns}
          setOrderByColumns={setOrderByColumnsAction}
          defaultOrderByColumns={'-process_map_type&order_by=process_name'}
          selection={_rowsSelected}
          className={processesStyles.processesListContainer}
          onRenderRow={onRenderRow}
          checkboxVisibility={CheckboxVisibility.hidden}
          rightHeaderButtons={renderProcessesBtns()}
          leftHeaderButtons={renderFilterButtons()}
        />
        <Stack horizontal horizontalAlign="end">
          <PaginationProcess
            toggleChangeParams={toggleChangeParams}
            currentOffset={currentOffset}
            itemsPerPage={itemsPerPage}
            setCurrentOffset={setCurrentOffset}
            setItemsPerPage={setItemsPerPage}
            search={location.search}
          />
        </Stack>
      </Stack>
      {modalVisible && (
        <ProcessForm
          visible={modalVisible}
          createAsCopy={createAsCopy}
          createAsChild={createAsChildModal}
          closeModal={dismissModal}
          setCreateAsCopy={setCreateAsCopy}
          setCreateAsChild={setCreateAsChildModal}
          selected={selectedProcess}
          processMapType={processMapType}
          modalActionType={modalActionType}
          updateRows={updateRows}
        />
      )}

      {sendVisible && selectedProcess && (
        <SendForm
          visible={sendVisible}
          setVisible={setSendVisible}
          selected={selectedProcess}
          afterSend={() => getProcesses()}
          sendModel={sendModel}
        />
      )}
      {showSpinner && (
        <div className={processesStyles.spinnerOverlay}>
          <Spinner size={SpinnerSize.large} className={processesStyles.spinner} />
        </div>
      )}
      {reminderModalVisible && selectedProcess && (
        <ProcessReminderForm
          visible={reminderModalVisible}
          setVisible={setReminderModalVisible}
          selectedProcess={selectedProcess}
          selectedReminder={selectedProcessReminder}
        />
      )}
      {editReminderVisible && onRenderEditReminderModal()}

      {showProcessHistoryModal && (
        <Modal
          title={__('history')}
          isModalOpen={showProcessHistoryModal}
          hideFooter={true}
          onCancel={() => {
            setShowProcessHistoryModal(false);
          }}
        >
          <History selectedProcess={selectedProcess} />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title={__('delete selected processes')}
          isModalOpen={showDeleteModal}
          onSave={() => {
            onDeleteProcesses();
            setShowDeleteModal(false);
          }}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          saveButtonText={__('yes')}
        >
          <Text>{__('are you sure to delete this processes?')}</Text>
        </Modal>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({
  DropdownDataReducer,
  MenuReducer,
  ProcessReducer,
  ProcessReminderReducer,
  StatusReducer,
  UserReducer,
}: RootState) => ({
  DropdownDataReducer,
  MenuReducer,
  ProcessReducer,
  ProcessReminderReducer,
  StatusReducer,
  UserReducer,
});
const connector = connect(mapStateToProps, {
  clearSelectProcessAction,
  deleteProcessReminderAction,
  fetchDropdownDataByType,
  fetchProcessChildrenAction,
  fetchProcessForListAction,
  fetchProcessGroupsAction,
  fetchProcessReminderAction,
  searchMinProcessesAction,
  searchProcessesAction,
  selectProcessByIdAction,
  selectProcessReminderByIdAction,
  setOrderByColumnsAction,
});
export default connector(Processes);
