import { Meta, Tenant } from '../../ts/interfaces';

export interface TenantDashboardReducer {
  tenant: Tenant[];
  buyers: Tenant[];
  expired: Tenant[];
  terminated: Tenant[];
  meta?: Meta;
}

export default function reducer(
  state: TenantDashboardReducer = {
    tenant: [],
    buyers: [],
    expired: [],
    terminated: [],
    meta: undefined,
  },
  action: any,
): TenantDashboardReducer {
  switch (action.type) {
    case 'FETCH_TENANTS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        tenant: action.payload.objects,
      });
    }
    case 'FETCH_TENANTS_BUYER': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        buyers: action.payload.objects,
      });
    }
    case 'FETCH_TENANTS_EXPIRED': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        expired: action.payload.objects,
      });
    }
    case 'FETCH_TENANTS_TERMINATED': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        terminated: action.payload.objects,
      });
    }
    case 'UPDATE_LICENSE': {
      const purchased = action.payload.purchased;

      // remove from tenant if buyer
      const buyerEntry = state.buyers.find((f) => f.license.id == action.payload.id);
      if (buyerEntry) {
        const index = state.buyers.indexOf(buyerEntry);

        if (buyerEntry.license.purchased && !purchased) {
          state.buyers.splice(index, 1);
          buyerEntry.license = action.payload;
          state.tenant.push(buyerEntry);
        } else if (buyerEntry.license.purchased && purchased) {
          state.buyers.splice(index, 1);
          buyerEntry.license = action.payload;
          state.expired.push(buyerEntry);
        } else {
          state.buyers[index].license = action.payload;
        }
      }
      // remove from buyer if now tenant
      const tenantEntry = state.tenant.find((f) => f.license.id == action.payload.id);
      if (tenantEntry) {
        const index = state.tenant.indexOf(tenantEntry);

        if (!tenantEntry.license.purchased && purchased) {
          state.tenant.splice(index, 1);
          tenantEntry.license = action.payload;
          state.buyers.push(tenantEntry);
        } else {
          state.tenant[index].license = action.payload;
        }
      }

      const expiredEntry = state.expired.find((f) => f.license.id == action.payload.id);
      if (expiredEntry) {
        const index = state.expired.indexOf(expiredEntry);
        state.expired[index].license = action.payload;
      }

      const terminatedEntry = state.terminated.find((f) => f.license.id == action.payload.id);
      if (terminatedEntry) {
        const index = state.terminated.indexOf(terminatedEntry);
        state.terminated[index].license = action.payload;
      }

      return Object.assign({}, state, {
        ...state,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
