import { useEffect, useRef, useState } from 'react';
import { getStyles } from './element-informations-section-styles';
import { clearModelerDataAction, getFlatFullModelByInstanceId } from '../../../../store/actions/modeler-actions';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import { BasicList, GLOBALENUMS, IconSvg, __ } from 'modeling-tool';
import { IColumn, SelectionMode, Stack } from '@fluentui/react';
import { ElementInformation, FileProps, PreSuccessor, RequirementChapterData } from '../../../../ts/interfaces';
import { Parser } from 'html-to-react';
import { Person, ViewType } from '@microsoft/mgt-react';
import { SVGNameEnum } from '../../../../ts/enums/svg-name-enum';
import { useStyleProps } from '../../../../hooks';
import { File, MgtTemplateProps } from '@microsoft/mgt-react';
import { PersonDisplayName } from '../../../../components';
import { findRequirementName } from '../../../../utils';

interface ElementInformationsSectionProps {
  instanceId: number;
  chapterData: RequirementChapterData[];
}

interface ListElement {
  id?: number;
  idx: number;
  titleElement?: JSX.Element;
  descriptionElement?: JSX.Element;
  linksAttachments?: JSX.Element;
  predecessors?: JSX.Element;
  successors?: JSX.Element;
  responsible?: any;
}

const imgHeight = 20;
const iconSize = 24;
const ElementInformationsSection = (props: PropsFromRedux & ElementInformationsSectionProps) => {
  const {
    DropdownDataReducer: { nodeTypes, resources },
    ModelerReducer: { flatModelElements },
    getFlatFullModelByInstanceId,
    instanceId,
    chapterData,
  } = props;

  const [listElements, setListElements] = useState<ListElement[]>([]);
  const requirementRef = useRef<RequirementChapterData[]>([]);
  
  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  const getIdByUri = (uri: string): number => {
    const parts = uri.split('/');
    return Number(parts[parts.length - 2]);
  };

  const getResourceNameByUri = (uri: string): string => {
    const resource = resources.find((res) => res.id === getIdByUri(uri));
    return resource?.name || uri;
  };

  const elementColumns: IColumn[] = [
    {
      key: 'idx',
      name: '#',
      fieldName: 'idx',
      minWidth: 15,
      maxWidth: 20,
      onRender: (record: ListElement) => (
        <Stack className={styles.rowStyle}>
          <Stack.Item style={{ height: 20 }}>{record.idx}</Stack.Item>
        </Stack>
      ),
    },
    {
      key: 'title',
      name: __('title'),
      fieldName: 'title',
      minWidth: 40,
      maxWidth: 200,
      isResizable: true,
      onRender: (record: ListElement) => <Stack className={styles.rowStyle}>{record.titleElement}</Stack>,
    },
    {
      key: 'predecessor',
      name: __('predecessor'),
      fieldName: 'predecessor',
      minWidth: 80,
      maxWidth: 200,
      isResizable: true,
      onRender: (record: ListElement) => <Stack className={styles.rowStyle}>{record.predecessors}</Stack>,
    },
    {
      key: 'description',
      name: __('description'),
      fieldName: 'description',
      minWidth: 40,
      maxWidth: 600,
      isResizable: true,
      isMultiline: true,

      onRender: (record: ListElement) => <Stack className={styles.rowStyle}>{record.descriptionElement}</Stack>,
    },
    {
      key: 'assignments',
      name: __('assignments'),
      fieldName: 'linkUrl',
      minWidth: 80,
      maxWidth: 200,
      isResizable: true,
      isMultiline: true,
      onRender: (record: ListElement) => <Stack className={styles.rowStyle}>{record.linksAttachments}</Stack>,
    },
    {
      key: 'successor',
      name: __('successor'),
      fieldName: 'successor',
      minWidth: 80,
      maxWidth: 200,
      isResizable: true,
      onRender: (record: ListElement) => <Stack className={styles.rowStyle}>{record.successors}</Stack>,
    },
    {
      key: 'responsible',
      name: __('responsible column'),
      fieldName: 'responsible',
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
      isMultiline: true,
      onRender: (record: ListElement) => <Stack className={styles.rowStyle}>{record.responsible}</Stack>,
    },
  ];

  useEffect(() => {
    chapterData && requirementRef.current.push(...chapterData);
  }, [chapterData]);

  useEffect(() => {
    getFlatFullModelByInstanceId(instanceId, (res) => {
      if (res?.data?.objects?.length) {
        prepareListElements(res.data.objects[0].elements);
      }
    });
    return () => {
      setListElements([]);
      clearModelerDataAction();
    };
  }, []);

  const renderTitleElement = (element: ElementInformation | undefined): JSX.Element | undefined => {
    if (!element) return undefined;
    return (
      <Stack horizontal>
        <Stack.Item className={styles.svgIconStack}>
          <img
            height={imgHeight}
            style={{ maxWidth: '34px' }}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(element.svgString!)}`}
          />
        </Stack.Item>
        <Stack.Item>{element.title}</Stack.Item>
      </Stack>
    );
  };

  const renderPreSuccessors = (element: ElementInformation, isPredecessors: boolean): JSX.Element | undefined => {
    const xCessors = isPredecessors ? element.predecessors : element.successors;
    if (!xCessors?.length) return undefined;
    return (
      <Stack tokens={{ childrenGap: 8 }}>
        {xCessors.map((el: PreSuccessor) => {
          return (
            <Stack horizontal verticalAlign="center" style={{ height: 'auto' }}>
              <Stack.Item style={{ width: 20, height: 20 }}>{el.idx}</Stack.Item>
              <Stack.Item className={styles.svgIconStack}>
                <img
                  height={imgHeight}
                  style={{ maxWidth: '34px' }}
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(el.svgString!)}`}
                />
              </Stack.Item>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const shadowHost = document.querySelectorAll('[class*="person"]');
  if (shadowHost.length > 0) {
    shadowHost.forEach((sh) => {
      const shadowRoot = sh.shadowRoot;
      if (shadowRoot) {
        const line1Element = shadowRoot.querySelector('.line1') as HTMLElement;
        if (line1Element) {
          line1Element.style.fontWeight = '400';
        }
      }
    });
  }

  const renderResponsible = (element: ElementInformation): JSX.Element | undefined => {
    const { departmentNames, roleNames, employees, workingGroupNames } = element;
    return (
      <Stack>
        <>
          {employees?.split(',').map((person: string) => {
            return (
              <Stack horizontal className={styles.responsibleStack}>
                {styleProps.isContentOnly ? (
                  <Person userId={person} view={ViewType.oneline}>
                    <PersonDisplayName />
                  </Person>
                ) : (
                  <Person userId={person} view={ViewType.oneline}></Person>
                )}
              </Stack>
            );
          })}

          {departmentNames?.map((dep: string) => {
            return (
              <Stack horizontal className={styles.responsibleStack}>
                <Stack.Item className={styles.iconDiv}>
                  <IconSvg
                    svgName={SVGNameEnum.FIRSTLETTERCIRCLE}
                    width={iconSize}
                    height={iconSize}
                    text={dep}
                    color={styleProps.theme.palette.themePrimary}
                  />
                </Stack.Item>
                <Stack.Item>{dep}</Stack.Item>
              </Stack>
            );
          })}
          {roleNames?.map((role: string) => {
            return (
              <Stack horizontal className={styles.responsibleStack}>
                <Stack.Item className={styles.iconDiv}>
                  <IconSvg svgName={SVGNameEnum.ROLEHAT} width={iconSize} height={iconSize} />
                </Stack.Item>
                <Stack.Item>{role}</Stack.Item>{' '}
              </Stack>
            );
          })}
          {workingGroupNames?.map((wg: string) => {
            return (
              <Stack horizontal className={styles.responsibleStack}>
                <Stack.Item className={styles.iconDiv}>
                  <IconSvg
                    svgName={SVGNameEnum.FIRSTLETTERCIRCLE}
                    width={iconSize}
                    height={iconSize}
                    text={wg}
                    color={styleProps.theme.palette.themePrimary}
                  />
                </Stack.Item>
                <Stack.Item>{wg}</Stack.Item>{' '}
              </Stack>
            );
          })}
        </>
      </Stack>
    );
  };

  const ClickableDocument = (props: MgtTemplateProps & FileProps) => {
    const { name, webUrl } = props.dataContext.file;
    return (
      <Stack className={styles.assignmentElements} horizontal verticalAlign="center">
        <IconSvg svgName={SVGNameEnum.DOCUMENT} height={18} width={18} />
        <Stack.Item className={styles.linkText}>{name}</Stack.Item>
      </Stack>
    );
  };

  const renderDescription = (element: ElementInformation): JSX.Element | undefined => {
    return (
      <Stack className={styles.descriptionElement}>
        <Stack>{Parser().parse(element.description)}</Stack>
      </Stack>
    );
  };

  const renderLinksAttachments = (element: ElementInformation): JSX.Element | undefined => {
    const fileList: string[] = element?.attachments?.split('|');
    const elementList: JSX.Element[] = [];

    if (element?.requirements?.length) {
      element?.requirements.forEach((req) => {
        elementList.push(
          <Stack horizontal verticalAlign="center" className={styles.assignmentElements}>
            <IconSvg width={18} height={18} svgName={SVGNameEnum.HAMMER} />
            <Stack.Item className={styles.linkText}>
              {findRequirementName(req, requirementRef.current) as string}
            </Stack.Item>
          </Stack>,
        );
      });
    }

    if (element?.resources?.length) {
      element?.resources.forEach((res) => {
        elementList.push(
          <Stack horizontal verticalAlign="center" className={styles.assignmentElements}>
            <IconSvg width={18} height={18} svgName={SVGNameEnum.LAPTOP} />
            <Stack.Item className={styles.linkText}>{getResourceNameByUri(res)}</Stack.Item>
          </Stack>,
        );
      });
    }

    if (fileList?.length) {
      {
        fileList
          .map((file) => file.split('::'))
          .forEach((fileData) =>
            elementList.push(
              <File siteId={fileData[0]} driveId={fileData[1]} itemId={fileData[2]}>
                <ClickableDocument siteId={fileData[0]} driveId={fileData[1]} itemId={fileData[2]} />
              </File>,
            ),
          );
      }
    }

    if (element?.linkUrl?.length) {
      elementList.push(
        <Stack horizontal verticalAlign="center" className={styles.assignmentElements}>
          <IconSvg width={18} height={18} svgName={SVGNameEnum.LINK} />
          <Stack.Item className={styles.linkText}>{element.linkText || element.linkUrl}</Stack.Item>
        </Stack>,
      );
    }

    return (
      <Stack className={styles.descriptionElement}>
        <Stack wrap tokens={{ childrenGap: 10 }}>
          {elementList}
        </Stack>
      </Stack>
    );
  };

  const prepareListElements = (flatModelElements: ElementInformation[]) => {
    const listResult: ListElement[] = [];
    flatModelElements.forEach((element: ElementInformation, idx: number) => {
      if (element.nodeType === GLOBALENUMS.MODELERNODETYPE.TEXT) {
        return;
      }

      const listElement: ListElement = {
        idx: idx + 1,
        titleElement: renderTitleElement(element),
        descriptionElement: renderDescription(element),
        linksAttachments: renderLinksAttachments(element),
        predecessors: renderPreSuccessors(element, true),
        successors: renderPreSuccessors(element, false),
        responsible: renderResponsible(element),
      };
      listResult.push(listElement);
    });
    setListElements(listResult);
  };

  return (
    <>
      <BasicList
        setKey={GLOBALENUMS.BASICLISTTYPES.ELEMENT_INFORMATIONS_LIST}
        items={listElements}
        columns={elementColumns}
        selectionMode={SelectionMode.none}
        className={styles.basicList}
      ></BasicList>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ DropdownDataReducer, ModelerReducer }: RootState) => ({
  DropdownDataReducer,
  ModelerReducer,
});

const connector = connect(mapStateToProps, {
  getFlatFullModelByInstanceId,
  clearModelerDataAction,
});

export default connector(ElementInformationsSection);
