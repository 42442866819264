import React, { useEffect, useState } from 'react';
import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { DropdownWithSearch, GLOBALENUMS, PeoplePicker, __ } from 'modeling-tool';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../config/store';
import { useStyleProps } from '../../../../hooks';
import { getStyles } from './process-form-styles';
import { Control, FieldValues, useWatch } from 'react-hook-form';

enum OptionsKey {
  PERSON = 'person',
  ROLE = 'role',
  DEPARTMENT = 'department',
  STAFFPOSITION = 'staffposition',
  EXTERNALPARTNER = 'externalpartner',
}

interface ProcessOwnerProps extends PropsFromRedux {
  control: Control<FieldValues, any>;
  field: string;
  renderSelectDropDown(type: GLOBALENUMS.DROPDOWNDATA, data?: any): any;
}

const ProcessOwner = (props: ProcessOwnerProps) => {
  const {
    DropdownDataReducer: { roles, orgUnits, externalPartners, staffPositions },
    control,
    field,
    renderSelectDropDown,
  } = props;

  const styleProps = useStyleProps();
  const styles = getStyles(styleProps.theme);

  const watches = useWatch({
    control,
    name: [field, `${field}Departments`, `${field}Roles`, `${field}StaffPositions`, `${field}ExternalPartners`],
  });

  const [selectedKey, setSelectedKey] = useState<OptionsKey>(OptionsKey.PERSON);

  useEffect(() => {
    const [person, department, role, staffPosition, externalPartner] = watches;
    setSelectedKey(
      person?.length
        ? OptionsKey.PERSON
        : department?.length
        ? OptionsKey.DEPARTMENT
        : role?.length
        ? OptionsKey.ROLE
        : staffPosition?.length
        ? OptionsKey.STAFFPOSITION
        : externalPartner?.length
        ? OptionsKey.EXTERNALPARTNER
        : OptionsKey.PERSON,
    );
  }, [watches]);

  const dropdownOptions: IDropdownOption[] = Object.values(OptionsKey).map((key) => ({ key, text: __(key) }));

  const isOtherFieldPopulated = (currentFieldKey: any) => {
    return watches.some((watch, index) => watch?.length && index !== currentFieldKey);
  };

  const commonProps = {
    control,
    multiSelect: true,
  };

  const isFieldPopulated = () => {
    return watches.some((watch) => watch?.length);
  };

  const renderDropdown = () => {
    switch (selectedKey) {
      case OptionsKey.PERSON:
        return <PeoplePicker {...commonProps} name={field} disabled={isOtherFieldPopulated(0)} />;
      case OptionsKey.DEPARTMENT:
        return (
          <DropdownWithSearch
            {...commonProps}
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ORG_UNITS, orgUnits)}
            name={`${field}Departments`}
            disabled={isOtherFieldPopulated(1)}
            placeholder={__('please select department')}
          />
        );
      case OptionsKey.ROLE:
        return (
          <DropdownWithSearch
            {...commonProps}
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.ROLES, roles)}
            name={`${field}Roles`}
            disabled={isOtherFieldPopulated(2)}
            placeholder={__('please select role')}
          />
        );
      case OptionsKey.STAFFPOSITION:
        return (
          <DropdownWithSearch
            {...commonProps}
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.STAFF_POSITIONS, staffPositions)}
            name={`${field}StaffPositions`}
            disabled={isOtherFieldPopulated(3)}
            placeholder={__('please select staff position')}
          />
        );
      case OptionsKey.EXTERNALPARTNER:
        return (
          <DropdownWithSearch
            {...commonProps}
            options={renderSelectDropDown(GLOBALENUMS.DROPDOWNDATA.EXTERNAL_PARTNERS, externalPartners)}
            name={`${field}ExternalPartners`}
            disabled={isOtherFieldPopulated(4)}
            placeholder={__('please select external partner')}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.ownerWrapper}>
      <div className={styles.dropdownWithSearch}>{renderDropdown()}</div>
      <Dropdown
        className={styles.dropdownStyles}
        options={dropdownOptions}
        defaultSelectedKey={selectedKey}
        dropdownWidth={120}
        onChange={(event, item) => setSelectedKey(item?.key as OptionsKey)}
        disabled={isFieldPopulated()}
      />
    </div>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ DropdownDataReducer }: RootState) => ({
  DropdownDataReducer,
});

const connector = connect(mapStateToProps, {});
export default connector(ProcessOwner);
