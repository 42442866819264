import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const fetchRequirementsAction = (min?: boolean, cb?: (...args: any[]) => void) =>
  UseAction({
    url: min ? `requirement_min_chapters/` : `requirement/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_REQUIREMENTS',
    cb,
  });

export const fetchRequirementsWithProcessAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    url: `requirement_min_processes/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_REQUIREMENTS',
    cb,
  });

export const searchRequirementsAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `requirement/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_REQUIREMENTS',
    cb,
  });
export const searchRequirementChaptersAction = (search: string, orderBy?: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `requirementchapter/${search.length ? `${search}${orderBy}` : `?${orderBy}` || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'SEARCH_REQUIREMENT_CHAPTERS',
    cb,
  });

export const fetchOpenRequirementByIdAction = (id: number, min?: boolean, cb?: (...args: any[]) => void) =>
  UseAction({
    url: min ? `requirement_min_processes/${id}/` : `requirement/${id}/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_REQUIREMENT_BY_ID',
    cb,
  });
export const selectOpenRequirementByIdAction = (id: number) =>
  UseAction({
    normalAction: true,
    action: 'OPEN_REQUIREMENT_BY_ID',
    payload: id,
  });

export const selectSelectedRequirementByIdAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_REQUIREMENT_BY_ID',
    payload: id,
    cb,
  });

export const clearRequirementsAction = (cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_REQUIREMENTS',
    cb,
  });

export const clearSelectedRequirement = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_SELECTED_REQUIREMENT',
  });

export const clearOpenRequirement = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_OPEN_REQUIREMENT',
  });

export const updateRequirementAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `requirement/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_REQUIREMENT',
    reqPayload: data,
    cb,
  });
export const fetchRequirementChapterByIdAction = (id: number, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `requirementchapter/${id}/`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_REQUIREMENT_CHAPTER_BY_ID',
    cb,
  });

export const addRequirementAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'requirement/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_REQUIREMENT',
    reqPayload: data,
  });

export const addRequirementChapterAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: 'requirementchapter/',
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'ADD_REQUIREMENT_CHAPTER',
    reqPayload: data,
    cb,
  });
export const selectRequirementChapterAction = (data: any) =>
  UseAction({
    normalAction: true,
    action: 'SELECT_REQUIREMENT_CHAPTER',
    payload: data,
  });
export const clearSelectRequirementChapterAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_SELECT_REQUIREMENT_CHAPTER',
  });
export const updateRequirementChapterAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `requirementchapter/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.PATCH,
    action: 'UPDATE_REQUIREMENT_CHAPTER',
    reqPayload: data,
    cb,
  });
export const deleteRequirementChapterAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `requirementchapter/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_REQUIREMENT_CHAPTER',
    payload: data.id,
    cb,
  });
export const deleteRequirementAction = (data: any, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `requirement/${data.id}/`,
    method: GLOBALENUMS.HTTPREQUEST.DELETE,
    action: 'DELETE_REQUIREMENT',
    payload: data.id,
    cb,
  });

export const fetchChapterChildrenAction = (
  id: number,
  nextLevel?: number,
  orderBy?: string,
  cb?: (...args: any[]) => void,
) =>
  UseAction({
    url: nextLevel
      ? `requirementchapter/?parent=${id}&level=${nextLevel}${'&' + orderBy || ''}`
      : `requirementchapter/?parent=${id}&${'&' + orderBy || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'FETCH_CHAPTER_CHILDREN',
    cb,
  });

export const setChapterOrderByColumnsAction = (orderByColumns: string, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'SET_CHAPTER_ORDER_BY_COLUMNS',
    payload: orderByColumns,
    cb,
  });

export const addQueryChapterParamsAction = (payload: any, cb?: (...args: any[]) => void) =>
  UseAction({
    normalAction: true,
    action: 'ADD_QUERY_CHAPTER_PARAMS',
    payload: payload,
    cb,
  });

export const clearQueryChapterParamsAction = () =>
  UseAction({
    normalAction: true,
    action: 'CLEAR_QUERY_CHAPTER_PARAMS',
  });
