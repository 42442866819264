import { useTheme } from '@fluentui/react';
import { IsTeamsApp } from '../config/config';

export const useStyleProps = () => {
  const theme = useTheme();
  const styleProps = {
    theme: theme,
    isContentOnly: !!sessionStorage.contentOnly,
    isTeamsApp: IsTeamsApp(),
  };
  return styleProps;
};
