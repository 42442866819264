import { makeStyles, shorthands } from '@fluentui/react-components';

export const getStyles = makeStyles({
  initialDataDashboardContainer: {
    height: '100%',
    width: '100%',
  },
  initialDataDashboardHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sliderStyles: {
    width: '300px',
  },
  sliderSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
  },
  buttonSection: {
    marginTop: '20px',
    '& > button': {
      ...shorthands.margin('0px 4px'),
    },
  },
  inputFieldContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.padding('24px'),
  },
  associatedSection: { ...shorthands.margin('16px') },

  cautionBoxVisibilityHidden: {
    visibility: 'hidden',
  },
  cautionBoxVisibilityVisible: {
    visibility: 'visible',
  },
  cautionBox: {
    position: 'absolute',
    right: '80px',
    top: '160px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...shorthands.padding('12px'),
    ...shorthands.border('5px solid purple'),
  },
  cautionText: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'red',
    ...shorthands.padding('8px 0px'),
  },
});
