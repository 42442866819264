import React from 'react';
interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback = (props: ErrorFallbackProps) => {
  const { error, resetErrorBoundary } = props;

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <p>Stack: {error.stack}</p>
    </div>
  );
};

export default ErrorFallback;
