import { mergeStyleSets, keyframes, IStackStyles } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { StyleProp } from '../../../../ts/interfaces';

const fadeIn = keyframes({
  from: {
    opacity: 0.5,
  },
  to: {
    opacity: 1,
  },
});

export const getStyles = memoizeFunction((props: StyleProp) => {
  const { theme } = props;
  return mergeStyleSets({
    blinkHintAccept: {
      animation: 'blinker 1s linear 2',
      color: theme.palette.themePrimary,
      '&:hover': {
        color: theme.palette.themePrimary,
      },
      animationName: fadeIn,
    },
    blinkHint: {
      animation: 'blinker 1s linear',
      color: theme.palette.themePrimary,
      animationName: fadeIn,
      '&:hover': {
        color: theme.palette.themePrimary,
      },
    },
  });
});
