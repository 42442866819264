import React from 'react';
import './login.less';

const Login = () => {
  return (
    <div className="login-page">
      <div className="logo-holder">
        <a href="/">
          <img alt="aiio logo" src="/images/logo.svg" />
        </a>
      </div>
      <div className="copy-rights">{new Date().getFullYear()} © aiio - aiio GmbH</div>
    </div>
  );
};

export default Login;
