import { useEffect } from 'react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { initializeIcons, ThemeProvider } from '@fluentui/react';
import { Providers, ProviderState } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { Provider } from 'react-redux';
import './app.less';
import TelemetryProvider from './config/appinsights/telemetry-provider';
import { getAppInsights } from './config/appinsights/telemetry-service';
import { basicScopes } from './config/authConfig';
import { GetAppInsightsKey, PendoEnabled } from './config/config';
import configureStore from './config/store';
import { Login } from './pages';
import Router from './router';
import { msalInstance } from '.';
import { initializePendo } from './config/utils';
import { BrowserRouter } from 'react-router-dom';
import { FluentProvider } from '@fluentui/react-components';
import { GetAppLightThemeFluentV9, GetAppThemeFluentV8 } from 'modeling-tool';

const store = configureStore();

export default function App(): JSX.Element {
  // MSAL INSTANT AUTHENTICATION. If in iframe use popup, else use redirect even on error
  try {
    if (window == window.parent) {
      useMsalAuthentication(InteractionType.Redirect, { scopes: basicScopes });
    } else {
      useMsalAuthentication(InteractionType.Popup, { scopes: basicScopes });
    }
  } catch (error) {
    useMsalAuthentication(InteractionType.Redirect, { scopes: basicScopes });
    console.log(error);
  }

  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance } = useMsal();

  // MGT provider
  Providers.globalProvider = new Msal2Provider({
    publicClientApplication: msalInstance,
    isIncrementalConsentDisabled: true,
    scopes: basicScopes,
  });

  // MSAL AUTO GRAPH AUTHENTICATION
  useEffect(() => {
    Providers.globalProvider.setState(
      inProgress !== InteractionStatus.None
        ? ProviderState.Loading
        : isAuthenticated
        ? ProviderState.SignedIn
        : ProviderState.SignedOut,
    );
    if (isAuthenticated && PendoEnabled()) {
      initializePendo(instance);
    }
  }, [isAuthenticated, inProgress]);

  // icons and design
  initializeIcons();

  // App Insights
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let appInsights = null;
  const instrumentationKey = GetAppInsightsKey();

  return (
    <>
      <ThemeProvider theme={GetAppThemeFluentV8()}>
        <FluentProvider theme={GetAppLightThemeFluentV9()}>
          <AuthenticatedTemplate>
            <Provider store={store}>
              <BrowserRouter>
                {instrumentationKey && (
                  <TelemetryProvider
                    instrumentationKey={instrumentationKey}
                    after={() => {
                      appInsights = getAppInsights();
                    }}
                  >
                    <div id="app-container">
                      <div className="content-container">
                        <Router />
                      </div>
                    </div>
                  </TelemetryProvider>
                )}
                {!instrumentationKey && (
                  <div id="app-container">
                    <div className="content-container">
                      <Router />
                    </div>
                  </div>
                )}
              </BrowserRouter>
            </Provider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        </FluentProvider>
      </ThemeProvider>
    </>
  );
}
