import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';

export const searchProcessInstancesAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processinstance/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'DB_SEARCH_PROCESS_INSTANCES',
    cb,
  });

export const searchProcessInstancesStatsAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `processinstance/stats/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'DB_SEARCH_PROCESS_INSTANCES_STATS',
    cb,
  });

export const searchTasksCardDataAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `task/stats/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'DB_SEARCH_TASKS_CARD_DATA',
    cb,
  });

export const searchTasksAction = (search: string, cb?: (...args: any[]) => void) =>
  UseAction({
    url: `task/${search || ''}`,
    method: GLOBALENUMS.HTTPREQUEST.GET,
    action: 'DB_SEARCH_TASKS',
    cb,
  });
