import { ProcessGroup, Meta } from '../../ts/interfaces';
interface ProcessGroupReducerState {
  processgroups: ProcessGroup[];
  meta?: Meta;
  selectedProcessgroup?: ProcessGroup;
}

export default function reducer(
  state: ProcessGroupReducerState = {
    processgroups: [],
    meta: undefined,
    selectedProcessgroup: undefined,
  },
  action: any,
): ProcessGroupReducerState {
  switch (action.type) {
    case 'FETCH_PROCESS_GROUPS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        processgroups: action.payload.objects,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
