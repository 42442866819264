import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IDropdownOption, Dropdown, Stack, Icon } from '@fluentui/react';
import ReactPaginate from 'react-paginate';
import { getStyles } from '../../../process-management/processes/pagination-process/pagination-process-styles';
import { useStyleProps } from '../../../../hooks';
import { RootState } from '../../../../config/store';
import { Requirement } from '../../../../ts/interfaces';
import { addQueryChapterParamsAction } from '../../../../store/actions/requirement-action';

interface IPagination extends PropsFromRedux {
  currentOffset: number;
  search: string;
  itemsPerPage: string;
  openRequirement?: Requirement | undefined;
  setItemsPerPage: (arg: string) => void;
  setCurrentOffset: (arg: number) => void;
  toggleChangeParams: () => void;
}

const Pagination = (props: IPagination) => {
  const {
    RequirementReducer: { meta, openRequirement },
    currentOffset,
    itemsPerPage,
    addQueryChapterParamsAction,
    setCurrentOffset,
    setItemsPerPage,
    toggleChangeParams,
  } = props;

  const [pageOffset, setPageOffset] = useState<number>(0);
  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  const options: IDropdownOption[] = [
    { key: '20', text: '20' },
    { key: '50', text: '50' },
    { key: '100', text: '100' },
  ];

  useEffect(() => {
    addQueryChapterParamsAction([
      { from_requirement: `${openRequirement?.id}` },
      { offset: currentOffset },
      { limit: itemsPerPage },
      { level: 0 },
    ]);

    toggleChangeParams();
  }, [currentOffset, itemsPerPage]);

  const onChange = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
    setItemsPerPage(`${item?.key}`);
  };

  const handlePageClick = (event: any) => {
    const newOffset = event.selected * Number(itemsPerPage);
    setPageOffset(event.selected);
    setCurrentOffset(newOffset);
  };

  const previousLabel = <Icon iconName="ChevronLeft" style={{ fontSize: 12 }} />;
  const nextLabel = <Icon iconName="ChevronRight" style={{ fontSize: 12 }} />;

  const renderPageControl = () => {
    if (meta && meta.totalCount && meta.totalCount > parseInt(itemsPerPage)) {
      return (
        <ReactPaginate
          previousLabel={previousLabel}
          nextLabel={nextLabel}
          pageCount={meta && meta?.totalCount === 0 ? 1 : Math.ceil(Number(meta?.totalCount) / Number(itemsPerPage))}
          marginPagesDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={styles.pagginationBttns}
          previousLinkClassName={styles.paginationLink}
          nextLinkClassName={styles.paginationLink}
          disabledClassName={styles.paginationLinkDisabled}
          activeClassName={styles.paginationLinkActive}
          forcePage={pageOffset}
        />
      );
    }
  };

  return (
    <Stack horizontal className={styles.controllSection} tokens={{ childrenGap: 10 }}>
      {renderPageControl()}
      <Dropdown
        defaultSelectedKey={itemsPerPage}
        options={options}
        onChange={onChange}
        className={styles.dropdownStyles}
      />
    </Stack>
  );
};
type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ ProcessReducer, RequirementReducer }: RootState) => ({
  ProcessReducer,
  RequirementReducer,
});
const connector = connect(mapStateToProps, {
  addQueryChapterParamsAction,
});
export default connector(Pagination);
