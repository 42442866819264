import { GLOBALENUMS } from 'modeling-tool';
import { UseAction } from './base-actions';
import { Process, ProcessInstance } from '../../ts/interfaces';

export const setNewProcessInstanceStateAction = (
  process: ProcessInstance,
  newState: GLOBALENUMS.PROCESSSTATUS,
  cb?: (...args: any[]) => void,
  ecb?: (...args: any[]) => void,
) =>
  UseAction({
    url: `processstatemachine/set_new_process_instance_state/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'SET_NEW_PROCESS_INSTANCE_STATE',
    reqPayload: { process: process, newState: newState },
    cb,
    ecb
  });

export const setNewProcessState = (
  process: Process,
  newState: GLOBALENUMS.PROCESSSTATUS,
  cb?: (...args: any[]) => void,
) =>
  UseAction({
    url: `processstatemachine/set_new_process_state/`,
    method: GLOBALENUMS.HTTPREQUEST.POST,
    action: 'SET_NEW_PROCESS_STATE',
    reqPayload: { process: process, newState: newState },
    cb,
  });
