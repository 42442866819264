import { Stack } from '@fluentui/react';
import React, { useLayoutEffect } from 'react';

interface ItemProps {
  item: any;
  calloutVisible?: boolean;
  isDepartment?: boolean;
  children?: string;
}
const Item = (props: ItemProps) => {
  const { item, calloutVisible, isDepartment, children } = props;
  const itemRef = React.useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (itemRef.current) {
      const width = itemRef.current.clientWidth;
      item.width = width;
    }
  }, [item]);

  return (
    <Stack>
      {calloutVisible ? (
        <div style={{ marginBottom: '5px' }}>
          {isDepartment ? (
            <>{item.departmentName || item.staffPositionName || item.roleName || item.externalPartnerName}</>
          ) : (
            <>{item.locationName}</>
          )}
          {children}
        </div>
      ) : (
        <div ref={itemRef}>
          {isDepartment ? (
            <>{item.departmentName || item.staffPositionName || item.roleName || item.externalPartnerName}</>
          ) : (
            <>{item.locationName}</>
          )}
          {children}
        </div>
      )}
    </Stack>
  );
};
export default Item;
