import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal } from 'modeling-tool';
import { __ } from 'modeling-tool';
import { RootState } from '../../../../config/store';
import { ApproversList } from '../../approvers-list';
import { getStyles } from './recording-button-section-styles';
import { useStyleProps } from '../../../../hooks';
import { DefaultButton } from '@fluentui/react';

const ApproversListModal = (props: PropsFromRedux) => {
  const [visible, setVisible] = useState<boolean>(false);

  const styleProps = useStyleProps();
  const styles = getStyles(styleProps);

  return (
    <>
      <DefaultButton
        iconProps={{ iconName: 'ContactList' }}
        title={__('approvers')}
        onClick={() => setVisible(true)}
        className={styles.blinkHint}
      />
      <Modal
        title={__('approvers')}
        isModalOpen={visible}
        hideFooter={true}
        onSave={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <>
          <ApproversList />
        </>
      </Modal>
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({}: RootState) => ({});
const connector = connect(mapStateToProps, {});
export default connector(ApproversListModal);
