import { Meta, Status } from '../../ts/interfaces';

interface StatusReducerState {
  stateList: Status[];
  meta?: Meta;
  selectedStatus?: Status;
}

export default function reducer(
  state: StatusReducerState = {
    stateList: [],
    meta: undefined,
    selectedStatus: undefined,
  },
  action: any,
): StatusReducerState {
  switch (action.type) {
    case 'FETCH_STATUS': {
      return Object.assign({}, state, {
        meta: action.payload.meta,
        stateList: action.payload.objects,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
