import { mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';

export const getStyles = memoizeFunction(() => {
  return mergeStyleSets({
    label: {
      paddingBottom: '0',
      paddingTop: '10px',
    },
  });
});
