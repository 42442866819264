import { useStyles } from './entity-list-styles';
import { AddCircle32Regular } from '@fluentui/react-icons';
import { IOrgChart, IProcessMap } from '../../../ts/interfaces';

interface EntityListProps {
  entities: IProcessMap[] | IOrgChart[];
  renderEntity: (entity: any) => JSX.Element;
  readonly?: boolean;
  addActionText: string;
  addAction: (callback: (error: boolean) => void) => void;
}

const EntityList = (props: EntityListProps) => {
  const { entities, renderEntity, readonly, addAction, addActionText } = props;

  const classes = useStyles();
  return (
    <div className={classes.entityListWrapper} id="entity-list-wrapper">
      <div className={classes.cardWrapper}>
        {entities.map((entity) => (
          <div key={entity.id}>{renderEntity(entity)}</div>
        ))}
        {!readonly && (
          <div
            className={classes.card}
            onClick={() => {
              addAction((err: boolean) => {
                if (err) {
                  console.log('Error creating new entity');
                }
              });
            }}
          >
            <div className={classes.iconWrapper}>
              <AddCircle32Regular className={classes.addCircleIcon} />
            </div>
            <span className={classes.text}>{addActionText}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default EntityList;
