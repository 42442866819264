import { ProcessInstance } from '../../ts/interfaces';

interface DashboardReducerState {
  processInstances: ProcessInstance[];
  tasksCardData: any[];
  tasks: ProcessInstance[];
  closed: number;
  open: number;
  total: number;
  closedTasks: number;
  openTasks: number;
  totalTasks: number;
}

export default function reducer(
  state: DashboardReducerState = {
    processInstances: [],
    tasksCardData: [],
    tasks: [],
    closed: 0,
    open: 0,
    total: 0,
    closedTasks: 0,
    openTasks: 0,
    totalTasks: 0,
  },
  action: any,
): DashboardReducerState {
  switch (action.type) {
    case 'DB_SEARCH_PROCESS_INSTANCES': {
      return Object.assign({}, state, {
        processInstances: action.payload.objects,
      });
    }
    case 'DB_SEARCH_PROCESS_INSTANCES_STATS': {
      return Object.assign({}, state, action.payload);
    }
    case 'DB_SEARCH_TASKS_CARD_DATA': {
      return Object.assign({}, state, action.payload);
    }
    case 'DB_SEARCH_TASKS': {
      return Object.assign({}, state, {
        tasks: action.payload.objects,
      });
    }
    default: {
      break;
    }
  }
  return state;
}
