import React, { useEffect, useState } from 'react';
import { Stack, IStackTokens } from '@fluentui/react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { AlertTemplate } from '../alert-template';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../error-fallback';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { getStyles } from './content-layout-styles';
import { useStyleProps } from '../../hooks';
import { Breadcrumbs, Sidebar } from '../../components';
import { IsTeamsApp } from '../../config/config';
import { StyleContext, UtilityContext } from 'modeling-tool';
import { useLocation } from 'react-router-dom';

interface ContentLayoutProps {
  children: JSX.Element[];
}

const ContentLayout = (props: ContentLayoutProps) => {
  const appInsights = useAppInsightsContext();
  const [utilitySideBar, setUtilitySideBar] = useState<{ list: JSX.Element[] }>({ list: [] });
  const [breadcrumbButtons, setBreadcrumbButtons] = useState<{ list: JSX.Element[] }>({ list: [] });

  const location = useLocation();

  const styleProps = useStyleProps();
  const styles = getStyles(styleProps, utilitySideBar.list.length > 0);

  const utilityValue = {
    utilitySideBar: utilitySideBar,
    setUtilitySideBar: setUtilitySideBar,
    breadcrumbButtons: breadcrumbButtons,
    setBreadcrumbButtons: setBreadcrumbButtons,
  };

  const errorHandler = (error: Error) => {
    try {
      appInsights.trackException({ exception: error });
    } catch (e) {
      console.warn('Azure Application Insights is not correctly initialized: ' + e);
    }

    console.log(error.stack);
  };

  return (
    <UtilityContext.Provider value={utilityValue}>
      <StyleContext.Provider value={{ useStyleProps }}>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_CENTER}
          transition={transitions.FADE}
          timeout={5000}
          offset={'10px'}
        >
          <div className={styles.appLayout}>
            <Stack horizontal className={styles.mainBackground}>
              <Stack.Item disableShrink>{IsTeamsApp() && <Sidebar />}</Stack.Item>
              <Stack.Item grow={10}>
                <div className={styles.container}>
                  <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Stack.Item className={styles.breadCrumbStack}>{IsTeamsApp() && <Breadcrumbs />}</Stack.Item>

                    <Stack.Item className={styles.buttonSection}>
                      {breadcrumbButtons && <>{breadcrumbButtons.list}</>}
                    </Stack.Item>
                  </Stack>
                  <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} resetKeys={[location]}>
                    <div className={styles.componentContainer}>{props.children}</div>
                  </ErrorBoundary>
                </div>
              </Stack.Item>
              {utilitySideBar && <Stack.Item className={styles.utilitySideBar}>{utilitySideBar.list}</Stack.Item>}
            </Stack>
          </div>
        </AlertProvider>
      </StyleContext.Provider>
    </UtilityContext.Provider>
  );
};

export default ContentLayout;
