import { ConnectedProps, connect } from 'react-redux';
import EntityCard from '../../../components/shared/entity-card/entity-card';
import { IOrgChart } from '../../../ts/interfaces';
import { RootState } from '../../../config/store';
import { selectOrgChartAction, updateOrgChartAction } from '../../../store/actions/org-action';
import { useContext, useState } from 'react';
import { UtilityContext } from 'modeling-tool';
import { useBoolean } from '@fluentui/react-hooks';
import { EntityCardType } from '../../../ts/enums';

interface OrganizationCardProps {
  orgchart: IOrgChart;
  renderDetailsBar: (orgchart: IOrgChart) => void;
  closeSidebar: () => void;
  readonly?: boolean;
  redirectToOrgchart: (subId: number | undefined) => void;
}
const OrganizationCard = (props: OrganizationCardProps & PropsFromRedux) => {
  const {
    OrgChartReducer: { selectedOrgChart },
    orgchart,
    readonly,
    renderDetailsBar,
    closeSidebar,
    updateOrgChartAction,
    selectOrgChartAction,
    redirectToOrgchart,
  } = props;

  const { name, description, logo } = orgchart;
  const { utilitySideBar } = useContext(UtilityContext);

  const selectedCard = orgchart.id === selectedOrgChart?.id;
  const [showPicturePopup, { toggle: togglePicturePopup }] = useBoolean(false);
  const [imageUrl, setImageUrl] = useState<any>('');

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    renderDetailsBar(orgchart);
    if (orgchart.id) {
      selectOrgChartAction(orgchart);
    }
  };

  const handleOpenOrgChart = (e: any) => {
    e.stopPropagation();
    redirectToOrgchart(orgchart.id);
    closeSidebar();
  };

  const onClickAddLogo = (e: any) => {
    e.stopPropagation();
    if (orgchart.id) {
      selectOrgChartAction(orgchart);
      if (!!utilitySideBar.list.length) {
        renderDetailsBar(orgchart);
      }
      togglePicturePopup();
    }
  };

  const handleCancelAddLogoModal = () => {
    togglePicturePopup();
  };

  const handleSave = () => {
    const values = {
      ...selectedOrgChart,
      logo: imageUrl.replaceAll('|', '::'),
    };
    if (!utilitySideBar.list.length) {
      updateOrgChartAction(values);
    } else {
      renderDetailsBar({ ...orgchart, logo: imageUrl.replaceAll('|', '::') });
      selectOrgChartAction(values);
    }

    togglePicturePopup();
  };

  return (
    <EntityCard
      cardType={EntityCardType.ORG_CHART}
      name={name}
      description={description}
      logo={logo}
      readonly={readonly}
      showPicturePopup={showPicturePopup}
      selected={selectedCard}
      setImageUrl={setImageUrl}
      handleOnClick={handleOnClick}
      handleOpenCard={handleOpenOrgChart}
      onClickAddLogo={onClickAddLogo}
      handleCancelAddLogoModal={handleCancelAddLogoModal}
      handleSave={handleSave}
    />
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = ({ UserReducer, OrgChartReducer }: RootState) => ({
  UserReducer,
  OrgChartReducer,
});
const connector = connect(mapStateToProps, {
  selectOrgChartAction,
  updateOrgChartAction,
});
export default connector(OrganizationCard);
