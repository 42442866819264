import React, { useEffect, useState } from 'react';
import { Modal } from 'modeling-tool';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../config/store';
import {
  getProcessInstanceAction,
  deleteProcessInstanceAction,
  selectProcessInstanceByIdAction,
} from '../../store/actions/process-instance-actions';
import { ApprovedByModal, ApproversList } from '../../components';
import { useBoolean } from '@fluentui/react-hooks';
import { ProcessInstance } from '../../ts/interfaces';
import { __ } from 'modeling-tool';
import { updateProcessInstanceAction } from '../../store/actions/process-instance-actions';
import { useTheme } from '@fluentui/react';
import { ReportProblemModal } from '../../components/process/report-problem-modal';
import { addCommentAction, getCommentsByProcessInstanceIdAction } from '../../store/actions/comment-actions';
import AppPMContextWrapper from './app-PM-context-wrapper';
import { useNavigate, useParams } from 'react-router';

const ProcessModeler = (props: PropsFromRedux) => {
  const {
    ProcessInstanceReducer: { selectedInstance },
    UserCommentReducer: { comments },
    selectProcessInstanceByIdAction,
    updateProcessInstanceAction,
    addCommentAction,
    getCommentsByProcessInstanceIdAction,
    getProcessInstanceAction,
  } = props;

  const theme = useTheme();
  const [processinstanceId, setProcessinstanceId] = useState<number>();
  const [isApprovedByModalVisible, { setTrue: showApprovalModal, setFalse: dismissApprovalModal }] = useBoolean(false);
  useBoolean(false);
  const [isApproverListModalVisible, { setTrue: showApproverListModal, setFalse: dismissApproverListModal }] =
    useBoolean(false);
  const [instanceSelected, setInstanceSelected] = useState<ProcessInstance>();
  const [problemModalVisible, { setTrue: showProblemModal, setFalse: closeProblemModal }] = useBoolean(false);
  const [hasUrgentProblems, setHasUrgentProblems] = useState<boolean>(false);
  const [problemModalReadOnly, setProblemModalReadOnly] = useState<boolean>(false);
  const [hasSeenProblem, setHasSeenProblem] = useState<boolean>(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params && params.id) {
      getProcessInstanceAction(parseInt(params.id));
      setProcessinstanceId(parseInt(params.id));
      selectProcessInstanceByIdAction(parseInt(params.id));
    }
  }, [params.id]);

  useEffect(() => {
    if (selectedInstance) {
      const selected = selectedInstance;

      if (selected.isNew) {
        updateProcessInstanceAction({
          id: selected?.id,
          isNew: false,
        });
      }

      if (selectedInstance?.resourceUri) {
        getCommentsByProcessInstanceIdAction(selectedInstance?.id);
      }
    }
  }, [selectedInstance]);

  useEffect(() => {
    if (selectedInstance && comments) {
      const urgentComments = comments.some((item) => {
        return item.isUrgent;
      });

      if (urgentComments) {
        setHasUrgentProblems(true);

        if (!hasSeenProblem && selectedInstance.relevantUser) {
          openProblemModal(true);
          setHasSeenProblem(true);
        }
      } else {
        setHasUrgentProblems(false);
      }
    }
  }, [comments]);

  const openProblemModal = (readonly: boolean) => {
    showProblemModal();
    setProblemModalReadOnly(readonly);
  };

  const onApproveModel = (instance: ProcessInstance) => {
    setInstanceSelected(instance);
    showApprovalModal();
  };

  const handleCancel = () => {
    dismissApprovalModal();
  };

  const onDeleteModel = () => {
    if (selectedInstance) {
      props.deleteProcessInstanceAction(selectedInstance, () => {
        if (selectedInstance.createdFromInstance) {
          const redirect = `/process-management/processes/${selectedInstance.sourceProcess.id}/applications`;
          navigate(redirect);
        } else {
          const redirect = `/process-management/processes/`;
          navigate(redirect);
        }
      });
    }
  };

  return (
    <>
      {processinstanceId && (
        <>
          <AppPMContextWrapper
            theme={theme}
            processinstanceId={processinstanceId}
            hasUrgentProblems={hasUrgentProblems}
            onApproveModel={onApproveModel}
            openProblemModal={openProblemModal}
            onDeleteModel={onDeleteModel}
            showApproverListModal={showApproverListModal}
          />

          {instanceSelected && (
            <ApprovedByModal
              selectedInstance={instanceSelected}
              isApprovedByModalVisible={isApprovedByModalVisible}
              handleApprovedByCancel={handleCancel}
            />
          )}
          {selectedInstance && (
            <ReportProblemModal
              readonly={problemModalReadOnly}
              instance={selectedInstance}
              visible={problemModalVisible}
              closeProblemModal={closeProblemModal}
              addProblem={addCommentAction}
            ></ReportProblemModal>
          )}

          <Modal
            title={__('approvers')}
            isModalOpen={isApproverListModalVisible}
            hideFooter={true}
            onSave={dismissApproverListModal}
            onCancel={dismissApproverListModal}
          >
            <ApproversList />
          </Modal>
        </>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapStateToProps = ({ ModelerReducer, ProcessInstanceReducer, UserCommentReducer }: RootState) => ({
  ModelerReducer,
  ProcessInstanceReducer,
  UserCommentReducer,
});

const connector = connect(mapStateToProps, {
  getProcessInstanceAction,
  selectProcessInstanceByIdAction,
  deleteProcessInstanceAction,
  updateProcessInstanceAction,
  addCommentAction,
  getCommentsByProcessInstanceIdAction,
});

export default connector(ProcessModeler);
