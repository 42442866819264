import React, { useEffect, useState } from 'react';
import { __ } from 'modeling-tool';
import { DirectionalHint, IColumn, IconButton, Selection, TooltipHost, Link } from '@fluentui/react';
import ResourcesButtons from '../resources-buttons/resources-buttons';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../config/store';
import {
  fetchResourcesAction,
  clearResourceAction,
  selectResourceByIdAction,
  deleteResourceAction,
} from '../../../store/actions/resource-actions';
import { Process, Resource, ResourceGroup } from '../../../ts/interfaces';
import { getDateFormatted } from '../../../config/utils';
import { File, Person } from '@microsoft/mgt-react';
import { PersonDisplayName } from '../../../components';
import { getStyles } from './resource-list-styles';
import { ResourceForm } from '../resource-form';
import { checkPermission } from '../../../config/permission-utils';
import { BasicList, GLOBALENUMS, PeopleWithPopover } from 'modeling-tool';
import { useNavigate } from 'react-router';
import { updateColumnsWithLanguage } from '../../../utils';

interface IResourcesList extends PropsFromRedux {
  isFilter: boolean;
  setIsFilter(arg: boolean): void;
  queryResource?: string;
}

const ResourcesList = (props: IResourcesList) => {
  const {
    ResourceReducer: { selectedResource, resources, filterResources },
    UserReducer: { language, permissions },
    isFilter,
    queryResource,
    clearResourceAction,
    deleteResourceAction,
    fetchResourcesAction,
    selectResourceByIdAction,
    setIsFilter,
  } = props;

  const styles = getStyles();

  const navigate = useNavigate();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedResources, setSelectedResources] = useState<Resource[]>([]);
  const [enableDelete, setEnableDelete] = useState<boolean>(false);
  const [orderByColumns, setOrderByColumns] = useState<string>();

  useEffect(() => {
    clearResourceAction();
  }, []);

  useEffect(() => {
    if (orderByColumns) {
      fetchResourcesAction(false, orderByColumns);
    }
  }, [orderByColumns]);

  const onResourceEdit = (resource: Resource) => {
    selectResourceByIdAction(resource.id);
    setModalVisible(true);
  };

  const _rowsSelected: Selection = new Selection({
    onSelectionChanged: () => _getSelectionDetails(),
  });

  const onClickLink = (link?: string) => {
    window.open(link?.toString(), '_blank')?.focus();
  };

  const _getSelectionDetails = () => {
    const selectionCount = _rowsSelected.getSelectedCount();
    if (selectionCount > 0) {
      setSelectedResources(_rowsSelected.getSelection() as Resource[]);

      setEnableDelete(true);
    } else {
      setSelectedResources([]);
      setEnableDelete(false);
    }
  };

  const onDeleteResources = () => {
    for (const resource of selectedResources) {
      deleteResourceAction(resource);
    }
    fetchResourcesAction();
    setEnableDelete(false);
  };

  const columns: IColumn[] = [
    {
      key: 'name',
      name: __('name'),
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ name }: Resource) => <div>{name}</div>,
    },
    {
      name: __('group'),
      fieldName: 'group',
      key: 'parent_group_name',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ parentGroup }: Resource) =>
        parentGroup?.length ? (
          parentGroup.map((resourcegroup: ResourceGroup, i: number) => (
            <span key={`${resourcegroup.id}-resourcegroup-item`}>{(i ? ', ' : '') + resourcegroup.name}</span>
          ))
        ) : (
          <IconButton iconProps={{ iconName: 'Warning' }} className={styles.blinkHint} />
        ),
    },
    {
      name: __('creator'),
      fieldName: 'creator',
      key: 'creator',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      onRender: ({ createdBy }: Resource) =>
        createdBy && (
          <Person userId={createdBy.username}>
            <PersonDisplayName />
          </Person>
        ),
    },
    {
      name: __('responsible'),
      fieldName: 'responsible',
      key: 'responsible',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      onRender: ({ responsible, id }: Resource) =>
        responsible &&
        responsible.employees && <PeopleWithPopover employees={responsible.employees} id={id} field={'responsible'} />,
    },
    {
      name: __('date'),
      fieldName: 'date',
      key: 'date_created',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ dateCreated }: Resource) => <div>{dateCreated && getDateFormatted(dateCreated, 'lll')}</div>,
    },
    {
      name: __('process'),
      fieldName: 'process',
      key: 'process',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      onRender: ({ processLink }: Resource) =>
        processLink &&
        processLink.map((process: Process, i: number) => {
          return process.processMapType === GLOBALENUMS.PROCESSMAPNODETYPE.PROCESS ? (
            <Link
              key={process.id}
              onClick={() => {
                navigate(`/process-management/processes/${process.id}`);
              }}
            >
              {(i ? ', ' : '') + process.processName}
            </Link>
          ) : (
            (i ? ', ' : '') + process.processName
          );
        }),
    },
    {
      name: __('document'),
      fieldName: 'document',
      key: 'document',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      onRender: ({ attachments }: Resource) =>
        attachments &&
        attachments
          .split('|')
          .map((f: string) => f.split('::'))
          .map((fileData: string[]) => (
            <File key={fileData[2]} siteId={fileData[0]} driveId={fileData[1]} itemId={fileData[2]}></File>
          )),
    },
    {
      name: __('link'),
      fieldName: 'link',
      key: 'link_text',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      sortableAriaLabel: 'sortable',
      showSortIconWhenUnsorted: true,
      onRender: ({ linkText, linkUrl }: Resource) => (
        <Link variant="medium" onClick={() => onClickLink(linkUrl)}>
          {linkText ? linkText : linkUrl}
        </Link>
      ),
    },
    {
      name: __(''),
      fieldName: 'editGroup',
      key: 'editGroup',
      minWidth: 20,
      maxWidth: 20,
      isResizable: true,
      onRender: (resource: Resource) => renderEditButton(resource),
    },
  ];
  const [tableColumns, setTableColumns] = useState<IColumn[]>(columns);

  //This useEffect is used to update the columns header when the language is changed
  useEffect(() => {
    const updatedColumns = updateColumnsWithLanguage(columns);
    setTableColumns(updatedColumns);
  }, [language]);

  const renderEditButton = (resource: Resource) => {
    if (checkPermission(permissions, GLOBALENUMS.PERMISSIONS.change_resource)) {
      return (
        <TooltipHost content={__('edit resource')} directionalHint={DirectionalHint.bottomCenter}>
          <IconButton onClick={() => onResourceEdit(resource)} iconProps={{ iconName: 'Edit' }}></IconButton>
        </TooltipHost>
      );
    }
  };

  return (
    <>
      <BasicList
        setKey={GLOBALENUMS.BASICLISTTYPES.RESOURCES_LIST}
        columns={tableColumns}
        setTableColumns={setTableColumns}
        setOrderByColumns={setOrderByColumns}
        items={isFilter ? filterResources : resources}
        selection={_rowsSelected}
        className={styles.resourcesListContainer}
        rightHeaderButtons={
          <ResourcesButtons
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            queryResource={queryResource}
            enableDelete={enableDelete}
            onDelete={onDeleteResources}
          />
        }
      ></BasicList>

      {selectedResource && (
        <ResourceForm
          setModalVisible={setModalVisible}
          isModalVisible={isModalVisible}
          selectedResource={selectedResource}
          queryResource={queryResource}
        />
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
const mapsStateToProps = ({ ResourceReducer, UserReducer }: RootState) => ({ ResourceReducer, UserReducer });
const connector = connect(mapsStateToProps, {
  clearResourceAction,
  deleteResourceAction,
  fetchResourcesAction,
  selectResourceByIdAction,
});
export default connector(ResourcesList);
