import { GetApiScope, GetAppId, GetRedirectUri } from './config';

export const msalConfig = {
  auth: {
    clientId: GetAppId(),
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: GetRedirectUri(),
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const apiRequest = {
  scopes: [GetApiScope()],
};

export const basicScopes = ['User.Read', 'User.ReadBasic.All'];
export const apiAuthority = 'https://login.microsoftonline.com/organizations';

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};
